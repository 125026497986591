import { VehicleData } from '../../models/vehicle-data';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { StringUtil } from '../../components/utils/string-util/string-util';
import { VehicleListServiceAem } from '../vehicle-list-service-aem/vehicle-list-service-aem';

export class ModelYearVehicleService {
    private readonly make: string;
    private readonly model: string;
    private readonly year: number;

    appConfig = new AppConfigurationService();

    constructor(model: string, year: number, make?: string) {
        this.model = model;
        this.year = year;
        this.make = !make
            ? (this.make =
                  this.appConfig.brand === 'demo'
                      ? 'Ford'
                      : StringUtil.capitalizeFirstLetter(this.appConfig.brand))
            : make;
    }

    async request(): Promise<VehicleData> {
        const { currentRegionCode, currentLanguageRegionCode } = this.appConfig;

        const service = new VehicleListServiceAem();
        return service
            .getVehicles(
                this.make,
                currentRegionCode,
                currentLanguageRegionCode
            )
            .then(
                async (): Promise<VehicleData> => {
                    const serviceUrl = `${DSL_BASE_URL +
                        DSL_API_PATH.VEHICLE_IMAGE}?year=${this.year}&make=${
                        this.make
                    }&model=${this.model}`;

                    const imageAvailable = false;
                    return {
                        recalls: {},
                        ownersManual: {
                            link: `https://owner.ford.com/tools/account/how-tos/owner-manuals.html?year=${this.year}&make=${this.make}&model=${this.model}`,
                        },
                        modelYear: this.year,
                        modelName: this.model,
                        vehicleMake: this.make,
                        imageSrc: imageAvailable ? serviceUrl : undefined,
                    };
                }
            );
    }

    async imageAvailable(serviceUrl: string): Promise<boolean> {
        try {
            const response = HttpService.get(serviceUrl, true);
            await response;
        } catch (error) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }
}
