import React from 'react';
import playButton from '../../../../assets/play.png';
import { useHandoverModel } from '../hooks/use-vehicle-handover-model';
import { HandoverDetailsPage } from '../handover-controller';
import { useHistory } from 'react-router-dom';
interface Props {
    otherFeatures: any;
    selectedCategoryList: any;
    selectedCategory: string;
    selectedFeature: string;
}
export const AdditionalFeatures = (props: Props) => {
    const { otherFeatures } = props;
    const i18n = useHandoverModel();
    const categoryName = String(i18n.get('otherCategory'));
    const history = useHistory();
    const url: any = HandoverDetailsPage().split(':');

    const handleClick: any = (feature: string) => (event: any) => {
        event.preventDefault();
        window.scrollTo(0, 0);
        history.push(url[0] + encodeURIComponent(feature));
    };

    return (
        <div className="additionalContainer">
            <div className="handovers-category">
                <h2 className="related-feature-eyf">
                    {categoryName
                        .toString()
                        .replace(/%%category%%/, props.selectedCategory)}
                </h2>

                {otherFeatures.length > 0 &&
                    otherFeatures.map((feature: any, index: number) => {
                        let imageSrc;

                        if (feature.handoverImage) {
                            imageSrc = feature.handoverImage.startsWith('http')
                                ? feature.handoverImage
                                : process.env.REACT_APP_AEM_BASE_URL +
                                  feature.handoverImage;
                        } else {
                            imageSrc =
                                'https://img.youtube.com/vi/' +
                                feature.ytVideoID +
                                '/mqdefault.jpg';
                        }

                        return (
                            <div
                                className="related-feature"
                                key={feature.headline}
                                data-idx={index}
                            >
                                <div className="video-thumbnail">
                                    <a onClick={handleClick(feature.key)}>
                                        <img
                                            alt={feature.headline + '-image'}
                                            src={imageSrc}
                                        />
                                        {feature.ytVideoID ? (
                                            <div className="video-thumbnail-icon">
                                                <img
                                                    className="playButton"
                                                    src={playButton}
                                                    alt="video-play-icon"
                                                />
                                            </div>
                                        ) : null}
                                    </a>
                                </div>
                                <div className="feature-title">
                                    {feature.headline}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default AdditionalFeatures;
