import React, { useContext, useEffect, useRef, useState } from 'react';
import './notification-banner.scss';

import { useAnalytics } from '../../../hooks/use-analytics';
import Warning from '../../../assets/warning-icon.svg';
import InfoIcon from '../../../assets/info-icon-filled.svg';
import InfoIconLincoln from '../../../assets/info-icon-filled-lincoln.svg';
import WarningLincoln from '../../../assets/warning-icon-notification-lincoln.svg';
import WarningIconRed from '../../../assets/warning-icon-red.svg';
import { NotificationBannerProps } from './hooks/use-notification-banner';
import ServerContext from '../../../contexts/serverContext';
const NOTIFICATION_TYPE = {
    DEFAULT: 'default',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
};

function NotificationBanner(props: NotificationBannerProps) {
    const {
        hide,
        type,
        bannerDismissible,
        dismissAriaLabel,
        bannerText,
        showChevron,
        shortCode,
        eventName,
        contentId,
    } = props;
    const [isVisible, setIsVisible] = useState(true);
    const [fireEvents] = useAnalytics();
    const ref = useRef<HTMLDivElement | null>(null);
    const chevron = '<span class="chevron-icon"></span>';
    const make = useContext(ServerContext).brand;

    const handleClick = () => {
        setIsVisible(false);
    };

    const imgConst = `<img class="forward-icon" src=${
        type === NOTIFICATION_TYPE.WARNING
            ? 'icons/forward.svg'
            : 'icons/forward-white.svg'
    } alt=""/>`;

    const bannerContent = () => {
        if (make === 'lincoln' && showChevron) {
            return bannerText + imgConst;
        } else {
            if (showChevron) {
                return bannerText + chevron;
            } else {
                return bannerText;
            }
        }
    };

    const imageurl = () => {
        if (type === NOTIFICATION_TYPE.ERROR) {
            return WarningIconRed;
        } else if (type === NOTIFICATION_TYPE.WARNING) {
            if (make === 'lincoln') {
                return WarningLincoln;
            } else {
                return Warning;
            }
        } else if (type === NOTIFICATION_TYPE.DEFAULT) {
            if (make === 'lincoln') {
                return InfoIconLincoln;
            } else {
                return InfoIcon;
            }
        } else {
            return '';
        }
    };

    const fireAnalytics = () => {
        if (eventName && shortCode) {
            fireEvents(eventName, undefined, shortCode, false);
        }
    };

    const getColorBarStyle = () => {
        if (type === NOTIFICATION_TYPE.WARNING) {
            return '#DA7029';
        } else if (type === NOTIFICATION_TYPE.ERROR) {
            return '#D62D0A';
        } else {
            return '#00095B';
        }
    };
    useEffect(() => {
        if (ref.current && ref.current?.getElementsByTagName('a').length > 0) {
            ref.current
                .getElementsByTagName('a')[0]
                .addEventListener('click', fireAnalytics);
        }
        return () => {
            if (
                ref.current &&
                ref.current?.getElementsByTagName('a').length > 0
            ) {
                ref.current
                    .getElementsByTagName('a')[0]
                    .removeEventListener('click', fireAnalytics);
            }
        };
    }, []);
    return !hide ? (
        <div className="parent">
            {isVisible && (
                <div className="notification-banner-container">
                    <div
                        className={`notification-banner ${type}`}
                        role="alert"
                        aria-live="polite"
                    >
                        <div className="notification-wrapper">
                            <img
                                className={`${type}`}
                                src={imageurl()}
                                alt=""
                            />
                            <div
                                className="notification-content"
                                ref={ref}
                                id={contentId || type}
                                dangerouslySetInnerHTML={{
                                    __html: String(bannerContent()),
                                }}
                            />
                        </div>
                        {bannerDismissible && (
                            <button
                                className="closeButton"
                                onClick={() => {
                                    handleClick();
                                }}
                                aria-label={dismissAriaLabel}
                            >
                                <img
                                    className="close-icon"
                                    src={
                                        type === NOTIFICATION_TYPE.WARNING
                                            ? make === 'lincoln'
                                                ? 'icons/close-notification-banner.svg'
                                                : 'icons/f-close.svg'
                                            : make === 'lincoln'
                                            ? 'icons/close-white.svg'
                                            : 'icons/f-close.svg'
                                    }
                                    alt=""
                                />
                            </button>
                        )}
                    </div>
                    <div
                        className="color-bar"
                        style={{
                            backgroundColor: `${getColorBarStyle()}`,
                        }}
                    ></div>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
}

export default NotificationBanner;
