import { GetChecklistFeatureservice } from '../../../../services/handover/get-checklist-feature-service';
import handoverService from '../../../../services/handover/handover-service';
import ServerSideService from '../../../../services/server-side-service/server-side-service';

export class UseChecklistFeatures {
    private selectedFeaturesSet = new Set<string>();

    public async useChecklistService() {
        const selectedVin = ServerSideService.isClientSide()
            ? sessionStorage.getItem('selectedVin')
            : '';

        try {
            await new GetChecklistFeatureservice()
                .getCreateChecklistservice(selectedVin)
                .then((response: any) => {
                    const customerNotesObj = {
                        vin: selectedVin,
                        customerNotesValue: response.customerNotes,
                    };

                    const customerDataAlready =
                        handoverService.getDataFromSessionStorage(
                            'customerNotes'
                        ) || [];

                    const isDuplicate = customerDataAlready.some(
                        (customer: any) => customer.vin === customerNotesObj.vin
                    );

                    if (!isDuplicate) {
                        customerDataAlready.push(customerNotesObj);
                    }

                    handoverService.setDataToSessionStorage(
                        'customerNotes',
                        customerDataAlready
                    );

                    if (response.checkedFeatures) {
                        response.checkedFeatures.forEach((item: any) => {
                            this.selectedFeaturesSet.add(item.key);
                        });
                    } else {
                        this.selectedFeaturesSet.clear();
                    }
                });
        } catch (e) {
            const res: any = e;
            this.selectedFeaturesSet = res;
        }
        return this.selectedFeaturesSet;
    }
}
const useChecklistFeatures = new UseChecklistFeatures();
export default useChecklistFeatures;
