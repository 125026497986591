import { BRAND, DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

interface NvoApiResponse {
    guideUrl?: string;
    error?: any;
}

export const modelMap = new Map<string, string>();
modelMap.set('f-250', 'SuperDuty');
modelMap.set('f-350', 'SuperDuty');
modelMap.set('f-450', 'SuperDuty');
modelMap.set('fusion hybrid/phev', 'Fusion');
modelMap.set('fusion hybrid/energi', 'Fusion');
modelMap.set('c-max hybrid', 'C-MAX');
modelMap.set('c-max hybrid/energi', 'C-MAX');
modelMap.set('focus rs', 'Focus');
modelMap.set('focus electric', 'Focus');
modelMap.set('mustang mach-e', 'Mache');
modelMap.set('ecosport', 'EcoSport');
modelMap.set('mkz hybrid', 'MKZ');

export default class NvoService {
    async request(vin: string | null): Promise<NvoApiResponse | undefined> {
        const {
            currentLanguageCode,
            currentRegionCode,
            brand,
        } = new AppConfigurationService();
        const params = {
            brandCode: (BRAND as any)[brand].SHORT_NAME,
            lang: `${currentLanguageCode}_${currentRegionCode}`,
            locale: `en_${currentRegionCode}`,
        };
        const nvoUrl = DSL_BASE_URL + DSL_API_PATH.NVO;
        const response = HttpService.get<NvoApiResponse>(nvoUrl, true, {
            params,
            headers: {
                vin: vin ?? '',
            },
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<NvoApiResponse>
    ): NvoApiResponse | undefined {
        if (response.data.guideUrl) {
            return {
                guideUrl: response.data.guideUrl,
            };
        }
    }
}
