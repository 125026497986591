import { useState, useContext, useEffect } from 'react';
import ServerSideService from '../services/server-side-service/server-side-service';
import ServerContext from '../contexts/serverContext';

export interface ServerData<T> {
    data?: T;
}

export function useServerData<T>(
    key: string,
    func: () => Promise<T>,
    deps?: React.DependencyList
): [ServerData<T> | null, Error | null] {
    const context = useContext(ServerContext);
    const [data, setData] = useState<ServerData<T> | null>(
        context && context?.data[key] ? context.data[key] : null
    );
    const [error, setError] = useState<Error | null>(null);

    const loadData = async () => {
        if (typeof context.data[key] !== 'undefined') {
            if (ServerSideService.isClientSide()) {
                setData(context.data[key]);
                delete context.data[key];
            }
        } else {
            const promise = func();
            if (ServerSideService.isServerSide()) {
                context.promises.push(promise);
            }
            promise.then(
                (response: T) => {
                    if (ServerSideService.isServerSide()) {
                        context.data[key] = { data: response };
                    } else setData({ data: response });
                },
                reason => {
                    setError(new Error(`Request Failed. Reason: ${reason}`));
                }
            );
        }
    };

    if (ServerSideService.isServerSide()) {
        try {
            loadData();
        } catch (error) {
            setError(error as Error | null);
        }
    }

    useEffect(() => {
        try {
            loadData();
        } catch (error) {
            setError(error as Error | null);
        }
    }, deps || []);

    return [data, error];
}
