import React from 'react';
import './fm-dropdown.scss';
import { FMDropdownProps } from '../../types/fm-dropdown.types';

const FMDropdown = (props: FMDropdownProps) => {
    const inputClasses = () => {
        const classes: string[] = ['fm-dropdown'];
        if (props.state) {
            classes.push(`fm-dropdown--${props.state}`);
        }
        if (props.customClass) {
            classes.push(props.customClass);
        }
        return classes.join(' ').trim();
    };
    const supportTextClasses = () => {
        const classes: string[] = ['fm-dropdown-support-text'];
        if (props.state) {
            classes.push(`fm-dropdown-support-text--${props.state}`);
        }
        return classes.join(' ').trim();
    };
    function headerClasses() {
        return ['fm-dropdown-header', `${props.disabled ? '--disabled' : ''}`]
            .join(' ')
            .trim();
    }
    return (
        <div className={'fm-dropdown-container'}>
            {props.header && (
                <label htmlFor={props.name} className={headerClasses()}>
                    {props.header}
                </label>
            )}
            <select
                className={inputClasses()}
                {...(props.disabled ? { disabled: true } : {})}
                aria-label={props.ariaLabel}
                data-testid={props.dataTestId}
                onChange={props.onChange}
            >
                <option>{''}</option>
                {props.options?.map((option, index) => (
                    <option
                        value={option.value || option}
                        key={`${option.value || option}-${index}`}
                    >
                        {option.label || option}
                    </option>
                ))}
            </select>
            {props?.footer && (
                <div className={supportTextClasses()}>{props.footer}</div>
            )}
        </div>
    );
};

export default FMDropdown;
