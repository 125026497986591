import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import './dealer-info-tile.scss';
import { PrimaryButton } from '../../../../../common';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import { FormatAddress } from '../../../common/osb-common-components/osb-common-components';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { getObjectFromAEMJson } from '../../../osb-utils';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';
import { useOSBStep } from '../../../../../../hooks/owners-osb';
import DealerCapabilities from './dealer-capabilities';

interface Props {
    index: number;
    dealer: DealerProfileInfo;
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    onSelectDealer: (dealer: DealerProfileInfo, index: number) => void;
    onSelectDealerDetails: (dealer: DealerProfileInfo, index: number) => void;
}

export default function DealerInfoTile(props: Props) {
    const {
        index,
        dealer,
        dealerStepContent,
        dealerStepMoreContent,
        onSelectDealer,
        onSelectDealerDetails,
    } = props;
    const { osbStep } = useOSBStep();
    const distanceUnit = getObjectFromAEMJson(
        DEALER_STEP_KEYS.DISTANCE_UNIT,
        dealerStepMoreContent
    ).toLowerCase();
    const distance =
        distanceUnit === 'miles'
            ? OsbUtilService.getDistanceInMiles(Number(dealer.distance))
            : OsbUtilService.getDistanceInKm(Number(dealer.distance));

    return (
        <div
            className={`dealer-info-box-container ${
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.ENABLE_DEALER_CAPABILITIES,
                    dealerStepMoreContent
                ) === true
                    ? 'with-dealer-capability'
                    : 'without-dealer-capability'
            }`}
        >
            <div className="dealer-info-box">
                <div className="dealer-info">
                    <div className="dealer-name">
                        {index + 1}.&nbsp;&nbsp;{dealer?.dealerName}
                    </div>
                    <div className="dealer-address">
                        <FormatAddress
                            addressData={dealer}
                            addressOrder={osbStep.dealerAddressOrder}
                            isDisplaySingleRow={true}
                        />
                    </div>

                    <div className="dealer-distance-label">
                        {dealer?.distance > 0 && (
                            <>
                                <span className="distance-label-text">
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.DISTANCE_TITLE,
                                        dealerStepContent
                                    )}
                                </span>
                                <span className="distance-value">
                                    {distance}{' '}
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_DISTANCE_LABEL,
                                        dealerStepMoreContent
                                    )}
                                </span>
                            </>
                        )}
                    </div>

                    {dealer?.additionalInfo?.earliestAvailableTimeslot && (
                        <div className="dealer-earliest-available-slot">
                            <span className="earliest-slot-label-text">
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT,
                                    props.dealerStepMoreContent
                                )}
                            </span>
                            <span className="dealer-earliest-slot-value">
                                {
                                    dealer.additionalInfo
                                        ?.earliestAvailableTimeslot
                                }
                            </span>
                        </div>
                    )}
                    {getObjectFromAEMJson(
                        DEALER_STEP_KEYS.ENABLE_DEALER_CAPABILITIES,
                        dealerStepMoreContent
                    ) === true && (
                        <DealerCapabilities
                            dealer={dealer}
                            dealerStepMoreContent={dealerStepMoreContent}
                        />
                    )}
                </div>
                <div className="dealer-actions">
                    <PrimaryButton
                        role="link"
                        color={'dark'}
                        fill={'fill'}
                        chevron={false}
                        aria-label="select-dealer-cta"
                        aria-labelledby="select dealer cta"
                        dataTestId={`SelectDealer-${dealer.dealerCode}`}
                        className="select-dealer-button"
                        onClick={() => onSelectDealer(dealer, index)}
                        data-testid="dealer-info-tile"
                    >
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.LIGHT_JOURNEY_SELECT_DEALER_BUTTON,
                            dealerStepMoreContent
                        )}
                    </PrimaryButton>
                    <div className="details-link">
                        <OSBSecondaryButton
                            type="unfilled"
                            direction="right"
                            text={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_MOREINFO_BUTTON,
                                props.dealerStepMoreContent
                            )}
                            onClickHandler={() =>
                                onSelectDealerDetails(dealer, index)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
