import AppConfigurationService from '../../app-configuration-service/app-configuration-service';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { ServiceData } from '../../../models/osb-model/osb-dealerservice-info';
import DateFnsLocaleSupportService from '../dateFns-locale-support-service/dateFns-locale-support-service';
import {
    getObjectFromAEMJson,
    isThisVIN,
} from '../../../components/sections/owners-osb/osb-utils';
import { ServiceCard } from '../../../models/osb-model/osb-service-step';
import { ContentElements } from '../../../models/osb-model/osb-content-details';

const dateFns = require('date-fns');
const getLocale = (locale: string) => require(`date-fns/locale/${locale}`);

export default class OsbUtilService {
    private static appConfigService: AppConfigurationService = new AppConfigurationService();

    static getOsbAppIdRequestHeaders() {
        if (this.getAppConfiguration().environment === 'prod') {
            return {
                'Application-Id': 'B42C36C2-A7E1-4C12-8852-3C63761739D2',
            };
        } else {
            return {
                'Application-Id': '550ABAED-7780-4F8E-8EC0-CD6470DCEF15',
            };
        }
    }

    static getAppConfiguration() {
        return this.appConfigService.getAppConfiguration();
    }

    static getGeoCountryCode(geoCountryCode: any) {
        return OsbUtilService.getAppConfiguration().countryCode === 'mothersite'
            ? 'gb'
            : geoCountryCode;
    }

    static getProxyAgent() {
        const proxyAgent = new HttpsProxyAgent({
            host: 'internet.ford.com',
            port: 83,
        });
        return proxyAgent;
    }

    //constructDisplayDate - locale issue
    static constructDisplayDate(
        sessionDate: any,
        format: string,
        locale?: string
    ) {
        if (sessionDate) {
            const localeReceived =
                new DateFnsLocaleSupportService().getDateFnsLocale(locale) ||
                'en-GB';
            const formatReceived =
                format !== '' && format !== 'undefined' && format !== 'null'
                    ? format
                    : 'dd MMMM yyyy';
            const date = sessionDate.split('-');
            const localeSelected = getLocale(localeReceived);
            const selectedDate = dateFns.format(
                new Date(date[0], date[1] - 1, date[2]),
                formatReceived,
                {
                    locale: localeSelected?.default
                        ? localeSelected.default
                        : localeSelected,
                }
            );
            return selectedDate;
        }

        return '';
    }

    static getDayName(date: string, localeAsString: string) {
        if (date) {
            const d = new Date(date);
            return d.toLocaleString(localeAsString, {
                weekday: 'long',
            });
        } else {
            return '';
        }
    }

    static getTimeBasedOnAuthoring(
        time: string, // time always comes 24 hours format
        isHour12Format: boolean,
        amLabel: string,
        pmLabel: string,
        suffixLabel: string // like 'O' Clock'
    ) {
        if (isHour12Format) {
            const timeArray = time.split(':');
            let hours = parseInt(timeArray[0]);
            const minutes = parseInt(timeArray[1]);
            const ampm = hours >= 12 ? pmLabel : amLabel;
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            const minuteStr = minutes < 10 ? '0' + minutes : minutes;
            return hours + ':' + minuteStr + ' ' + ampm;
        } else {
            return time + ' ' + suffixLabel;
        }
    }

    static getUTCDate = (date: string | Date) => {
        const d = new Date(date ? date : new Date());
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    };

    static stripHtml = (html: string) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };
    static isAnchorTagPresentInString = (text: string) => {
        return /(<a[^>]+?>|<\/a>)/gim.test(text);
    };
    static formatDistance(distance: number) {
        let formattedDistance;
        if (distance) {
            if (distance >= 1) {
                formattedDistance = distance.toFixed(2);
            } else {
                formattedDistance = Math.round(distance * 10) / 10;
            }
            return formattedDistance;
        }
    }

    static getDistanceInMiles(distance: number) {
        return this.formatDistance(distance * 0.00062137119);
    }

    static getDistanceInKm(distance: number) {
        return this.formatDistance(distance * 0.001);
    }

    static isEmptyObject = (obj: any) => {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return (
            obj === null ||
            obj === undefined ||
            JSON.stringify(obj) === JSON.stringify({})
        );
    };
    static addPrecision = (price: string, precision: number) => {
        return Number.parseFloat(price).toFixed(precision);
    };
    static addSeparators = (price: string, separator: string) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    };
    static addDecimalSeparator = (price: string, separator: string) => {
        const lastIndex = price.lastIndexOf('.');
        if (lastIndex !== -1) {
            return (
                price.slice(0, lastIndex) +
                separator +
                price.slice(lastIndex + separator.length)
            );
        }
        return price.toString().replace('.', separator);
    };
    static spliceWithColon = (hours: string) => {
        if (hours.length > 0 && hours.split(':').length - 1 <= 0) {
            hours = hours.slice(0, 2) + ':' + hours.slice(2);
        }
        if (hours.lastIndexOf('-') !== -1 && hours.split(':').length - 1 <= 1) {
            hours = hours.slice(0, 10) + ':' + hours.slice(10);
        }
        return hours;
    };
    static hasDiscount = (priceAfterDiscount: string, price: string) => {
        return Number(priceAfterDiscount) < Number(price);
    };

    static getServiceIDs = (selectedServices: ServiceData[]) => {
        let serviceCodeArray = [];
        try {
            serviceCodeArray = selectedServices.reduce(
                (acc: any, service: { serviceCode: string; type: string }) => [
                    ...acc,
                    service.type + ':' + service.serviceCode,
                ],
                []
            );
        } catch (e) {
            console.log('Invalid service json');
        }
        return serviceCodeArray;
    };

    static frameServiceData = (
        serviceType: string,
        serviceOption: string,
        service: any
    ) => {
        const serviceData: ServiceData = {
            id: service.id,
            serviceType: serviceType,
            serviceName: service.name,
            serviceCode: service.serviceId.toString(),
            priceAfterDiscount: service.priceAfterDiscount,
            price: service.price,
            type: service.type,
            subType: service.subType
                ? service.subType
                : service.additionalInfo.subType,
            serviceOption: serviceOption,
            serviceUniqueId: service.serviceUniqueId,
            brightCoveAccountId:
                service?.additionalInfo?.brightCoveAccountId || '',
            brightCoveVideoId: service?.additionalInfo?.brightCoveVideoId || '',
            brightCovePlayerId:
                service?.additionalInfo?.brightCovePlayerId || '',
            brightCoveVideoName:
                service?.additionalInfo?.brightCoveVideoName || '',
            convenienceOptionTypes: service?.convenienceOptionTypes || '',
        };
        return serviceData;
    };

    static cardslist = (cardkey: string, results: ContentElements[]) => {
        const serviceCardsOrder = getObjectFromAEMJson(cardkey, results);
        let serviceCardOrderArr: string[] = [];
        if (serviceCardsOrder) {
            serviceCardOrderArr = serviceCardsOrder?.split(',');
        }
        return serviceCardOrderArr;
    };

    static frameCardsList = (
        serviceCardOrderArray: string[],
        results: ContentElements[]
    ) => {
        //order array is used when click back button on specific service page
        const cardList: ServiceCard[] = [];
        serviceCardOrderArray.forEach(serviceName => {
            cardList.push({
                cardKey: serviceName,
                cardTitle: getObjectFromAEMJson(
                    serviceName + '_card_title',
                    results
                ),
                cardDesc: getObjectFromAEMJson(
                    serviceName + '_card_desc',
                    results
                ),
                ctaLabel: getObjectFromAEMJson('selectCard', results),
            });
        });
        return cardList;
    };

    static sortServicesByRank = (serviceDataArray: ServiceData[]) => {
        const mainServiceArray: ServiceData[] = [];
        const motServiceArray: ServiceData[] = [];
        const repairServiceArray: ServiceData[] = [];
        const noTouchServiceArray: ServiceData[] = [];
        const additionalServiceArray: ServiceData[] = [];
        const defaultServiceArray: ServiceData[] = [];
        const sortedServiceArray: ServiceData[] = [];

        serviceDataArray.forEach((service: ServiceData) => {
            switch (service.serviceType) {
                case 'mainService':
                    mainServiceArray.push(service);
                    break;
                case 'MOTService':
                    motServiceArray.push(service);
                    break;
                case 'repairService':
                    repairServiceArray.push(service);
                    break;
                case 'noTouchService':
                    noTouchServiceArray.push(service);
                    break;
                case 'additionalService':
                    additionalServiceArray.push(service);
                    break;
                default:
                    defaultServiceArray.push(service);
                    break;
            }
        });
        if (mainServiceArray.length > 0) {
            mainServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        if (motServiceArray.length > 0) {
            motServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        if (repairServiceArray.length > 0) {
            repairServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        if (noTouchServiceArray.length > 0) {
            noTouchServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        if (additionalServiceArray.length > 0) {
            additionalServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        if (defaultServiceArray.length > 0) {
            defaultServiceArray.forEach(service => {
                sortedServiceArray.push(service);
            });
        }
        return sortedServiceArray;
    };

    static formVehicleObjectWithVinOrReg = (
        vinRegNo: string,
        modelName: string,
        buildYear: string,
        mileage: string
    ) => {
        let vehicleData = {};
        if (isThisVIN(vinRegNo)) {
            vehicleData = {
                vin: vinRegNo,
                modelName: modelName,
                buildYear: buildYear,
                mileage: mileage,
            };
        } else {
            vehicleData = {
                registrationNumber: vinRegNo,
                modelName: modelName,
                buildYear: buildYear,
                mileage: mileage,
            };
        }
        return vehicleData;
    };
}
