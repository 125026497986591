import { useEffect, useState } from 'react';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import handoverService from '../../../../services/handover/handover-service';

export function useSendToDealerModel(): Map<string, string | boolean> {
    const appConfig = new AppConfigurationService();
    const [brand, region, language] = appConfig.getBrandRegionLanguage();
    const [i18nMap, seti18nMap] = useState<Map<string, string | boolean>>(
        new Map<string, string | boolean>()
    );

    const cacheSendToDealerDataTitle = 'cacheSendToDealerModel';
    const cacheSendToDealerModelData:
        | Map<string, string | boolean>
        | undefined = handoverService.getDataFromCache(
        cacheSendToDealerDataTitle
    );

    useEffect(() => {
        if (cacheSendToDealerModelData) {
            seti18nMap(cacheSendToDealerModelData);
        } else {
            loadContent();
        }
    }, []);

    async function loadContent() {
        const content = await handoverService.getSendToDealerModel(
            brand,
            region,
            language
        );
        const i18nFromAem = mapContentToI18n(content);
        if (i18nMap && i18nMap.size === 0 && i18nFromAem.size > 0) {
            seti18nMap(i18nFromAem);
        }
    }

    function mapContentToI18n(content: any): Map<string, string | boolean> {
        const i18nFromAem = new Map<string, string | boolean>();
        if (content && content.elements) {
            content.elements.forEach((ele: any) => {
                if (ele.name) {
                    i18nFromAem.set(ele.name, ele.value);
                }
            });
        }
        return i18nFromAem;
    }

    if (i18nMap && i18nMap.size > 0 && !cacheSendToDealerModelData) {
        handoverService.setDataToCache(cacheSendToDealerDataTitle, i18nMap);
    }

    return i18nMap;
}
