import React, { ChangeEvent } from 'react';
import { VehicleFeature } from '../../../../models/handover-data';
import handoverService from '../../../../services/handover/handover-service';
import './handover-checkbox.scss';
interface Props {
    id: string;
    type: string;
    label: any;
    category: string;
    featuresFromCategory: VehicleFeature[];
    onChange(
        value: number,
        checkboxstate?: boolean,
        id?: string,
        category?: string,
        featureNameByCategory?: string
    ): void;
    value?: number;
    imgSrc: string;
    wersCode: string | undefined;
    detailsPage?: boolean;
    featureName?: string;
}
export const HandoverCheckbox = (props: Props) => {
    const selectedVin = 'vinPlaceholder';
    const selectedFeaturesName = 'selected-features';
    const selectedVinImage = 'imagePlaceHolder';
    const selectedFeaturesImage = 'selected-feature-img';

    interface CheckboxStorageData {
        storageName: string;
        placeholder: string;
        imageName: string;
        imgPlaceHolder: string;
        checkboxstate: boolean;
        id: string;
        imageUrl: string;
        category: string;
    }

    function handleCheckBoxStorage(data: CheckboxStorageData) {
        const {
            storageName,
            placeholder,
            imageName,
            imgPlaceHolder,
            checkboxstate,
            id,
            imageUrl,
            category,
        } = data;

        const selectedFeaturesJson = sessionStorage[storageName],
            selectedFeatures =
                selectedFeaturesJson !== undefined
                    ? JSON.parse(selectedFeaturesJson)
                    : { [placeholder]: [] },
            selectedFeaturesSet = new Set(selectedFeatures[placeholder]);
        let currentIndex = -1;
        for (let i = 0; i < Array.from(selectedFeaturesSet).length; i++) {
            if (Array.from(selectedFeaturesSet)[i] === id) {
                currentIndex = i;
            }
        }

        checkboxstate
            ? selectedFeaturesSet.add(id)
            : selectedFeaturesSet.delete(id);

        //new functionality
        const featureCategory: any =
            handoverService.getAllFeaturesListOf(category, 'all')[0] ||
            props.featuresFromCategory;
        const useSelectFeature = featureCategory.filter(
            (item: any) => item.key.toLowerCase() === id.toLowerCase()
        );

        useSelectFeature &&
            handoverService.handleCheckboxStateOnClick(
                useSelectFeature,
                checkboxstate,
                category
            );
        //udpate 'Add All' checkbox as well
        handoverService.updateAddAllCheckbox(
            handoverService.getAllFeaturesListOf(
                category,
                'unSelectedFeatures'
            )[0],
            category
        );

        if (storageName === selectedFeaturesName) {
            props.onChange(
                handoverService.getSelectedFeaturesCount(),
                checkboxstate,
                id,
                category,
                props.featureName
            );
        }

        handoverService.handleSelectedFeatureImage(
            imageName,
            imgPlaceHolder,
            imageUrl,
            checkboxstate,
            currentIndex
        );
    }

    function handleSingleAdd(
        isChecked: boolean,
        handoverId: string,
        imageUrl: string,
        category: string
    ) {
        const checkboxData = {
            storageName: selectedFeaturesName,
            placeholder: selectedVin,
            imageName: selectedFeaturesImage,
            imgPlaceHolder: selectedVinImage,
            checkboxstate: isChecked,
            id: handoverId,
            imageUrl: imageUrl,
            category: category,
        };
        handleCheckBoxStorage(checkboxData);
    }

    function handleAddAllInCategory(isChecked: boolean, id: string) {
        handoverService.updateAddAllOnClick(props.category, isChecked);

        //to update footer
        props.onChange(
            handoverService.getSelectedFeaturesCount(),
            isChecked,
            id,
            props.category,
            props.featureName
        );
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked: boolean = event.currentTarget.checked;
        props.type === 'add'
            ? handleSingleAdd(
                  isChecked,
                  props.id,
                  props.imgSrc,
                  '__' + props.category.toUpperCase() + '_'
              )
            : handleAddAllInCategory(isChecked, props.id);
    };

    let cat = '';
    if (props.label) {
        cat = props.label.replace(
            /##category##/,
            props.featuresFromCategory[0].category
        );
    }

    return (
        <div
            className={props.type === 'add' ? 'add-to-handover-eyf' : 'add-all'}
        >
            <label className="handover-checkbox-eyf">
                <input
                    type="checkbox"
                    onChange={handleChange}
                    id={props.id}
                    aria-label={cat}
                    data-code={props.wersCode}
                />
                <span className="add-all-category-name-EYF">{cat}</span>
            </label>
        </div>
    );
};

export default HandoverCheckbox;
