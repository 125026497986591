import handoverService from '../../../services/handover/handover-service';

export const GetFeatureName = () => {
    const vinLookupResponse = handoverService.getDataFromSessionStorage(
            'vinLookupResponse'
        ),
        featuresArray: any[] = [];

    const categoryFeatureMap = vinLookupResponse
        ? vinLookupResponse.features
        : undefined;

    if (categoryFeatureMap !== undefined) {
        Object.keys(categoryFeatureMap)
            .filter(category => categoryFeatureMap[category].length > 0)
            .forEach(category => {
                categoryFeatureMap[category].forEach((feature: any) => {
                    featuresArray.push({
                        id: feature.key,
                        name: feature.headline,
                        imgUrl: feature.handoverImage,
                        ytVideoID: feature.ytVideoID,
                    });
                });
            });
    }
    return { featuresArray };
};
