import React, { Fragment, useContext, useState } from 'react';
import { LiveStream } from '../../components/sections/live-stream/live-stream';
import { ModelYearVehicleService } from '../../services/model-year-vehicle-service/model-year-vehicle-service';
import { useHistory, useParams } from 'react-router-dom';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import './live-stream-view.scss';
import { ViewTemplate } from '../../components/sections';
import { ModelNotSupport } from '../../components/sections/live-stream/components/model-not-support';
import { useDyfEligibleModelNew } from '../../hooks/dyf/use-dyf-eligible-model/use-dyf-eligible-model-new';
import ServerContext from '../../contexts/serverContext';
import { useAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import useSeoConfig from '../../hooks/use-seo-config';

export const LiveStreamView = () => {
    const page = 'Discover Your Ford';
    const { year = '', model = '' } = useParams<{
        year?: string;
        model?: string;
    }>();
    const history = useHistory();
    const [currentVehicle, setCurrentVehicle] = useState<VehicleAttributes>();
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );
    const vehicleYmmGroup = useAemVehicleModelData(
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        year,
        model,
        'SEO_KEY'
    );

    const { data: dyfEligible } = useDyfEligibleModelNew({
        year,
        modelAlias: vehicleYmmGroup?.seoKey || '',
    });

    const goToPageNotFound = (error: Error) => {
        console.error(error);
        history.push('/404');
    };

    if (!currentVehicle && year && brand && model && vehicleYmmGroup) {
        if (model.toLowerCase() != 'f150-lightning') {
            new ModelYearVehicleService(model, parseInt(year, 10))
                .request()
                .catch(error => {
                    goToPageNotFound(error);
                });
        }
        const vehicleAttributes: VehicleAttributes = {
            year: parseInt(year, 10),
            make: brand,
            model: vehicleYmmGroup.marketName,
            seoKey: vehicleYmmGroup.seoKey,
            ownerState: 1,
        };
        setCurrentVehicle(vehicleAttributes);
    }

    const capitalizeYmm = (str: string) => {
        return str
            .split(' ')
            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
            .join(' ');
    };
    useSeoConfig('livestream', 'dyf', [
        {
            fieldName: 'ymm',
            fieldValue: capitalizeYmm(
                `${year} ` +
                    capitalizeYmm(brand) +
                    ' ' +
                    capitalizeYmm(vehicleYmmGroup?.marketName || model)
            ),
        },
    ]);
    return (
        <Fragment>
            <ViewTemplate
                hideLinksList={true}
                page={page}
                ctaBottomComponent={
                    dyfEligible?.eligibleLivestream && currentVehicle ? (
                        <LiveStream vehicleAttributes={currentVehicle} />
                    ) : (
                        <ModelNotSupport />
                    )
                }
            />
        </Fragment>
    );
};

export default LiveStreamView;
