import React, { useEffect, useState } from 'react';
import {
    useStepProgressBar,
    useOSBStep,
    useVehicleStep,
} from '../../../../../hooks/owners-osb';
import { StepProgressBarDetail } from '../../../../../models/osb-model/osb-progress-bar';
import { triggerBookingSummaryAndProgressBarAmendAnalytics } from '../../analytics/osb-analytics';
import {
    OSB_AEM_PROPERTY_KEYS,
    STEP_PROGRESS_BAR_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
} from '../../osb-constant';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './osb-step-progress-bar-lincoln.scss';
import {
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathPersonalDetailsStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
} from '../../osb-controller';
import {
    setRouteToPersist,
    buildNavigationUrl,
    getObjectFromAEMJson,
} from '../../osb-utils';
import { useHistory } from 'react-router-dom';
import serverSideService from '../../../../../services/server-side-service/server-side-service';

export const OSBStepProgressBar = () => {
    const {
        osbStepProgressBar,
        setOSBStepProgressBarPayload,
        callContentService,
    } = useStepProgressBar();
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep } = useOSBStep();
    const [fireEvents] = useAnalytics();
    const history = useHistory();
    const [top, setTop] = useState<number>(0);

    const getStepNameList = (elements: any[]): string[] => {
        const stepNameList: string[] = [];

        const labels = [
            OSB_AEM_PROPERTY_KEYS.DEALER_STEP_LABEL,
            OSB_AEM_PROPERTY_KEYS.SERVICE_STEP_LABEL,
            OSB_AEM_PROPERTY_KEYS.DELIVERY_STEP_LABEL,
            OSB_AEM_PROPERTY_KEYS.LOCATION_STEP_LABEL,
            OSB_AEM_PROPERTY_KEYS.DATETIME_STEP_LABEL,
            OSB_AEM_PROPERTY_KEYS.CONTACT_STEP_LABEL,
        ];

        labels.forEach(labelKey => {
            const label = getObjectFromAEMJson(labelKey, elements) || '';
            if (label) {
                stepNameList.push(label);
            }
        });

        const isStepNameExceedFirstBreakpoint = stepNameList.some(
            stepName =>
                stepName.length >
                STEP_PROGRESS_BAR_KEYS.STEP_NAME_LENGTH_BREAKPOINT_1
        );

        if (isStepNameExceedFirstBreakpoint) {
            setOSBStepProgressBarPayload({
                isStepNameExceedFirstBreakpoint: true,
            });
        }

        return stepNameList;
    };
    const updateProgressBarDetails = (stepNameList: string[]) => {
        const stepDetailsMap: {
            [key: string]: { name: string; path: () => string };
        } = {
            [STEP_PROGRESS_BAR_KEYS.DEALER]: {
                name: stepNameList[0],
                path: OsbPathDealerStep,
            },
            [STEP_PROGRESS_BAR_KEYS.SERVICE]: {
                name: stepNameList[1],
                path: OsbPathServicesStep,
            },
            [STEP_PROGRESS_BAR_KEYS.DELIVERY]: {
                name: stepNameList[2],
                path: OsbPathDeliveryStep,
            },
            [STEP_PROGRESS_BAR_KEYS.LOCATION]: {
                name: stepNameList[3],
                path: OsbPathServiceLocationStep,
            },
            [STEP_PROGRESS_BAR_KEYS.DATETIME]: {
                name: stepNameList[4],
                path: OsbPathCalenderStep,
            },
            [STEP_PROGRESS_BAR_KEYS.CONTACT]: {
                name: stepNameList[5],
                path: OsbPathPersonalDetailsStep,
            },
        };

        osbStepProgressBar.progressBarDetails.forEach(
            (step: StepProgressBarDetail) => {
                const detail = stepDetailsMap[step.key];
                if (detail) {
                    step.stepName = detail.name;
                    step.navigationPath = detail.path();
                }

                if (osbStepProgressBar.isRetrieveFlow) {
                    step.isEditable = true;
                    step.hasChanged = true;
                }
            }
        );

        setOSBStepProgressBarPayload({
            progressBarDetails: osbStepProgressBar.progressBarDetails,
            isContentLoaded: true,
        });
    };
    const getAEMContent = async () => {
        const result = await callContentService('osb-app');
        if (result) {
            const stepNameList: string[] = getStepNameList(result.elements);
            updateProgressBarDetails(stepNameList);
        }
    };

    useEffect(() => {
        if (!osbStepProgressBar.isContentLoaded) {
            getAEMContent();
        }
    }, [osbStepProgressBar.isContentLoaded]);
    useEffect(() => {
        const handleTop = () => {
            setTop(document.getElementById('osbBannerId')?.clientHeight ?? 0);
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleTop);
            window.addEventListener('resize', handleTop);
            return () => {
                window.removeEventListener('scroll', handleTop);
                window.removeEventListener('resize', handleTop);
            };
        }
    }, []);

    const setDefaultMobileProgressBarScrollLeft = (
        element: HTMLElement,
        activeStep: any
    ) => {
        const isRetrieveFlow = osbStepProgressBar.isRetrieveFlow;
        const isDealerDeepLinked = osbStep.isDealerDeepLinked;
        switch (activeStep?.key) {
            case STEP_PROGRESS_BAR_KEYS.DEALER:
                element.scrollLeft = 0;
                break;
            case STEP_PROGRESS_BAR_KEYS.SERVICE:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 0 : 100;
                break;
            case STEP_PROGRESS_BAR_KEYS.DELIVERY:
            case STEP_PROGRESS_BAR_KEYS.LOCATION:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 100 : 250;
                break;
            case STEP_PROGRESS_BAR_KEYS.DATETIME:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 250 : 430;
                break;
            case STEP_PROGRESS_BAR_KEYS.CONTACT:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 430 : 580;
                break;
        }
    };

    const setFirstBreakpointScrollLeft = (
        element: HTMLElement,
        activeStep: any
    ) => {
        if (!activeStep) {
            return;
        }
        const isRetrieveFlow = osbStepProgressBar.isRetrieveFlow;
        const isDealerDeepLinked = osbStep.isDealerDeepLinked;
        switch (activeStep.key) {
            case STEP_PROGRESS_BAR_KEYS.DEALER:
                element.scrollLeft = 0;
                break;
            case STEP_PROGRESS_BAR_KEYS.SERVICE:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 0 : 140;
                break;
            case STEP_PROGRESS_BAR_KEYS.DELIVERY:
            case STEP_PROGRESS_BAR_KEYS.LOCATION:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 140 : 330;
                break;
            case STEP_PROGRESS_BAR_KEYS.DATETIME:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 330 : 520;
                break;
            case STEP_PROGRESS_BAR_KEYS.CONTACT:
                element.scrollLeft =
                    isRetrieveFlow || isDealerDeepLinked ? 500 : 630;
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // For automatic step transition in mobile along with smooth scroll
        if (osbStepProgressBar?.progressBarDetails) {
            const activeStep: any = osbStepProgressBar.progressBarDetails.find(
                detail => detail.isActive === true
            );

            const element = document.getElementById('progress-bar-steps');
            if (element) {
                // Mobile Progress bar - Default View
                if (!osbStepProgressBar.isStepNameExceedFirstBreakpoint) {
                    setDefaultMobileProgressBarScrollLeft(element, activeStep);
                } else if (osbStepProgressBar.isStepNameExceedFirstBreakpoint) {
                    setFirstBreakpointScrollLeft(element, activeStep);
                }
            }
        }
    });

    const updateProgressBar = (stepIndex: number) => {
        osbStepProgressBar.progressBarDetails.forEach(
            (step: StepProgressBarDetail) => {
                if (step.isActive) {
                    step.isActive = false;
                }
            }
        );
        const currentStepDetail =
            osbStepProgressBar.progressBarDetails[stepIndex];
        currentStepDetail.isActive = true;
        setOSBStepProgressBarPayload(currentStepDetail);
        triggerBookingSummaryAndProgressBarAmendAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            currentStepDetail.key,
            LIGHT_JOURNEY_ANALYTICS.PROGRESS_BAR_CONTENT_TYPE
        );
        setRouteToPersist(
            OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
            currentStepDetail.navigationPath,
            osbStep.localStorageExpiry
        );
        history.push(
            buildNavigationUrl(
                currentStepDetail.navigationPath,
                osbStep.UrlQueryParams
            )
        );
    };

    const getStepStyleClassName = (
        progressBarStep: StepProgressBarDetail,
        stepIndex: number
    ): string => {
        let stepStyleClass = '';
        if (osbStepProgressBar.isMobileView) {
            if (osbStepProgressBar.isStepNameExceedFirstBreakpoint) {
                stepStyleClass = 'mobile-step-width-1 ';
            } else {
                stepStyleClass = 'mobile-step-width-default ';
            }

            if (
                stepIndex > 0 &&
                stepIndex < osbStepProgressBar.progressBarDetails.length - 1
            ) {
                stepStyleClass += 'mobile-step-middle ';
            }
        } else if (osbStepProgressBar.isStepNameExceedFirstBreakpoint) {
            stepStyleClass = 'desktop-step-width-1 ';
        } else {
            stepStyleClass = 'desktop-step-width-default ';
        }
        if (progressBarStep.isActive) {
            stepStyleClass = stepStyleClass + 'active';
        } else if (progressBarStep.hasChanged) {
            stepStyleClass = stepStyleClass + 'change';
        } else if (progressBarStep.isEditable) {
            stepStyleClass = stepStyleClass + 'edit';
        }
        return stepStyleClass;
    };

    const getSteps = (
        progressBarStep: StepProgressBarDetail,
        stepIndex: number
    ): JSX.Element => {
        if (
            progressBarStep.isActive ||
            progressBarStep.hasChanged ||
            progressBarStep.isEditable
        ) {
            return (
                <li
                    key={stepIndex}
                    className={`${getStepStyleClassName(
                        progressBarStep,
                        stepIndex
                    )}`}
                    onClick={() => {
                        updateProgressBar(stepIndex);
                    }}
                >
                    <div className="step-count-outer">
                        <div className="step-count"></div>
                    </div>
                    <div className="step-label">{progressBarStep.stepName}</div>
                </li>
            );
        } else {
            return (
                <li
                    key={stepIndex}
                    className={`${getStepStyleClassName(
                        progressBarStep,
                        stepIndex
                    )} `}
                >
                    <div className="step-count-outer">
                        <div className="step-count"></div>
                    </div>
                    <div className="step-label">{progressBarStep.stepName}</div>
                </li>
            );
        }
    };

    const getDesktopProgressBar = (): JSX.Element => {
        return (
            <div
                className="step-progress-bar-container"
                style={{ top }}
                id="step-progress-bar-container"
            >
                <ul
                    className="step-progress-bar"
                    aria-label="steps"
                    id="progress-bar-steps"
                >
                    {osbStepProgressBar?.progressBarDetails?.map(
                        (
                            progressBarStep: StepProgressBarDetail,
                            index: number
                        ) => {
                            return getSteps(progressBarStep, index);
                        }
                    )}
                </ul>
            </div>
        );
    };

    return <>{getDesktopProgressBar()}</>;
};
