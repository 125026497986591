import { useContent } from '../../../hooks/use-server-content';

export interface HowToVideoContentProps {
    hide: boolean;
    htvBannerTitle: string;
    htVBannerDescription: string;
    htvBannerUnauthDescription: string;
    htvBannerAuthDescription: string;
    htvBackgroundImageDesktop: string;
    htvBackgroundImageMobile: string;
    htvQuickTipImageDesktop: string;
    htvQuickTipImageMobile: string;
    htvElectricVehiclesImageDesktop: string;
    htvElectricVehiclesImageMobile: string;
    htvFordPassImageDesktop: string;
    htvFordPassImageMobile: string;
    htvFordServicesImageDesktop: string;
    htvFordServicesImageMobile: string;
    htvFordTechnologyImageDesktop: string;
    htvFordTechnologyImageMobile: string;
    htvKeysAndLocksImageDesktop: string;
    htvKeysAndLocksImageMobile: string;
    htvOilChangeImageDesktop: string;
    htvOilChangeImageMobile: string;
    htvOwnerResourcesImageDesktop: string;
    htvOwnerResourcesImageMobile: string;
    htvRecallsImageDesktop: string;
    htvRecallsImageMobile: string;
    htvSyncImageDesktop: string;
    htvSyncImageMobile: string;
    htvTiresAndWheelsImageDesktop: string;
    htvTiresAndWheelsImageMobile: string;
    htvWarrantyImageDesktop: string;
    htvWarrantyImageMobile: string;
    htvMoreVehicleTopicsImageDesktop: string;
    htvMoreVehicleTopicsImageMobile: string;
    htvCategoryVideosQuickTipImageDesktop: string;
    htvCategoryVideosQuickTipImageMobile: string;
    searchByVehicleTitle: string;
    yearTitle: string;
    modelTitle: string;
    searchPrimaryButton: string;
    searchPrimaryButtonAriaLabel: string;
    viewAllModelsSecondaryButton: string;
    viewAllModelsSecondaryButtonAriaLabel: string;
    popularHowToVideosTitle: string;
    moreDyfVideosTitle: string;
    htvLibrarylandingPageTitle: string;
    htvLibraryBannerDescription: string;
    searchAnotherVehicleSecondaryButton: string;
    searchAnotherVehicleSecondaryButtonAriaLabel: string;
    viewAllVideosSecondaryButton: string;
    viewAllVideosSecondaryButtonAriaLabel: string;
    viewMoreVideos: string;
    viewAllHtvTitle: string;
    videoLibraryPageSubTitle: string;
    htvLibraryErrorDescription: string;
    videoPageErrorDescription: string;
    lincolnServicesImageDesktop: string;
    lincolnServicesImageMobile: string;
    lincolnTechnologyImageDesktop: string;
    lincolnTechnologyImageMobile: string;
    lincolnWayAppImageDesktop: string;
    lincolnWayAppImageMobile: string;
    howToVideosBreadcrumbSupport: string;
    howToVideosBreadcrumbHowToVideos: string;
    livestreamBannerYmmText: string;
    livestreamBannerCtaText: string;
    livestreamBannerCtaAriaLabel: string;
}
export const useHowToVideoContent = (): HowToVideoContentProps | null => {
    const [content, getValueByName] = useContent('dyf', 'how-to-videos');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.htvBannerTitle) {
            contentFragment = null;
        }
    }
    return contentFragment as HowToVideoContentProps;
};
