import React, { useState, useContext } from 'react';
import '../service-history.scss';
import '../service-history.lincoln.scss';
import { PrimaryButton, SecondaryButton, Accordion } from '../../../common';
import Popover from '../../../common/popover/popover';
import checkMark from '../../../../assets/check-mark.svg';
import { ServiceRecord } from '../../../../models/service-history';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import { BrandUtil } from '../../../utils/brand-util/brand-util';
import { replacePlaceholderByValue } from '../../../utils/placeholder-util/replace-placeholder-by-value';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import { useAnalytics } from '../../../../hooks/use-analytics';
import ServerContext from '../../../../contexts/serverContext';
import Moment from 'moment';

interface Props {
    vehicleAttributes: VehicleAttributes;
    records: ServiceRecord[];
    euRegion: boolean;
    naRegion: boolean;
    imgRegion: boolean;
    recordEnd: number;
    onEditForm: any;
    serviceHistoryContent: any;
    selectedTabName: string;
    deleteRecord: any;
    recordsType: string;
    selfServiceContent: string;
    uomDistance: number;
}

export const ServiceHistoryAccordion = (props: Props) => {
    const {
        records,
        euRegion,
        naRegion,
        imgRegion,
        recordEnd,
        vehicleAttributes,
        serviceHistoryContent,
        onEditForm,
        selectedTabName,
        deleteRecord,
        recordsType,
        selfServiceContent,
        uomDistance,
    } = props;
    const [popoverID, setPopoverID] = useState<string>('');
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });
    const { brandName } = new BrandUtil();
    const isMobile = ServerSideService.isClientSide()
        ? window.innerWidth <= 780
        : false;
    const [fireEvents] = useAnalytics();
    const serverContext = useContext(ServerContext);

    const { currentLanguageRegionCode } = serverContext;

    const triggerAccordionExpandAnalytics = (recordType: string) => {
        fireEvents(
            'service-history-accordion',
            undefined,
            { recordType: recordType, fieldName: recordsType },
            false
        );
    };
    const serviceHeader = (serviceHistoryItem: ServiceRecord) => {
        const { servicesPerformed } = serviceHistoryItem;
        let operations = '';
        if (naRegion || imgRegion) {
            if (servicesPerformed && servicesPerformed.operations) {
                const operationsData = servicesPerformed.operations;
                operationsData.forEach(operation => {
                    operations += operation['desc'] + ', ';
                });

                operations = operations.slice(0, -2);
                return operations;
            }
        }
        if (euRegion) {
            if (servicesPerformed) {
                return uomDistance === 1
                    ? serviceHistoryItem.servicesPerformed.scheduled.milesDesc
                    : serviceHistoryItem.servicesPerformed.scheduled.kmsDesc;
            }
        }
        return '';
    };

    const serviceHeaderLogo = (
        serviceHistoryItem: ServiceRecord,
        showApprovedText = false
    ) => {
        let logoDisplay = '';
        const { dealer } = serviceHistoryItem.serviceLocation;
        const headerLogo = showApprovedText ? '' : 'header-logo';
        if (
            (euRegion && dealer?.authorised === false) ||
            ((naRegion || imgRegion) &&
                serviceHistoryItem.serviceCategory === 'owner')
        ) {
            logoDisplay = 'hide';
        }

        return (
            <div
                className={`service-history-accordion-title-details-left ford-col-logo ${logoDisplay}`}
            >
                <p>
                    <img
                        className={`ford-col-logo ${logoDisplay} ${headerLogo}`}
                        alt={`${brandName}-logo`}
                    />
                    {showApprovedText && (
                        <span>{serviceHistoryContent.approvedText}</span>
                    )}
                </p>
            </div>
        );
    };

    const setPageScrollTo = (rect: DOMRect) => {
        if (!isMobile && rect.top < 120) {
            window.scrollBy(0, -150);
        } else if (!isMobile && rect.top > 450 && rect.top >= 160) {
            window.scrollBy(0, 150);
        }
    };
    const setPopoverPositionState = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x + rect.width - 10 : 0,
            top: !isMobile ? rect.y - 70 : 0,
        };
        setPopoverPosition(newPosition);
    };

    const togglePopover = (e: any, serviceId: string) => {
        if (
            serviceId &&
            e &&
            e.currentTarget &&
            e.currentTarget.getBoundingClientRect
        ) {
            let rect = e.currentTarget.getBoundingClientRect();
            setPageScrollTo(rect);
            rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionState(rect);
            setPopoverID(serviceId);
            e.preventDefault();
        } else {
            setPopoverID('');
        }
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLImageElement>,
        serviceId: any
    ) => {
        if (event.key === 'Enter' || event.key === ' ') {
            togglePopover(event, '');
            setTimeout(() => {
                const focusedElement = document.querySelectorAll(
                    '.delete-record-' + serviceId
                )[0] as HTMLElement;
                focusedElement.setAttribute('tabIndex', '0');
                focusedElement.focus();
            }, 500);
        }
    };
    // Analytics for deleteConfirm,deleteCancel,editRecord and deleteOnClick events
    const triggerEditDeleteButtonClickAnalytics = (eventName: any) => {
        let event = '';
        switch (eventName) {
            case 'delete-cancel': {
                event = 'service-history-delete-record-cancel-onclick-event';
                break;
            }
            case 'delete-confirm': {
                event = 'service-history-delete-record-confirm-onclick-event';
                break;
            }
            case 'edit-record': {
                event = 'service-history-edit-record-onclick-event';
                break;
            }
            case 'delete-btn-click': {
                event = 'service-history-delete-record-onclick-event';
                break;
            }
        }
        fireEvents(
            [event],
            undefined,
            {
                fieldName: selectedTabName,
            },
            false
        );
    };

    const renderPopover = (serviceId: string) => {
        const {
            deleteConfirmationHeading,
            deleteConfirmationContent,
            deleteConfirmationCTALabel,
            deleteConfirmationCancelCTALabel,
        } = serviceHistoryContent;
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: deleteConfirmationContent,
                    }}
                />
            </div>
        );
        const popoverFooter = (
            <div className="delete-confirmation-footer">
                <PrimaryButton
                    color={'light'}
                    fill={'fill'}
                    chevron={false}
                    className={'no-shadow-transparent'}
                    onClick={(e: any) => {
                        togglePopover(e, '');
                        triggerEditDeleteButtonClickAnalytics('delete-cancel');
                    }}
                    onKeyDown={e => handleKeyDown(e, serviceId)}
                >
                    {deleteConfirmationCancelCTALabel}
                </PrimaryButton>
                <PrimaryButton
                    color={'light'}
                    fill={'fill'}
                    chevron={false}
                    className={'no-shadow-transparent'}
                    onClick={() => {
                        deleteRecord(serviceId);
                        togglePopover({}, '');
                        triggerEditDeleteButtonClickAnalytics('delete-confirm');
                    }}
                >
                    {deleteConfirmationCTALabel}
                </PrimaryButton>
            </div>
        );

        return (
            <Popover
                name="delete-record-popover"
                tooltipPosition="left"
                mask={true}
                handleClose={e => togglePopover(e, '')}
                popoverPosition={popoverPosition}
                heading={deleteConfirmationHeading}
                content={popoverContent}
                footer={popoverFooter}
                focusElement={`.delete-record-${serviceId}`}
            />
        );
    };

    const getFormattedDate = (date: string) => {
        const formattedDate = Moment(date).format('DD/MM/YYYY');
        return formattedDate;
    };

    const accordionTitle = (serviceHistoryItem: ServiceRecord) => {
        return (
            <>
                {serviceHeaderLogo(serviceHistoryItem)}
                <div
                    className="service-history-accordion-title-details-left service-date"
                    data-testid="accordion-header"
                >
                    <p className="service-history-accordion-details-item">
                        {currentLanguageRegionCode === 'fr-ca'
                            ? getFormattedDate(serviceHistoryItem.serviceDate)
                            : serviceHistoryItem.serviceDate}
                    </p>
                </div>
                <div className="service-history-accordion-title-details-right service-item">
                    <p className="service-history-accordion-details-header">
                        {serviceHeader(serviceHistoryItem)}
                    </p>
                </div>
                <div
                    className={`service-history-accordion-title-details-right service-category ${
                        naRegion ? '' : 'hide'
                    }`}
                >
                    <p className="service-history-accordion-details-header">
                        {serviceHistoryItem.serviceCategory === 'owner'
                            ? serviceHistoryContent.ownersEntryLabel
                            : serviceHistoryContent.dealerServiceLabel}
                    </p>
                </div>
            </>
        );
    };
    const formattedDate = (RegionDate: string) => {
        if (naRegion || imgRegion) {
            return new DateTimeUtil().formatDate(RegionDate)?.currentRegionDate;
        }
        if (euRegion) {
            const modifiedDateString = RegionDate.split(/\//)
                .reverse()
                .join('/');
            return new DateTimeUtil().formatDate(modifiedDateString)
                ?.currentRegionDate;
        }
    };

    const deleteButtonClick = (e: any, serviceId: string) => {
        togglePopover(e, serviceId);
        triggerEditDeleteButtonClickAnalytics('delete-btn-click');
    };

    const accordionDetails = (serviceHistoryItem: ServiceRecord) => {
        const { vin, make, model, year } = vehicleAttributes;
        const {
            serviceLocation,
            servicesPerformed,
            serviceAmount,
            vehicleMileage,
            serviceDate,
            notes,
            editable,
            vehicleMileageUnit,
            serviceId,
            inspections,
            createdOn,
            serviceCategory,
            nextService,
        } = serviceHistoryItem;
        const { dealer } = serviceLocation;
        const {
            scheduled,
            bodyAndPaintCheckDesc,
            operations,
            otherRelatedItems,
        } = servicesPerformed;

        return (
            <article
                className="service-history-accordion-article"
                data-testid="accordion-details"
            >
                <div className="service-history-panel-item">
                    <div className="service-history-panel-item-inner">
                        <div className="service-history-item-header">
                            {serviceHistoryContent.vehicle}
                        </div>
                        <div className="service-history-item">
                            {`${make} ${model} (${year})`}
                        </div>
                        <div className="service-history-item">{`${serviceHistoryContent.vinLabel}: ${vin}`}</div>
                        {serviceDate && (
                            <>
                                <div className="service-history-item-header">
                                    {serviceHistoryContent.serviceDate}
                                </div>
                                <div className="service-history-item">
                                    {formattedDate(serviceDate)}
                                </div>
                            </>
                        )}
                        {imgRegion && (
                            <>
                                {serviceId && (
                                    <>
                                        <div className="service-history-item-header">
                                            {
                                                serviceHistoryContent.roNumberLabel
                                            }
                                        </div>
                                        <div className="service-history-item">
                                            {serviceId}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <div className="service-history-item-header">
                            {serviceHistoryContent.mileageLabel}
                        </div>
                        <div className="service-history-item">
                            {new Intl.NumberFormat().format(vehicleMileage)}{' '}
                            {serviceHistoryContent.mileageUnitLabel
                                ? serviceHistoryContent.mileageUnitLabel
                                : vehicleMileageUnit}
                        </div>
                        {dealer && (
                            <>
                                <div className="service-history-item-header">
                                    {serviceHistoryContent.locationNameLabel}
                                </div>
                                <div className="service-history-item">
                                    {dealer.name
                                        ? dealer.name
                                        : selfServiceContent}

                                    {(dealer.address || dealer.countryCode) &&
                                        ','}
                                </div>
                                <div className="service-history-item">
                                    {dealer.address && `${dealer.address},`}
                                    {dealer.countryCode}
                                </div>
                            </>
                        )}
                        {serviceHeaderLogo(serviceHistoryItem, true)}
                        {euRegion && (
                            <>
                                {(scheduled.kmsDesc || scheduled.milesDesc) && (
                                    <>
                                        <div className="service-history-item-header">
                                            {
                                                serviceHistoryContent.scheduledServiceCompleted
                                            }
                                        </div>
                                        <div className="service-history-item">
                                            {uomDistance === 1
                                                ? scheduled.milesDesc
                                                : scheduled.kmsDesc}
                                        </div>
                                    </>
                                )}
                                {bodyAndPaintCheckDesc && (
                                    <>
                                        <div className="service-history-item-header">
                                            {
                                                serviceHistoryContent.bodyAndPaintCheckComplete
                                            }
                                        </div>
                                        <div className="service-history-item">
                                            {bodyAndPaintCheckDesc}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {(naRegion || imgRegion) && (
                            <>
                                {operations?.length ? (
                                    <>
                                        <div
                                            className="service-history-item-header"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    serviceHistoryContent.servicesPerformedLabel,
                                            }}
                                        />
                                        <div className="service-history-item operations">
                                            {operations.map(
                                                (operation, index) => (
                                                    <span key={operation.id}>
                                                        {index !== 0 && ', '}
                                                        {operation.desc}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {inspections?.length ? (
                                    <>
                                        <div className="service-history-item-header">
                                            {
                                                serviceHistoryContent.inspectionsPerformedLabel
                                            }
                                        </div>
                                        <div className="service-history-item inspections">
                                            {inspections.map(
                                                (inspection, index) => (
                                                    <span key={inspection}>
                                                        {`${inspection}${index !==
                                                            inspections.length -
                                                                1 && ', '}`}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {notes && (
                                    <>
                                        <div className="service-history-item-header">
                                            {serviceHistoryContent.notesLabel}
                                        </div>
                                        <div className="service-history-item">
                                            {notes}
                                        </div>
                                    </>
                                )}
                                <div className="service-history-item-header">
                                    {serviceHistoryContent.totalCostLabel}
                                </div>
                                <div className="service-history-item total-cost">
                                    {serviceHistoryContent.totalCostUnitLabel
                                        ? serviceHistoryContent.totalCostUnitLabel
                                        : '$'}
                                    {serviceAmount}
                                </div>
                                {imgRegion && (
                                    <>
                                        {nextService && (
                                            <>
                                                <div className="service-history-item-header">
                                                    {
                                                        serviceHistoryContent.nextServiceDateLabel
                                                    }
                                                </div>
                                                <div className="service-history-item">
                                                    {formattedDate(
                                                        nextService.dueDate
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {euRegion && (
                            <>
                                <div className="service-history-item-header">
                                    {serviceHistoryContent.otherRelatedItems}
                                </div>
                                <div className="service-history-item">
                                    {
                                        <ul>
                                            {otherRelatedItems.map(
                                                otherItems => (
                                                    <li key={otherItems.id}>
                                                        <img
                                                            src={checkMark}
                                                            alt="Check"
                                                            className="check-img"
                                                        ></img>
                                                        {otherItems.desc}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    }
                                </div>
                                {nextService && (
                                    <>
                                        <div className="service-history-item-header">
                                            {
                                                serviceHistoryContent.nextServiceDueLabel
                                            }
                                        </div>
                                        <div className="service-history-item">
                                            {
                                                serviceHistoryContent.nextServiceDueText
                                            }
                                        </div>
                                        <div className="service-history-item">
                                            {nextService?.kmsDesc}
                                        </div>
                                        <div className="service-history-item">
                                            {
                                                serviceHistoryContent.nextServiceDateDueLabel
                                            }{' '}
                                            {formattedDate(nextService.dueDate)}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {naRegion && editable && (
                    <div className="service-history-panel-item-button">
                        <div className="service-history-panel-button-edit">
                            <PrimaryButton
                                color={'dark'}
                                fill={'fill'}
                                chevron={false}
                                className={'blue'}
                                onClick={() => {
                                    onEditForm(serviceHistoryItem);
                                    triggerEditDeleteButtonClickAnalytics(
                                        'edit-record'
                                    );
                                }}
                            >
                                {serviceHistoryContent.editLabel}
                            </PrimaryButton>
                        </div>
                        <div className="service-history-panel-button-delete">
                            <SecondaryButton
                                className={`delete-record delete-record-${serviceId}`}
                                onClick={(e: any) =>
                                    deleteButtonClick(e, serviceId)
                                }
                            >
                                {serviceHistoryContent.deleteLabel}
                            </SecondaryButton>
                            <div className="service-history-panel-button-delete">
                                {popoverID === serviceId &&
                                    renderPopover(serviceId)}
                            </div>
                        </div>
                    </div>
                )}
                {naRegion && serviceCategory === 'owner' && (
                    <div className={'owner-edit-msg'}>
                        <span>
                            {replacePlaceholderByValue(
                                editable
                                    ? serviceHistoryContent.ownerEditableMessage
                                    : serviceHistoryContent.ownerNonEditableMessage,
                                {
                                    createdOnDate: formattedDate(createdOn),
                                }
                            )}
                        </span>
                    </div>
                )}
            </article>
        );
    };
    return (
        <>
            {records &&
                records
                    ?.slice(0, recordEnd)
                    .map((userService: ServiceRecord, key: number) => (
                        <Accordion
                            key={userService.serviceId}
                            header={accordionTitle(userService)}
                            panel={accordionDetails(userService)}
                            index={key.toString()}
                            className={'service-item'}
                            expandAnalytics={triggerAccordionExpandAnalytics}
                            accordionType={
                                userService.serviceCategory === 'owner'
                                    ? 'owner-entry'
                                    : 'dealer-service'
                            }
                            accordionName={'service-history-panel'}
                        />
                    ))}
        </>
    );
};
