import React, { useState, useEffect } from 'react';
import './connected-vehicle-odometer.scss';
import ConnectedVehicleService from '../../../services/connected-vehicle-service/connected-vehicle-service';
import getNumberFormatByRegion from '../../utils/number-format-utill/number-format-mileage';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import Popover from '../../common/popover/popover';
import { ConnectedVehicleResponse } from '../../../models/connected-vehicle';

interface Props {
    vinNumber: string;
    miLabelForSubHeadline: string;
    kmLabelForSubHeadline: string;
    odometerLabel: string;
    uomDistance: number;
    odometerTooltipHeader: string;
    odometerTooltipContent: string;
}

export const ConnectedVehicleOdometer = (props: Props) => {
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();
    const [showPopover, setPopover] = useState<boolean>(false);
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });
    const isMobile = window.innerWidth <= 780;
    const [distanceUnit, setDistanceUnit] = useState(0);
    const authenticationService = new AuthenticationService();

    const getVehicleStatus = () => {
        if (
            !vehicleStatus &&
            props.vinNumber &&
            authenticationService.getCatBundle()
        )
            new ConnectedVehicleService()
                .getVehicleStatus(props.vinNumber)
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
    };

    useEffect(() => {
        getVehicleStatus();
    });

    useEffect(() => {
        setDistanceUnit(props.uomDistance);
    }, [props.uomDistance]);

    const isVehicleStatusValid = () =>
        vehicleStatus &&
        vehicleStatus.vehiclestatus &&
        vehicleStatus.vehiclestatus.odometer &&
        vehicleStatus.vehiclestatus.odometer.value > 0;

    const setPageScrollTo = (rect: DOMRect) => {
        if (!isMobile && rect.top < 120) {
            window.scrollBy(0, -150);
        } else if (!isMobile && rect.top > 450 && !(rect.top < 160)) {
            window.scrollBy(0, 150);
        }
    };
    const setPopoverPositionState = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x + 40 : 0,
            top: !isMobile ? rect.y - 90 : 0,
        };
        setPopoverPosition(newPosition);
    };
    const togglePopover = (e: any, popoverState: boolean) => {
        if (e && e.currentTarget && e.currentTarget.getBoundingClientRect) {
            let rect = e.currentTarget.getBoundingClientRect();
            setPageScrollTo(rect);
            setPopover(popoverState);
            rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionState(rect);
            e.preventDefault();
        } else {
            setPopover(popoverState);
        }
        if (!popoverState) {
            document.getElementById('tooltipFocus')?.focus();
        }
        e.preventDefault();
    };
    const renderPopover = () => {
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.odometerTooltipContent,
                    }}
                />
            </div>
        );
        return (
            <Popover
                name="service-schedule-odometer"
                tooltipPosition="left"
                mask={true}
                handleClose={e => togglePopover(e, false)}
                popoverPosition={popoverPosition}
                heading={props.odometerTooltipHeader}
                content={popoverContent}
            />
        );
    };

    return (
        <>
            {isVehicleStatusValid() && (
                <div className="service-schedule-vehicle-details-info">
                    <section className={'service-schedule-vehicle-odometer'}>
                        <span className={'service-schedule-odometer-label'}>
                            {props.odometerLabel}:
                        </span>
                        <span className={'service-schedule-odometer'}>
                            {getNumberFormatByRegion(
                                String(
                                    vehicleStatus?.vehiclestatus.odometer.value
                                )
                            )}{' '}
                            {distanceUnit === 1
                                ? props.miLabelForSubHeadline
                                : ''}
                            {distanceUnit === 2
                                ? props.kmLabelForSubHeadline
                                : ''}
                        </span>

                        {showPopover && renderPopover()}
                        {props.odometerTooltipHeader &&
                        props.odometerTooltipContent ? (
                            <button
                                id="tooltipFocus"
                                className="service-schedule-odometer-info-wrapper"
                                onClick={(e: any) => togglePopover(e, true)}
                            >
                                <img
                                    className="odometer-info-icon info-icon"
                                    aria-label={
                                        'icon' + props.odometerLabel + 'tooltip'
                                    }
                                    role="button"
                                    alt=""
                                />
                            </button>
                        ) : (
                            ''
                        )}
                    </section>
                </div>
            )}
        </>
    );
};
