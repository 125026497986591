import React, { useState, useEffect } from 'react';
import './handover-print.scss';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { PageType } from '../view-template/page-type';
import fordLogo from '../../../assets/ford-logo.svg';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import LocalizedStrings from 'react-localization';
import { useVehicleImage } from '../vehicle-portal/hooks/use-vehicle-image';
import useUserProfile from '../../../hooks/use-user-profile';
import Footerchecklist from '../handover/footer-checklist/footer-checklist';
import handoverService from '../../../services/handover/handover-service';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    downloadLabel: string;
    page: PageType;
    vehicleAttributesData: VehicleAttributes;
    i18n: Map<string, string | boolean>;
    selectedFeatures: any;
}
const localizedMonths = new LocalizedStrings({
    en: {
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    },
    fr: {
        monthNames: [
            'janvier',
            'fèvrier',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'aout',
            'septembre',
            'octobre',
            'novembre',
            'decembre',
        ],
    },
});
export const PDFCreation = (props: Props) => {
    const vehicleImageUrl = useVehicleImage(
        props.vehicleAttributesData,
        props.page
    );

    const userSelectedVin = ServerSideService.isClientSide()
        ? window.sessionStorage.getItem('selectedVin')
        : '';

    const authenticationService = new AuthenticationService();

    const profile = useUserProfile();

    const [vehicleModel, setVehicleModel] = useState<string>();
    const [modelYear, setModelYear] = useState<string>();
    const [vehicleVin, setVehicleVin] = useState<string>();
    const [name, setName] = useState<string>();
    const [currentDate, setCurrentDate] = useState<string>();
    const vinLookupResponse = handoverService.getDataFromSessionStorage(
        'vinLookupResponse'
    );
    const vehicleDetails = vinLookupResponse
        ? vinLookupResponse.vehicleDetails
        : [];
    const isPostHandover = handoverService.getDataFromSessionStorage(
        'isPostHandoverState'
    );
    const [fireEvents] = useAnalytics();

    useEffect(() => {
        if (profile) {
            setName(profile.profile.firstName + ' ' + profile.profile.lastName);
            if (isPostHandover) {
                const match = profile.vehicles.find(
                    vehicle => vehicle.vin === userSelectedVin
                );
                if (match) {
                    setVehicleModel(match.modelName);
                    setModelYear(match.modelYear);
                    setVehicleVin(match.vin);
                }
            }

            if (!isPostHandover && vehicleDetails.vin) {
                setVehicleModel(vehicleDetails.modelDescription);
                setModelYear(vehicleDetails.modelYear);
                setVehicleVin(vehicleDetails.vin);
            }
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated && authenticationService.login();
            });
        }
    }, [profile, name]);

    const handleClick = () => {
        fireEvents(
            'Eyf-checklist-pdf',
            undefined,
            {
                nameplate: vehicleModel,
            },
            false
        );

        const filenameToDownloadPDF: string | any =
            props.i18n.get('checklistPdfFilename') ||
            props.i18n.get('createChecklistTitle') ||
            undefined;

        let originalWindowTitleBeforePrint: string;

        if (typeof window !== 'undefined') {
            originalWindowTitleBeforePrint = window.document.title;
            window.onbeforeprint = () => {
                window.document.title = filenameToDownloadPDF
                    ? filenameToDownloadPDF
                    : window.document.title;
            };

            window.onafterprint = () => {
                window.document.title = originalWindowTitleBeforePrint;
            };
            window.print();
        }
    };

    const getCurrentDate = () => {
        const localeLanguage = new AppConfigurationService().currentRegionCode;
        localizedMonths.setLanguage(localeLanguage);

        const tempDate = new Date();
        const dateTemp =
            tempDate.getDate() +
            ' ' +
            localizedMonths.monthNames[tempDate.getMonth()] +
            ' ' +
            tempDate.getFullYear();
        const currDate = dateTemp;
        setCurrentDate(currDate);
    };

    useEffect(() => {
        getCurrentDate();
    }, []);

    const getImageUrl = (feature: any) => {
        if (feature.imgUrl) {
            if (feature.imgUrl.startsWith('http')) {
                return feature.imgUrl;
            } else {
                return process.env.REACT_APP_AEM_BASE_URL + feature.imgUrl;
            }
        } else {
            return (
                'https://img.youtube.com/vi/' +
                feature.ytVideoID +
                '/mqdefault.jpg'
            );
        }
    };

    return (
        <div className={'Download-CTA'}>
            <div className="checklist-pdf-button-change">
                <Footerchecklist
                    featureLength={1}
                    i18n={props.i18n}
                    handleClick={handleClick}
                    page={'confirmationPage'}
                />
            </div>

            <div className="print-handover">
                <div className="print-wrapper-1">
                    <div className="print-header-left">
                        <div className="print-header">
                            <img
                                src={fordLogo}
                                crossOrigin="anonymous"
                                alt="Ford"
                                className="print-header-ford-logo"
                            />
                        </div>
                        <div className="handover-checklist-title">
                            {props.i18n.get('handoverChecklist')}
                        </div>
                    </div>
                    <div className="vehicleImage">
                        <img
                            src={vehicleImageUrl ? vehicleImageUrl : ''}
                            crossOrigin="anonymous"
                            alt="Vehicle Image"
                        />
                    </div>
                </div>
                <div className="print-divider" />

                <div className="print-wrapper-2">
                    <div className="print-col">
                        <label>{props.i18n.get('date')}</label>
                        <span>{currentDate}</span>
                    </div>
                    <div className="print-col">
                        <label>{props.i18n.get('vehicle')}</label>
                        <span>
                            {vehicleModel}&nbsp;&nbsp;({modelYear})
                        </span>
                    </div>
                    <div className="print-col">
                        <label>{props.i18n.get('vin')}</label>
                        <span>{vehicleVin}</span>
                    </div>
                    <div className="print-col">
                        <label>{props.i18n.get('customer')}</label>
                        <span>{name}</span>
                    </div>
                </div>
                <div className="print-wrapper-3">
                    <div className="print-features">
                        <label>
                            {props.selectedFeatures.length}&nbsp;&nbsp;
                            <label>{props.i18n.get('featuresChosen')}</label>
                        </label>
                        <ul>
                            {props.selectedFeatures.map((feature: any) => (
                                <li key={feature.id}>
                                    <div className="featureImg">
                                        <img
                                            alt="Feature Image"
                                            src={getImageUrl(feature)}
                                        />
                                    </div>
                                    <div className="featureWrapper">
                                        <input
                                            aria-label="feature checkbox"
                                            type="checkbox"
                                        />
                                        <span className="featureName">
                                            {feature.name}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
