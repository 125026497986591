import React from 'react';
import './handover-notification.scss';
import successIcon from '../../../../assets/success-checkmark.svg';

import closeThickIcon from '../../../../assets/close-thick.svg';
import editIcon from '../../../../assets/edit-icon.svg';
import errorRedCrossIcon from '../../../../assets/red-cross.svg';

interface Props {
    customClass?: string;
    notificationMessage: string;
    notificationStatus: string;
    handler: (event: React.MouseEvent) => void;
}

export const HandoverNotification = (props: Props) => {
    const { notificationMessage, notificationStatus, customClass } = props;

    const getNotificationIconEYF = () => {
        switch (notificationStatus) {
            case 'successBar':
                return successIcon;
            case 'errorBar':
                return errorRedCrossIcon;
            case 'editBar':
                return editIcon;
            default:
                break;
        }
    };

    const getTopPosition = () => {
        const headerEle = (document.querySelector('.header') ||
            document.querySelector('.euheadernav')) as HTMLElement;
        return headerEle ? `${headerEle.offsetHeight}px` : '0px';
    };

    return (
        <div
            className={`notification-message-container-eyf ${notificationStatus +
                '-eyf'} ${customClass}`}
            style={{ top: getTopPosition() }}
        >
            <div className="notifactionContainer">
                <img
                    className="notification-icon-sucess"
                    src={getNotificationIconEYF()}
                    alt="notification icon"
                />
                <div
                    className="notification-text-message"
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage,
                    }}
                ></div>
            </div>
            <button
                onClick={props.handler}
                data-testid="close"
                className="notification-close-button"
            >
                <img
                    src={closeThickIcon}
                    alt="closeIcon"
                    className="closeIcon"
                />
            </button>
        </div>
    );
};
