import React, { useState, useEffect } from 'react';

import './handover.scss';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import {
    CategoryFeatureMap,
    VehicleFeature,
} from '../../../models/handover-data';
import { FeaturedArticlesCarousel } from './featured-articles-carousel/featured-articles-carousel';
import { Footerchecklist } from './footer-checklist/footer-checklist';
import { HandoverInfo } from './handover-info/handover-info';
import { HandoverNotification } from '../handover/handover-notification/handover-notification';
import { useAnalytics } from '../../../hooks/use-analytics';
import { useHandoverFeatures } from './hooks/use-handover-features';
import { useHandoverModel } from './hooks/use-vehicle-handover-model';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import getChecklistFeaturesService from './hooks/use-checklist-api-call';
import handoverService from '../../../services/handover/handover-service';
import { ServerSideService } from '../../../services/server-side-service/server-side-service';
import HandoverDisclosure from './handover-disclosure/handover-disclosure';
import { Handover_Constants } from './handover-constants';
import { HandoverBreadCrumbs } from './handover-breadcrumbs/handover-breadcrumbs';
import useUserProfile from '../../../hooks/use-user-profile';
import { useHandoverVehicleOrderStatusLookup } from './hooks/use-vehicle-handover-status';

export const Handover = () => {
    const handoverStatus = useHandoverVehicleOrderStatusLookup();
    const { handoverStatusError } = handoverStatus;
    const profileData = useUserProfile();
    const selectedVin = handoverService.fetchSelectedVin() || '';
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]),
        postHandover = handoverService.getDataFromSessionStorage(
            'isPostHandoverState'
        ),
        [categoryFeatureMap, setCategoryFeatureMap] = useState<any>(),
        [featureFlag, setFeatureFlag] = useState(
            handoverService.getSelectedFeaturesCount()
        ),
        [showModifyNotificationBar, setShowModifyNotificationBar] = useState(
            false
        ),
        [
            isFeatureChecklistSavedinDB,
            setIsFeatureChecklistSavedinDB,
        ] = useState(false),
        [checklistFeaturesFromDB, setChecklistFeaturesFromDB] = useState<
            Set<string>
        >(new Set<string>()),
        [isServiceLoading, setIsServiceLoading] = useState(true),
        i18n = useHandoverModel(),
        sendToDealerLink: string = handoverService.fetchPathName(
            'SendToDealerRoute'
        ),
        [fireEvents] = useAnalytics(),
        triggerLoadAnalytics = (selectedVehicleData: VehicleAttributes) => {
            fireEvents(
                ['target-trigger-view', 'handover-explore-features'],
                'handover-explore-features',
                {
                    namePlateValue: selectedVehicleData.model,
                    ymm: Promise.resolve(selectedVehicleData),
                    fieldName: postHandover ? 'post handover' : 'pre handover',
                }
            );
        };

    const [categoryArray, setCategoryArray] = useState<string[]>([]);
    const serverService = new ServerSideService();
    const [
        showNotificationChecklistBar,
        setShowNotificationChecklistBar,
    ] = useState(false);
    const [isChecklistError, setIsChecklistErorr] = useState(false);
    const [showErrorNotificationBar, setShowErrorNotificationBar] = useState(
        true
    );
    const [
        showVehicleErrorNotificationBar,
        setShowVehicleErrorNotificationBar,
    ] = useState(true);
    const mappedData: any = useHandoverFeatures(selectedVin || '', profileData);
    const [showVehicleErrorFlag, setShowVehicleErrorFlag] = useState(false);

    const vinLookupResponse = handoverService.getDataFromSessionStorage(
        'vinLookupResponse'
    );
    const vehicleDetails = vinLookupResponse
        ? vinLookupResponse.vehicleDetails
        : [];

    //loading mappedData
    useEffect(() => {
        if (!mappedData) {
            setShowVehicleErrorFlag(true);
            return;
        }

        setCategoryFeatureMap(mappedData);
        handleVisitedVehicleCache(mappedData);
        scrollToTopIfClientSide();
        sessionStorage.setItem('submitted-features', '');

        getChecklistFeaturesService
            .useChecklistService()
            .then(handleChecklistServiceResponse)
            .catch(handleChecklistService);
    }, [mappedData]);

    function handleVisitedVehicleCache(mappedData: any) {
        const visitedVehicleCached =
            handoverService.getDataFromSessionStorage('visited-vehicles') ||
            undefined;

        if (!visitedVehicleCached) {
            handoverService.createCacheonFirstVisit(mappedData);
        }
    }

    function scrollToTopIfClientSide() {
        if (serverService.isClientSide()) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function handleChecklistServiceResponse(response: any) {
        if (response && response.size > 0) {
            handleSuccessfulResponse(response);
        } else if (response.code) {
            handleChecklistService();
        }
        setIsServiceLoading(false);
    }

    function handleSuccessfulResponse(response: any) {
        setShowModifyNotificationBar(true);
        setChecklistFeaturesFromDB(response);
        setIsFeatureChecklistSavedinDB(true);
        handoverService.setDataToSessionStorage(
            'submitted-features',
            Array.from(response)
        );
        handoverService.populateVisitedVehicleByCheckList(response);
        setFeatureFlag(
            handoverService.getSelectedFeaturesCount() || response.size
        );
    }

    function handleChecklistService() {
        setShowNotificationChecklistBar(true);
        sessionStorage.setItem('isPostHandoverState', 'true');
        setIsChecklistErorr(true);
    }

    useEffect(() => {
        if (categoryFeatureMap) {
            const array: any[] = [];
            let splitValue: any = [];
            Object.keys(categoryFeatureMap)
                .filter(category => categoryFeatureMap[category].length > 0)
                .forEach(category => array.push(category));

            array.forEach((item: any, index: number) => {
                if (
                    item.split(' ')[0].toUpperCase() ===
                    Handover_Constants.FORDPASS
                ) {
                    splitValue = array.splice(index, 1);
                }
            });
            array.sort();
            setCategoryArray(splitValue.concat(array));
        } else {
            setShowVehicleErrorFlag(true);
        }
    }, [categoryFeatureMap]);

    //handling visited-vehicle cache
    useEffect(() => {
        if (!mappedData || postHandover) return;

        let visitedVehicleCached =
            handoverService.getDataFromSessionStorage('visited-vehicles') ||
            undefined;

        if (selectedVin && mappedData) {
            if (!visitedVehicleCached) {
                //first time visit
                handoverService.createCacheonFirstVisit(mappedData);
            } else {
                const vinList = Object.keys(visitedVehicleCached);

                if (!vinList.includes(selectedVin)) {
                    const factoryObj = handoverService.visitedVehiclesFactoryObj(
                        selectedVin.toString(),
                        mappedData
                    );

                    visitedVehicleCached = {
                        ...visitedVehicleCached,
                        [selectedVin.toString()]: factoryObj[selectedVin],
                    };
                    handoverService.setDataToSessionStorage(
                        'visited-vehicles',
                        visitedVehicleCached
                    );
                }
            }
            handoverService.updateCheckboxStateOnLoad();
        }
    }, [postHandover, mappedData]);

    //getting checklist data
    useEffect(() => {
        if (profileData) {
            const checklistFeatures =
                sessionStorage.getItem('submitted-features') || '';

            setIsServiceLoading(true);
            if (checklistFeatures.length) {
                setIsFeatureChecklistSavedinDB(true);
                setIsServiceLoading(false);
                setChecklistFeaturesFromDB(
                    new Set(JSON.parse(checklistFeatures))
                );
                setFeatureFlag(JSON.parse(checklistFeatures).length);
                return;
            }
        } else {
            sessionStorage.setItem('submitted-features', '');
        }
    }, [profileData]);

    const handleChange = (newValue: number) => {
        setFeatureFlag(newValue);
        if (checklistFeaturesFromDB && checklistFeaturesFromDB.size > 0) {
            setShowModifyNotificationBar(true);
        }
    };

    const featuresCarouselData = (
        i18n: Map<string, string | boolean>,
        cardsFeatures: VehicleFeature[],
        category: string,
        handleChangeCheckBox: (
            newValue: number,
            isChecked: boolean,
            id: string
        ) => void,
        value: number
    ) => {
        return {
            i18n,
            cards: cardsFeatures,
            mainCategory: category,
            handleChangeCheckBox,
            value,
            vehiclesData,
        };
    };

    const closeNotificationBar = () => {
        setShowModifyNotificationBar(!showModifyNotificationBar);
    };

    const closeErrorNotificationBar = () => {
        setShowErrorNotificationBar(false);
        setIsChecklistErorr(false);
    };

    const closeVehicleErrorNotificationBar = () => {
        setShowVehicleErrorNotificationBar(false);
    };

    const closeNotificationChecklistBar = () => {
        setShowNotificationChecklistBar(!showNotificationChecklistBar);
    };

    const whenDataNotAvailble = () => {
        return (
            <h2 className="dataNotFound">
                Data not available, Please try again.....
            </h2>
        );
    };

    const createVehicleFeatures = (categoryFeatureMap: CategoryFeatureMap) => {
        if (categoryArray.length < 1) {
            setTimeout(() => {
                whenDataNotAvailble();
            }, 1000);
        }

        return (
            <div className="handovers">
                {categoryArray.length > 0
                    ? categoryArray.map(category => (
                          <div
                              key={category}
                              className="handovers-category"
                              id={category}
                          >
                              <h2
                                  className="handover-category-EYF"
                                  aria-label={category + ' ' + 'category'}
                              >
                                  {categoryFeatureMap[category][0].category}
                              </h2>

                              <div className="handovers-row">
                                  <FeaturedArticlesCarousel
                                      {...featuresCarouselData(
                                          i18n,
                                          categoryFeatureMap[category],
                                          category,
                                          handleChange,
                                          featureFlag
                                      )}
                                      handoverState={
                                          postHandover == ''
                                              ? false
                                              : postHandover
                                      }
                                  />
                              </div>
                          </div>
                      ))
                    : ''}
            </div>
        );
    };

    useEffect(() => {
        if (profileData && selectedVin && selectedVin !== '') {
            const selectedVehicleData: any = profileData.vehicles.find(
                vehicle => vehicle.vin === selectedVin
            );
            if (selectedVehicleData) {
                setVehiclesData([
                    {
                        year: selectedVehicleData.modelYear,
                        make: selectedVehicleData.vehicleMake,
                        model: selectedVehicleData.modelName,
                        vin: selectedVehicleData.vin,
                    },
                ]);
            } else {
                setVehiclesData([
                    {
                        year: vehicleDetails.modelYear,
                        make: 'ford',
                        model: vehicleDetails.modelDescription,
                        vin: vehicleDetails.vin,
                    },
                ]);
            }
        }
    }, [profileData]);

    useEffect(() => {
        if (vehiclesData.length > 0) {
            const selectedVehicleData: VehicleAttributes =
                vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                vehiclesData[0];

            if (selectedVehicleData) {
                triggerLoadAnalytics(selectedVehicleData);
            }
        }
    }, [vehiclesData, postHandover]);

    useEffect(() => {
        setFeatureFlag(handoverService.getSelectedFeaturesCount());
    }, [handoverService.getSelectedFeaturesCount]);

    const clickEvent = (featuresSelectedData: any) => {
        let featuresListString = '';
        featuresSelectedData.map((item: any, index: number) => {
            featuresListString =
                featuresListString +
                item.headline +
                (index === featuresSelectedData.length - 1 ? '' : ',');
        });
        const selectedVehicleDataValue: VehicleAttributes =
            vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
            vehiclesData[0];
        let modelName = '';
        if (selectedVehicleDataValue) {
            modelName = selectedVehicleDataValue.model;
        } else {
            modelName = vehicleDetails.modelDescription;
        }
        fireEvents(
            ['Eyf-view-checklist-features'],
            undefined,
            {
                namePlateValue: modelName,
                featuresList: featuresListString,
            },
            false
        );
    };

    useEffect(() => {
        const data: any = handoverService.getDataFromSessionStorage(
            Handover_Constants.Handover_Clicked_Feature_Data
        );
        if (data && categoryArray.length != 0) {
            const myElement: HTMLElement | null = document.getElementById(
                data.category.toUpperCase()
            );
            const header: HTMLElement | null = document.querySelector(
                '.euheader'
            );
            if (myElement && header) {
                setTimeout(() => {
                    const offset = header.getBoundingClientRect().height;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = myElement.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }, 500);

                handoverService.removeFromSessionStorage(
                    Handover_Constants.Handover_Clicked_Feature_Data
                );
            }
        }
    });

    return (
        <>
            {categoryFeatureMap && !isServiceLoading ? (
                <>
                    <div className="handover-main">
                        {(handoverStatusError || isChecklistError) &&
                            showErrorNotificationBar && (
                                <HandoverNotification
                                    notificationMessage={String(
                                        i18n.get('checklistApiErrorMessage')
                                    )}
                                    notificationStatus="errorBar"
                                    handler={closeErrorNotificationBar}
                                />
                            )}
                        {!handoverStatusError &&
                            !isChecklistError &&
                            !postHandover &&
                            showModifyNotificationBar && (
                                <HandoverNotification
                                    notificationMessage={String(
                                        i18n.get('modifyNotification')
                                    )}
                                    notificationStatus="editBar"
                                    handler={closeNotificationBar}
                                />
                            )}
                        {!handoverStatusError &&
                            !isChecklistError &&
                            showNotificationChecklistBar &&
                            i18n.get('genericErrorMessage') && (
                                <HandoverNotification
                                    notificationMessage={String(
                                        i18n.get('genericErrorMessage')
                                    )}
                                    notificationStatus="editBar"
                                    handler={closeNotificationChecklistBar}
                                />
                            )}

                        <HandoverBreadCrumbs
                            currentPage="landing"
                            originFromVD={
                                postHandover == '' ? false : postHandover
                            }
                        />

                        <HandoverInfo
                            i18n={i18n}
                            handoverState={
                                postHandover == '' ? false : postHandover
                            }
                            dropDownList={categoryArray}
                            isFeatureChecklistinDB={isFeatureChecklistSavedinDB}
                        />
                        <div className="handover-container">
                            {createVehicleFeatures(categoryFeatureMap)}
                        </div>

                        <div className="footer-container">
                            {handoverService.getSelectedFeaturesCount() > 0 &&
                                !postHandover && (
                                    <Footerchecklist
                                        featureLength={handoverService.getSelectedFeaturesCount()}
                                        link={sendToDealerLink}
                                        i18n={i18n}
                                        page={'sendtodealer'}
                                        handleClick={() =>
                                            clickEvent(
                                                handoverService.getSelectedFeaturesCount(
                                                    true
                                                )
                                            )
                                        }
                                    />
                                )}
                        </div>

                        <HandoverDisclosure
                            defaultStateOpen={false}
                            i18n={i18n}
                        />
                    </div>
                </>
            ) : (
                <>
                    {!isServiceLoading && showVehicleErrorFlag && (
                        <>
                            {showVehicleErrorNotificationBar && (
                                <HandoverNotification
                                    notificationMessage={String(
                                        i18n.get('vehicleApiErrorMessage')
                                    )}
                                    notificationStatus="errorBar"
                                    handler={closeVehicleErrorNotificationBar}
                                />
                            )}
                            <HandoverBreadCrumbs
                                currentPage="landing"
                                originFromVD={
                                    postHandover == '' ? false : postHandover
                                }
                            />
                        </>
                    )}
                    <ActivityIndicator className="full-height" />
                </>
            )}
        </>
    );
};

export default React.memo(Handover);
