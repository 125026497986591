import React, {
    ChangeEventHandler,
    FocusEventHandler,
    forwardRef,
    KeyboardEventHandler,
} from 'react';
import './input-field.scss';
import errorWarning from '../../../assets/error-warning.svg';
import { BrandUtil } from '../../utils/brand-util/brand-util';
import successIcon from '../../../assets/check-mark.svg';

interface Props extends React.HTMLProps<HTMLElement> {
    placeHolder?: string;
    className?: string;
    label: string;
    ariaLabel: string;
    name: string;
    value?: string;
    onChange?: ChangeEventHandler<any>;
    onFocus?: FocusEventHandler<any>;
    onKeyDown?: KeyboardEventHandler<any>;
    errorMessage?: string;
    maxLength?: number;
    fdsStyle?: boolean;
    ariaRequired?: boolean;
    validate?: boolean;
}

const { isLincoln } = new BrandUtil();

const InputField = forwardRef(
    (props: Props, ref: React.Ref<HTMLInputElement>) =>
        isLincoln || props.fdsStyle ? (
            <>
                {' '}
                <div
                    className={`input-field-container ${
                        props.errorMessage ? 'error' : ''
                    } ${
                        props.fdsStyle && !isLincoln
                            ? props.value && !props.errorMessage
                                ? 'fds-style valid'
                                : 'fds-style'
                            : ''
                    }`}
                >
                    <input
                        id={props.name + '-label'}
                        data-testid="input-text"
                        type="text"
                        className={`input-field${
                            props.errorMessage ? '-error' : ''
                        } ${
                            props.className === undefined ? '' : props.className
                        }`}
                        placeholder={props.placeHolder}
                        aria-label={props.ariaLabel}
                        aria-labelledby={props.name + '-label'}
                        name={props.name}
                        value={props.value}
                        maxLength={props.maxLength}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        onKeyDown={props.onKeyDown}
                        onKeyUp={props.onKeyUp}
                        ref={ref}
                        {...(props.errorMessage && {
                            'aria-describedby': `${props.name}-error-message`,
                        })}
                        aria-required={props.ariaRequired}
                    />
                    <label
                        htmlFor={props.name + '-label'}
                        className="input-label"
                        aria-hidden="true"
                    >
                        {props.label}
                    </label>
                    {props.errorMessage && (
                        <img
                            className={`error-icon ${
                                !props.value ? 'empty' : ''
                            }`}
                            alt="error"
                        />
                    )}
                    {props.fdsStyle &&
                        !isLincoln &&
                        props.value &&
                        !props.errorMessage &&
                        !props.validate && (
                            <img
                                src={successIcon}
                                alt=""
                                className="success-icon"
                            />
                        )}
                </div>
                {props.errorMessage && (
                    <div
                        className="error-message"
                        id={props.name + '-error-message'}
                    >
                        {props.errorMessage}
                    </div>
                )}
            </>
        ) : (
            <>
                <label
                    htmlFor={props.name + '-label'}
                    className="input-label"
                    aria-hidden="true"
                >
                    {props.label}
                    {props.errorMessage && (
                        <img
                            className="error-icon"
                            src={errorWarning}
                            alt="error"
                        />
                    )}
                </label>
                <input
                    ref={ref}
                    id={props.name + '-label'}
                    data-testid="input-text"
                    type="text"
                    className={`input-field${
                        props.errorMessage ? '-error' : ''
                    } ${props.className === undefined ? '' : props.className}`}
                    placeholder={props.placeHolder}
                    aria-label={props.ariaLabel}
                    aria-labelledby={props.name + '-label'}
                    name={props.name}
                    value={props.value}
                    maxLength={props.maxLength}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    onKeyDown={props.onKeyDown}
                    onKeyUp={props.onKeyUp}
                    {...(props.errorMessage && {
                        'aria-describedby': `${props.name}-error-message`,
                    })}
                    aria-required={props.ariaRequired}
                />
                {props.errorMessage && (
                    <div
                        className="error-message"
                        id={props.name + '-error-message'}
                    >
                        {props.errorMessage}
                    </div>
                )}
            </>
        )
);
InputField.displayName = 'InputField';
export default InputField;
