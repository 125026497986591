import React, { useState, useEffect } from 'react';
import { CreateCheckList } from '../../components/sections/create-checklist/create-checklist';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import useUserProfile from '../../hooks/use-user-profile';
import { useHandoverFeatures } from '../../components/sections/handover/hooks/use-handover-features';
import handoverService from '../../services/handover/handover-service';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useHandoverVehicleOrderStatusLookup } from '../../components/sections/handover/hooks/use-vehicle-handover-status';

export const CreateCheckListView = () => {
    const authenticationService = new AuthenticationService();
    const profile = useUserProfile();

    const userVin = localStorage.getItem('USER_VIN');
    if (userVin) {
        sessionStorage.setItem('selectedVin', userVin);
        localStorage.removeItem('USER_VIN');
    }
    const userSelectedVin =
        typeof window === 'undefined'
            ? null
            : window.sessionStorage.getItem('selectedVin');
    useHandoverVehicleOrderStatusLookup();
    useHandoverFeatures(userSelectedVin || '', profile);
    const vinLookupResponse = handoverService.getDataFromSessionStorage(
        'vinLookupResponse'
    );
    const vehicleDetails = vinLookupResponse
        ? vinLookupResponse.vehicleDetails
        : [];
    const isPostHandover = handoverService.getDataFromSessionStorage(
        'isPostHandoverState'
    );

    const [
        vehicleAttributes,
        setVehicleAttributes,
    ] = useState<VehicleAttributes | null>(null);

    const page = 'DASHBOARD';

    useEffect(() => {
        if (profile && vehicleAttributes == null) {
            if (isPostHandover) {
                const match = profile.vehicles.find(
                    vehicle => vehicle.vin === userSelectedVin
                );
                if (match) {
                    const vehicleAttributes: VehicleAttributes = {
                        year: parseInt(match.modelYear),
                        make: match.make,
                        model: match.modelName,
                        vin: match.vin,
                        ownerState: match.ownerState,
                    };
                    setVehicleAttributes(vehicleAttributes);
                }
            }

            if (!isPostHandover && vehicleDetails.vin) {
                const vehicleAttributes: VehicleAttributes = {
                    year: parseInt(vehicleDetails.modelYear),
                    make: 'ford',
                    model: vehicleDetails.modelDescription,
                    vin: vehicleDetails.vin,
                };
                setVehicleAttributes(vehicleAttributes);
            }
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated && authenticationService.login();
            });
        }
    }, [profile, vehicleDetails]);

    return (
        <div className="checklist-main">
            {vehicleAttributes ? (
                <CreateCheckList
                    vehicleAttributes={vehicleAttributes}
                    page={page}
                />
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </div>
    );
};
export default CreateCheckListView;
