import React, { useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import {
    useCalendarStep,
    useDealerStep,
    useHttp,
    useOSBStep,
    useServiceStep,
    useVehicleStep,
    useViewport,
    useLightDeliveryOptionServiceStep,
    useServiceLocationStep,
    useOsbImageUpload,
    useStepProgressBar,
} from '../../../../hooks/owners-osb';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import {
    BOOKING_SUMMARY_CONTAINER_KEYS,
    DELIVERY_STEPS_KEYS,
    OSB_LIGHTT_VOUCHER_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    SERVICES_STEP_KEYS,
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
    SERVICE_DELIVERY_TYPE,
} from '../osb-constant';
import {
    getFormattedPrice,
    getLightVehicleData,
    getVoucherAppliedSelectedDeliveryOptions,
    getVoucherAppliedSelectedLocationOptions,
    getVoucherAppliedSelectedServices,
    showServiceLocationDetails,
    hasAutomaticVoucherApplied,
    buildNavigationUrl,
    getActualPrice,
    getDefaultDeliveryOption,
    ErrorResponse,
    isConvenienceOptionIncluded,
    filterObjectsBySectionType,
    calculateTotalPriceSummary,
    getObjectFromAEMJsonArray,
    isPOROrNoPriceServiceSelected,
} from '../osb-utils';
import { VehicleData } from '../../../../models/osb-model/osb-vehicle-step';
import './light-booking-summary-lincoln.scss';
import { LightJourneyVouchers } from '../services-step/light-journey/light-vouchers/light-vouchers';
import CheckedIcon from '../../../../assets/icons-notification-checkmark-secondary.svg';
import {
    DealerServices,
    ServiceData,
} from '../../../../models/osb-model/osb-dealerservice-info';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { OSBBrightcoveVideoPlayer } from '../common/osb-brightcove-video-player/osb-brightcove-video-player';
import { OSBVideoImageOverlay } from '../common/osb-overlay/osb-video-image-overlay';
import {
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
} from '../osb-controller';
import {
    triggerRemoveVoucherCtaAnalytics,
    triggerBookingSummaryAndProgressBarAmendAnalytics,
} from '../analytics/osb-analytics';
import { OSBVideoLink } from '../common/osb-video-link/osb-video-link';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../common/osb-loader/osb-loader';
import { FormatAddress } from '../common/osb-common-components/osb-common-components';
import ServiceHandler from '../../../../services/service-handler';
import CampaignValidation from '../campaign-validation/campaign-validation';
import { useCampaignStatesStep } from '../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    stepName?: string;
    refreshBookingSummary?: () => void;
    refreshServiceTotal?: () => void;
    showVoucherLink: boolean;
    loadVoucherAlertMessage: (
        alertMessage: string,
        alertSuccess: boolean
    ) => void;
}

export const LightBookingSummary = (props: Props) => {
    const {
        osbServiceLocationDetail,
        setOSBServiceLocationStepPayload,
    } = useServiceLocationStep();
    const { osbDealerStep, callContentService } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbCalendarStep } = useCalendarStep();
    const {
        osbLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const {
        setOSBCampaignStates,
        getCampaignVoucherInfo,
    } = useCampaignStatesStep();
    const voucherDetails = getCampaignVoucherInfo();
    const { updateProgressBarStatus } = useStepProgressBar();
    const { osbImageUploadStep } = useOsbImageUpload();
    const { osbStep } = useOSBStep();
    const history = useHistory();
    const { httpState, dispatch } = useHttp();
    const { isMobileView } = useViewport();
    const [fireEvents] = useAnalytics();
    const [errorMsgService, setErrorMsgService] = useState('');
    const [reviewBookingContent, setReviewBookingContent] = useState<any>();
    const [osbAppMoreContent, setOsbAppMoreContent] = useState<any>();
    const [voucherContent, setVoucherContent] = useState<any>();
    const vehicleDetails = osbVehicleStep.vehicleDetails;
    const vehicleName =
        vehicleDetails?.modelName +
        ' ' +
        vehicleDetails?.buildDate.substring(0, 4);
    const dealerProfile = osbDealerStep.selectedDealerProfile;
    const [dateAndMonthFormat, setdateandMonthFormat] = useState('');
    const [isVideoOverlayOpened, setIsVideoOverlayOpened] = useState(false);
    const [brightCoveAccountId, setBrightCoveAccountId] = useState('');
    const [brightCoveVideoId, setBrightCoveVideoId] = useState('');
    const [brightCovePlayerId, setBrightCovePlayerId] = useState('');
    const [brightCoveVideoName, setBrightCoveVideoName] = useState('');
    const bookable = useAllOSBState();
    let dateFormat = '';

    const selectedServicesList = osbServiceStep.selectedServices;
    const selectedOtherOptionsServices = osbServiceStep.isMobileServiceSelected
        ? osbServiceLocationDetail.selectedOtherOptions
        : osbLightDeliveryServiceStep.selectedServices;
    const errorResponse = (error: any) => {
        if (error?.response) {
            const errorMessage = error?.response?.data?.error?.message;
            dispatch({ type: 'ERROR', error: errorMessage });
        }
    };

    const { osbCampaignStates } = useCampaignStatesStep();
    const potentialSavingAmount = useMemo(() => {
        return osbCampaignStates.campaignStates.reduce((total, item) => {
            if (item.state === 'VALID') {
                total += item.potentialSavings;
            }
            return total;
        }, 0);
    }, [osbCampaignStates]);

    const getReviewBookingContent = async () => {
        await ServiceHandler.OsbContentService.getOsbContentObject(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-review-booking'
        )
            .then(results => {
                setReviewBookingContent(results);
                dateFormat = results[
                    BOOKING_SUMMARY_CONTAINER_KEYS.DATE_FORMAT_SUMMARY
                ]
                    .toString()
                    .trim();
                setdateandMonthFormat(dateFormat);
                dispatch({ type: 'RESPONSE' });
            })
            .catch(error => errorResponse(error));
    };
    const getOsbAppMoreElementsContent = async () => {
        await ServiceHandler.OsbContentService.getOsbContentObject(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-app-more-elements'
        )
            .then(results => {
                setOsbAppMoreContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch(error => errorResponse(error));
    };

    const calculateTotalPrice = (selectedServices: any) => {
        let totalPrice = '0';
        let totalDiscountPrice = '0';
        if (selectedServices.length > 0) {
            totalPrice = selectedServices
                .map(
                    (result: { price: number }) =>
                        result.price >= 0 && result.price
                )
                .reduce((total: number, currentPrice: number) =>
                    currentPrice > 0 ? total + currentPrice : total
                );

            // if the total price is zero (0) then it is free.
            if (Number(totalPrice) <= 0) {
                totalPrice = '0';
            }

            totalDiscountPrice = selectedServices
                .map(
                    (result: { priceAfterDiscount: number }) =>
                        result.priceAfterDiscount >= 0 &&
                        result.priceAfterDiscount
                )
                .reduce((total: number, currentPrice: number) =>
                    currentPrice > 0 ? total + currentPrice : total
                );
            // if the total price is zero (0) then it is free.
            if (Number(totalDiscountPrice) <= 0) {
                totalDiscountPrice = '0';
            }
        }

        setOSBServiceStepPayload({
            totalPrice: totalPrice,
            totalDiscountPrice: totalDiscountPrice,
            totalPriceForAnalytics: totalDiscountPrice,
        });

        props.refreshServiceTotal?.();
    };

    const loadVoucherContent = async () => {
        await callContentService(OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_FRAGMENT_NAME)
            .then(results => {
                setVoucherContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response?.data?.error?.message;
                    dispatch({ type: 'ERROR', error: errorMessage });
                }
            });
    };

    const addDefaultDeliveryService = () => {
        const defaultService: ServiceData | null = getDefaultDeliveryOption(
            osbServiceStep?.dealerServiceInfo?.dealerServices
                ?.additionalServices
        );

        if (defaultService) {
            if (defaultService.subType === DELIVERY_STEPS_KEYS.FROM_DB) {
                setOSBLightDeliveryServiceStep({
                    selectedServices: [defaultService],
                });
            }
        }
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getReviewBookingContent();
        getOsbAppMoreElementsContent();
        loadVoucherContent();
        if (
            osbLightDeliveryServiceStep.selectedServices.length === 0 &&
            !osbServiceStep.isMobileServiceSelected &&
            !osbDealerStep.isDealerHasDeliveryServices
        ) {
            addDefaultDeliveryService();
        }
    }, []);

    useEffect(() => {
        calculateTotalPrice([
            ...osbServiceStep.selectedServices,
            ...osbLightDeliveryServiceStep.selectedServices,
            ...osbServiceLocationDetail.selectedOtherOptions,
        ]);
    }, [
        osbServiceStep.selectedServices,
        osbLightDeliveryServiceStep.selectedServices,
        osbServiceLocationDetail.selectedOtherOptions,
    ]);

    const [openModel, setOpenModel] = useState(false);

    const setStatus = (status: boolean) => {
        setOpenModel(status);
    };

    const openModelData = () => {
        setOpenModel(true);
    };

    const goToDealerStep = () => {
        setOSBServiceStepPayload({
            voucherCode: '',
            voucherDesc: '',
        });
        triggerBookingSummaryAndProgressBarAmendAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            LIGHT_JOURNEY_ANALYTICS.AMEND_DEALER,
            LIGHT_JOURNEY_ANALYTICS.BOOK_SUMMARY_CONTENT_TYPE
        );
        history.push(
            buildNavigationUrl(OsbPathDealerStep(), osbStep.UrlQueryParams)
        );
    };
    const loadServiceData = async (
        voucherCode: string,
        vehicleData: VehicleData,
        selectedDealerCode: string
    ) => {
        // TODO: Hack to avoid the race condition of updating global state and making a call to api service.
        if (!voucherCode) {
            bookable.campaignSelections = [];
        }
        await ServiceHandler.OsbDealerServices.getServicesList({
            dealerCode: selectedDealerCode,
            vehicleData: vehicleData,
            vouchers: voucherCode ? [voucherCode] : [],
            serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            requestSourceType: osbStep.source,
            bookable: bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(results => {
                setErrorMsgService('');
                setOSBServiceStepPayload({
                    dealerServiceInfo: results.value,
                    voucherDesc:
                        results.value.dealerServices.serviceVouchers.length > 0
                            ? results.value.dealerServices.serviceVouchers[0]
                                  .description
                            : '',
                });

                if (
                    Array.isArray(results.value.dealerServices?.campaignStates)
                ) {
                    setOSBCampaignStates({
                        campaignStates: [
                            ...results.value.dealerServices.campaignStates,
                        ],
                    });
                }

                dispatch({ type: 'RESPONSE' });
                props.refreshBookingSummary?.();
            })
            .catch((error: ErrorResponse) => {
                if (error?.errorMessage) {
                    setErrorMsgService(error.errorMessage);
                } else {
                    const errorMessage = osbStep.internalErrorMessage;
                    setErrorMsgService(errorMessage);
                }
            });
    };

    const setAppliedVoucher = async (voucherCode: string) => {
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerCode =
            osbDealerStep.selectedDealerProfile.dealerCode || '';
        if (vehicleData) {
            await loadServiceData(voucherCode, vehicleData, selectedDealerCode);
        }
    };

    const refreshServicesData = async (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedServices = getVoucherAppliedSelectedServices(
            dealerServices,
            osbServiceStep.selectedServices,
            isVoucherApplied
        );

        setOSBServiceStepPayload({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedServices
            ),
        });
    };

    const refreshDeliveryData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedDeliveryOptions = getVoucherAppliedSelectedDeliveryOptions(
            dealerServices,
            osbLightDeliveryServiceStep.selectedServices,
            isVoucherApplied
        );

        setOSBLightDeliveryServiceStep({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedDeliveryOptions
            ),
        });
    };

    const refreshLocationData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedLocationOptions = getVoucherAppliedSelectedLocationOptions(
            dealerServices,
            osbServiceLocationDetail.selectedOtherOptions,
            isVoucherApplied
        );

        setOSBServiceLocationStepPayload({
            selectedOtherOptions: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedLocationOptions
            ),
        });
    };

    const removeVoucherDetails = () => {
        triggerRemoveVoucherCtaAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents,
            osbServiceStep.voucherCode,
            LIGHT_JOURNEY_ANALYTICS.REMOVE_VOUCHER_CODE
        );
        setOSBServiceStepPayload({ voucherCode: '' });
        setAppliedVoucher('');
        refreshServicesData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshDeliveryData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshLocationData(osbServiceStep.dealerServiceInfo.dealerServices);
        setOpenModel(false);
    };

    const goToCalendarStep = () => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DATETIME, false, true);
        triggerBookingSummaryAndProgressBarAmendAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            LIGHT_JOURNEY_ANALYTICS.AMEND_DATE_TIME,
            LIGHT_JOURNEY_ANALYTICS.BOOK_SUMMARY_CONTENT_TYPE
        );
        history.push(
            buildNavigationUrl(OsbPathCalenderStep(), osbStep.UrlQueryParams)
        );
    };

    const goToServicesStep = (reviewAmendField: string) => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false, true);
        triggerBookingSummaryAndProgressBarAmendAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            reviewAmendField,
            LIGHT_JOURNEY_ANALYTICS.BOOK_SUMMARY_CONTENT_TYPE
        );
        history.push(
            buildNavigationUrl(OsbPathServicesStep(), osbStep.UrlQueryParams)
        );
    };

    const goToServiceLocation = () => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.LOCATION, false, true);
        history.push(
            buildNavigationUrl(
                OsbPathServiceLocationStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const goToDeliveryOptions = () => {
        if (!osbServiceStep.isMobileServiceSelected) {
            updateProgressBarStatus(
                STEP_PROGRESS_BAR_KEYS.DELIVERY,
                false,
                true
            );
            triggerBookingSummaryAndProgressBarAmendAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_DELIVERY,
                LIGHT_JOURNEY_ANALYTICS.BOOK_SUMMARY_CONTENT_TYPE
            );
            history.push(
                buildNavigationUrl(
                    OsbPathDeliveryStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else {
            goToServiceLocation();
        }
    };

    const showVideoOverlay = (
        accountId = '',
        videoId = '',
        playerId = '',
        videoName = ''
    ) => {
        if (accountId && videoId && playerId) {
            setIsVideoOverlayOpened(true);
            setBrightCoveAccountId(accountId);
            setBrightCoveVideoId(videoId);
            setBrightCovePlayerId(playerId);
            setBrightCoveVideoName(videoName);
        }
    };

    const hideVideoOverlay = () => {
        setIsVideoOverlayOpened(false);
        setBrightCoveAccountId('');
        setBrightCoveVideoId('');
        setBrightCovePlayerId('');
        setBrightCoveVideoName('');
    };

    function specificServiceListFormat(
        serviceData: ServiceData[],
        formatType: string,
        dropOff?: boolean
    ) {
        return (
            <div className="services-list">
                {filterObjectsBySectionType(
                    serviceData,
                    formatType,
                    dropOff
                ).map((selectedService: ServiceData, index: number) => (
                    <div key={`${index + Date.now()}`}>
                        <div className="single-service">
                            <div className="service-name">
                                {selectedService.serviceName}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.OTHER_CUSTOMSERVICE && (
                                        <div className="light-recall-service-desc">
                                            - {osbServiceStep.otherServiceDesc}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_RECALL && (
                                        <div className="light-recall-service-desc">
                                            {osbServiceStep.recallServiceDescription.map(
                                                (desc, idx) => (
                                                    <div
                                                        key={`${idx +
                                                            Date.now()}`}
                                                    >
                                                        {desc}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_VHA && (
                                        <div className="light-recall-service-desc">
                                            {osbServiceStep.vhaServiceDescription?.map(
                                                (desc, idx) => (
                                                    <div
                                                        key={`${idx +
                                                            Date.now()}`}
                                                    >
                                                        {desc}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_OIL && (
                                        <div className="light-recall-service-desc">
                                            <div>
                                                {
                                                    osbServiceStep.oilLifeServiceReminderDescription
                                                }
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {
                                <div className="service-price">
                                    {(osbServiceStep.voucherDesc.length > 0 &&
                                        osbServiceStep.voucherCode.length >
                                            0) ||
                                    hasAutomaticVoucherApplied(
                                        osbServiceStep.totalDiscountPrice,
                                        osbServiceStep.totalPrice
                                    ) ? (
                                        Number(
                                            selectedService.priceAfterDiscount
                                        ) > 0 ? (
                                            <>
                                                <div>
                                                    {getFormattedPrice(
                                                        selectedService.price,
                                                        osbStep
                                                    )}
                                                </div>
                                            </>
                                        ) : Number(
                                              selectedService.priceAfterDiscount
                                          ) === 0 ? (
                                            <label>
                                                {!httpState.isLoading &&
                                                    getObjectFromAEMJsonArray(
                                                        SERVICES_STEP_KEYS.FREE_LABEL,
                                                        osbAppMoreContent
                                                    )}
                                            </label>
                                        ) : (
                                            getFormattedPrice(
                                                selectedService.price,
                                                osbStep
                                            )
                                        )
                                    ) : Number(selectedService.price) > 0 ? (
                                        getFormattedPrice(
                                            selectedService.price,
                                            osbStep
                                        )
                                    ) : Number(selectedService.price) === 0 ? (
                                        <label>
                                            {' '}
                                            {!httpState.isLoading &&
                                                getObjectFromAEMJsonArray(
                                                    SERVICES_STEP_KEYS.FREE_LABEL,
                                                    osbAppMoreContent
                                                )}
                                        </label>
                                    ) : (
                                        getFormattedPrice(
                                            selectedService.price,
                                            osbStep
                                        )
                                    )}
                                </div>
                            }
                        </div>
                        <OSBVideoLink
                            selectedService={selectedService}
                            showVideoOverlay={showVideoOverlay}
                        />
                    </div>
                ))}
            </div>
        );
    }

    function deliveryServiceListFormat(
        serviceData: ServiceData[],
        formatType: string,
        dropOff?: boolean
    ) {
        return (
            <div className="services-list">
                {filterObjectsBySectionType(
                    serviceData,
                    formatType,
                    dropOff
                ).map((selectedOptionalService: any, index: number) => (
                    <div key={`${index + Date.now()}`}>
                        <div className="single-service">
                            <div className="service-name">
                                {selectedOptionalService.serviceName}
                            </div>
                            {
                                <div className="service-price">
                                    {(osbServiceStep.voucherDesc.length > 0 &&
                                        osbServiceStep.voucherCode.length >
                                            0) ||
                                    hasAutomaticVoucherApplied(
                                        osbServiceStep.totalDiscountPrice,
                                        osbServiceStep.totalPrice
                                    ) ? (
                                        Number(
                                            selectedOptionalService.priceAfterDiscount
                                        ) > 0 ? (
                                            <>
                                                <div>
                                                    {getFormattedPrice(
                                                        selectedOptionalService.price,
                                                        osbStep
                                                    )}
                                                </div>
                                            </>
                                        ) : Number(
                                              selectedOptionalService.priceAfterDiscount
                                          ) === 0 ? (
                                            <label>
                                                {!httpState.isLoading &&
                                                    getObjectFromAEMJsonArray(
                                                        SERVICES_STEP_KEYS.FREE_LABEL,
                                                        osbAppMoreContent
                                                    )}
                                            </label>
                                        ) : (
                                            getFormattedPrice(
                                                selectedOptionalService.price,
                                                osbStep
                                            )
                                        )
                                    ) : Number(selectedOptionalService.price) >
                                      0 ? (
                                        getFormattedPrice(
                                            selectedOptionalService.price,
                                            osbStep
                                        )
                                    ) : Number(
                                          selectedOptionalService.price
                                      ) === 0 &&
                                      selectedOptionalService.serviceOption !==
                                          DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD ? (
                                        <label>
                                            {' '}
                                            {!httpState.isLoading &&
                                                getObjectFromAEMJsonArray(
                                                    SERVICES_STEP_KEYS.FREE_LABEL,
                                                    osbAppMoreContent
                                                )}
                                        </label>
                                    ) : (
                                        selectedOptionalService.serviceOption !==
                                            DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD &&
                                        getFormattedPrice(
                                            selectedOptionalService.price,
                                            osbStep
                                        )
                                    )}
                                </div>
                            }
                        </div>
                        <OSBVideoLink
                            selectedService={selectedOptionalService}
                            showVideoOverlay={showVideoOverlay}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="osb-review-booking-component-container">
            {!isMobileView && (
                <div className="review-booking-title-style">
                    {getObjectFromAEMJsonArray(
                        BOOKING_SUMMARY_CONTAINER_KEYS.BOOKING_SUMMARY_HEADING,
                        reviewBookingContent
                    )}
                </div>
            )}

            <div className="osb-review-booking-container">
                {!isMobileView && <div className="hr-line-style"></div>}
                {errorMsgService.length > 0 ? (
                    <div className="services-flow-error">
                        {parse(
                            getObjectFromAEMJsonArray(
                                BOOKING_SUMMARY_CONTAINER_KEYS.BOOKING_ERROR,
                                reviewBookingContent
                            ).toString()
                        )}
                    </div>
                ) : (
                    ''
                )}
                {httpState.isLoading && <OsbLoader />}
                {osbDealerStep.selectedDealerProfile && (
                    <div className="dealer-section">
                        <div className="edit-section">
                            <div className="dealer-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.DEALER_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            {!osbStep.isDealerDeepLinked &&
                                !osbStep.isRetrieveFlow && (
                                    <div
                                        className="change-link"
                                        onClick={goToDealerStep}
                                    >
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                )}
                        </div>

                        <div className="dealer-name">
                            {dealerProfile.dealerName}
                        </div>

                        <div className="dealer-address">
                            <FormatAddress
                                addressData={dealerProfile}
                                addressOrder={osbStep.dealerAddressOrder}
                                isDisplaySingleRow={false}
                            />
                        </div>
                        <div className="hr-line-style"></div>
                    </div>
                )}
                {!OsbUtilService.isEmptyObject(vehicleDetails) &&
                    vehicleDetails?.vin !== '' &&
                    vehicleDetails?.vin !== null && (
                        <div className="vehicle-section">
                            <div className="edit-section">
                                <div className="vehicle-title">
                                    {getObjectFromAEMJsonArray(
                                        'Vehicle_Label',
                                        reviewBookingContent
                                    )}
                                </div>
                                {osbStep.isRetrieveFlow ||
                                history.location.pathname ===
                                    OsbPathServicesStep() ? (
                                    <></>
                                ) : (
                                    <div
                                        className="change-link"
                                        onClick={() =>
                                            goToServicesStep(
                                                LIGHT_JOURNEY_ANALYTICS.AMEND_VEHICLE
                                            )
                                        }
                                    >
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="vehicle-name">{vehicleName}</div>
                            <div className="hr-line-style"></div>
                        </div>
                    )}
                {selectedServicesList.length > 0 && (
                    <div className="osb-light-booking-summary-service-section">
                        <div className="edit-section">
                            <div className="service-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.SERVICES_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            {props.stepName !==
                                STEP_PROGRESS_BAR_KEYS.SERVICE && (
                                <div
                                    className="service-change-link"
                                    data-testid="servicesAmend"
                                    onClick={() =>
                                        goToServicesStep(
                                            LIGHT_JOURNEY_ANALYTICS.AMEND_SERVICES
                                        )
                                    }
                                >
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                        reviewBookingContent
                                    )}
                                </div>
                            )}
                        </div>
                        <div>
                            {selectedServicesList[0].serviceOption ===
                            SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE ? (
                                <>
                                    <div className="service-sub-title">
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.GENERAL_APPOINTMENT_SERVICE_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                    <OSBVideoLink
                                        selectedService={
                                            selectedServicesList[0]
                                        }
                                        showVideoOverlay={showVideoOverlay}
                                    />
                                </>
                            ) : (
                                <div className="service-sub-title">
                                    {!httpState.isLoading
                                        ? osbServiceStep.isMobileServiceSelected
                                            ? getObjectFromAEMJsonArray(
                                                  BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE,
                                                  reviewBookingContent
                                              )
                                            : getObjectFromAEMJsonArray(
                                                  BOOKING_SUMMARY_CONTAINER_KEYS.SPECIFIC_SERVICES_LABEL,
                                                  reviewBookingContent
                                              )
                                        : ''}
                                </div>
                            )}
                        </div>
                        <div>
                            {specificServiceListFormat(
                                selectedServicesList,
                                SERVICE_FLOW.SERVICE_PRICE_OR_FREE
                            )}
                        </div>
                        {osbImageUploadStep.uploadedImage &&
                            osbImageUploadStep.uploadedImage?.length > 0 &&
                            getObjectFromAEMJsonArray(
                                SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL,
                                reviewBookingContent
                            ) && (
                                <div className="image-upload-count">
                                    {`${getObjectFromAEMJsonArray(
                                        SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL,
                                        reviewBookingContent
                                    )} (${
                                        osbImageUploadStep.uploadedImage?.length
                                    })`}
                                </div>
                            )}

                        <div className="hr-line-style"></div>
                    </div>
                )}
                {selectedServicesList.length === 0 &&
                    osbServiceStep.isMobileServiceSelected && (
                        <div className="osb-light-booking-summary-service-section">
                            <div className="edit-section">
                                <div className="service-title">
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.SERVICES_LABEL,
                                        reviewBookingContent
                                    )}
                                </div>
                                {props.stepName !==
                                    STEP_PROGRESS_BAR_KEYS.SERVICE && (
                                    <div
                                        className="service-change-link"
                                        data-testid="servicesAmend"
                                        onClick={() =>
                                            goToServicesStep(
                                                LIGHT_JOURNEY_ANALYTICS.AMEND_SERVICES
                                            )
                                        }
                                    >
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="service-sub-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE,
                                    reviewBookingContent
                                )}
                            </div>
                            <div className="hr-line-style"></div>
                        </div>
                    )}
                {showServiceLocationDetails(
                    osbServiceStep.isMobileServiceSelected,
                    osbServiceLocationDetail
                ) &&
                    props.stepName !== STEP_PROGRESS_BAR_KEYS.LOCATION && (
                        <div className="service-location-container">
                            <div className="edit-section">
                                <div className="service-location-title">
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.SERVICE_LOCATION_TITLE,
                                        reviewBookingContent
                                    )}
                                </div>
                                {props.stepName !==
                                    STEP_PROGRESS_BAR_KEYS.LOCATION && (
                                    <div
                                        className="change-link"
                                        data-testid="serviceLocationAmend"
                                        onClick={goToServiceLocation}
                                    >
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="service-location-details">
                                <div className="customer-address">
                                    <FormatAddress
                                        addressData={osbServiceLocationDetail}
                                        addressOrder={
                                            osbStep.dealerAddressOrder
                                        }
                                        isDisplaySingleRow={false}
                                    />
                                </div>

                                <div className="parking-type">
                                    {osbServiceLocationDetail.parkingType}
                                </div>
                                {osbServiceLocationDetail.comment.trim()
                                    .length > 0 && (
                                    <div className="sl-comments">
                                        {`"${osbServiceLocationDetail.comment}"`}
                                    </div>
                                )}
                            </div>

                            <div className="hr-line-style"></div>
                        </div>
                    )}
                {filterObjectsBySectionType(
                    selectedOtherOptionsServices,
                    SERVICE_FLOW.SERVICE_PRICE_OR_FREE
                ).length > 0 && (
                    <div className="osb-light-booking-summary-service-section">
                        <div className="edit-section">
                            <div className="service-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.DELIVERY_SERVICES_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            {props.stepName !==
                                STEP_PROGRESS_BAR_KEYS.DELIVERY &&
                                osbDealerStep.isDealerHasDeliveryServices &&
                                props.stepName !==
                                    STEP_PROGRESS_BAR_KEYS.LOCATION && (
                                    <div
                                        className="service-change-link"
                                        data-testid="servicesAmend"
                                        onClick={goToDeliveryOptions}
                                    >
                                        {getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                            reviewBookingContent
                                        )}
                                    </div>
                                )}
                        </div>

                        <div>
                            {deliveryServiceListFormat(
                                selectedOtherOptionsServices,
                                SERVICE_FLOW.SERVICE_PRICE_OR_FREE,
                                true
                            )}
                        </div>

                        <div className="hr-line-style"></div>
                    </div>
                )}

                {selectedOtherOptionsServices.length > 0 && (
                    <div className="osb-light-booking-summary-service-section">
                        {selectedOtherOptionsServices.map(
                            (selectedOptionalService: any, index: any) => (
                                <>
                                    <div key={`${index + Date.now()}`}>
                                        {selectedOptionalService.convenienceOptionTypes &&
                                            isConvenienceOptionIncluded(
                                                selectedOptionalService.convenienceOptionTypes,
                                                SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE
                                            ) &&
                                            (osbCalendarStep.selectedPickupDate ||
                                                osbServiceLocationDetail.streetName !==
                                                    '') && (
                                                <>
                                                    <div className="edit-section">
                                                        <div className="service-title">
                                                            {getObjectFromAEMJsonArray(
                                                                BOOKING_SUMMARY_CONTAINER_KEYS.PICKUP_AND_DELIVERY_LOCATION_LABEL,
                                                                reviewBookingContent
                                                            )}
                                                        </div>
                                                        {props.stepName !==
                                                            STEP_PROGRESS_BAR_KEYS.DELIVERY &&
                                                            osbDealerStep.isDealerHasDeliveryServices &&
                                                            props.stepName !==
                                                                STEP_PROGRESS_BAR_KEYS.LOCATION && (
                                                                <div
                                                                    className="service-change-link"
                                                                    data-testid="servicesAmend"
                                                                    onClick={
                                                                        goToDeliveryOptions
                                                                    }
                                                                >
                                                                    {getObjectFromAEMJsonArray(
                                                                        BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                                                        reviewBookingContent
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="pick-up-details">
                                                        <div className="selected-pickup-date">
                                                            {osbCalendarStep
                                                                .selectedPickupDate
                                                                .length > 0 &&
                                                                OsbUtilService.constructDisplayDate(
                                                                    osbCalendarStep.selectedPickupDate,
                                                                    dateAndMonthFormat,
                                                                    OsbUtilService.getAppConfiguration()
                                                                        .languageRegionCode
                                                                )}
                                                        </div>
                                                        <div className="selected-pickup-time">
                                                            {osbCalendarStep
                                                                .selectedPickupTime
                                                                .length > 0 &&
                                                                OsbUtilService.getTimeBasedOnAuthoring(
                                                                    osbCalendarStep.selectedPickupTime,
                                                                    osbStep.twelveHrsTimeFormat,
                                                                    osbStep.amTimeLabel,
                                                                    osbStep.pmTimeLabel,
                                                                    osbStep.timeSuffixLabel
                                                                )}
                                                        </div>
                                                        <div className="customer-address">
                                                            <FormatAddress
                                                                addressData={
                                                                    osbServiceLocationDetail
                                                                }
                                                                addressOrder={
                                                                    osbStep.dealerAddressOrder
                                                                }
                                                                isDisplaySingleRow={
                                                                    false
                                                                }
                                                            />
                                                        </div>

                                                        {osbServiceLocationDetail.comment.trim()
                                                            .length > 0 && (
                                                            <div className="sl-comments">
                                                                {`"${osbServiceLocationDetail.comment}"`}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="hr-line-style"></div>
                                                </>
                                            )}
                                    </div>
                                </>
                            )
                        )}
                    </div>
                )}

                {filterObjectsBySectionType(
                    [...selectedServicesList, ...selectedOtherOptionsServices],
                    SERVICE_FLOW.SERVICE_POR_OR_EMPTY,
                    false
                ).length > 0 && (
                    <div className="osb-light-booking-summary-service-section">
                        <div className="edit-section">
                            <div className="service-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.SERVICES_WITHOUT_PRICE_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            {props.stepName !==
                                STEP_PROGRESS_BAR_KEYS.SERVICE && (
                                <div
                                    className="service-change-link"
                                    data-testid="servicesAmend"
                                    onClick={() =>
                                        goToServicesStep(
                                            LIGHT_JOURNEY_ANALYTICS.AMEND_SERVICES
                                        )
                                    }
                                >
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                        reviewBookingContent
                                    )}
                                </div>
                            )}
                        </div>

                        <div>
                            {deliveryServiceListFormat(
                                selectedOtherOptionsServices,
                                SERVICE_FLOW.SERVICE_POR_OR_EMPTY
                            )}
                            {specificServiceListFormat(
                                selectedServicesList,
                                SERVICE_FLOW.SERVICE_POR_OR_EMPTY
                            )}
                        </div>

                        <div className="hr-line-style"></div>
                    </div>
                )}

                {isVideoOverlayOpened && (
                    <OSBVideoImageOverlay
                        isOpen={isVideoOverlayOpened}
                        backButtonText={osbStep.videoPlayerBackButtonLabel}
                        hideVideoOverlay={hideVideoOverlay}
                        isVideo={true}
                    >
                        <OSBBrightcoveVideoPlayer
                            accountId={brightCoveAccountId}
                            videoId={brightCoveVideoId}
                            playerId={brightCovePlayerId}
                            videoName={brightCoveVideoName}
                        />
                    </OSBVideoImageOverlay>
                )}
                {osbCalendarStep.selectedDate && (
                    <div className="datetime-section">
                        <div className="edit-section">
                            <div className="datetime-title">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.DATE_TIME_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            {props.stepName !==
                                STEP_PROGRESS_BAR_KEYS.DATETIME && (
                                <span
                                    className="change-link"
                                    data-testid="calendarAmend"
                                    onClick={goToCalendarStep}
                                >
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.AMEND_LABEL,
                                        reviewBookingContent
                                    )}
                                </span>
                            )}
                        </div>

                        <div className="date-time">
                            {OsbUtilService.constructDisplayDate(
                                osbCalendarStep.selectedDate,
                                dateAndMonthFormat,
                                OsbUtilService.getAppConfiguration()
                                    .languageRegionCode
                            )}
                            {osbCalendarStep.selectedTime && (
                                <div className="time-style">
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.TIME_AT_LABEL,
                                        reviewBookingContent
                                    )}{' '}
                                    {OsbUtilService.getTimeBasedOnAuthoring(
                                        osbCalendarStep.selectedTime,
                                        osbStep.twelveHrsTimeFormat,
                                        osbStep.amTimeLabel,
                                        osbStep.pmTimeLabel,
                                        osbStep.timeSuffixLabel
                                    )}
                                </div>
                            )}
                            {osbCalendarStep.selectedTimeRange && (
                                <div className="time-style">
                                    {getObjectFromAEMJsonArray(
                                        BOOKING_SUMMARY_CONTAINER_KEYS.TIME_BETWEEN_LABEL,
                                        reviewBookingContent
                                    )}{' '}
                                    {osbCalendarStep.selectedTimeRange}
                                </div>
                            )}
                        </div>

                        <div className="hr-line-style"></div>
                    </div>
                )}
                {isMobileView && (
                    <>
                        {osbStep.isVoucherDeepLinked &&
                            !osbStep.isValidVoucher && (
                                <div className="invalid-voucher">
                                    {getObjectFromAEMJsonArray(
                                        OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_INVALID_ERROR,
                                        voucherContent
                                    )}
                                </div>
                            )}
                        {osbServiceStep.voucherCode !== '' && (
                            <>
                                <div className="voucher-title-mobile">
                                    <img
                                        src={CheckedIcon}
                                        alt="Check"
                                        className="voucher-check-img-mobile"
                                    ></img>
                                    <div>
                                        {osbServiceStep.voucherCode} &nbsp;
                                        {getObjectFromAEMJsonArray(
                                            OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_APPLIED,
                                            reviewBookingContent
                                        )}
                                    </div>
                                </div>
                                {voucherDetails.voucherDescription && (
                                    <div
                                        className="voucher-desc-mobile"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                voucherDetails.voucherDescription,
                                        }}
                                    />
                                )}
                                <div className="campaign-validation-in-summary">
                                    <CampaignValidation />
                                </div>
                                <div className="hr-line-style"></div>
                            </>
                        )}

                        {isPOROrNoPriceServiceSelected(
                            selectedServicesList,
                            selectedOtherOptionsServices
                        ) ? (
                            <>
                                <div className="voucher-disclaimer">
                                    {getObjectFromAEMJsonArray(
                                        OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_DISCLAIMER,
                                        reviewBookingContent
                                    )}
                                </div>
                                <div className="hr-line-style"></div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                {!isMobileView && (
                    <>
                        {osbStep.isVoucherDeepLinked &&
                            !osbStep.isValidVoucher && (
                                <div className="invalid-voucher">
                                    {getObjectFromAEMJsonArray(
                                        OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_INVALID_ERROR,
                                        voucherContent
                                    )}
                                </div>
                            )}
                        {osbServiceStep.voucherCode !== '' ? (
                            <div className="light-review-voucher-section">
                                <div className="light-review-hr-line-style"></div>

                                <div className="light-review-voucher-title">
                                    <img
                                        src={CheckedIcon}
                                        alt="Check"
                                        className="voucher-check-img"
                                    ></img>
                                    <div className="voucher-section">
                                        <p className="voucher-code">
                                            {osbServiceStep.voucherCode} &nbsp;
                                            {getObjectFromAEMJsonArray(
                                                OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_APPLIED,
                                                reviewBookingContent
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {voucherDetails.voucherDescription && (
                                    <div
                                        className="light-voucher-des"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                voucherDetails.voucherDescription,
                                        }}
                                    />
                                )}
                                <div
                                    className="light-remove-voucher-link"
                                    onClick={removeVoucherDetails}
                                >
                                    {getObjectFromAEMJsonArray(
                                        OSB_LIGHTT_VOUCHER_KEYS.REMOVE_VOUCHER,
                                        reviewBookingContent
                                    )}
                                </div>
                                <div className="campaign-validation-in-summary">
                                    <CampaignValidation />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="add-voucher-link"
                                    onClick={() => openModelData()}
                                >
                                    {!httpState.isLoading &&
                                        props.showVoucherLink &&
                                        getObjectFromAEMJsonArray(
                                            BOOKING_SUMMARY_CONTAINER_KEYS.ADD_VOUCHER_LABEL,
                                            osbAppMoreContent
                                        )}

                                    {openModel && (
                                        <div>
                                            <LightJourneyVouchers
                                                openPopupData={openModel}
                                                setStatus={setStatus}
                                                refreshBookingSummary={
                                                    props.refreshBookingSummary
                                                }
                                                refreshServicesData={
                                                    refreshServicesData
                                                }
                                                refreshDeliveryData={
                                                    refreshDeliveryData
                                                }
                                                refreshLocationData={
                                                    refreshLocationData
                                                }
                                                loadVoucherAlertMessage={
                                                    props.loadVoucherAlertMessage
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {osbServiceStep.voucherCode !== '' ||
                        props.showVoucherLink ? (
                            <div className="hr-line-style"></div>
                        ) : (
                            <div></div>
                        )}
                        <div className="voucher-disclaimer">
                            {isPOROrNoPriceServiceSelected(
                                selectedServicesList,
                                selectedOtherOptionsServices
                            ) &&
                                getObjectFromAEMJsonArray(
                                    OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_DISCLAIMER,
                                    reviewBookingContent
                                )}
                        </div>
                        <div className="total-price-section">
                            <div className="total-price-label">
                                {getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.ESTIMATED_COST_LABEL,
                                    reviewBookingContent
                                )}
                            </div>
                            <div className="total-price">
                                <>
                                    <div>
                                        {calculateTotalPriceSummary(
                                            osbStep,
                                            osbServiceStep,
                                            osbLightDeliveryServiceStep.selectedServices,
                                            osbServiceLocationDetail.selectedOtherOptions,
                                            potentialSavingAmount
                                        )}
                                    </div>
                                    {potentialSavingAmount > 0 && (
                                        <div className="discountedPrice">
                                            {getActualPrice(
                                                potentialSavingAmount + '',
                                                osbServiceStep.totalPrice,
                                                osbStep
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
