import React from 'react';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import { useExperienceContent } from '../../../../../hooks/use-server-content';
import { FeatureLinksListFragment } from '../../../../../models/experiencefragments/featurelinkslist';
import { Article } from '../../../article/article';
import { getVehicleDataSelector } from '../../../../../services/content-service/content-service-util';
import './feature-links-list.scss';
import { Heading } from '../../../../common/heading/heading';

interface Props {
    vehicleAttributes?: VehicleAttributes;
}

export const FeatureLinksList = (props: Props) => {
    const [experienceContent] = useExperienceContent<FeatureLinksListFragment>(
        'vehicles',
        getVehicleDataSelector('feature-links-list', props.vehicleAttributes),
        'featurelinkslist',
        undefined,
        true
    );
    const sectionId =
        experienceContent &&
        experienceContent.title?.replace(/ /g, '-').toLowerCase();
    return (
        <>
            {experienceContent && !experienceContent.hide && (
                <article className="articles-links-container" id={sectionId!}>
                    <section id="HL4">
                        <Heading type="h2" className="articles-links-title">
                            {experienceContent.title}
                        </Heading>
                    </section>
                    <section className="articles-links">
                        {experienceContent.categories.map((article, index) => {
                            return (
                                <>
                                    <div
                                        key={`article-wrapper-${index}`}
                                        className="article-wrapper"
                                    >
                                        <Article
                                            key={index}
                                            articleCategory={article}
                                            index={index + 1}
                                        />
                                    </div>
                                    {index <
                                        experienceContent.categories.length -
                                            1 && (
                                        <div
                                            key={`article-divider-${index}`}
                                            className="article-divider"
                                        />
                                    )}
                                </>
                            );
                        })}
                    </section>
                </article>
            )}
        </>
    );
};
