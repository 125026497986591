import React, { useEffect, useState } from 'react';
import ServicePriceCalculatorDesktopSearch from './service-price-input/service-price-calculator-desktop-search';
import ServicePriceCalculatorMobileSearch from './service-price-input/service-price-calculator-mobile-search';
import {
    DealerAdditionalInfo,
    DealerDetails,
    DealerProfileInfo,
} from '../../../models/osb-model/osb-dealer-info';
import ServicePriceCalculatorDesktopDealerDetails from './service-price-calculator-result/service-price-calculator-desktop-dealer-details';
import ServicePriceCalculatorMobileMapDealerDetails from './service-price-calculator-result/service-price-calculator-mobile-map-dealer-details';
import ServicePriceCalculatorMobileListDealerDetails from './service-price-calculator-result/service-price-calculator-mobile-list-dealer-details';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';
import './service-price-calculator-wrapper.scss';
import {
    useDealerStep,
    useHttp,
    useOSBStep,
    useVehicleStep,
} from '../../../hooks/owners-osb';
import ServiceHandler from '../../../services/service-handler';
import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import {
    PRICE_CALCULATOR_ANALYTICS,
    PRICE_CALCULATOR_CONSTANTS,
    PRICE_CALCULATOR_RESULT_CONSTANTS,
} from './price-calculator-constant';
import { VehicleData } from '../../../models/osb-model/osb-vehicle-step';
import {
    findDealerFromMarketDealers,
    formatRegVinNumber,
    getObjectFromAEMJson,
    getVehiclePreferredDealer,
    getCustomerPreferredDealers,
} from '../owners-osb/osb-utils';
import { ContentElements } from '../../../models/priceCalculator-content-details';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import VehicleImageService from '../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import GoogleMapService from '../../../services/osb-service/google-map-service/google-map-service';

import DealerSearchService from '../../../services/osb-service/google-map-service/dealer-search-service';

import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { OsbLoader } from '../../common/osb-loader/osb-loader';
import useUserProfile from '../../../hooks/use-user-profile';
import { limitArray } from './service-price-input/service-price-calculator-search';
import OSBBanner from '../owners-osb/osb-banner/osb-banner';
import { useHistory, useLocation } from 'react-router-dom';
import useMarketDealerInfoService from '../../../hooks/owners-osb/use-market-dealerInfo-service';
import { useAnalytics } from '../../../hooks/use-analytics';
import {
    triggerDealerSearchLoadAnalytics,
    triggerGetQuoteAnalytics,
    triggerInternalCtaAnalytics,
} from './analytics/price-calculator-analytics';
import {
    API_REQUEST_SOURCE_TYPE,
    SERVICE_DELIVERY_TYPE,
} from '../owners-osb/osb-constant';
interface Props {
    priceCalculatorContent: ContentElements[];
}
const ServicePriceCalculatorWrapper = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const history = useHistory();
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const [vehicleApiError, setVehicleApiError] = useState('');
    const [serviceApiError, setServiceApiError] = useState('');
    const { osbDealerStep, setOSBDealerStepPayload } = useDealerStep();
    const [vinSelectionType, setVinSelectionType] = useState('');
    const profile = useUserProfile();
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const [preferredDealerIds, setPreferredDealerIds] = useState<string[]>([]);
    const { httpState, dispatch } = useHttp();

    const { isMobileView } = ViewportUtil();
    const [findMyVinContent, setFindMyVinContent] = useState<ContentElements[]>(
        []
    );
    const [filteredRecord, setFilteredRecord] = useState<DealerProfileInfo[]>(
        []
    );
    const [
        priceCalculatorResultContent,
        setPriceCalculatorResultContent,
    ] = useState<ContentElements[]>([]);
    const [errorMsgDealer, setErrorMsgDealer] = useState('');
    const [vehiclePicUrl, setVehiclePicUrl] = useState<string>();
    const [vehicleInfoRequested, setVehicleInfoRequested] = useState(false);
    const [
        numberOfLeadingCharToStrip,
        setNumberOfLeadingCharToStrip,
    ] = useState<number>(2);
    const [isViewMoreButtonVisible, setIsViewMoreButtonVisible] = useState<
        boolean
    >(false);
    const [allDealersShown, setAllDealersShown] = useState<DealerProfileInfo[]>(
        []
    );
    const [isVehicleLoading, setIsVehicleLoading] = useState(false);
    const [isMapView, setIsMapView] = useState<boolean>(true);
    const fetchVehicleStepContent = async (modal: string) => {
        try {
            const results = await ServiceHandler.OsbContentService.getOsbContentModel(
                OsbUtilService.getAppConfiguration().brand,
                OsbUtilService.getAppConfiguration().countryCode,
                OsbUtilService.getAppConfiguration().languageRegionCode,
                modal
            );

            setFindMyVinContent(results.elements);
        } catch (error) {
            dispatch({
                type: 'ERROR',
                error: error,
            });
        }
    };

    const buildDealerDataListWithType = async (data: DealerDetails[]) => {
        const dealerProfileInfo: DealerProfileInfo[] = data
            .map((item: DealerDetails) => item.dealerProfile)
            .filter(
                (
                    profile: DealerProfileInfo | undefined
                ): profile is DealerProfileInfo => {
                    return profile !== undefined;
                }
            );

        setOSBStepPayload({
            allOSBDealers: dealerProfileInfo,
        });
    };

    const marketDealers = async () => {
        await ServiceHandler.OsbDslDealerDetailsService.getDealerDetails()
            .then(results => {
                setOSBDealerStepPayload({
                    filterDeliveryServices:
                        results.value.filterDeliveryServices || [],
                    filterDidServices: results.value.filterDidServices || [],
                    filterRegularServices:
                        results.value.filterRegularServices || [],
                });
                buildDealerDataListWithType(results.value.dealers);
            })
            .catch((error: any) => {
                if (error) {
                    const errorMessage = error.errorMessage;
                    setErrorMsgDealer('SERVICE_ERROR - ' + errorMessage);
                    dispatch({
                        type: 'ERROR',
                        error: errorMessage,
                    });
                }
            });
    };
    const fetchMarketDealers = async () => {
        try {
            const geoCountryCodeAEM = getObjectFromAEMJson(
                PRICE_CALCULATOR_CONSTANTS.Geo_Country_Code,
                props.priceCalculatorContent
            );

            setOSBStepPayload({
                geoCountryCode: geoCountryCodeAEM
                    ? geoCountryCodeAEM
                    : OsbUtilService.getAppConfiguration().countryCode,
            });
            marketDealers();
        } catch (error) {
            dispatch({
                type: 'ERROR',
                error: error,
            });
        }
    };
    const fetchPriceCalculatorResultContent = async (modal: string) => {
        try {
            const { brand, countryCode, languageRegionCode } =
                OsbUtilService.getAppConfiguration() ?? {};
            const results = await ServiceHandler.PriceCalculatorContentService.getPriceCalculatorContentModel(
                brand,
                countryCode,
                languageRegionCode,
                modal
            );
            setPriceCalculatorResultContent(results.elements);
            setNumberOfLeadingCharToStrip(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.NUMBER_OF_CHAR_STRIP_FROM_DEALERID,
                    results.elements
                )
            );
        } catch (error) {
            dispatch({
                type: 'ERROR',
                error: error,
            });
        }
    };

    const fetchVehicleImage = (vehicleAttributes: VehicleAttributes) => {
        new VehicleImageService()
            .getVehicleImage(vehicleAttributes, 'page')
            .then(vehicleImageUrl => {
                setVehiclePicUrl(vehicleImageUrl ?? '#');
            });
    };

    const getVehicle = async (vinOrReg: string, mileage: string) => {
        if (!vinOrReg || !mileage) {
            return;
        }
        const isVin = vinOrReg.length > 10;
        const vehicleData: VehicleData = {
            [isVin ? 'vin' : 'registrationNumber']: formatRegVinNumber(
                vinOrReg,
                true
            ),
            mileage: mileage,
        };
        let { brand } = new AppConfigurationService();
        brand = brand === 'demo' ? 'ford' : brand;
        setIsVehicleLoading(true);
        setVehicleApiError('');
        setVehicleInfoRequested(true);
        await ServiceHandler.OsbVehicleLookUp.getVehicleInfo({
            vehicleData,
            delivery: SERVICE_DELIVERY_TYPE.WORKSHOP,
            source: API_REQUEST_SOURCE_TYPE.WEB,
        })
            .then(result => {
                fetchVehicleImage({
                    model: result.value.modelName,
                    make: brand,
                    vin: result.value.vin,
                    year: parseInt(result.value.buildDate.substring(0, 4)),
                });
                setOSBVehicleStepPayload(
                    { vehicleDetails: result.value },
                    false
                );
                setIsVehicleLoading(false);
            })
            .catch((error: any) => {
                setIsVehicleLoading(false);
                const errorCode = error?.code || error?.errorCode;
                const errorMessage = error?.message || error?.errorMessage;

                if (errorCode && errorMessage) {
                    setVehicleApiError(`${errorCode} - ${errorMessage}`);
                    dispatch({
                        type: 'ERROR',
                        error: `${errorCode} - ${errorMessage}`,
                    });
                }
            });
    };

    const geoCode =
        getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Geo_Country_Code,
            props.priceCalculatorContent
        )
            ?.trim()
            ?.toString() || OsbUtilService.getAppConfiguration().countryCode;

    const getDealersFromMarketDealers = async (
        osbEnabledDealerIds: string[]
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        const dealerProfileInfos: DealerProfileInfo[] = [];
        osbEnabledDealerIds.forEach(id => {
            const dealerProfileInfo = findDealerFromMarketDealers(
                id,
                results?.dealers || []
            );
            if (dealerProfileInfo != null) {
                dealerProfileInfos.push(dealerProfileInfo);
            }
        });
        return dealerProfileInfos;
    };
    const getDealerName = (osbDealers: any, dealerCode: string): string => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerCode
        );
        return dealer ? dealer.dealerName : '';
    };
    const getOpeningHours = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.openingHours;
    };
    function getDealerFilterServiceAvailability(osbDealers: any, data: any) {
        try {
            return JSON.parse(
                getPropertyFromDealerAdditionalInfo(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? data.DealerID.substring(numberOfLeadingCharToStrip)
                        : data.DealerID,
                    'dealerFilterServiceAvailability'
                )
            );
        } catch (error) {
            return [];
        }
    }
    function getPropertyFromDealerAdditionalInfo(
        osbDealers: any,
        dealerId: string,
        property: keyof DealerAdditionalInfo
    ) {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.additionalInfo[property];
    }

    const createDealerProfileFromBingDealer = async (
        osbDealers: DealerProfileInfo[]
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        if (!osbDealers) {
            return;
        }
        const dealerCount = Number(
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                priceCalculatorResultContent
            )
        );
        const isViewMoreButtonVisible = osbDealers.length > dealerCount;
        setIsViewMoreButtonVisible(isViewMoreButtonVisible);
        const dealerList: DealerProfileInfo[] = [];
        const _osbEnabledDealerFromBing =
            osbDealerStep.osbEnabledDealerFromBing;
        _osbEnabledDealerFromBing.forEach((element: any) => {
            const dealerProfile: DealerProfileInfo = {
                dealerCode:
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID,
                dealerName: getDealerName(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                postalCode: element.PostCode,
                street: element.AddressLine1,
                town: element.Locality,
                openingHours: getOpeningHours(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                customerRating: element.CustomerRatingsService,
                customerRespondentsService: element.CustomerRespondentsService,
                distance: element.distance,
                primaryPhone: element.PrimaryPhone,
                location: element.location,
                didVideoCheck: Boolean(element.VideoCheck),
                didMobileService: Boolean(element.MobileService),
                dealerType:
                    results?.dealers?.find(
                        (e: { dealerCode: string }) =>
                            e.dealerCode ===
                            (numberOfLeadingCharToStrip > 0
                                ? element.DealerID.substring(
                                      numberOfLeadingCharToStrip
                                  )
                                : element.DealerID)
                    )?.dealerType + ''.trim().toString(),
                additionalInfo: {
                    earliestAvailableTimeslot: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'earliestAvailableTimeslot'
                    ),
                    dealerFilterServiceAvailability: getDealerFilterServiceAvailability(
                        osbDealers,
                        element
                    ),
                    nextAppointmentDateAm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateAm'
                    ),
                    nextAppointmentDatePm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDatePm'
                    ),
                    nextAppointmentDateWeekend: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateWeekend'
                    ),
                },
                specialServices: [],
            };
            dealerList.push(dealerProfile);
        });
        setAllDealersShown(dealerList);
        setOSBDealerStepPayload({
            dealerProfiles: dealerList,
        });
        return limitArray(dealerList, dealerCount);
    };

    const getOSBDealers = async (dealerData: any) => {
        if (dealerData.length <= 0) {
            setErrorMsgDealer('OSB_NO_DATAFOUND');
            return;
        }
        // form osb dealer id list for the OSB dealer information
        const osbEnabledDealerIds = dealerData.map(function(dlr: {
            [x: string]: any;
        }) {
            if (numberOfLeadingCharToStrip > 0) {
                return dlr['DealerID'].substring(numberOfLeadingCharToStrip);
            } else {
                return dlr['DealerID'];
            }
        });

        if (osbEnabledDealerIds.length === 0) {
            return;
        }

        const dealerProfileInfos = await getDealersFromMarketDealers(
            osbEnabledDealerIds
        );
        // const dealerProfileFromBing = dealerProfileInfos
        //     ? createDealerProfileFromBingDealer(dealerProfileInfos) || []
        //     : [];
        const dealerProfileFromBing: Promise<
            DealerProfileInfo[] | undefined
        > = dealerProfileInfos
            ? createDealerProfileFromBingDealer(dealerProfileInfos) || []
            : Promise.resolve(undefined);

        //setFilteredRecord(dealerProfileFromBing);
        const filteredRecord = await dealerProfileFromBing;
        if (filteredRecord) {
            setFilteredRecord(filteredRecord);
        }
    };

    const onSearchDealersByDistanceSuccess = async (
        bingDealers: any,
        origin: string,
        coordinate?: any,
        callback?: any
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        const _osbEnabledDealerFromBing = DealerSearchService.filterOnOsbDealerIds(
            results?.dealers,
            bingDealers,
            numberOfLeadingCharToStrip
        );

        // increase bing search logic - begin
        const osbEnabledDealerFromBingCount = _osbEnabledDealerFromBing.length;
        const maxDealerShown = Number(
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                priceCalculatorResultContent
            )
        );
        const _searchDistanceIndex = osbDealerStep.searchDistanceIndex;

        if (
            osbEnabledDealerFromBingCount < maxDealerShown &&
            origin === 'searchByLocation' &&
            _searchDistanceIndex <
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
                    priceCalculatorResultContent
                ).length
        ) {
            callback(null);
            return;
        }
        if (origin === 'searchByLocation') {
            GoogleMapService.getDealerDistance(
                coordinate,
                100,
                _osbEnabledDealerFromBing,
                dealerData => {
                    osbDealerStep.osbEnabledDealerFromBing = dealerData;
                    getOSBDealers(dealerData);
                }
            );
        } else {
            osbDealerStep.osbEnabledDealerFromBing = _osbEnabledDealerFromBing;
            getOSBDealers(_osbEnabledDealerFromBing);
        }
    };

    const geocodeLocation = (results?: any) => {
        if (results && results !== null) {
            osbDealerStep.geocoordinate = results;
        } else {
            results = osbDealerStep.geocoordinate;
        }

        if (results.length <= 0) {
            return;
        }

        const coordinate: any = results[0];
        const _searchDistanceIndex: number =
            osbDealerStep.searchDistanceIndex ||
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
                priceCalculatorResultContent
            ).indexOf(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                    priceCalculatorResultContent
                )
            );

        let radius = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
            priceCalculatorResultContent
        )[_searchDistanceIndex];

        radius = Number(radius) * 1.60934;
        osbDealerStep.searchDistanceIndex = _searchDistanceIndex + 1;
        const polygon = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.POLYGON,
            priceCalculatorResultContent
        );
        const maxResult = 100;
        DealerSearchService.bingDealerSearchByLocation(
            results,
            radius,
            maxResult,
            (dealers: any) => {
                onSearchDealersByDistanceSuccess(
                    dealers,
                    'searchByLocation',
                    coordinate,
                    () => {
                        geocodeLocation();
                    }
                );
            },
            polygon
        );
    };
    function resetErrorMessages() {
        setVehicleApiError('');
        setServiceApiError('');
    }
    const getQuoteHandler = async (
        vinInput: string,
        mileageInput: string,
        locationInput: string,
        isLocationDisabled: boolean
    ) => {
        if (!vinInput || !mileageInput) {
            return;
        }
        dispatch({ type: 'REQUEST' });
        resetErrorMessages();
        getVehicle(vinInput, mileageInput);
        const results = await fetchMarketDealerInfo(deepLinkParams);
        if (vinSelectionType === 'DROPDOWN_VIN_AUTH' && isLocationDisabled) {
            const preferredDealer =
                (profile?.vehicles &&
                    getVehiclePreferredDealer(
                        profile?.vehicles,
                        vinInput,
                        osbStep.numberOfLeadingCharactersToStrip,
                        results?.dealers || [],
                        false
                    )) ||
                '';
            if (preferredDealer !== '') {
                DealerSearchService.bingDealerByName(
                    1,
                    (dealers: any) => {
                        onSearchDealersByDistanceSuccess(
                            dealers,
                            'searchByDealer',
                            location
                        );
                        setOSBDealerStepPayload({
                            selectedDealerName: dealers?.[0]?.DealerName,
                        });
                    },
                    osbStep.polygon,
                    preferredDealer,
                    osbStep.osbTwoLetterCountryCode
                );
            } else {
                if (!locationInput) {
                    dispatch({ type: 'RESPONSE' });
                    return;
                }
                GoogleMapService.getGeocodeLocation(
                    geoCode,
                    locationInput,
                    geocodeLocation
                );
            }
        } else {
            if (!locationInput) {
                dispatch({ type: 'RESPONSE' });
                return;
            }
            GoogleMapService.getGeocodeLocation(
                geoCode,
                locationInput,
                geocodeLocation
            );
        }
        setOSBVehicleStepPayload({
            vinRegNo: vinInput,
            vinMileage: mileageInput,
            manualMileage: mileageInput,
        });
        setOSBDealerStepPayload({
            selectedLocation: locationInput,
        });
        triggerGetQuoteAnalytics(fireEvents);
        dispatch({ type: 'RESPONSE' });
    };

    const refershDealerList = (dealerProfiles: DealerProfileInfo[]) => {
        if (isViewMoreButtonVisible) {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                        priceCalculatorResultContent
                    )
                ) || [];
        } else {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                        priceCalculatorResultContent
                    )
                ) || [];
        }

        setFilteredRecord(dealerProfiles);
    };
    const showMaxDealers = () => {
        if (allDealersShown) {
            setFilteredRecord(
                limitArray(
                    allDealersShown,
                    Number(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                            priceCalculatorResultContent
                        )
                    )
                ) || []
            );
            setIsViewMoreButtonVisible(false);
        }
        triggerInternalCtaAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.LOADMORE_LABEL
        );
    };

    const handleListLinkClick = () => {
        setIsMapView(false);
    };
    const handleMapsLinkClick = () => {
        setIsMapView(true);
    };
    const goToServicesStep = (dealer: DealerProfileInfo) => {
        setOSBStepPayload({ isPcFlow: true });
        setOSBDealerStepPayload({
            selectedDealerId: dealer.dealerCode,
        });
        triggerInternalCtaAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.BOOKNOW_LABEL
        );
        history.push(
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.OSB_HOME_PAGE_STEP,
                priceCalculatorResultContent
            )
        );
    };
    //const isListView = true;
    let dealerDetails;
    if (filteredRecord.length > 0) {
        if (isMobileView) {
            if (!isMapView) {
                dealerDetails = (
                    <ServicePriceCalculatorMobileListDealerDetails
                        dealers={filteredRecord}
                        profile={profile}
                        preferredDealerIds={preferredDealerIds}
                        priceCalculatorContent={props.priceCalculatorContent}
                        priceCalculatorResultContent={
                            priceCalculatorResultContent
                        }
                        showMaxDealers={showMaxDealers}
                        isViewMoreButton={isViewMoreButtonVisible}
                        onMapsLinkClick={handleMapsLinkClick}
                        refreshDealerList={refershDealerList}
                        goToServicesStep={goToServicesStep}
                    />
                );
            } else {
                dealerDetails = (
                    <ServicePriceCalculatorMobileMapDealerDetails
                        dealers={filteredRecord}
                        profile={profile}
                        preferredDealerIds={preferredDealerIds}
                        priceCalculatorContent={props.priceCalculatorContent}
                        priceCalculatorResultContent={
                            priceCalculatorResultContent
                        }
                        showMaxDealers={showMaxDealers}
                        isViewMoreButton={isViewMoreButtonVisible}
                        onListLinkClick={handleListLinkClick}
                        refreshDealerList={refershDealerList}
                        goToServicesStep={goToServicesStep}
                    />
                );
            }
        } else {
            dealerDetails = (
                <ServicePriceCalculatorDesktopDealerDetails
                    profile={profile}
                    dealers={filteredRecord}
                    priceCalculatorContent={props.priceCalculatorContent}
                    priceCalculatorResultContent={priceCalculatorResultContent}
                    showMaxDealers={showMaxDealers}
                    isViewMoreButton={isViewMoreButtonVisible}
                    refreshDealerList={refershDealerList}
                    goToServicesStep={goToServicesStep}
                />
            );
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const results = await fetchMarketDealerInfo(deepLinkParams);
            setPreferredDealerIds(
                getCustomerPreferredDealers(
                    profile,
                    osbStep.numberOfLeadingCharactersToStrip,
                    results?.dealers || []
                )
            );
        };
        fetchData();
    }, [profile]);
    useEffect(() => {
        const fetchAEMContent = async () => {
            try {
                dispatch({ type: 'REQUEST' });
                await Promise.all([
                    fetchVehicleStepContent('vehicle-step-more-elements'),
                    fetchMarketDealers(),
                    fetchPriceCalculatorResultContent(
                        'price-calculator-result'
                    ),
                ]);
                dispatch({ type: 'RESPONSE' });
            } catch (error) {
                dispatch({
                    type: 'ERROR',
                    error: error,
                });
            }
        };
        fetchAEMContent();
        triggerDealerSearchLoadAnalytics(fireEvents);
    }, []);

    return (
        <React.Fragment>
            {httpState.isLoading ? (
                <OsbLoader />
            ) : (
                <>
                    <div className="spc-top-banner">
                        <OSBBanner
                            applicationName={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.PC_Top_Banner_Text,
                                props.priceCalculatorContent
                            )}
                        />
                    </div>
                    {(vehicleApiError || serviceApiError || errorMsgDealer) && (
                        <div className="spc-wrapper-api-error">
                            {errorMsgDealer && <p>{errorMsgDealer}</p>}
                            {vehicleApiError && <p>{vehicleApiError}</p>}
                            {serviceApiError && <p>{serviceApiError}</p>}
                        </div>
                    )}
                    {isMobileView ? (
                        <ServicePriceCalculatorMobileSearch
                            priceCalculatorContent={
                                props.priceCalculatorContent
                            }
                            priceCalculatorResultContent={
                                priceCalculatorResultContent
                            }
                            findMyVinContent={findMyVinContent}
                            getQuoteHandler={getQuoteHandler}
                            profile={profile}
                            getVinSelectionType={type => {
                                setVinSelectionType(type);
                            }}
                        />
                    ) : (
                        <ServicePriceCalculatorDesktopSearch
                            priceCalculatorContent={
                                props.priceCalculatorContent
                            }
                            priceCalculatorResultContent={
                                priceCalculatorResultContent
                            }
                            findMyVinContent={findMyVinContent}
                            getQuoteHandler={getQuoteHandler}
                            profile={profile}
                            getVinSelectionType={type => {
                                setVinSelectionType(type);
                            }}
                        />
                    )}

                    {!vehicleApiError && vehicleInfoRequested && (
                        <div className="spc-vehicle-container">
                            {isVehicleLoading ? (
                                <ActivityIndicator />
                            ) : (
                                osbVehicleStep.vehicleDetails && (
                                    <>
                                        {vehiclePicUrl &&
                                            vehiclePicUrl !== '#' && (
                                                <div className="spc-vehicle-image-container">
                                                    <img
                                                        src={vehiclePicUrl}
                                                        alt={`${osbVehicleStep.vehicleDetails?.modelName}`}
                                                        className="spc-vehicle-image"
                                                        crossOrigin="anonymous"
                                                    />
                                                </div>
                                            )}
                                        <div className="spc-vehicle-info-container">
                                            <span className="spc-left-span">
                                                {getObjectFromAEMJson(
                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.RECOMMENDEC_LABEL,
                                                    priceCalculatorResultContent
                                                )}{' '}
                                            </span>
                                            &nbsp;
                                            <span className="spc-right-span">
                                                {
                                                    osbVehicleStep
                                                        .vehicleDetails
                                                        ?.modelName
                                                }{' '}
                                                {osbVehicleStep.vehicleDetails?.buildDate?.substring(
                                                    0,
                                                    4
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    )}

                    {!vehicleApiError && dealerDetails}
                </>
            )}
        </React.Fragment>
    );
};

export default ServicePriceCalculatorWrapper;
