import { useEffect, useState } from 'react';
import { VehicleYmm } from '../models/vehicle-ymm';
import { VehicleListServiceAem } from '../services/vehicle-list-service-aem/vehicle-list-service-aem';

type MATCH_MODEL_WITH = 'PTS_MARKET_NAME' | 'DISPLAY_MODEL_NAME' | 'SEO_KEY';
export const useAemVehicleModelData = (
    brand: string,
    countryCode: string,
    languageRegionCode: string,
    year: number | string,
    toMatch: string,
    toMatchType: MATCH_MODEL_WITH
): VehicleYmm | undefined => {
    const [vehicleYmm, setVehicleYmm] = useState<VehicleYmm>();
    const service = new VehicleListServiceAem();
    useEffect(() => {
        if (
            !vehicleYmm &&
            brand &&
            countryCode &&
            languageRegionCode &&
            year &&
            toMatch &&
            toMatchType
        ) {
            if (typeof year === 'string') year = Number.parseInt(year);
            service
                .getVehicles(brand, countryCode, languageRegionCode, true)
                .then(data => {
                    if (data.length > 0) {
                        const yearData = data.filter(
                            data => data.year === year
                        )[0];
                        if (toMatch && !vehicleYmm)
                            if (toMatchType === 'PTS_MARKET_NAME') {
                                setVehicleYmm(
                                    yearData?.vehicles.filter(
                                        vehicle =>
                                            vehicle.marketName.toLowerCase() ===
                                            toMatch.toLowerCase()
                                    )[0]
                                );
                            } else if (toMatchType === 'DISPLAY_MODEL_NAME') {
                                setVehicleYmm(
                                    yearData?.vehicles.filter(
                                        vehicle => vehicle.model === toMatch
                                    )[0]
                                );
                            } else if (toMatchType === 'SEO_KEY') {
                                setVehicleYmm(
                                    yearData?.vehicles.filter(
                                        vehicle => vehicle.seoKey === toMatch
                                    )[0]
                                );
                            } else
                                console.error(
                                    `useAemVehicleModelData: supplied predicate ${toMatch} doesn't match ${toMatchType} in vehicleYmm `,
                                    vehicleYmm
                                );
                    }
                });
        }
    }, [brand, countryCode, languageRegionCode, year, toMatch, toMatchType]);
    return vehicleYmm;
};

export const getAemVehicleModelData = async (
    brand: string,
    countryCode: string,
    languageRegionCode: string,
    year: number | string,
    toMatch: string,
    toMatchType: MATCH_MODEL_WITH
): Promise<VehicleYmm | undefined> => {
    const service = new VehicleListServiceAem();
    if (
        brand &&
        countryCode &&
        languageRegionCode &&
        year &&
        toMatch &&
        toMatchType
    ) {
        if (typeof year === 'string') year = Number.parseInt(year);
        const data = await service.getVehicles(
            brand,
            countryCode,
            languageRegionCode,
            true
        );
        if (data.length > 0) {
            const yearData = data.filter(data => data.year === year)[0];
            if (toMatchType === 'PTS_MARKET_NAME') {
                return Promise.resolve(
                    yearData?.vehicles.filter(
                        vehicle =>
                            vehicle.marketName.toLowerCase() ===
                            toMatch.toLowerCase()
                    )[0]
                );
            } else if (toMatchType === 'DISPLAY_MODEL_NAME') {
                return Promise.resolve(
                    yearData?.vehicles.filter(
                        vehicle => vehicle.model === toMatch
                    )[0]
                );
            } else if (toMatchType === 'SEO_KEY') {
                return Promise.resolve(
                    yearData?.vehicles.filter(
                        vehicle => vehicle.seoKey === toMatch
                    )[0]
                );
            } else
                console.error(
                    `getAemVehicleModelData: supplied predicate ${toMatch} doesn't match ${toMatchType}`
                );
        }
    }
    return Promise.resolve(undefined);
};

export default useAemVehicleModelData;
