import { useEffect, useState } from 'react';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../../../constants';
import VehicleOrderStatus from '../../../../services/handover/vehicle-order-status-service';
import handoverService from '../../../../services/handover/handover-service';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';
import useUserProfile from '../../../../hooks/use-user-profile';

/**
 * Hook to get the status of Vehicle Order & set the status of Handover Stated accordingly
 */
const appConfig = new AppConfigurationService();

export function createUrl() {
    const url = `${DSL_BASE_URL +
        DSL_API_PATH.VEHICLE_STATUS}?countryCode=${appConfig.current3LetterCountryCode.toUpperCase()}`;
    return url;
}

export function useHandoverVehicleOrderStatusLookup(): boolean | any {
    let selectedVin: string | undefined = handoverService.fetchSelectedVin();
    const profile = useUserProfile();
    const [showStatusError, setshowStatusError] = useState(false);
    let isPostHandover: boolean | undefined;

    useEffect(() => {
        async function loadVinStatus() {
            if (selectedVin != undefined) {
                const response = await new VehicleOrderStatus().getVehicleOrder(
                    createUrl(),
                    selectedVin
                );
                if (response) {
                    isPostHandover = response.postHandover;
                } else {
                    isPostHandover = true;
                    setshowStatusError(true);
                }
                sessionStorage.setItem(
                    'isPostHandoverState',
                    JSON.stringify(isPostHandover)
                );
            }
        }
        if (profile) {
            const localStoreVin = localStorageWrapper.getItem('USER_VIN') || '';
            if (localStoreVin) {
                sessionStorage.setItem('selectedVin', localStoreVin);
                selectedVin = localStoreVin;
            }
            loadVinStatus();
        }
    }, [selectedVin, profile]);
    const statusObj = {
        isPostHandover: isPostHandover,
        handoverStatusError: showStatusError,
    };
    return statusObj;
}
