import React, { useEffect } from 'react';
import { PrimaryButton } from '../../../../../common';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import Popup from 'reactjs-popup';
import './dealer-more-info-lincoln.scss';
import { OsbMap } from '../osb-map';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import {
    useViewport,
    useOSBStep,
    useVehicleStep,
} from '../../../../../../hooks/owners-osb';
import {
    getObjectFromAEMJson,
    getVehicleAttributesForAnalytics,
    formatMobileNumber,
} from '../../../osb-utils';
import parse from 'html-react-parser';
import {
    DEALER_STEP_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
} from '../../../osb-constant';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { OSBPayload } from '../../../../../../services/shortcode-service/shortcode-service';
import { FormatAddress } from '../../../common/osb-common-components/osb-common-components';
import tickMark from '../../../../../../assets/owners-osb/checkmark_green-icon_24.svg';
import closeMark from '../../../../../../assets/owners-osb/close-red-icon-24px.svg';
import DealerCapabilities from '../light-dealer-details/dealer-capabilities';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';

interface Props {
    preferredDealerIds?: string[];
    searchResultsCount: string;
    dealers: DealerProfileInfo[];
    selectedLocation?: string;
    dealerProfile: DealerProfileInfo;
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepSpecialServicesContent: JsonFromAEMResponse[];
    goToLightDealerDetail: () => void;
    setIsDealerIndexValue: number;
    goToServicesStep: (dealerProfile: DealerProfileInfo, index: number) => void;
}

export const DealerMoreInfo = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { osbVehicleStep } = useVehicleStep();
    const { isMobileView } = useViewport();
    const { osbStep } = useOSBStep();

    const triggerDealerSearchMoreInfoAnalytics = () => {
        const osbDealerMoreInfo: OSBPayload = {
            dealerID: props.dealerProfile.dealerCode,
            userLocationSearchTerm: props.selectedLocation
                ? props.selectedLocation
                : ' ',
            searchDepth: osbStep.isDealerDeepLinked
                ? DEALER_STEP_KEYS.SEARCH_DEPTH_FOR_DEALER_DEEPLINK
                : String(props.setIsDealerIndexValue + 1) +
                  ':' +
                  props.searchResultsCount,
        };
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DEALER_INFO,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                ymm: Promise.resolve(
                    getVehicleAttributesForAnalytics(osbVehicleStep)
                ),
                osbPayload: osbDealerMoreInfo,
            },
            false
        );
    };

    const getDayLabel = (dayIndex: number, length: any) => {
        const locale = OsbUtilService.getAppConfiguration().languageRegionCode;
        return new Date(
            Date.UTC(2017, 4, dayIndex, 0, 0, 0)
        ).toLocaleString(locale, { weekday: length });
    };

    const _insertSeparator = (time: string) => {
        return time && time.replace(/\B(?=(\d{2})+(?!\d))/g, ':');
    };

    const _formatTime = (timeString: string) => {
        let times, openTime, closeTime;
        const noDataAvailableLabel = getObjectFromAEMJson(
            'noDataAvailableLabel',
            props.dealerStepContent
        )
            ? getObjectFromAEMJson(
                  'noDataAvailableLabel',
                  props.dealerStepContent
              )
            : '';
        if (timeString && timeString.length > 0) {
            times = timeString.split('-');
            openTime = _insertSeparator(times[0]);
            closeTime = _insertSeparator(times[1]);

            openTime = openTime
                ? openTime.replace('~HRSMISSING', '')
                : openTime;
            closeTime = closeTime
                ? closeTime.replace('~HRSMISSING', '')
                : closeTime;

            if (
                !openTime ||
                openTime === undefined ||
                openTime.trim() === '' ||
                !closeTime ||
                closeTime === undefined ||
                closeTime.trim() === ''
            ) {
                return `${noDataAvailableLabel}`;
            }
            return `${openTime} - ${closeTime}`;
        }
        return '';
    };

    const checkDaysAreSame = (list: any) =>
        list.every(
            (item: string) => item.split('~')[0] === list[0].split('~')[0]
        );

    const getFormattedTimes = (dealer: DealerProfileInfo, isTable: boolean) => {
        let separator = ': ';
        const formattedTimesArray = [];
        const closedForDayLabel = getObjectFromAEMJson(
            'closedForDayLabel',
            props.dealerStepContent
        );
        let compactOpeningTimes = true;
        if (isTable) {
            compactOpeningTimes = false;
            separator = ' ';
        }

        const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
        } = dealer.openingHours;

        const weekDays = [monday, tuesday, wednesday, thursday, friday];

        const dayLength = getObjectFromAEMJson(
            'compactOpeningTimesLabel',
            props.dealerStepMoreContent
        )
            ? 'short'
            : 'long';

        if (compactOpeningTimes && checkDaysAreSame(weekDays)) {
            formattedTimesArray.push({
                label: `${getDayLabel(1, dayLength)} - ${getDayLabel(
                    5,
                    dayLength
                )}${separator}`, // Mon - Fri
                value:
                    (monday && monday.length > 0 && _formatTime(monday)) ||
                    closedForDayLabel,
            });
        } else {
            formattedTimesArray.push({
                label: `${getDayLabel(1, dayLength)}${separator}`, // Monday
                value:
                    (monday && monday.length > 0 && _formatTime(monday)) ||
                    closedForDayLabel,
            });

            formattedTimesArray.push({
                label: `${getDayLabel(2, dayLength)}${separator}`, // Tuesday
                value:
                    (tuesday && tuesday.length > 0 && _formatTime(tuesday)) ||
                    closedForDayLabel,
            });

            formattedTimesArray.push({
                label: `${getDayLabel(3, dayLength)}${separator}`, // Wednesday
                value:
                    (wednesday &&
                        wednesday.length > 0 &&
                        _formatTime(wednesday)) ||
                    closedForDayLabel,
            });

            formattedTimesArray.push({
                label: `${getDayLabel(4, dayLength)}${separator}`, // Thursday
                value:
                    (thursday &&
                        thursday.length > 0 &&
                        _formatTime(thursday)) ||
                    closedForDayLabel,
            });

            formattedTimesArray.push({
                label: `${getDayLabel(5, dayLength)}${separator}`, // Friday
                value:
                    (friday && friday.length > 0 && _formatTime(friday)) ||
                    closedForDayLabel,
            });
        }

        formattedTimesArray.push({
            label: `${getDayLabel(6, dayLength)}${separator}`, // Saturday
            value:
                (saturday && saturday.length > 0 && _formatTime(saturday)) ||
                closedForDayLabel,
        });

        formattedTimesArray.push({
            label: `${getDayLabel(7, dayLength)}${separator}`, // Sunday
            value:
                (sunday && sunday.length > 0 && _formatTime(sunday)) ||
                closedForDayLabel,
        });

        return formattedTimesArray;
    };

    const getOpeningHours = (dealerProfile: DealerProfileInfo) => {
        if (dealerProfile) {
            let formattedOpeningHours = '';
            const hours = getFormattedTimes(dealerProfile, false);
            hours.forEach(time => {
                const hrs = (time.value ? time.value.split('~') : '')[0];
                formattedOpeningHours += `<div>${time.label}<label>${hrs}</label></div>`;
            });
            return formattedOpeningHours;
        }
    };

    const getClosingStatus = (dealerProfile: DealerProfileInfo) => {
        // initialized with open/close status (authored), in case if no time (or) disabled this functionality.

        const hours = getFormattedTimes(dealerProfile, true);
        const openStatusLabel = getObjectFromAEMJson(
            'openStatusLabel',
            props.dealerStepContent
        );
        let closeRes = openStatusLabel;
        const closeStatusLabel = getObjectFromAEMJson(
            'closeStatusLabel',
            props.dealerStepContent
        );
        hours.forEach(element => {
            if (element.value && element.value.includes('~')) {
                const [time, status] = element.value.split('~');
                if (time && time.includes('-')) {
                    const [, end] = time.split('-');
                    if (status === 'CLOSE') {
                        closeRes = closeStatusLabel;
                    } else {
                        const endtime = end;
                        closeRes = openStatusLabel + endtime;
                    }
                }
            }
        });

        return closeRes;
    };

    const shouldShowCapabilities = () => {
        return (
            getObjectFromAEMJson(
                DEALER_STEP_KEYS.ENABLE_DEALER_CAPABILITIES,
                props.dealerStepMoreContent
            ) === true &&
            getObjectFromAEMJson(
                DEALER_STEP_KEYS.DEALER_CAPABILITIES_LIST,
                props.dealerStepMoreContent
            ) !== ''
        );
    };

    const triggerCallDealerCtaAnalytics = () => {
        const osbCallDealerInfo: OSBPayload = {
            dealerID: props.dealerProfile.dealerCode,
            userLocationSearchTerm: props.selectedLocation
                ? props.selectedLocation
                : ' ',
            searchDepth: osbStep.isDealerDeepLinked
                ? DEALER_STEP_KEYS.SEARCH_DEPTH_FOR_DEALER_DEEPLINK
                : String(props.setIsDealerIndexValue + 1) +
                  ':' +
                  props.searchResultsCount,
        };
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CALL_DEALER_CTA,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                ymm: Promise.resolve(
                    getVehicleAttributesForAnalytics(osbVehicleStep)
                ),
                osbPayload: osbCallDealerInfo,
            },
            false
        );
    };

    useEffect(() => {
        if (props.dealerProfile) {
            triggerDealerSearchMoreInfoAnalytics();
        }
    }, [props.dealerProfile]);

    return (
        <>
            {!osbStep.enableNewLandingPage && (
                <div className="osb-light-journey-dealer-more-info-container">
                    <div className="dealer-more-info">
                        <div className="dealer-map-view">
                            <OsbMap
                                dealers={[props.dealerProfile]}
                                isDealerIndexValue={
                                    props.setIsDealerIndexValue + 1
                                }
                            />
                        </div>
                        <div
                            className="dealer-more-detail"
                            data-testid="BackToDealerList"
                        >
                            <OSBSecondaryButton
                                type="filled"
                                direction="left"
                                text={getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.BACK_BUTTON_LABEL,
                                    props.dealerStepMoreContent
                                )}
                                onClickHandler={props.goToLightDealerDetail}
                            />
                            <div className="osb-dealer-details-view">
                                <div className="osb-dealer-details">
                                    <div className="sequence-number">
                                        {props.setIsDealerIndexValue + 1}
                                    </div>

                                    <div>
                                        <div className="osb-dealer-name">
                                            {props.dealerProfile.dealerName}
                                        </div>
                                        <div className="osb-dealer-address">
                                            <FormatAddress
                                                addressData={
                                                    props.dealerProfile
                                                }
                                                addressOrder={
                                                    osbStep.dealerAddressOrder
                                                }
                                                isDisplaySingleRow={true}
                                            />
                                        </div>
                                        <div className="dealer-other-details">
                                            {!isNaN(
                                                props.dealerProfile.distance
                                            ) && (
                                                <div className="osb-dealer-distance">
                                                    <div>
                                                        {getObjectFromAEMJson(
                                                            DEALER_STEP_KEYS.DISTANCE_UNIT,
                                                            props.dealerStepMoreContent
                                                        ).toLowerCase() ===
                                                        'miles'
                                                            ? OsbUtilService.getDistanceInMiles(
                                                                  Number(
                                                                      props
                                                                          .dealerProfile
                                                                          .distance
                                                                  )
                                                              )
                                                            : OsbUtilService.getDistanceInKm(
                                                                  Number(
                                                                      props
                                                                          .dealerProfile
                                                                          .distance
                                                                  )
                                                              )}
                                                    </div>
                                                    <div className="distance-unit">
                                                        {getObjectFromAEMJson(
                                                            DEALER_STEP_KEYS.LIGHT_JOURNEY_DISTANCE_LABEL,
                                                            props.dealerStepMoreContent
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {props.dealerProfile.primaryPhone &&
                                                props.dealerProfile
                                                    .primaryPhone !== '' && (
                                                    <>
                                                        <div
                                                            className="call-dealer-popup"
                                                            data-testid="dealer-call-cta-popup"
                                                        >
                                                            {!isMobileView && (
                                                                <Popup
                                                                    trigger={
                                                                        <div className="call-dealer-link">
                                                                            {getObjectFromAEMJson(
                                                                                DEALER_STEP_KEYS.CALL_DEALER_BUTTON_LABEL,
                                                                                props.dealerStepMoreContent
                                                                            )}
                                                                        </div>
                                                                    }
                                                                    onOpen={
                                                                        triggerCallDealerCtaAnalytics
                                                                    }
                                                                    className="dealer-call-cta-popup"
                                                                    position="right center"
                                                                    modal={
                                                                        false
                                                                    }
                                                                    arrow={true}
                                                                    closeOnDocumentClick={
                                                                        true
                                                                    }
                                                                >
                                                                    {
                                                                        props
                                                                            .dealerProfile
                                                                            .primaryPhone
                                                                    }
                                                                </Popup>
                                                            )}
                                                            {isMobileView && (
                                                                <a
                                                                    className="call-dealer-link"
                                                                    href={`tel:+${formatMobileNumber(
                                                                        props
                                                                            .dealerProfile
                                                                            .primaryPhone
                                                                    )}`}
                                                                    onClick={
                                                                        triggerCallDealerCtaAnalytics
                                                                    }
                                                                    title={
                                                                        props
                                                                            .dealerProfile
                                                                            .primaryPhone
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {getObjectFromAEMJson(
                                                                        DEALER_STEP_KEYS.CALL_DEALER_BUTTON_LABEL,
                                                                        props.dealerStepMoreContent
                                                                    )}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            <div className="dealer-vehicle-services">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.PASSANGER_SERVICE_VEHICLE_LABEL,
                                                    props.dealerStepMoreContent
                                                ) && (
                                                    <div className="service-name">
                                                        <img
                                                            src={`${
                                                                props
                                                                    .dealerProfile
                                                                    .serviceDepartmentPV
                                                                    ? tickMark
                                                                    : closeMark
                                                            }`}
                                                            className="icon"
                                                            alt={''}
                                                        />
                                                        <p>
                                                            {getObjectFromAEMJson(
                                                                DEALER_STEP_KEYS.PASSANGER_SERVICE_VEHICLE_LABEL,
                                                                props.dealerStepMoreContent
                                                            )}
                                                        </p>
                                                    </div>
                                                )}

                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.COMMERCIAL_SERVICE_VEHICLE_LABEL,
                                                    props.dealerStepMoreContent
                                                ) && (
                                                    <div className="service-name">
                                                        <img
                                                            src={`${
                                                                props
                                                                    .dealerProfile
                                                                    .serviceDepartmentCV
                                                                    ? tickMark
                                                                    : closeMark
                                                            }`}
                                                            className="icon"
                                                            alt={''}
                                                        />
                                                        <p>
                                                            {getObjectFromAEMJson(
                                                                DEALER_STEP_KEYS.COMMERCIAL_SERVICE_VEHICLE_LABEL,
                                                                props.dealerStepMoreContent
                                                            )}
                                                        </p>
                                                    </div>
                                                )}

                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.ELECTRIC_SERVICE_VEHICLE_LABEL,
                                                    props.dealerStepMoreContent
                                                ) && (
                                                    <div className="service-name">
                                                        <img
                                                            src={`${
                                                                props
                                                                    .dealerProfile
                                                                    .electricService
                                                                    ? tickMark
                                                                    : closeMark
                                                            }`}
                                                            className="icon"
                                                            alt={''}
                                                        />
                                                        <p>
                                                            {getObjectFromAEMJson(
                                                                DEALER_STEP_KEYS.ELECTRIC_SERVICE_VEHICLE_LABEL,
                                                                props.dealerStepMoreContent
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            {props.dealerProfile
                                                .specialServices &&
                                                props.dealerProfile
                                                    .specialServices.length >
                                                    0 && (
                                                    <>
                                                        <div className="special-service-provided">
                                                            <div className="special-service-provided-title">
                                                                {getObjectFromAEMJson(
                                                                    DEALER_STEP_KEYS.SPECIAL_SERVICES_LIST_TITLE,
                                                                    props.dealerStepSpecialServicesContent
                                                                )}
                                                            </div>
                                                            <ul className="service-provided-list">
                                                                {props
                                                                    .dealerProfile
                                                                    .specialServices &&
                                                                    props.dealerProfile.specialServices.map(
                                                                        (
                                                                            item: string,
                                                                            key: any
                                                                        ) => (
                                                                            <li
                                                                                className="service-provided-item"
                                                                                id={`item-${key}`}
                                                                                key={
                                                                                    item
                                                                                }
                                                                            >
                                                                                {
                                                                                    item
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                            </ul>
                                                        </div>
                                                    </>
                                                )}
                                            {props.dealerProfile.additionalInfo
                                                ?.earliestAvailableTimeslot && (
                                                <div className="Earliest-available-slot">
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT,
                                                        props.dealerStepMoreContent
                                                    )}{' '}
                                                    {
                                                        props.dealerProfile
                                                            .additionalInfo
                                                            ?.earliestAvailableTimeslot
                                                    }
                                                </div>
                                            )}
                                            <div className="osb-dealer-select-button">
                                                <PrimaryButton
                                                    role="link"
                                                    color={'dark'}
                                                    fill={'fill'}
                                                    chevron={false}
                                                    aria-label="select-dealer-cta"
                                                    dataTestId={`SelectDealer-${props.dealerProfile.dealerCode}`}
                                                    aria-labelledby="select dealer cta"
                                                    onClick={() =>
                                                        props.goToServicesStep(
                                                            props.dealerProfile,
                                                            props.setIsDealerIndexValue
                                                        )
                                                    }
                                                >
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_SELECT_DEALER_BUTTON,
                                                        props.dealerStepMoreContent
                                                    )}
                                                </PrimaryButton>
                                            </div>
                                            {!getObjectFromAEMJson(
                                                'hideOpeningHoursSection',
                                                props.dealerStepContent
                                            ) && (
                                                <div className="working-hours">
                                                    <div>
                                                        {getClosingStatus(
                                                            props.dealerProfile
                                                        )}
                                                    </div>
                                                    {parse(
                                                        getOpeningHours(
                                                            props.dealerProfile
                                                        ) ?? ''
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {osbStep.enableNewLandingPage && (
                <div className="new-dealer-more-info-section">
                    <div className="back-to-dealer-list">
                        <OSBSecondaryButton
                            type="svg"
                            direction="left"
                            text={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.BACK_BUTTON_LABEL,
                                props.dealerStepMoreContent
                            )}
                            onClickHandler={props.goToLightDealerDetail}
                        />
                    </div>
                    <div className="dealer-more-info">
                        <div className="dealer-map-view">
                            <OsbMap
                                dealers={[props.dealerProfile]}
                                isDealerIndexValue={
                                    props.setIsDealerIndexValue + 1
                                }
                                preferredDealerIds={props.preferredDealerIds}
                            />
                        </div>
                        <div className="dealer-more-detail">
                            <div className="osb-dealer-details-view">
                                <div className="osb-dealer-index">
                                    <div className="sequence-number">
                                        {props.setIsDealerIndexValue + 1}
                                    </div>
                                    <div className="osb-dealer-name">
                                        {props.dealerProfile.dealerName}
                                    </div>
                                </div>
                                <div className="osb-dealer-detail-section">
                                    <div className="osb-dealer-details">
                                        <div className="osb-dealer-address">
                                            <FormatAddress
                                                addressData={
                                                    props.dealerProfile
                                                }
                                                addressOrder={
                                                    osbStep.dealerAddressOrder
                                                }
                                                isDisplaySingleRow={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="dealer-other-details">
                                    {!isNaN(props.dealerProfile.distance) && (
                                        <div className="osb-dealer-distance">
                                            <div>
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.DISTANCE_TITLE,
                                                    props.dealerStepContent
                                                )}{' '}
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.DISTANCE_UNIT,
                                                    props.dealerStepMoreContent
                                                ).toLowerCase() === 'miles'
                                                    ? OsbUtilService.getDistanceInMiles(
                                                          Number(
                                                              props
                                                                  .dealerProfile
                                                                  .distance
                                                          )
                                                      )
                                                    : OsbUtilService.getDistanceInKm(
                                                          Number(
                                                              props
                                                                  .dealerProfile
                                                                  .distance
                                                          )
                                                      )}
                                            </div>
                                            <div className="distance-unit">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.LIGHT_JOURNEY_DISTANCE_LABEL,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {props.dealerProfile.additionalInfo
                                        ?.earliestAvailableTimeslot && (
                                        <div className="Earliest-available-slot">
                                            {getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT,
                                                props.dealerStepMoreContent
                                            )}{' '}
                                            {
                                                props.dealerProfile
                                                    .additionalInfo
                                                    ?.earliestAvailableTimeslot
                                            }
                                        </div>
                                    )}
                                    <div className="osb-dealer-select-cta">
                                        <PrimaryButton
                                            role="link"
                                            color={'dark'}
                                            fill={'fill'}
                                            chevron={false}
                                            aria-label="select-dealer-cta"
                                            dataTestId={`SelectDealer-${props.dealerProfile.dealerCode}`}
                                            aria-labelledby="select dealer cta"
                                            onClick={() =>
                                                props.goToServicesStep(
                                                    props.dealerProfile,
                                                    props.setIsDealerIndexValue
                                                )
                                            }
                                        >
                                            {getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.LIGHT_JOURNEY_SELECT_DEALER_BUTTON,
                                                props.dealerStepMoreContent
                                            )}
                                        </PrimaryButton>
                                    </div>

                                    {!getObjectFromAEMJson(
                                        'hideOpeningHoursSection',
                                        props.dealerStepContent
                                    ) && (
                                        <>
                                            <div className="opening-hours-title">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.OPENING_HOURS_TITLE,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                            <div className="working-hours">
                                                <div>
                                                    {getClosingStatus(
                                                        props.dealerProfile
                                                    )}
                                                </div>
                                                {parse(
                                                    getOpeningHours(
                                                        props.dealerProfile
                                                    ) ?? ''
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="dealer-vehicle-services">
                                        {shouldShowCapabilities() && (
                                            <>
                                                <div className="dealer-capability-title">
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.DEALER_CAPABILITY_TITLE,
                                                        props.dealerStepMoreContent
                                                    )}
                                                </div>
                                                <DealerCapabilities
                                                    dealer={props.dealerProfile}
                                                    dealerStepMoreContent={
                                                        props.dealerStepMoreContent
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    {props.dealerProfile.specialServices &&
                                        props.dealerProfile.specialServices
                                            .length > 0 && (
                                            <>
                                                <div className="special-service-provided">
                                                    <div className="special-service-provided-title">
                                                        {getObjectFromAEMJson(
                                                            DEALER_STEP_KEYS.SPECIAL_SERVICES_LIST_TITLE,
                                                            props.dealerStepSpecialServicesContent
                                                        )}
                                                    </div>
                                                    <ul className="service-provided-list">
                                                        {props.dealerProfile
                                                            .specialServices &&
                                                            props.dealerProfile.specialServices.map(
                                                                (
                                                                    item: string,
                                                                    key: any
                                                                ) => (
                                                                    <li
                                                                        className="service-provided-item"
                                                                        id={`item-${key}`}
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
