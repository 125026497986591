import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import './sendToDealer.scss';

import { HandoverDetailsPage } from '../handover/handover-controller';
import { HandoverNotification } from '../handover/handover-notification/handover-notification';
import { Link as CustomLink } from '../../../components/common';
import { SaveFeaturesToDataBaseService } from '../../../services/save-features-service/save-features-service-impl';

import { useHandoverModel } from '../handover/hooks/use-vehicle-handover-model';
import { useHistory } from 'react-router-dom';
import { useSendToDealerModel } from '../handover/hooks/use-sendToDealer-model';

import Footerchecklist from '../handover/footer-checklist/footer-checklist';
import handoverService from '../../../services/handover/handover-service';
import { ActivityIndicator } from './../../common/activity-indicator/activity-indicator';
import { useAnalytics } from '../../../hooks/use-analytics';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import ClientContext from '../../../contexts/clientContext';
import { ProfileWithVehiclesResponse } from '../../../models/profile-with-vehicles';
import { HandoverTracker } from '../create-checklist/handover-tracker/handover-tracker';
import { HandoverBreadCrumbs } from '../handover/handover-breadcrumbs/handover-breadcrumbs';

function createTrackerData(
    step: string | boolean | undefined | number,
    filled = true,
    active = true,
    text = ''
) {
    return { step, filled, active, text };
}

const customerNotesLabel = 'customerNotes';

export const SendToDealer = () => {
    const featureData = handoverService.getFeatureName(),
        i18n = useHandoverModel(),
        i18nSendToDealer = useSendToDealerModel(),
        [checkedItems, setCheckedItems] = useState({} as any),
        [customerNotes, setCustomerNotes] = useState(''),
        [dbUpdationInProgress, setDbUpdationInProgress] = useState(false),
        [featFlag, setFeatFlag] = useState(true),
        [isFeaturesSavedInDB, setIsFeaturesSavedInDB] = useState(false),
        [userSelectedFeaturesList, setUserSelectedFeaturesList] = useState<
            object[]
        >([]),
        [showNotificationErrorBar, setShowNotificationErrorBar] = useState(
            false
        ),
        [showModifyNotificationBar, setShowModifyNotificationBar] = useState(
            false
        ),
        [vehicleLine, setVehicleLine] = useState<any>(),
        [vehicleYear, setVehicleYear] = useState<any>(),
        [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]),
        [onAnalyticClickEvent, setOnAnalyticClickEvent] = useState<boolean>(
            false
        ),
        [checkedFeaturesList, setCheckedFeaturesList] = useState<any>(),
        createChecklistLink = handoverService.fetchPathName(
            'CreatChecklistRoute'
        ),
        result = handoverService.getSelectedFeaturesCount() || [],
        featureLength: number = result,
        [featuresNamesForAnalytics, setFeaturesNamesForAnalytics] = useState<
            string[]
        >([]);
    const [charCount, setCharCount] = useState<any>(0);

    let trackerDataEyfIdeal: any;
    const userSelectedVin: string = handoverService.fetchSelectedVin() || '';
    const profileData = useContext(ClientContext)
        .profile as ProfileWithVehiclesResponse;

    localStorage.removeItem('USER_VIN');

    if (
        featureData != undefined &&
        featureData?.featuresArray.length > 0 &&
        featFlag
    ) {
        setFeatFlag(false);
    }

    const [fireEvents] = useAnalytics();
    const isFeatureChecklistSubmitted =
        handoverService.getDataFromSessionStorage('submitted-features')
            ?.length > 0 || false;

    useEffect(() => {
        setUserSelectedFeaturesList(
            handoverService.getSelectedFeaturesCount(true)
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setShowModifyNotificationBar(isFeatureChecklistSubmitted);
    }, []);

    if (i18nSendToDealer.size > 0 && i18n.size > 0) {
        trackerDataEyfIdeal = [
            createTrackerData(
                i18n.get('handoverStepOne'),
                true,
                false,
                String(i18n.get('exploreFeaturesTitle'))
            ),
            createTrackerData(
                i18n.get('handoverStepTwo'),
                isFeatureChecklistSubmitted,
                true,
                String(i18n.get('reviewLabel'))
            ),
            createTrackerData(
                i18n.get('handoverStepThree'),
                isFeatureChecklistSubmitted,
                false,
                i18nSendToDealer.get('sendToDealer') &&
                    i18nSendToDealer.get('sendChecklistToDealer')
                    ? String(i18nSendToDealer.get('sendToDealer'))
                    : String(i18n.get('createChecklistTitle'))
            ),
        ];
    }

    useEffect(() => {
        if (profileData && userSelectedVin && userSelectedVin !== '') {
            const selectedVehicleData: any = profileData.vehicles.find(
                vehicle => vehicle.vin === userSelectedVin
            );
            if (selectedVehicleData) {
                setVehicleLine(selectedVehicleData.modelName);
                setVehicleYear(selectedVehicleData.modelYear);
                setVehiclesData([
                    {
                        year: selectedVehicleData.modelYear,
                        make: selectedVehicleData.vehicleMake,
                        model: selectedVehicleData.modelName,
                        vin: selectedVehicleData.vin,
                    },
                ]);
            } else {
                const vinLookupResponse = handoverService.getDataFromSessionStorage(
                    'vinLookupResponse'
                );
                const vehicleDetails = vinLookupResponse
                    ? vinLookupResponse.vehicleDetails
                    : [];
                setVehicleLine(vehicleDetails.modelDescription);
                setVehicleYear(vehicleDetails.modelYear);
                setVehiclesData([
                    {
                        year: vehicleDetails.modelYear,
                        make: 'ford',
                        model: vehicleDetails.modelDescription,
                        vin: vehicleDetails.vin,
                    },
                ]);
            }
        }
        //setting custom notes
        const cachedCustomerNotes: any = handoverService.getDataFromSessionStorage(
            customerNotesLabel
        );
        if (cachedCustomerNotes) {
            cachedCustomerNotes.map((item: any) => {
                if (item.vin == userSelectedVin) {
                    setCustomerNotes(item.customerNotesValue?.slice(0, 250));
                    setCharCount(250 - (item.customerNotesValue?.length || 0));
                }
            });
        }
    }, [profileData]);

    useEffect(() => {
        const statesUpdates: any = {};
        userSelectedFeaturesList.forEach((text: any) => {
            statesUpdates[text.key] = true;
        });
        setCheckedItems(Object.assign({}, checkedItems, statesUpdates));

        const selectedFeaturesKeyList: any = [];
        userSelectedFeaturesList.forEach((item: any) => {
            selectedFeaturesKeyList.push(item.key);
        });
        setCheckedFeaturesList(selectedFeaturesKeyList);
    }, [userSelectedFeaturesList]);

    const updateAnalyticsDataOnClick = () => {
        const selectedFeaturesNamesList: string[] = [];
        userSelectedFeaturesList &&
            userSelectedFeaturesList.forEach((item: any) => {
                selectedFeaturesNamesList.push(item.headline);
            });
        setFeaturesNamesForAnalytics(selectedFeaturesNamesList);
    };

    const url: any = HandoverDetailsPage()?.split(':'),
        history = useHistory(),
        showDetailView = (featureName: string, feature: any) => {
            handoverService.setSelectedFeatureForHandoverDetails(feature.key);

            history.push(url[0] + encodeURIComponent(feature.key));
        };

    useEffect(() => {
        for (const [key, value] of Object.entries(checkedItems)) {
            if (value === false) {
                delete checkedItems[key];
            }
        }
        const checkedFeatured = Object.keys(checkedItems).map(function(i) {
            return i;
        });
        setCheckedFeaturesList(checkedFeatured);
    }, [checkedItems]);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>,
        featureCard: any
    ) => {
        const checkBoxState = event.target.checked;
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: checkBoxState,
        });

        handoverService.handleCheckboxStateOnClick(
            [featureCard],
            event.target.checked,
            featureCard.category
        );

        const selectedFeaturesNamesList: string[] = featuresNamesForAnalytics;

        if (checkBoxState) {
            selectedFeaturesNamesList.push(featureCard.headline);
        } else {
            selectedFeaturesNamesList.splice(
                selectedFeaturesNamesList.indexOf(featureCard.headline),
                1
            );
        }
        setFeaturesNamesForAnalytics(selectedFeaturesNamesList);
    };

    useEffect(() => {
        updateAnalyticsDataOnClick();
    }, [userSelectedFeaturesList]);

    function filterText() {
        const text: string =
            String(i18nSendToDealer.get('secondComplimentryText')) || '';
        return text.length
            ? text.replace('##FEATURE_COUNT##', featureLength.toString())
            : '';
    }

    const setFormattedContent = React.useCallback(
        (text: string | any[]) => {
            setCharCount(250 - text.slice(0, 250).length);
        },
        [250, setCustomerNotes]
    );

    const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const customerNotesData = handoverService.getDataFromSessionStorage(
            'customerNotes'
        );

        customerNotesData.map((item: any) => {
            if (item.vin == userSelectedVin) {
                setCustomerNotes(e.currentTarget.value.slice(0, 250));
                setFormattedContent(e.target.value);
                item.customerNotesValue = e.currentTarget.value.slice(0, 250);
            }
        });

        handoverService.setDataToSessionStorage(
            'customerNotes',
            customerNotesData
        );
        return false;
    };

    const triggerLoadAnalytics = (userSelectedVin: any) => {
        /**On Page Load */
        fireEvents(
            ['Eyf-send-to-dealer-page-load'],
            undefined,
            {
                ymm: Promise.resolve(userSelectedVin),
                namePlateValue: userSelectedVin.model,
            },
            false
        );
    };
    useEffect(() => {
        if (vehiclesData.length > 0) {
            const selectedVehicleData: VehicleAttributes =
                vehiclesData.find(vehicle => vehicle.vin === userSelectedVin) ||
                vehiclesData[0];
            if (selectedVehicleData) {
                triggerLoadAnalytics(selectedVehicleData);
            }
        }
    }, [vehiclesData]);

    const triggerOnClickAnalytics = () => {
        /**Analytic onclick on Send to dealer button click */
        if (onAnalyticClickEvent) {
            fireEvents(
                'Eyf-checklist-to-dealer',
                undefined,
                {
                    namePlateValue: vehicleLine,
                },
                false
            );
        }
    };

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        setOnAnalyticClickEvent(true);
        const [
            visitedVehicles,
            selectedVinCategoryList,
        ] = handoverService.getSelectedVinCategoryList();
        let allFeatures: any = [];
        visitedVehicles &&
            selectedVinCategoryList.length &&
            selectedVinCategoryList.map((category: any) => {
                Object.keys(category).forEach((item: string) => {
                    const [featuresList] = handoverService.getAllFeaturesListOf(
                        item,
                        ''
                    );
                    allFeatures = [...allFeatures, ...featuresList];
                });
            });
        const checkedFeatures: any[] = [];
        const unCheckedFeatures: any[] = [];
        allFeatures.map((item: any) => {
            if (checkedFeaturesList.includes(item.key)) {
                checkedFeatures.push({ key: item.key });
            } else {
                unCheckedFeatures.push({ key: item.key });
            }
        });

        if (dbUpdationInProgress) {
            // to prevent repeated click on button/Link if call is in progress
            e.preventDefault();
            return;
        }
        if (!isFeaturesSavedInDB) {
            setDbUpdationInProgress(true);
            new SaveFeaturesToDataBaseService()
                .saveFeatures(
                    checkedFeatures,
                    customerNotes,
                    unCheckedFeatures,
                    vehicleYear && parseInt(vehicleYear),
                    vehicleLine
                )
                .then((response: any) => {
                    if (response?.status === 200 || response?.data.success) {
                        setIsFeaturesSavedInDB(true);
                        setShowNotificationErrorBar(false);
                        setDbUpdationInProgress(false);
                        handoverService.removeFromSessionStorage(
                            'create-checklist-features'
                        );
                        handoverService.setDataToSessionStorage(
                            'create-checklist-features',
                            {
                                vinPlaceholder: checkedFeaturesList,
                            }
                        );

                        handoverService.setDataToSessionStorage(
                            'submitted-features',
                            Array.from(checkedFeaturesList)
                        );
                        handoverService.setDataToSessionStorage(
                            'isFromReviewPage',
                            JSON.stringify(true)
                        );
                        history.push(createChecklistLink); // navigate to checklist page ONLY when success
                    }
                })
                .catch(() => {
                    setIsFeaturesSavedInDB(false);
                    setShowNotificationErrorBar(true);
                    setDbUpdationInProgress(false);
                });
        }
    };

    const onCloseClick = () => {
        setShowNotificationErrorBar(false);
    };

    const closeNotificationBar = () => {
        setShowModifyNotificationBar(false);
    };

    return (
        <div className="send-to-dealer-wrapper-ideal" id="scrollView">
            {showModifyNotificationBar && (
                <HandoverNotification
                    notificationMessage={String(i18n.get('modifyNotification'))}
                    handler={closeNotificationBar}
                    notificationStatus="editBar"
                />
            )}
            {showNotificationErrorBar && (
                <HandoverNotification
                    notificationMessage={String(
                        i18nSendToDealer.get('senderDealerErrorMessage')
                    )}
                    handler={onCloseClick}
                    notificationStatus="errorBar"
                />
            )}
            <HandoverBreadCrumbs currentPage="review" originFromVD={false} />
            <div className="send-to-dealer-description">
                {dbUpdationInProgress && (
                    <div className="loader">
                        <ActivityIndicator className="full-height" />
                    </div>
                )}

                <div className="send-to-dealer-info">
                    <HandoverTracker trackerData={trackerDataEyfIdeal} />
                    <div className="send-to-dealer-section section-1">
                        <div className="section-header">
                            <div className="heading-text">
                                {i18nSendToDealer.get('specialRequest')}
                            </div>
                        </div>
                        <div>
                            <p className="user-descp">
                                {i18nSendToDealer.get('complimentryText')}
                            </p>
                            <div className="text-area-customer-notes">
                                <textarea
                                    rows={4}
                                    cols={60}
                                    maxLength={250}
                                    placeholder={String(
                                        i18nSendToDealer.get(
                                            'freeTextPlaceholder'
                                        )
                                    )}
                                    value={customerNotes}
                                    onChange={onChangeTextArea}
                                    name="customerNotes"
                                    className="customerNotes customerNotes-ideal"
                                    data-testid="customerNotes"
                                />
                                <span className="char-count">
                                    {charCount}{' '}
                                    {i18nSendToDealer.get(
                                        'remainingCharacters'
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="send-to-dealer-section section-2">
                        <div className="section-header">
                            <div className="heading-text">
                                {i18nSendToDealer.get('checkSelectedFeatures')}
                            </div>
                        </div>
                        <div className="send-to-dealer-selected-features">
                            <p className="user-descp-feature">{filterText()}</p>

                            <div className="feature-group-main">
                                {userSelectedFeaturesList &&
                                    userSelectedFeaturesList.map(
                                        (feature: any) => (
                                            <div
                                                key={feature.key}
                                                className={`feature-group-${feature.name}`}
                                            >
                                                <input
                                                    id={
                                                        `${feature.key}` +
                                                        '-field'
                                                    }
                                                    data-testid="checkbox-text"
                                                    type="checkbox"
                                                    className={`checkbox`}
                                                    aria-label={`${feature.headline}`}
                                                    name={`${feature.key}`}
                                                    onChange={(e: any) =>
                                                        handleChange(e, feature)
                                                    }
                                                    checked={
                                                        checkedItems[
                                                            `${feature.key}`
                                                        ]
                                                    }
                                                    disabled={false}
                                                />
                                                <span className="link">
                                                    <CustomLink
                                                        aria-label={String(
                                                            i18n.get(
                                                                'viewButtonLabel'
                                                            )
                                                        )}
                                                        href="javascript:;"
                                                        className="checklist-view"
                                                        onClick={() => {
                                                            /**Analytic onclick on Send to dealer View link */
                                                            fireEvents(
                                                                'Eyf-checklist-sent',
                                                                undefined,
                                                                {
                                                                    sendToDealerTextName:
                                                                        feature.headline,
                                                                    namePlateValue: vehicleLine,
                                                                },
                                                                false
                                                            );

                                                            showDetailView(
                                                                feature.headline,
                                                                feature
                                                            );
                                                        }}
                                                    >
                                                        {feature.headline}
                                                    </CustomLink>
                                                </span>
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                    </div>
                </div>

                {handoverService.getSelectedFeaturesCount() > 0 && (
                    <Footerchecklist
                        featureLength={handoverService.getSelectedFeaturesCount()}
                        link={createChecklistLink}
                        i18n={i18n}
                        handleClick={handleClick}
                        isChecklistFeaturesSavedInDB={isFeaturesSavedInDB}
                        handoverOnclickAnalytics={() =>
                            triggerOnClickAnalytics()
                        }
                    />
                )}
            </div>
        </div>
    );
};
