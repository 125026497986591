import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { JiraGetTicketStatusResponse } from '../jira-service/jira-service';
import { XML_VIN_MISMATCH_MSG } from '../../support/support-constants';
import { SyncMapVehicleCtrEquipped } from './util/sync-map-vehicle-ctr-equipped';

export interface SyncMapApiResponse {
    sync?: {
        vehicleSyncEquipped?: boolean;
        vehicleCTREquipped?: boolean;
        error: string;
        updateType: {
            upToDate: boolean;
            consumerUpdateAvailable: boolean;
            dealerUpdateAvailable: boolean;
        };
        vehicleSyncGenerationStatus: {
            vin: string;
            message: string;
            genText: string;
            genId: string;
            gen11: boolean;
            syncEquipped: boolean;
        };
        latestUpdateDate: string;
        unconfirmedInstallation: boolean;
        currentSyncVersion: string;
        availableDealerUpdates?: boolean;
        availableConsumerUpdateDownload: {
            downloadInfo: {
                vin: string;
                esn: string;
                partNumber: string;
                softwareURL: string;
                syncVersion: string;
                softwareState: string;
            };
            updateDetails: {
                binaryFileROMSize: 450412145;
                dealerVisitRequiredFlag: false;
                functionalTypeValues: string[];
                runTimeSize: string;
                marketingDescription: string;
                softwarePartNumber: string;
                name: string;
                softwareSize: string;
                type: string;
                version: string;
                imageUpdateRequired: boolean;
                euladocumentLink: string;
            };
        };
        isGetInstallPackageService: boolean;
        firmwareUpdate: {
            key: string;
            status: string;
            downloadUrl: string;
            replyUrl: string;
        };
        httpStatus: string;
        lastRequested: string;
        jiraTicketStatusResponse: {
            vin: string;
            jiraTicketId: string;
            jiraTicketLink: string;
            summary: string;
            ticketCreationDate: string;
            ticketLastModifiedDate: string;
            ticketStatus: string;
            incidentType: string;
            modifiedBy: string;
        };
    };
    maps?: {
        status: {
            statusCode: number;
            status: string;
        };
        data: {
            vin?: string;
            electronicModuleSerialNumber?: string;
            totalSize?: string;
            syncGeneration?: string;
            mapInstalled: string;
            futureBinaries?: string;
            region?: string;
            message?: string;
            files?: MapFile[];
            applications: MapApplication[];
        };
        updatePackage: {
            status: {
                statusCode: string;
                status: string;
            };
            data: {
                DownloadUrl: string;
                Key: string;
                ReplyParameters: string;
                ReplyUrl: string;
                Status: string;
            };
        };

        updateState: UpdateState;
        error: string;
    };
    error?: string;
}

export interface VwMapsResponse {
    downloadUrl?: string;
    releaseDate?: string;
    error?: string;
}

export interface UpdateState {
    mapUpToDate: boolean;
    mapUpdateAvailable: boolean;
}

export interface MapFile {
    fileName: string;
    fileType: string;
    fileLocation: string;
    filePartNumber: string;
}

export interface MapApplication {
    name: string;
    description: string;
}

export interface SyncTile {
    vehicleSyncEquipped: boolean;
    syncUpdateAvailable: boolean;
    lastUpdatedDate: string;
    syncUpToDate: boolean;
    currentSyncVersion: string;
    hasCtrUpdate: boolean;
    showSyncTile: boolean;
}

export interface MapTile {
    mapUpdateAvailable: boolean;
    mapUpToDate: boolean;
}

export interface SyncMapTile {
    syncTile: SyncTile;
    mapTile: MapTile;
}

export interface SyncUpdateResponse {
    hasSync?: boolean;
    hasCtr?: boolean;
    hasUpdateAvailable?: boolean;
    currentSyncVersion?: string | null;
    newSyncVersion?: string;
    isDealerUpdateOnly?: boolean;
    hasFirmwareUpdate?: boolean;
    downloadUrl?: string;
    fileName?: string;
    rawResponse?: AxiosResponse;
    generation?: string;
    syncError?: string;
    eulaURL?: string;
    jiraTicketStatusResponse?: JiraGetTicketStatusResponse;
}

export interface MapUpdateResponse {
    hasUpdate?: boolean;
    syncGeneration?: string;
    downloadUrl?: string;
    mapError?: string;
}

export interface SyncMapUpdateResponse {
    syncUpdateResponse: SyncUpdateResponse;
    mapUpdateResponse: MapUpdateResponse;
    error?: string;
}

export interface ConfirmUploadResponse {
    error: boolean;
    errorDetails?: string;
}

export interface LogFileResponse {
    errorInfoTypeList: [
        {
            errorCode: string;
            errorDetails: string;
            errorSource: string;
        }
    ];
}

export class SyncMapService {
    private static dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
    };

    private static appConfig = new AppConfigurationService();

    private static parseResponse(
        response: AxiosResponse<SyncMapApiResponse>
    ): SyncMapTile {
        const syncTile = {
            vehicleSyncEquipped: false,
            syncUpdateAvailable: false,
            syncUpToDate: false,
            lastUpdatedDate: '',
            currentSyncVersion: '',
            hasCtrUpdate: false,
            showSyncTile: false,
        };
        const mapTile = {
            mapUpdateAvailable: false,
            mapUpToDate: false,
        };

        if (
            response.data.sync &&
            response.data.sync.vehicleSyncEquipped &&
            response.data.sync.updateType
        ) {
            syncTile.vehicleSyncEquipped = true;
            syncTile.currentSyncVersion = response.data.sync.currentSyncVersion;
            syncTile.lastUpdatedDate = new Date(
                response.data.sync.latestUpdateDate
            ).toLocaleDateString(
                SyncMapService.appConfig.currentLanguageRegionCode,
                SyncMapService.dateOptions
            );
            if (response.data.sync.updateType.consumerUpdateAvailable) {
                syncTile.syncUpdateAvailable = true;
            } else {
                syncTile.syncUpToDate = true;
            }
        }
        if (
            response.data.sync?.vehicleCTREquipped &&
            !!response.data.sync?.firmwareUpdate
        ) {
            syncTile.hasCtrUpdate = true;
        }

        SyncMapVehicleCtrEquipped({ response, syncTile });

        if (SyncMapService.appConfig.isRegionEU()) {
            if (response.data?.maps?.updateState) {
                if (response.data.maps.updateState.mapUpToDate) {
                    mapTile.mapUpToDate = true;
                } else if (response.data.maps.updateState.mapUpdateAvailable) {
                    mapTile.mapUpdateAvailable = true;
                }
            }
        }

        const syncMapTile = {
            syncTile: syncTile,
            mapTile: mapTile,
        };

        return syncMapTile;
    }

    private static getSyncVersion(updateResponse: any): string | undefined {
        if (updateResponse.data.sync.firmwareUpdate) {
            return updateResponse.data.sync.availableConsumerUpdateDownload
                ?.updateDetails?.version;
        }

        if (updateResponse.data.sync.availableDealerUpdates) {
            return updateResponse.data.sync.availableDealerUpdates[0].version;
        }
    }

    private static parseSyncUpdateResponse(
        response: AxiosResponse<SyncMapApiResponse>
    ): SyncMapUpdateResponse {
        const syncResponse = response?.data?.sync
            ? {
                  hasSync: response.data.sync?.vehicleSyncEquipped,
                  hasCtr: response.data.sync?.vehicleCTREquipped,
                  hasUpdateAvailable: response.data.sync?.updateType
                      ? !response.data.sync?.updateType.upToDate
                      : false,
                  currentSyncVersion: response.data.sync?.currentSyncVersion,
                  newSyncVersion: response.data.sync?.updateType
                      ? SyncMapService.getSyncVersion(response)
                      : undefined,
                  isDealerUpdateOnly:
                      response.data.sync?.updateType?.dealerUpdateAvailable,
                  hasFirmwareUpdate: !!response.data.sync?.firmwareUpdate,
                  downloadUrl:
                      !!response.data.sync?.firmwareUpdate &&
                      response.data.sync.firmwareUpdate.downloadUrl,
                  fileName:
                      response.data.sync?.availableConsumerUpdateDownload
                          ?.downloadInfo?.partNumber + '.zip',
                  generation:
                      response.data.sync?.vehicleSyncGenerationStatus?.genId,
                  rawResponse: response,
                  syncError: response?.data?.sync?.error,
                  eulaURL:
                      response.data.sync?.availableConsumerUpdateDownload
                          ?.updateDetails?.euladocumentLink,
                  jiraTicketStatusResponse:
                      response?.data?.sync?.jiraTicketStatusResponse,
              }
            : {};
        const mapResponse = {
            hasUpdate: response.data.maps?.updateState?.mapUpdateAvailable,
            syncGeneration: response.data.maps?.data?.syncGeneration,
            downloadUrl: response.data.maps?.updatePackage?.data?.DownloadUrl,
            mapError: response.data.maps?.error,
        };
        return {
            syncUpdateResponse: syncResponse,
            mapUpdateResponse: mapResponse,
            error: response?.data?.error ? response.data.error : '',
        };
    }

    async request(vin: string): Promise<SyncMapTile> {
        const headers = { vin: vin };
        const params = {
            locale: SyncMapService.appConfig.currentLanguageRegionCode,
            brand: SyncMapService.appConfig.brand,
            mapUpdates: SyncMapService.appConfig.isRegionEU() ? 'T' : 'F',
        };
        const syncUrl =
            DSL_BASE_URL +
            DSL_API_PATH.SYNC_FIRMWARE_UPDATE +
            HttpService.buildQueryParams(params);
        const response = HttpService.post<SyncMapApiResponse>(syncUrl, true, {
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(SyncMapService.parseResponse);
    }

    async requestSyncMapUpdates(vin: string): Promise<SyncMapUpdateResponse> {
        const params = {
            locale: SyncMapService.appConfig.currentLanguageRegionCode,
            brand: SyncMapService.appConfig.brand,
            mapUpdates: SyncMapService.appConfig.isRegionEU() ? 'T' : 'F',
        };
        const headers = { vin: vin };
        const syncUrl =
            DSL_BASE_URL +
            DSL_API_PATH.SYNC_FIRMWARE_UPDATE +
            HttpService.buildQueryParams(params);
        const response = HttpService.post<SyncMapApiResponse>(syncUrl, true, {
            headers,
        });
        response.catch(error => {
            console.error(error);
        });
        return response.then(SyncMapService.parseSyncUpdateResponse);
    }

    async getVwMaps(): Promise<VwMapsResponse> {
        const params = {
            regionCode: SyncMapService.appConfig.currentLanguageRegionCode,
        };

        const vwUrl =
            DSL_BASE_URL +
            DSL_API_PATH.VW_MAPS_UPDATE +
            HttpService.buildQueryParams(params);
        return HttpService.get<VwMapsResponse>(vwUrl, true, {})
            .then(res => {
                return res.data;
            })
            .catch(err => {
                return {
                    error: err?.message,
                } as VwMapsResponse;
            });
    }

    async uploadLogFiles(
        file: File,
        vin: string
    ): Promise<ConfirmUploadResponse> {
        const locale = SyncMapService.appConfig.currentLanguageRegionCode;

        if (!file.name.toLowerCase().includes(vin.toLowerCase())) {
            return {
                error: true,
                errorDetails: `Locale - ${locale} Error Description - ${XML_VIN_MISMATCH_MSG}`,
            };
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            vin && formData.append('vin', vin);
            formData.append('locale', locale);

            const url = DSL_BASE_URL + DSL_API_PATH.SYNC_UPLOAD_LOG_FILES;
            const response = HttpService.post<LogFileResponse>(url, formData);
            const data = (await response).data;
            if (data?.errorInfoTypeList[0]?.errorCode === '0') {
                return { error: false };
            } else {
                return {
                    error: true,
                    errorDetails: `Locale - ${locale} Error code - ${data?.errorInfoTypeList[0]?.errorCode} Error Description - ${data?.errorInfoTypeList[0]?.errorDetails} Error Source - ${data?.errorInfoTypeList[0]?.errorSource}`,
                };
            }
        } catch (e) {
            return {
                error: true,
                errorDetails: `Exception in Upload log file ${e}`,
            };
        }
    }
}
