import React, { useEffect, useState, useContext } from 'react';
import {
    PrimaryButton,
    SecondaryButton,
    Dropdown,
    InputField,
    Checkbox,
} from '../../../common';
import './service-history-form.scss';
import ServiceHandler from '../../../../services/service-handler';
import {
    ReactCategory,
    ServiceProviderValues,
    ServiceType,
} from '../../../../services/vehicle-service-history/vehicle-service-history';
import { ServiceHistory } from '../../../../models/service-history';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import errorWarning from '../../../../assets/error-warning.svg';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { BrandUtil } from '../../../utils/brand-util/brand-util';
import errorIcon from '../../../../assets/warning.png';
import successIcon from '../../../../assets/check-mark.svg';
import ServerContext from '../../../../contexts/serverContext';

interface Props {
    onCancelAddRecord: any;
    showAddServiceHistoryErrorModal: any;
    serviceHistoryContent: any;
    serviceHistoryAddRecordContent: any;
    vehicleAttributes: VehicleAttributes;
    editableRecord: any;
    addEditStatus: (status: string, type: string) => void;
    userGuid?: string;
}

const GARAGE = 'GARAGE';
const SELF_SERVICE = 'SELFSERVICE';
const OTHER = 'OTHER';

type InspectionLabels = {
    safety: string;
    emission: string;
};

export const ServiceHistoryForm = (props: Props) => {
    const {
        serviceHistoryContent,
        serviceHistoryAddRecordContent,
        editableRecord,
    } = props;
    const { vin, make, model, year } = props.vehicleAttributes;
    const [serviceTypeData, setServiceTypeData] = useState<ServiceType>();
    const [vehicleServiceProviders, setVehicleServiceProviders] = useState<
        ServiceProviderValues
    >();
    const [legalLanguageText, setLegalLanguageText] = useState<string>('');
    const { isLincoln } = new BrandUtil();

    const serverContext = useContext(ServerContext);

    const { currentLanguageRegionCode } = serverContext;

    useEffect(() => {
        const languageCode = currentLanguageRegionCode === 'fr-ca' ? 'fr' : '';
        ServiceHandler.VehicleServiceHistory.getVehicleServiceProviders()
            .then(serviceProvider => {
                setVehicleServiceProviders(serviceProvider?.value);
            })
            .catch(console.error);
        ServiceHandler.VehicleServiceHistory.getServiceTypes(languageCode)
            .then(setServiceTypeData)
            .catch(console.error);
        ServiceHandler.VehicleServiceHistory.getVehicleLegalTerms()
            .then(legalTerms => {
                if (legalTerms && legalTerms.legalLanguages.length) {
                    setLegalLanguageText(
                        legalTerms.legalLanguages[0].legalLanguageText
                    );
                }
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        const privacyCTA = document.querySelector('.privacy-link a[href]');
        if (privacyCTA) {
            privacyCTA.setAttribute(
                'aria-label',
                `${
                    privacyCTA.innerHTML
                } ${serviceHistoryContent.openInNewtabArialabel || ''}`
            );
            privacyCTA.removeAttribute('title');
        }
    });

    const {
        addService,
        dateLabel,
        businessNameLabel,
        serviceLocationLabel,
        saveCtaLabel,
        cancelCtaLabel,
        mileageErrorMessage,
        emptyDateErrorMessage,
        futureDateErrorMessage,
        invalidDateErrorMessage,
        totalCostErrorMessage,
        currencySymbol,
        mileageUnit,
        servicesPerformedCategory,
        termsAndConditionsError,
        privacyPolicyLink,
        safetyInspectionLabel,
        emissionsInspectionLabel,
        serviceLocationError,
        businessNameError,
        locationNameError,
        servicesPerformedError,
        totalCostDisclaimer,
        locationNameLabel,
    } = serviceHistoryAddRecordContent;
    const {
        vinLabel,
        mileageLabel,
        totalCostLabel,
        servicesPerformedLabel,
        inspectionsPerformedLabel,
        notesLabel,
    } = serviceHistoryContent;
    const serviceLocationOptions = [
        {
            label: serviceHistoryAddRecordContent.garageBodyShop,
            value: GARAGE,
        },
        {
            label: serviceHistoryAddRecordContent.selfService,
            value: SELF_SERVICE,
        },
        {
            label: serviceHistoryAddRecordContent.other,
            value: OTHER,
        },
    ];

    const [mileageView, setMileageView] = useState<string>('');
    const [mileageError, setMileageError] = useState<string>();
    const [isValidMileage, setValidMileage] = useState<boolean>(false);

    const [dateView, setDateView] = useState<string>('');
    const [dateErrorMessage, setDateErrorMessage] = useState<string>('');
    const [isValidDate, setValidDate] = useState<boolean>(false);

    let cost = '';
    const [costView, setCostView] = useState<string>('');
    const [costErrorMessage, setCostErrorMessage] = useState<string>();
    const [isValidCost, setValidCost] = useState<boolean>(false);

    const [serviceLocationView, setServiceLocationView] = useState<string>(' ');
    const [
        serviceLocationErrorMessage,
        setServiceLocationErrorMessage,
    ] = useState<string>('');
    const [initialChange, setInitialChange] = useState<boolean>(true);
    const [initalServiceLocation, setInitalServiceLocation] = useState<boolean>(
        true
    );
    const [isValidSelfService, setValidSelfService] = useState<boolean>(false);

    const [locationNameView, setLocationNameView] = useState<string>('');
    const [locationNameErrorMessage, setLocationNameErrorMessage] = useState<
        string
    >('');
    const [isValidLocationName, setValidLocationName] = useState<boolean>(
        false
    );

    const [businessNameView, setBusinessNameView] = useState<string>('');
    const [businessNameErrorMessage, setBusinessNameErrorMessage] = useState<
        string
    >('');
    let dealerName = '';
    const [isValidBusinessName, setValidBusinessName] = useState<boolean>(
        false
    );
    const [isValidServicePerformed, setValidServicePerformed] = useState<
        boolean
    >(false);
    const [servicesPerformed, setServicesPerformed] = useState<any>([]);
    const [
        servicesPerformedErrorMessage,
        setServicesPerformedErrorMessage,
    ] = useState<string>();
    const [inspectionPerformed, setInspectionPerformed] = useState<
        Array<string>
    >([]);

    const notes = '';
    const [notesView, setNotesView] = useState<string>('');
    const [isValidDisclaimer, setValidDisclaimer] = useState<boolean>(false);
    const [disclaimerErrorMessage, setDisclaimerErrorMessage] = useState<
        string
    >();
    const [
        showAddServiceHistoryErrorModal,
        setShowAddServiceHistoryErrorModal,
    ] = useState<boolean>(false);

    const [disabledButton, setDisabledButton] = useState<boolean>(true);
    const [fireEvents] = useAnalytics();

    const isAddRecordForm = () => {
        return !editableRecord.serviceId;
    };
    const triggerAddRecordsErrorAnalytics = (
        errorFieldName: string,
        errorDescription: string
    ) => {
        if (isAddRecordForm()) {
            fireEvents(
                'service-history-add-record-error',
                undefined,
                {
                    fieldName: errorFieldName,
                    errorDescription: errorDescription,
                },
                false
            );
        }
    };
    const handleSubmitForm = () => {
        setInitialChange(false);
        const result =
            isValidMileage &&
            isValidDate &&
            isValidCost &&
            (isValidSelfService ||
                isValidBusinessName ||
                isValidLocationName) &&
            isValidServicePerformed &&
            isValidDisclaimer;
        setDisabledButton(!result);
    };
    const handleEditForm = () => {
        if (editableRecord?.serviceId && initialChange) {
            setMileageView(
                editableRecord.vehicleMileage +
                    ' ' +
                    editableRecord.vehicleMileageUnit
            );
            setValidMileage(true);
            setDateView(editableRecord.serviceDate);
            setValidDate(true);
            const formattedCost = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            })
                .format(editableRecord.serviceAmount)
                .toString();
            setCostView(formattedCost.replace('$', currencySymbol));
            setValidCost(true);
            const _inspections: string[] = [];
            const _servicesPerformed: Array<string> = [];
            setTimeout(() => {
                const servicesPerformedEle = document.querySelectorAll(
                    '.check-box-spacing input'
                );
                servicesPerformedEle.forEach(function(checkbox: any) {
                    const checkedEleID = checkbox.getAttribute('name');
                    editableRecord.servicesPerformed.operations
                        .filter(
                            (operation: any) => checkedEleID === operation.id
                        )
                        .forEach((operation: any) => {
                            checkbox.checked = true;
                            const value = {
                                code: operation.id,
                                label: operation.desc,
                            };
                            _servicesPerformed.push(JSON.stringify(value));
                        });
                    editableRecord.inspections
                        .filter(
                            (inspection: any) =>
                                checkedEleID === inspection.toLowerCase()
                        )
                        .forEach((inspection: any) => {
                            checkbox.checked = true;
                            const inspectionLabels: InspectionLabels = {
                                safety: safetyInspectionLabel,
                                emission: emissionsInspectionLabel,
                            };
                            const inspectionCode: keyof InspectionLabels = inspection.toLowerCase() as keyof InspectionLabels;

                            if (inspectionLabels[inspectionCode]) {
                                _inspections.push(
                                    JSON.stringify({
                                        code: inspectionCode,
                                        desc: inspectionLabels[inspectionCode],
                                    })
                                );
                            }
                        });
                });
                setNotesView(editableRecord.notes);
                setServicesPerformed(_servicesPerformed);
                setInspectionPerformed(_inspections);
                setValidServicePerformed(true);
                setValidDisclaimer(false);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                handleSubmitForm();
            }, 1000);
        }
    };
    const handleServiceLocation = () => {
        if (
            editableRecord?.serviceId &&
            vehicleServiceProviders &&
            initalServiceLocation
        ) {
            setInitalServiceLocation(false);
            const dealer = editableRecord.serviceLocation.dealer;
            if (dealer) {
                dealerName = dealer.name;
            } else {
                if (editableRecord.serviceLocation.selfService === false) {
                    return;
                }
            }
            const serviceSelected = vehicleServiceProviders?.serviceProviders.filter(
                service => service.name === dealerName
            )[0];
            if (editableRecord.serviceLocation.selfService) {
                setServiceLocationView(SELF_SERVICE);
                setValidSelfService(true);
            } else {
                if (serviceSelected) {
                    setServiceLocationView(GARAGE);
                    setBusinessNameView(dealerName);
                    setValidBusinessName(true);
                } else {
                    setServiceLocationView(OTHER);
                    setLocationNameView(dealerName);
                    setValidLocationName(true);
                }
            }
        }
    };
    useEffect(() => {
        handleServiceLocation();
        handleSubmitForm();
        handleEditForm();
    }, [
        isValidMileage,
        isValidDate,
        isValidCost,
        isValidSelfService,
        isValidBusinessName,
        isValidLocationName,
        isValidServicePerformed,
        isValidDisclaimer,
        serviceLocationView,
    ]);

    const handleMileage = (e: any) => {
        if (e) {
            const input = e.target.value
                .replace(mileageUnit[0], '')
                .replace(mileageUnit[1], '')
                .replace(mileageUnit[2], '')
                .trim();
            if (input.match(/^\d+$/)) {
                setMileageError('');
                setMileageView(input);
                setValidMileage(true);
                if (e.type === 'focus') {
                    setMileageView(input);
                }
                if (e.type === 'blur') {
                    setMileageView(parseInt(input) + ' ' + mileageUnit);
                }
            } else if (
                e.type === 'blur' ||
                e.type === 'keydown' ||
                (e.type === 'change' && input === '')
            ) {
                setValidMileage(false);
                setMileageView('');
                setMileageError(mileageErrorMessage);
            }
        }
        handleSubmitForm();
    };

    const getSplittedDate = (date: string) => {
        const dateArr: any = date.split('/');
        const mm = (dateArr[0].length === 1 ? '0' : '') + dateArr[0];
        const dd = (dateArr[1].length === 1 ? '0' : '') + dateArr[1];
        return mm + '/' + dd + '/' + dateArr[2];
    };

    const dateErrorRex = (input: string) => {
        if (currentLanguageRegionCode !== 'fr-ca') {
            return input.match(
                /^([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/
            );
        } else {
            return input.match(
                /^([1-9]|0[1-9]|1\d|2\d|3[01])\/([1-9]|0[1-9]|1[0-2])\/(20)\d{2}$/
            );
        }
    };

    const handleDate = (e: any, eventType: string) => {
        if (e) {
            const input = e.target.value;
            if (dateErrorRex(input)) {
                const today = new Date();
                const userDate = new Date(input);
                const date =
                    eventType == 'focus' ? getSplittedDate(input) : input;
                if (userDate > today) {
                    setDateErrorMessage(futureDateErrorMessage);
                    setValidDate(false);
                    setDateView(date);
                } else {
                    setValidDate(true);
                    setDateErrorMessage('');
                    setDateView(date);
                }
            } else {
                const isEmptyDate = input.length === 0;
                setDateErrorMessage(
                    isEmptyDate
                        ? emptyDateErrorMessage
                        : invalidDateErrorMessage
                );
                setDateView(input);
                setValidDate(false);
            }
        }
        handleSubmitForm();
    };

    const handleCost = (e: any) => {
        if (e) {
            const input = e.target.value.replace(currencySymbol, '');
            if (input.match(/^\d+(,\d+)*(\.\d+)?$/)) {
                const inputValue = input;
                setCostErrorMessage('');
                setCostView(inputValue);
                cost = input;
                setValidCost(true);
                if (e.type === 'focus') {
                    setCostView(cost);
                }
                if (e.type === 'blur') {
                    const totalCost = input.split(',').join('');
                    const formattedCost = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })
                        .format(totalCost)
                        .toString();
                    setCostView(formattedCost.replace('$', currencySymbol));
                }
            } else if (input.match(/^[0-9.,]+$/) || input === '') {
                setCostView(input);
                if (
                    e.type === 'blur' ||
                    e.type === 'change' ||
                    e.type === 'keydown'
                ) {
                    setValidCost(false);
                    setCostErrorMessage(totalCostErrorMessage);
                }
            }
        }
        handleSubmitForm();
    };

    const updateLabelClassName = (type: string) => {
        const dropdownLabel = document.querySelector(
            type === 'service-location'
                ? '.service-location-label'
                : '.business-name-label'
        );
        if (dropdownLabel)
            dropdownLabel.className =
                dropdownLabel.className + ' dropdown-selected';
    };

    const onServiceLocationChange = (
        label: string,
        id: number,
        value: string | undefined
    ) => {
        if (isLincoln) {
            const dropdownLabel = document.querySelector(
                '.service-location-label'
            );
            if (dropdownLabel)
                dropdownLabel.className =
                    dropdownLabel.className + ' dropdown-selected';
        }
        if (label === undefined) {
            setServiceLocationErrorMessage('');
        } else {
            setValidBusinessName(false);
            setValidLocationName(false);
            setValidSelfService(false);
            setServiceLocationView(value || '');
            switch (value) {
                case SELF_SERVICE: {
                    setServiceLocationErrorMessage('');
                    setValidSelfService(true);
                    break;
                }
                case GARAGE: {
                    setServiceLocationErrorMessage('');
                    break;
                }
                case OTHER: {
                    setServiceLocationErrorMessage('');
                    break;
                }
                default: {
                    setServiceLocationErrorMessage(serviceLocationError);
                }
            }
        }
        handleSubmitForm();
    };

    const handleBusinessName = (e: any) => {
        if (e === undefined) {
            setBusinessNameErrorMessage(businessNameError);
        } else {
            if (isLincoln) {
                const dropdownLabel = document.querySelector(
                    '.business-name-label'
                );
                if (dropdownLabel)
                    dropdownLabel.className =
                        dropdownLabel.className + ' dropdown-selected';
            }
            const input = e;
            setBusinessNameView(input);
            if (input === businessNameLabel || input === undefined) {
                setValidBusinessName(false);
                setBusinessNameErrorMessage(businessNameError);
            } else {
                setValidBusinessName(serviceLocationView === GARAGE);
                setBusinessNameErrorMessage('');
            }
        }
        handleSubmitForm();
    };

    const handleLocationName = (e: any) => {
        if (e === undefined) {
            setLocationNameErrorMessage('');
            setValidLocationName(false);
        } else {
            const input = e.target.value.trim();
            setLocationNameView(input);
            if (input !== '') {
                setLocationNameErrorMessage('');
                setValidLocationName(serviceLocationView === OTHER);
                if (e.type === 'blur') {
                    setLocationNameView(input);
                }
            } else {
                setLocationNameView(input);
                setLocationNameErrorMessage(locationNameError);
                setValidLocationName(false);
            }
        }
        handleSubmitForm();
    };

    const handleServicePerformedSelected = (e: any) => {
        const _servicesPerformed: ReactCategory[] = servicesPerformed;
        if (e.target.checked) {
            _servicesPerformed.push(e.target.value);
            setServicesPerformedErrorMessage('');
        } else {
            _servicesPerformed.splice(
                servicesPerformed.indexOf(e.target.value),
                1
            );
        }
        setServicesPerformed(_servicesPerformed);
        if (servicesPerformed.length === 0) {
            setServicesPerformedErrorMessage(servicesPerformedError);
            setValidServicePerformed(false);
        } else {
            setServicesPerformedErrorMessage('');
            setValidServicePerformed(true);
        }
        handleSubmitForm();
    };
    const onHandleServicePerformedSelected = (e: any) => {
        const servicePerformedtCheckbox: any = document.querySelectorAll(
            '.services-performed.checkbox'
        );
        const servicePerformedLastCheckbox = servicePerformedtCheckbox.length;
        if (servicesPerformed.length === 0) {
            if (
                e.target ==
                servicePerformedtCheckbox[servicePerformedLastCheckbox - 1]
            ) {
                if (e.keyCode === 9) {
                    setServicesPerformedErrorMessage(servicesPerformedError);
                }
            }
        }
        handleSubmitForm();
    };
    const handleInspectionPerformedSelected = (e: any) => {
        const _inspectionPerformed: Array<string> = inspectionPerformed;
        if (e.target.checked) {
            _inspectionPerformed.push(e.target.value);
        } else {
            _inspectionPerformed.splice(
                inspectionPerformed.indexOf(e.target.value),
                1
            );
        }
        setInspectionPerformed(_inspectionPerformed);
        handleSubmitForm();
    };

    const handleNotes = (e: string) => {
        setNotesView(e);
    };

    const handleCheckDisclaimer = (e: any) => {
        if (e === undefined) {
            setDisclaimerErrorMessage('');
            setValidDisclaimer(false);
        } else {
            if (e.target.checked) {
                setDisclaimerErrorMessage('');
                setValidDisclaimer(true);
            } else {
                setDisclaimerErrorMessage(termsAndConditionsError);
                setValidDisclaimer(false);
            }
        }
        handleSubmitForm();
    };

    const addUpdateRecordFailure = () => {
        setShowAddServiceHistoryErrorModal(true);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        props.addEditStatus('error', editableRecord.serviceId ? 'edit' : 'add');
        triggerAddRecordsErrorAnalytics('form', 'backend submission error');
    };

    const triggerFormAnalytics = (event: string): void => {
        let eventName = '';
        switch (event) {
            case 'add-record-save':
                eventName = 'service-history-add-record-save-onclick-event';
                break;
            case 'add-record-cancel':
                eventName = 'service-history-add-record-cancel-onclick-event';
                break;
            case 'edit-record-save':
                eventName = 'service-history-edit-record-save-onclick-event';
                break;
            case 'edit-record-cancel':
                eventName = 'service-history-edit-record-cancel-onclick-event';
                break;
        }
        fireEvents(eventName, undefined, undefined, false);
    };

    const addServiceHistoryRecord = () => {
        const serviceHistory = new ServiceHistory();
        const mileage = mileageView.replace(' ' + mileageUnit, '');
        const cost = costView.replace(',', '').replace(currencySymbol, '');
        serviceHistory.setVin(vin ? vin : '');
        serviceHistory.setMileage(mileage && Number(mileage));
        serviceHistory.setCost(cost);
        serviceHistory.setDate(dateView.toString());
        serviceHistory.setProviderType(serviceLocationView);
        if (inspectionPerformed.length === 0) {
            serviceHistory.setSafetyInspection(false);
            serviceHistory.setEmissionInspection(false);
        } else {
            serviceHistory.setEmissionInspection(
                inspectionPerformed.filter(
                    (obj: string) => JSON.parse(obj).code === 'emission'
                ).length > 0
            );
            serviceHistory.setSafetyInspection(
                inspectionPerformed.filter(
                    (obj: string) => JSON.parse(obj).code === 'safety'
                ).length > 0
            );
        }
        const serviceSelected = vehicleServiceProviders?.serviceProviders.filter(
            service => service.name === businessNameView
        )[0];
        const _serviceLocation: any = {};
        switch (serviceHistory.providerType) {
            case OTHER: {
                _serviceLocation.serviceProvider = {
                    code: '',
                    value: locationNameView,
                };
                break;
            }
            case GARAGE:
            case 'DEALER': {
                _serviceLocation.serviceProvider = {
                    code: serviceSelected?.code,
                    value: serviceSelected?.name,
                };
                break;
            }
            case SELF_SERVICE: {
                _serviceLocation.serviceProvider = {
                    code: '',
                    value: '',
                };
                break;
            }
        }
        const selectedServices = document.querySelectorAll(
            '.services-performed'
        );
        const codes: any = [];
        selectedServices.forEach(function(checkbox: any) {
            const checkName = checkbox.getAttribute('name');
            checkbox.checked && codes.push(checkName);
        });
        serviceHistory.setServicePerformed(codes);
        serviceHistory.setProvider(_serviceLocation);
        serviceHistory.setNotes(notesView);
        ServiceHandler.VehicleServiceHistory.addUpdateServiceHistory(
            serviceHistory,
            editableRecord.serviceId
        )
            .then(response => {
                if (response?.error?.message) {
                    addUpdateRecordFailure();
                } else {
                    props.onCancelAddRecord(true);
                    props.addEditStatus(
                        'success',
                        editableRecord.serviceId ? 'edit' : 'add'
                    );
                    isAddRecordForm()
                        ? triggerFormAnalytics('add-record-save')
                        : triggerFormAnalytics('edit-record-save');
                }
            })
            .catch(() => {
                addUpdateRecordFailure();
            });
    };

    const checkConsentSelected = () => {
        if (!isValidDisclaimer) {
            setDisclaimerErrorMessage(termsAndConditionsError);
        } else {
            addServiceHistoryRecord();
            triggerFormAnalytics('add-record-save');
        }
    };

    const getServiceTypesData = (categoryData: any) => {
        const serviceTypes =
            serviceTypeData === undefined
                ? []
                : serviceTypeData.value.serviceTypes;
        let serviceTypeItems: any = [];
        const transformedData: any = [];
        if (serviceTypes.length > 0) {
            categoryData.forEach((category: any) => {
                const categoryVal = category.split('|');
                const servicesCode = (categoryVal[1] || []).split(',');
                serviceTypeItems = [];
                servicesCode.forEach((serviceCode: any) => {
                    const typesObj = serviceTypes.find(
                        type => type.code === serviceCode
                    );
                    if (typesObj !== undefined) {
                        serviceTypeItems.push({
                            code: serviceCode,
                            label: typesObj?.description,
                        });
                    }
                });
                if (serviceTypeItems.length > 0) {
                    const singleCategory = {
                        label: categoryVal[0],
                        values: serviceTypeItems,
                    };
                    transformedData.push(singleCategory);
                }
            });
        }
        return transformedData;
    };

    const servicesPerformedData =
        servicesPerformedCategory &&
        getServiceTypesData(servicesPerformedCategory);

    const handleOnClickCancel = () => {
        props.onCancelAddRecord(false);
        if (isAddRecordForm()) {
            triggerFormAnalytics('add-record-cancel');
        } else {
            triggerFormAnalytics('edit-record-cancel');
        }
    };

    const serviceLocationValidationDropdown = () => {
        if (!serviceLocationView || serviceLocationView === ' ') {
            setServiceLocationErrorMessage(serviceLocationError);
        } else {
            setServiceLocationErrorMessage('');
        }
    };
    const bussinessNameValidationDropdown = () => {
        if (!businessNameView || businessNameView === ' ') {
            setBusinessNameErrorMessage(businessNameError);
        } else {
            setBusinessNameErrorMessage('');
        }
    };
    const handleKeyDownOnCancel = () => {
        props.onCancelAddRecord(false);
        setTimeout(() => {
            const addRecordElement = document.getElementsByClassName(
                'service-history-add-button '
            )[0] as HTMLElement;
            addRecordElement.setAttribute('tabIndex', '0');
            addRecordElement.focus();
        }, 500);
        if (isAddRecordForm()) {
            triggerFormAnalytics('add-record-cancel');
        } else {
            triggerFormAnalytics('edit-record-cancel');
        }
    };

    const setLabel = (labelValue: string) => {
        let label = '';
        if (!isLincoln && labelValue) {
            label = labelValue;
        }
        return label;
    };

    return (
        <div>
            <div
                className={`modal-backdrop${!showAddServiceHistoryErrorModal &&
                    '-hidden'}`}
            ></div>
            <div className="service-history-header">
                <h2
                    className="service-history-item-header"
                    dangerouslySetInnerHTML={{
                        __html: serviceHistoryContent.vehicle,
                    }}
                ></h2>
                <div className="service-history-item">
                    {`${make} ${model} (${year})`}
                </div>
                <div className="service-history-item">{`${vinLabel}: ${vin}`}</div>
            </div>
            <div className="service-history-add-services-performed-block">
                <form>
                    <div className="service-history-form-add-service-input-fields add-fields">
                        <fieldset>
                            <h2>
                                <legend
                                    className="service-history-form-main-headers service-history-form-name"
                                    dangerouslySetInnerHTML={{
                                        __html: addService,
                                    }}
                                />
                            </h2>
                            <InputField
                                label={mileageLabel}
                                ariaLabel={mileageLabel}
                                name="mileage"
                                type="number"
                                value={mileageView}
                                errorMessage={mileageError}
                                onChange={e => handleMileage(e)}
                                onBlur={e => handleMileage(e)}
                                onFocus={e => handleMileage(e)}
                                onKeyDown={e => handleMileage(e)}
                                fdsStyle={true}
                                ariaRequired={true}
                            />
                            <span
                                className="error-message"
                                id="mileage-error-message"
                            >
                                {mileageError}
                            </span>
                            <InputField
                                label={dateLabel}
                                ariaLabel={dateLabel}
                                name="date"
                                value={dateView?.toString()}
                                errorMessage={dateErrorMessage}
                                onChange={e => handleDate(e, 'change')}
                                onBlur={e => handleDate(e, 'focus')}
                                onKeyDown={e => handleDate(e, 'keydown')}
                                fdsStyle={true}
                                ariaRequired={true}
                            />
                            <span
                                className="error-message"
                                id="date-error-message"
                            >
                                {dateErrorMessage}
                            </span>
                            <InputField
                                label={totalCostLabel}
                                ariaLabel={totalCostLabel}
                                name="cost"
                                value={
                                    costView === 'NaN' || costView === '$NaN'
                                        ? ''
                                        : costView
                                }
                                errorMessage={costErrorMessage}
                                onChange={e => handleCost(e)}
                                onBlur={e => handleCost(e)}
                                onFocus={e => handleCost(e)}
                                onKeyDown={e => handleCost(e)}
                                fdsStyle={true}
                                ariaRequired={true}
                            />
                            <span
                                className="error-message"
                                id="cost-error-message"
                            >
                                {costErrorMessage}
                            </span>
                            <p className="total-cost-disclaimer">
                                {totalCostDisclaimer}
                            </p>
                            <label
                                htmlFor="id-service-history-form__service-location"
                                className="input-label service-location-label"
                            >
                                {serviceLocationLabel}
                                {!isLincoln &&
                                    serviceLocationView !== ' ' &&
                                    !serviceLocationErrorMessage && (
                                        <img
                                            src={successIcon}
                                            alt=""
                                            className="success-icon"
                                        />
                                    )}
                                {!isLincoln && serviceLocationErrorMessage && (
                                    <img
                                        src={errorIcon}
                                        alt=""
                                        className="error-icon"
                                    />
                                )}
                            </label>
                            <Dropdown
                                id="id-service-history-form__service-location"
                                showLabel={false}
                                label={setLabel(serviceLocationLabel)}
                                ariaLabel={serviceLocationLabel}
                                onChange={(e, index, value) =>
                                    onServiceLocationChange(e, index, value)
                                }
                                onDropdownClick={() =>
                                    updateLabelClassName('service-location')
                                }
                                handelBlur={serviceLocationValidationDropdown}
                                options={
                                    Array.isArray(serviceLocationOptions)
                                        ? serviceLocationOptions
                                        : []
                                }
                                value={
                                    serviceLocationOptions.find(
                                        opt => opt.value === serviceLocationView
                                    )?.label || ''
                                }
                                errorMessage={serviceLocationErrorMessage || ''}
                                errorLabel="serviceLocation-error-label"
                                className={`dropdown-button service-history-form-service-location-dropdown ${
                                    serviceLocationView !== ' ' &&
                                    !serviceLocationErrorMessage
                                        ? 'valid'
                                        : ''
                                }`}
                            />
                            {serviceLocationView === GARAGE && (
                                <>
                                    <label
                                        htmlFor="id-service-history-form__business-name"
                                        className="input-label business-name-label"
                                    >
                                        {businessNameLabel}
                                        {!isLincoln &&
                                            (isValidBusinessName ? (
                                                <img
                                                    src={successIcon}
                                                    alt=""
                                                    className="success-icon"
                                                />
                                            ) : (
                                                <img
                                                    src={errorIcon}
                                                    alt=""
                                                    className="error-icon"
                                                />
                                            ))}
                                    </label>
                                    <Dropdown
                                        id="id-service-history-form__business-name"
                                        label={setLabel(businessNameLabel)}
                                        ariaLabel={businessNameLabel}
                                        onChange={e => handleBusinessName(e)}
                                        handelBlur={
                                            bussinessNameValidationDropdown
                                        }
                                        onDropdownClick={() =>
                                            updateLabelClassName(
                                                'business-name'
                                            )
                                        }
                                        options={
                                            vehicleServiceProviders ===
                                            undefined
                                                ? []
                                                : vehicleServiceProviders.serviceProviders.map(
                                                      service => service.name
                                                  )
                                        }
                                        value={businessNameView}
                                        errorMessage={businessNameErrorMessage}
                                        errorLabel="bussinessName-error-label"
                                        className={`dropdown-button service-history-form-business-name-dropdown ${
                                            isValidBusinessName ? 'valid' : ''
                                        }`}
                                    />
                                </>
                            )}
                            {serviceLocationView === OTHER && (
                                <>
                                    <InputField
                                        label={locationNameLabel}
                                        ariaLabel={locationNameLabel}
                                        name="location"
                                        value={locationNameView}
                                        errorMessage={locationNameErrorMessage}
                                        onChange={e => handleLocationName(e)}
                                        onBlur={e => handleLocationName(e)}
                                        onKeyDown={e => handleLocationName(e)}
                                        fdsStyle={true}
                                        ariaRequired={true}
                                    />
                                    <span
                                        className="error-message"
                                        id="location-error-message"
                                    >
                                        {locationNameErrorMessage}
                                    </span>
                                </>
                            )}
                        </fieldset>
                    </div>
                    <h2
                        className="service-history-form-services-performed"
                        dangerouslySetInnerHTML={{
                            __html: servicesPerformedLabel,
                        }}
                    ></h2>
                    <div
                        id="services-performed-error-message"
                        className={`services-performed-error-message${
                            servicesPerformedErrorMessage ? '-show' : '-hide'
                        }`}
                    >
                        {servicesPerformedErrorMessage}
                    </div>
                    <div className="service-history-form-add-service-input-fields">
                        {servicesPerformedData?.map((check: any) => (
                            <React.Fragment key={check.label}>
                                <fieldset aria-describedby="services-performed-error-message">
                                    <div
                                        className="service-history-form-checkbox-category-name"
                                        id="service-history-form-checkbox-category-name"
                                    >
                                        <legend aria-describedby="services-performed-error-message">
                                            {check.label}
                                        </legend>
                                    </div>
                                    <hr className="service-history-form-horizontal-line" />
                                    {check.values?.map((checkbox: any) => (
                                        <Checkbox
                                            className={'services-performed'}
                                            key={checkbox.code}
                                            label={checkbox.label}
                                            ariaLabel={checkbox.label}
                                            name={checkbox.code}
                                            value={JSON.stringify({
                                                code: checkbox.code,
                                                desc: checkbox.label,
                                            })}
                                            onClick={
                                                handleServicePerformedSelected
                                            }
                                            onKeyDown={
                                                onHandleServicePerformedSelected
                                            }
                                            ariadescribedBy="services-performed-error-message"
                                        />
                                    ))}
                                </fieldset>
                            </React.Fragment>
                        ))}
                    </div>
                    <fieldset>
                        <h2 className="service-history-form-headers service-history-form-inspections-performed">
                            <legend>{inspectionsPerformedLabel}</legend>
                        </h2>
                        <div className="service-history-form-add-service-input-fields">
                            {safetyInspectionLabel && (
                                <Checkbox
                                    key="safety"
                                    label={safetyInspectionLabel}
                                    ariaLabel="safety Label"
                                    name="safety"
                                    value={JSON.stringify({
                                        code: 'safety',
                                        desc: safetyInspectionLabel,
                                    })}
                                    onClick={handleInspectionPerformedSelected}
                                />
                            )}
                            {emissionsInspectionLabel ? (
                                <Checkbox
                                    key="emission"
                                    label={emissionsInspectionLabel}
                                    ariaLabel="Emissions Label"
                                    name="emission"
                                    value={JSON.stringify({
                                        code: 'emission',
                                        desc: emissionsInspectionLabel,
                                    })}
                                    onClick={handleInspectionPerformedSelected}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </fieldset>
                    <label className="service-history-form-headers">
                        {notesLabel}
                    </label>
                    <div className="service-history-form-notes">
                        <textarea
                            aria-label="Notes"
                            rows={7}
                            cols={5}
                            name={notes}
                            value={notesView}
                            onChange={e => handleNotes(e.target.value)}
                            className="service-history-form-notes-input"
                        />
                        <fieldset aria-describedby="disclaimer-error-label">
                            <div className="service-history-form-disclaimer">
                                <input
                                    type="checkbox"
                                    className="disclaimer-checkbox"
                                    id="id-service-history-form__disclaimer"
                                    onClick={handleCheckDisclaimer}
                                    onKeyDown={handleCheckDisclaimer}
                                    aria-describedby="disclaimer-error-label"
                                />
                                <label
                                    htmlFor="id-service-history-form__disclaimer"
                                    className="service-history-form-disclaimer-label"
                                    dangerouslySetInnerHTML={{
                                        __html: legalLanguageText,
                                    }}
                                />
                            </div>
                        </fieldset>
                        <div
                            id="disclaimer-error-label"
                            className={`service-history-disclaimer-error-message${
                                disclaimerErrorMessage ? '-show' : '-hide'
                            } service-history-form-error-message`}
                        >
                            <img
                                className="disclaimer-error"
                                src={errorWarning}
                            />
                            <span className="disclaimer-error-label">
                                {disclaimerErrorMessage}
                            </span>
                        </div>
                        <div
                            className="privacy-link"
                            dangerouslySetInnerHTML={{
                                __html: privacyPolicyLink,
                            }}
                        />
                    </div>

                    <div className="service-history-form-panel-button">
                        <div className="service-history-form-panel-button-save">
                            <PrimaryButton
                                color={'dark'}
                                fill={'fill'}
                                chevron={false}
                                className={'blue'}
                                onClick={checkConsentSelected}
                                disabled={disabledButton}
                                ariaLabel={saveCtaLabel}
                                type="button"
                            >
                                {saveCtaLabel}
                            </PrimaryButton>
                        </div>
                        <div className="service-history-form-panel-button-cancel">
                            <SecondaryButton
                                onClick={handleOnClickCancel}
                                onKeyDown={handleKeyDownOnCancel}
                                type="button"
                            >
                                {cancelCtaLabel}
                            </SecondaryButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
