import { useContent } from '../../../../hooks/use-server-content';

export interface VehiclePageContentProps {
    hide: boolean;
    changeVehicleButtonLabel: string;
    changeVehicleButtonAriaLabel: string;
    goodMorningGreeting: string;
    goodAfternoonGreeting: string;
    goodEveningGreeting: string;
    registrationIdLabel: string;
    vinLabel: string;
    addVehicleAriaLabel?: string;
    addVehicleCtaUrl?: string;
    addVehicleCtaLabel?: string;
    vehInfoUnauthText: string;
    vehInfoAuthText: string;
    registerOrSignInText: string;
    registerOrSignInCTALabel: string;
    hideVehicleImage: boolean;
}
export const useVehiclePageContent = (): VehiclePageContentProps | null => {
    const [content, getValueByName] = useContent('common', 'vehicle-page');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        contentFragment.hide && (contentFragment = null);
    }
    return contentFragment as VehiclePageContentProps;
};
