import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import { SaveFeatures } from './save-features-service';
import { UserData } from '../../models/handover-data';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';
import saveFeatureService from '../../services/handover/save-feature-to-db-service';
import ServerSideService from '../../services/server-side-service/server-side-service';
import Integer from '@zxing/library/esm/core/util/Integer';

const appConfig = new AppConfigurationService();

export class SaveFeaturesToDataBaseService extends SaveFeatures {
    loadSelectedVin(): string | undefined {
        return ServerSideService.isClientSide()
            ? (window.sessionStorage.getItem('selectedVin') as string)
            : undefined;
    }

    appConfig = new AppConfigurationService();

    async useSaveFeatureCheckList(user: UserData) {
        const selectedVin: string | undefined = this.loadSelectedVin();
        if (selectedVin != undefined) {
            return await saveFeatureService.saveFeaturesToDb(
                DSL_BASE_URL + DSL_API_PATH.CHECKLIST,
                user
            );
        }
    }

    saveFeatures(
        checkedFeaturesList: any[],
        customerNotes: string | null,
        uncheckedFeaturesList: any[],
        vehicleYear: Integer,
        vehicleName: string
    ) {
        const user: UserData = {
            country: appConfig.current3LetterCountryCode.toUpperCase(),
            language: appConfig.getAppConfiguration().languageRegionCode,
            vin: this.loadSelectedVin(),
            vehicleLine: vehicleName,
            year: vehicleYear,
            brand: appConfig.brand,
            checkedFeatures: checkedFeaturesList,
            uncheckedFeatures: uncheckedFeaturesList,
            customerNotes: customerNotes,
        };
        return this.useSaveFeatureCheckList(user);
    }
}
