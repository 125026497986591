import ConnectedVehicle, {
    HeaderType,
} from '../connected-vehicle/connected-vehicle';
import { ChargeLevelContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import React from 'react';
import { UomDistance, Vehiclestatus } from '../../../models/connected-vehicle';
import './connected-vehicle-charge-level.scss';

interface Props {
    vin: string;
    connectedVehicleInfo: Vehiclestatus;
    isBev: boolean;
}

const ConnectedVehicleChargeLevel = (props: Props & ChargeLevelContent) => {
    const batteryFillLevel = props.connectedVehicleInfo.batteryFillLevel;
    const elVehDTE = props.connectedVehicleInfo.elVehDTE;
    const uomDistance: UomDistance = props.connectedVehicleInfo.uomDistance;
    const { isBev } = props;
    const others = () => {
        let headerType: HeaderType = 'none';
        let fillLevelIcon = '';
        if (batteryFillLevel) {
            if (batteryFillLevel.quarterRange === 'FULL') {
                fillLevelIcon = props.fullIconPath;
            } else if (batteryFillLevel.quarterRange === 'THREEFOURTH') {
                fillLevelIcon = props.threeFourthIconPath;
            } else if (batteryFillLevel.quarterRange === 'HALF') {
                fillLevelIcon = props.halfIconPath;
            } else if (batteryFillLevel.quarterRange === 'QUARTER') {
                fillLevelIcon = props.quarterIconPath;
                headerType = 'moderate';
            }
        }
        return { headerType, fillLevelIcon };
    };
    return (
        <>
            {batteryFillLevel && elVehDTE?.value && isBev && (
                <div className={'vehicle-charge-container'}>
                    <ConnectedVehicle
                        className={'charge-level'}
                        header={props.title}
                        headerType={others().headerType}
                        iconPath={others().fillLevelIcon}
                        number={
                            Math.round(elVehDTE?.value).toLocaleString() +
                            (elVehDTE?.value?.toLocaleString() === '0'
                                ? ''
                                : uomDistance === 1
                                ? ' MI'
                                : ' KM')
                        }
                    />
                </div>
            )}
        </>
    );
};
export default ConnectedVehicleChargeLevel;
