import { useEffect, useState } from 'react';
import './footerChecklist.scss';
import { useHistory } from 'react-router-dom';
import { HandoverSendToDealerPage } from '../handover-controller';
import { Button } from '@own/fds-react';
import PrimaryButton from '../../../common/primary-button/primary-button';
import downloadIcon from '../../../../assets/download-icon-solid.svg';
interface Props {
    i18n: Map<string, string | boolean>;
    featureLength: number;
    link?: string;
    handleClick?: any;
    isChecklistFeaturesSavedInDB?: boolean;
    handoverOnclickAnalytics?: any;
    page?: any;
}

export const Footerchecklist = (props: Props) => {
    const history = useHistory();
    if (props.handoverOnclickAnalytics) props.handoverOnclickAnalytics();

    const handleClickEYF = (value: any) => {
        if (value == 'sendtodealer' || value == 'featureDetails') {
            history.push(HandoverSendToDealerPage());
            props.handleClick();
        } else {
            props.handleClick();
        }
    };

    const labelString = () => {
        if (props.page == 'sendtodealer') {
            return String(props.i18n.get('nextStepCTAText'));
        } else if (props.page == 'confirmationPage') {
            return String(props.i18n.get('downloadCTAText'));
        } else {
            return String(props.i18n.get('confirmCTAText'));
        }
    };

    const [selectedFeatures, setSelectedFeatures] = useState(0);

    useEffect(() => {
        setSelectedFeatures(props.featureLength);
    }, [props.featureLength]);

    return (
        <>
            {selectedFeatures > 0 && (
                <div className="checklist-footer-EYF-ideal">
                    {props.page != 'confirmationPage' && (
                        <div className="features">
                            {selectedFeatures}{' '}
                            {props.i18n.get('featuresSelectedLabel')}
                        </div>
                    )}
                    {props.page == 'confirmationPage' && (
                        <div className="features">
                            {props.i18n.get('downloadChecklistAsPDF')}
                        </div>
                    )}

                    <div className="create-checklist-cta">
                        {props.page != 'confirmationPage' ? (
                            <Button
                                type="standard"
                                label={labelString()}
                                outlined={false}
                                chevron="right"
                                theme="dark"
                                onClick={() => {
                                    handleClickEYF(props.page);
                                }}
                            ></Button>
                        ) : (
                            <PrimaryButton
                                className={`checklist-primary-button`}
                                color="light"
                                fill="fill"
                                chevron={false}
                                onClick={() => {
                                    handleClickEYF(props.page);
                                }}
                            >
                                <div className="checklist-primary-button-format">
                                    <div>{labelString()}</div>
                                    <div>
                                        <img
                                            className="checklist-primary-button-download-icon"
                                            src={downloadIcon}
                                            alt={'Downlaod CTA'}
                                        />
                                    </div>
                                </div>
                            </PrimaryButton>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Footerchecklist;
