import { FMAccordion, FMButton } from '@own/fds-react';
import React from 'react';
import { NotificationItemButtonProps } from '../notification-item/notification-item';
import { HandoverContent } from '../../../../models/experiencefragments/vehicle-notifications';
import { AccordionItem, ListItem } from './detailed-notification-item';
import './pre-imagine-detailed-notification-item.ford.scss';

export interface Props {
    headText?: string;
    subText?: string;
    iconPath?: string;
    detailedIconPath?: string;
    buttonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    secondaryButtonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    notificationDetails?: ListItem[];
    handover?: HandoverContent;
    notificationAccordionDetails?: AccordionItem[];
    notificationName?: string;
    category?: string;
    handoverOnclickAnalytics?: () => void;
}
export const PreImagineDetailedNotificationItem = (props: Props) => {
    const setSelectedVin = () => {
        if (props.handoverOnclickAnalytics) props.handoverOnclickAnalytics();
        const vin: any = sessionStorage.getItem('selectedVin') || null;
        if (vin) {
            localStorage.setItem('selectedVin', vin);
        }
    };
    const notificationDetail = (idx: number, item: ListItem) => {
        return (
            <>
                <hr className="notifications-item-divider" />
                <div className="list-item" key={idx}>
                    <div className="list-icon">
                        <img src={props.detailedIconPath} alt="" />
                    </div>
                    <div className="list-text">{item?.description}</div>
                </div>
            </>
        );
    };
    const notificationAccordionDetails = (idx: number, item: AccordionItem) => {
        return {
            index: idx,
            title: item.accordionHeader,
            panel: item.description,
        };
    };
    return (
        <div className="fm-detailed-notification-item-wrapper">
            <div className="fm-detailed-notification-item-header">
                <div className="fm-details-item">
                    <div className="fm-details-message">
                        {props.headText}
                        {props?.handover && (
                            <div className="fm-details-message-inline-link">
                                <FMButton
                                    type={'tertiary'}
                                    label={props.handover.ctaTitle}
                                    href={props.handover.ctaUrl}
                                    target={'_blank'}
                                    onClick={setSelectedVin}
                                    chevron={'right'}
                                ></FMButton>
                            </div>
                        )}
                    </div>
                    <div className="fm-details-subtext">{props.subText}</div>
                </div>
            </div>

            {props && props.notificationDetails && (
                <div className="fm-details-list">
                    {props.notificationDetails.map((item, idx) =>
                        notificationDetail(idx, item)
                    )}
                </div>
            )}

            {props && props.notificationAccordionDetails && (
                <div className="fm-details-list">
                    <FMAccordion
                        panels={props.notificationAccordionDetails.map(
                            (item: AccordionItem, idx: number) =>
                                notificationAccordionDetails(idx, item)
                        )}
                    ></FMAccordion>
                </div>
            )}

            {props.buttonProps && (
                <div className="fm-button-wrapper">
                    <FMButton
                        type={'tertiary'}
                        label={props.buttonProps.children}
                        href={props.buttonProps.link}
                        ariaLabel={props.buttonProps.ariaLabel}
                        target={props.buttonProps.linkTarget ? '_blank' : ''}
                        onClick={props.buttonProps.onClick}
                        chevron={'right'}
                    ></FMButton>
                    {props.secondaryButtonProps &&
                        props.secondaryButtonProps.children &&
                        props.secondaryButtonProps.href && (
                            <FMButton
                                className={'secondary-button-cta'}
                                href={props.secondaryButtonProps.link}
                                ariaLabel={props.secondaryButtonProps.ariaLabel}
                                target={
                                    props.secondaryButtonProps.linkTarget
                                        ? '_blank'
                                        : ''
                                }
                                onClick={props.secondaryButtonProps.onClick}
                                label={props.secondaryButtonProps.children}
                                type={'tertiary'}
                                chevron={'right'}
                            ></FMButton>
                        )}
                </div>
            )}
        </div>
    );
};
