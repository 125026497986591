import { useContent } from '../../../hooks/use-server-content';

export interface PreferredDealerListContentProps {
    dealerIds: string[];
    redirectUrlForNonParticipatingDealers: string;
    serviceCXBreadcrumb: string;
}
export const usePreferredDealerListContent = (): PreferredDealerListContentProps => {
    const [content, getValueByName] = useContent('common', 'servicecx');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as PreferredDealerListContentProps;
};
export default usePreferredDealerListContent;
