import React, {
    useState,
    useEffect,
    useRef,
    useLayoutEffect,
    useCallback,
} from 'react';
import { VehicleFeature } from '../../../../models/handover-data';
import { useSwipeable } from 'react-swipeable';
import { FeatureCard } from '../feature-card/feature-card';
import './featured-articles-carousel.scss';
import PrimaryButton from '../../../common/primary-button/primary-button';
import { HandoverCheckbox } from '../handover-checkbox/handover-checkbox';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import handoverService from '../../../../services/handover/handover-service';
import { HandoverDetailsPage } from '../handover-controller';
import { useHistory } from 'react-router-dom';
import { Handover_Constants } from '../handover-constants';
import { useAnalytics } from '../../../../hooks/use-analytics';

interface Props {
    i18n: Map<string, string | boolean>;
    cards: VehicleFeature[];
    mainCategory: string;
    handleChangeCheckBox: (
        newValue: number,
        checkboxstate: boolean,
        id: string
    ) => void;
    value: number;
    handoverState: any;
    vehiclesData?: any;
}
export const FeaturedArticlesCarousel = (props: Props) => {
    const postHandover = props.handoverState;
    const [x, setX] = useState<number>(0);
    const cardRef = useRef<HTMLDivElement>(null);
    const carouselWrappeRef = useRef<HTMLDivElement>(null);
    const [maxPosition] = useState<number>(props.cards.length - 1);
    const [maxDisplayCard, setMaxDisplayCard] = useState<number>(1);
    const [currentPosition, setCurrentPosition] = useState<number>(0);
    const [media, setMedia] = useState<string>('');
    const history = useHistory();
    const [fireEvents] = useAnalytics();

    const checkMedia = () => {
        const mediaType = navigator.userAgent;

        if (mediaType.includes('iPad')) {
            // tablet
            setMaxDisplayCard(1);
            setMedia('tablet');
        } else if (
            mediaType.includes('Phone') ||
            mediaType.includes('iPhone') ||
            mediaType.includes('Mobile')
        ) {
            // mobile
            setMaxDisplayCard(1);
            setMedia('mobile');
        } else {
            // desktop
            setMedia('desktop');
            if (props.cards.length >= 3) {
                setMaxDisplayCard(3);
            } else if (props.cards.length == 2) {
                setMaxDisplayCard(2);
            } else {
                setMaxDisplayCard(1);
            }
        }
    };
    const handleWindowSizeChange = () => {
        checkMedia();
    };

    useEffect(() => {
        checkMedia();
    }, [media]);

    const handleScrollRight = () => {
        if (
            currentPosition === Math.ceil(props.cards.length - maxDisplayCard)
        ) {
            return;
        }
        if (currentPosition < maxPosition) {
            setCurrentPosition(currentPosition + 1);
        }
    };
    const handleScrollLeft = () => {
        if (currentPosition > 0) {
            setCurrentPosition(currentPosition - 1);
        }
    };

    const updateCardPositions = useCallback(() => {
        handleWindowSizeChange();
        const cardWidth = cardRef.current?.getBoundingClientRect().width || 0;

        /**
         * FOR DESKTOP / TABLET ONLY
         * if currentPosition is second last then next move must be setX(currentPosition * (cardWidth/2))
         * else setX(currentPosition * (cardWidth)) ;
         * this will show partial view of next slide and then in last move, last slide will be shown full.
         */
        /** Removing the existing logic for feature cut off issue */
        setX(Math.ceil(currentPosition * cardWidth));
    }, [currentPosition]);

    useEffect(() => {
        window.addEventListener('resize', updateCardPositions);
        return () => {
            window.removeEventListener('resize', updateCardPositions);
        };
    }, [updateCardPositions]);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentPosition, updateCardPositions]);

    const handlers = useSwipeable({
        onSwipedLeft: handleScrollRight,
        onSwipedRight: handleScrollLeft,
    });
    const handleCardClick = (card: any, mainCategory: string) => {
        fireEvents(
            'Eyf-learn-more',
            undefined,
            {
                handoverState: postHandover ? 'post handover' : 'pre handover',
                feature: card.headline.toLowerCase(),
            },
            false
        );
        const selectedData = {
            data: card,
            mycategory: mainCategory,
            cards: props.cards,
        };
        const url: any = HandoverDetailsPage().split(':'),
            vehicleData = props?.vehiclesData || {},
            handoverDetailsData = {
                selectedCategoryData: selectedData,
                vehicleData,
            };

        handoverService.removeFromSessionStorage('handover-details');
        handoverService.removeFromSessionStorage(
            Handover_Constants.Handover_Clicked_Feature_Data
        );
        handoverService.setDataToSessionStorage(
            'handover-details',
            handoverDetailsData
        );
        handoverService.setDataToSessionStorage(
            Handover_Constants.Handover_Clicked_Feature_Data,
            card
        );
        history.push(url[0] + encodeURIComponent(card.key));
    };
    return (
        <article
            className="featured-articles-carousel-container-custom"
            data-testid="featured-articles-carousel-id"
        >
            <section
                className="featured-articles-carousel-cards"
                ref={carouselWrappeRef}
            >
                <div className="featured-articles-carousel">
                    {props.cards.map((card: any, index: number) => {
                        return (
                            <div
                                ref={cardRef}
                                className="featured-articles-carousel-card-wrapper"
                                data-idx={index}
                                data-len={props.cards.length}
                                key={card.headline}
                                style={{
                                    transform: `translateX(-${x}px)`,
                                    transition: 'all 500ms linear',
                                }}
                            >
                                <div {...handlers}>
                                    <FeatureCard
                                        key={card.headline}
                                        data={card}
                                        mycategory={props.mainCategory}
                                        cards={props.cards}
                                        handleChangeCheckBox={
                                            props.handleChangeCheckBox
                                        }
                                        value={props.value}
                                        handoverState={postHandover}
                                        i18n={props.i18n}
                                        vehicleData={props?.vehiclesData}
                                        handleClick={() =>
                                            handleCardClick(
                                                card,
                                                props.mainCategory
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>

            <section
                className={
                    !postHandover
                        ? 'pre-slider-checkbox'
                        : 'post-handover-slider'
                }
            >
                {!postHandover ? (
                    <section className="pre-handover-checkbox">
                        <HandoverCheckbox
                            label={props.i18n.get('addAllFeatures')}
                            type="addAll"
                            id={handoverService.getId(props.mainCategory)}
                            featuresFromCategory={props.cards}
                            category={props.mainCategory}
                            onChange={props.handleChangeCheckBox}
                            value={props.value}
                            imgSrc={''}
                            wersCode={''}
                        />
                    </section>
                ) : null}

                <section
                    className={
                        !postHandover
                            ? 'featured-articles-carousel-slider'
                            : 'post-articles-carousel-slider'
                    }
                >
                    <PrimaryButton
                        className={`featured-articles-carousel-button ${
                            currentPosition === 0 ? 'disabled' : ''
                        }`}
                        color="dark"
                        fill="fill"
                        chevron={false}
                        ariaLabel="left-arrow"
                        onClick={handleScrollLeft}
                    >
                        <label>text</label>
                        <FdsChevron direction="left" type="unfilled" />
                    </PrimaryButton>

                    <PrimaryButton
                        className={`featured-articles-carousel-button ${
                            currentPosition ===
                            props.cards.length - maxDisplayCard
                                ? 'disabled'
                                : ''
                        }`}
                        color="dark"
                        fill="fill"
                        chevron={false}
                        ariaLabel="right-arrow"
                        onClick={handleScrollRight}
                    >
                        <label>text</label>
                        <FdsChevron direction="right" type="unfilled" />
                    </PrimaryButton>
                </section>
            </section>
        </article>
    );
};

export default FeaturedArticlesCarousel;
