import React, { useEffect, useState } from 'react';
import './ford-account-lincoln.scss';
import { useHttp } from '../../../../hooks/owners-osb/';
import ServiceHandler from '../../../../services/service-handler';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { OsbContentResponse } from '../../../../models/osb-model/osb-content-details';
import { PrimaryButton } from '../../../common';
import parse from 'html-react-parser';
import { FORD_ACCOUNT_KEYS, LIGHT_JOURNEY_ANALYTICS } from '../osb-constant';
import { OSBSecondaryButton } from '../common/osb-secondary-button/osb-secondary-button';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import { triggerBookServiceGlobalCTAOnClickAnalytics } from '../analytics/osb-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';

export const FordAccount = () => {
    const [fireEvents] = useAnalytics();
    const authService = new AuthenticationService();
    const [osbAppContentData, setOsbAppContentData] = useState<
        OsbContentResponse
    >();
    const { httpState, dispatch } = useHttp();

    const getOsbAppContent = async () => {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-app-more-elements'
        )
            .then((results: any) => {
                setOsbAppContentData(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getOsbAppContent();
    }, []);

    const handleCreateAccount = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.CREAT_ACCOUNT_BTN_CTA_NAME,
            fireEvents
        );
        authService.register();
    };

    const handleSignInClick = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.SIGN_IN_BTN_CTA_NAME,
            fireEvents
        );
        authService.login();
    };

    return (
        <div className="ford-account-section">
            {!httpState.isLoading ? (
                <>
                    <div className="ford-account-title">
                        {!httpState.isLoading
                            ? osbAppContentData?.elements.find(
                                  e =>
                                      e.name ===
                                      FORD_ACCOUNT_KEYS.FORD_ACCOUNT_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div className="ford-desc-container">
                        <div className="ford-account-desc">
                            {parse(
                                osbAppContentData?.elements.find(
                                    e =>
                                        e.name ===
                                        FORD_ACCOUNT_KEYS.FORD_ACCOUNT_LABEL_DESC
                                )?.value + ''.toString() ?? ''
                            )}
                            <div />
                        </div>
                    </div>
                    <div className="ford-button-container">
                        <div className="login-btn">
                            <PrimaryButton
                                dataTestId="FordLoginCTA"
                                role="link"
                                color={'dark'}
                                fill={'fill'}
                                chevron={false}
                                onClick={handleSignInClick}
                            >
                                {!httpState.isLoading
                                    ? osbAppContentData?.elements.find(
                                          e =>
                                              e.name ===
                                              FORD_ACCOUNT_KEYS.LOGIN_BUTTON_LABEL
                                      )?.value + ''.toString()
                                    : 'Login'}
                            </PrimaryButton>
                        </div>
                        <div className="or-style">
                            {!httpState.isLoading
                                ? osbAppContentData?.elements.find(
                                      e => e.name === FORD_ACCOUNT_KEYS.OR_LABEL
                                  )?.value + ''.toString()
                                : 'Or'}
                        </div>
                        <OSBSecondaryButton
                            dataTestId="FordCreateAccountLink"
                            direction="right"
                            type="filled"
                            text={
                                !httpState.isLoading
                                    ? osbAppContentData?.elements.find(
                                          e =>
                                              e.name ===
                                              FORD_ACCOUNT_KEYS.REGISTER_LABEL
                                      )?.value + ''.toString()
                                    : 'Register'
                            }
                            onClickHandler={() => handleCreateAccount()}
                        />
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
};
