import React from 'react';
import { PrimaryButton } from '../../../common';
import './osb-card-lincoln.scss';
import parse from 'html-react-parser';
interface Props {
    title?: string;
    description?: string;
    ctaLabel?: string;
    onClick?: (event: any) => void;
    index?: number;
}
export default function OSBCard(props: Props) {
    return (
        <div className="osb-card" key={props.index}>
            <div className="osb-card-title">{props.title}</div>
            <div className="osb-card-description">
                {props.description && parse(props.description)}
            </div>
            <div className="osb-card-cta">
                <PrimaryButton
                    dataTestId={`Select ${props.title}`}
                    role="link"
                    color={'dark'}
                    fill={'fill'}
                    chevron={false}
                    aria-label="service-card-list-cta"
                    aria-labelledby="select a service cta"
                    onClick={props.onClick}
                >
                    {props.ctaLabel}
                </PrimaryButton>
            </div>
        </div>
    );
}
