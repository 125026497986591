import React, { useState } from 'react';
import '../handover.scss';

import { Dropdown } from '../../../common';
import { useSendToDealerModel } from '../hooks/use-sendToDealer-model';
import { useAnalytics } from '../../../../hooks/use-analytics';
import handoverService from '../../../../services/handover/handover-service';
import { HandoverTracker } from '../../create-checklist/handover-tracker/handover-tracker';

function createTrackerData(
    step: string | boolean | undefined | '1',
    text: any,
    filled = true,
    active = true
) {
    return { step, filled, active, text };
}
interface Props {
    i18n: Map<string, string | boolean>;
    dropDownList: Array<string>;
    handoverState: any;
    isFeatureChecklistinDB?: boolean;
}

const VehicleImageSection = ({ vehicleDetails }: { vehicleDetails: any }) => {
    return (
        <>
            {vehicleDetails && vehicleDetails.vehicleImage && (
                <img
                    data-testid="vehicle-image"
                    className="vehicle-image"
                    src={vehicleDetails.vehicleImage}
                    alt={vehicleDetails.modelDescription}
                    crossOrigin="anonymous"
                />
            )}
        </>
    );
};

export const HandoverInfo = (props: Props) => {
    const postHandover = props.handoverState;
    const [selectedModel, setSelectedModel] = useState<string>('');
    const i18nSendToDealer = useSendToDealerModel();
    const [fireEvents] = useAnalytics();

    const trackerDataEyfIdeal = [
        createTrackerData(
            props.i18n.get('handoverStepOne'),
            props.i18n.get('exploreFeaturesTitle'),
            props.isFeatureChecklistinDB,
            true
        ),
        createTrackerData(
            props.i18n.get('handoverStepTwo'),
            props.i18n.get('reviewLabel'),
            props.isFeatureChecklistinDB,
            false
        ),
        createTrackerData(
            props.i18n.get('handoverStepThree'),
            i18nSendToDealer.get('sendToDealer') &&
                i18nSendToDealer.get('sendChecklistToDealer')
                ? i18nSendToDealer.get('sendToDealer')
                : props.i18n.get('createChecklistTitle'),
            props.isFeatureChecklistinDB,
            false
        ),
    ];

    const { vehicleDetails } = handoverService.getDataFromSessionStorage(
        'vinLookupResponse'
    );

    const postSubHeading = props.i18n.get('postHandoverSubHeading');
    const postHandoverDecription = props.i18n.get('postHandoverDescription');
    const handoverSubheading = props.i18n.get('handoverSubHeading');
    const handoverDescription = props.i18n.get('handoverDescription');

    const categoryLabel = props.i18n.get('categoryLabel');

    const jumpToCategory = props.i18n.get('jumpToCategory');

    const getSelectedValue = (e: string) => {
        setSelectedModel(e);
        const myElement: HTMLElement | null = document.getElementById(e);
        const header: HTMLElement | null = document.querySelector('.euheader');
        fireEvents(
            'jump-to-category',
            undefined,
            {
                handoverState: postHandover ? 'post handover' : 'pre handover',
                category: e,
            },
            false
        );
        if (myElement && header) {
            const offset = header.getBoundingClientRect().height;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = myElement.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    const getImageAndRightContent = () => {
        return (
            <div className="handover-banner-wrapper">
                {!postHandover && (
                    <HandoverTracker trackerData={trackerDataEyfIdeal} />
                )}
                <div
                    className={`handover-banner${
                        !postHandover ? ' banner-top-padding' : ''
                    }`}
                >
                    <VehicleImageSection vehicleDetails={vehicleDetails} />
                    <div className="handover-right-content">
                        <h3>
                            {postHandover ? postSubHeading : handoverSubheading}
                        </h3>
                        <h2>{vehicleDetails.modelDescription}</h2>
                        <p>
                            {postHandover
                                ? postHandoverDecription
                                : handoverDescription}
                        </p>
                    </div>
                </div>
                <div className="jump-to-category">
                    <p className="category-label">{categoryLabel}</p>
                    <Dropdown
                        value={selectedModel}
                        label={String(jumpToCategory).toUpperCase()}
                        showLabel={false}
                        noLabel={true}
                        options={props.dropDownList}
                        onChange={e => getSelectedValue(e)}
                        className="selected-category"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="handover-wrapper" id="handover-info">
            <>{getImageAndRightContent()}</>
        </div>
    );
};

export default HandoverInfo;
