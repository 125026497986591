import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import VehicleImageService from '../services/vehicle-image-service/vehicle-image-service';
import { useEffect, useState } from 'react';

export const useVehicleImage = (
    vehicleAttributes: VehicleAttributes,
    page = 'undefined'
): string | null => {
    const [vehicleImage, setVehicleImage] = useState<string | null>(null);

    useEffect(() => {
        new VehicleImageService()
            .getVehicleImage(vehicleAttributes, page)
            .then(vehicleImageUrl => {
                setVehicleImage(vehicleImageUrl);
            });
    }, [vehicleAttributes]);
    return vehicleImage;
};
