import React from 'react';
import './disclosure.scss';

interface Props {
    i18n: Map<string, string | boolean>;
    featureDisclosure?: React.ReactNode;
    showHeader?: boolean;
}

const Disclosure = (props: Props) => {
    const showImportantInfo =
        !!props.i18n.get('importantInfo') &&
        !!props.i18n.get('importantInfoHeader') &&
        props.i18n.get('importantInfo') !== ' ' &&
        props.i18n.get('importantInfoHeader') !== ' ';

    const showHeader =
        props.featureDisclosure === ' ' ||
        props.featureDisclosure ||
        showImportantInfo ||
        (props.showHeader && showImportantInfo);
    const showFeatureDisclosure =
        props.featureDisclosure === '' || props.featureDisclosure;
    const showImportantInfoContent =
        showImportantInfo &&
        (props.featureDisclosure === '' || props.featureDisclosure)
            ? props.i18n.get('importantInfo')
            : props.featureDisclosure;

    return (
        <div className="important-container">
            {showHeader ? (
                <div className="important-inforamtion-heading">
                    <h4>{String(props.i18n.get('importantInfoHeader'))}</h4>
                </div>
            ) : (
                <div></div>
            )}

            {showFeatureDisclosure || showImportantInfo ? (
                <div className="importantheading-container">
                    <div className="important-information-text">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: String(showImportantInfoContent),
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Disclosure;
