import { SyncMapsCard, SyncMapStatus } from '../use-syncs-maps-status-updates';

export const getCTRCards = (
    syncMapContent: SyncMapStatus | null,
    syncOption: string,
    desktopOrLarger: boolean
): SyncMapsCard[] | undefined => {
    return (
        syncMapContent?.ctrCards &&
        syncMapContent?.ctrCards
            .filter(card => card.syncMapsCardType === syncOption)
            .filter(card =>
                card.syncMapsCardType === 'usb'
                    ? desktopOrLarger
                        ? !card.isMobileCard
                        : card.isMobileCard
                    : true
            )
    );
};
