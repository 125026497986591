import React, { ReactNode } from 'react';
import Link from '../link/link';
import { FdsChevron } from '../chevron/fds-chevron';

import './secondary-button.scss';

interface Props {
    onClick?: (e: React.MouseEvent) => void;
    link?: string;
    linkTarget?: string;
    children: ReactNode;
    className?: string;
    noChevron?: boolean;
    dataTestId?: string;
    type?: any;
    preventCtaDefault?: boolean;
    direction?: string;
    disabled?: boolean;
}

const SecondaryButton = (props: Props & React.HTMLProps<HTMLAnchorElement>) => {
    if (props.children) {
        return (
            <>
                {props.link ? (
                    <div
                        className="secondary-button"
                        data-testid={props.dataTestId}
                    >
                        <Link
                            onClick={(e: React.MouseEvent) =>
                                props.onClick && props.onClick(e)
                            }
                            role="link"
                            className={`secondary-button-link ${props.className}`}
                            href={props.link}
                            target={props.linkTarget}
                            aria-label={props['aria-label']}
                            preventdefault={
                                props.preventCtaDefault ? 'true' : 'false'
                            }
                        >
                            {props.direction === 'left' ? (
                                <>
                                    {(props.noChevron === undefined ||
                                        props.noChevron === false) && (
                                        <FdsChevron
                                            type="filled"
                                            direction="left"
                                        />
                                    )}
                                    <span className="secondary-button-text">
                                        {props.children}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="secondary-button-text">
                                        {props.children}
                                    </span>
                                    {(props.noChevron === undefined ||
                                        props.noChevron === false) && (
                                        <FdsChevron
                                            type="filled"
                                            direction="right"
                                        />
                                    )}
                                </>
                            )}
                        </Link>
                    </div>
                ) : (
                    <div
                        className="secondary-button"
                        data-testid={props.dataTestId}
                    >
                        <button
                            onClick={(e: React.MouseEvent) =>
                                props.onClick && props.onClick(e)
                            }
                            role="link"
                            type={props.type}
                            className={[
                                `secondary-button-link ${props.className}`,
                                `${props.disabled ? 'disabled' : ''}`,
                            ]
                                .join(' ')
                                .trim()}
                            aria-label={props['aria-label']}
                            data-testid={`${props.dataTestId}-button`}
                            disabled={props.disabled}
                        >
                            {props.direction === 'left' ? (
                                <>
                                    {(props.noChevron === undefined ||
                                        props.noChevron === false) && (
                                        <FdsChevron
                                            type="filled"
                                            direction="left"
                                        />
                                    )}
                                    <span className="secondary-button-text">
                                        {props.children}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="secondary-button-text">
                                        {props.children}
                                    </span>
                                    {(props.noChevron === undefined ||
                                        props.noChevron === false) && (
                                        <FdsChevron
                                            type="filled"
                                            direction="right"
                                        />
                                    )}
                                </>
                            )}
                        </button>
                    </div>
                )}
            </>
        );
    } else {
        return <>{<div className="secondary-button"></div>}</>;
    }
};

export default SecondaryButton;
