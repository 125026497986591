export const BOOKING_CONFIRMATION_KEYS = {
    BOOKING_CONFIRMATION_LABEL: 'bookingConfirmationLabel',
    BOOKING_CONFIRMATION_SUBTITLE: 'bookingConfirmationSubTitle',
    WHAT_HAPPEN_LABEL: 'whatHappenLabel',
    WHAT_HAPPEN_DESC_LABEL: 'whatHappenLabelDesc',
    CONTACT_DEALER_LINK: 'contactDealerLink',
    MANAGE_YOUR_BOOKING: 'manageYourBooking',
    MANAGE_BOOKING_DESC_LABEL: 'manageBookingLabelDesc',
    BOOKING_DETAIL_TITLE: 'bookingDetails',
    SERVICE_LABEL: 'ServiceLabel',
    WHERE_LABEL: 'WhereLabel',
    WHEN_LABEL: 'WhenLabel',
    BOOKING_NUMBER_LABEL: 'bookingNumberLabel',
    ACCESS_CODE_LABEL: 'accessCodeLabel',
    PRINT_BOOKING_LINK: 'printBookingLink',
    TIME_AT_LABEL: 'timeAtLabel',
};

export const SERVICE_FLOW = {
    REPAIR_SERVICE: 'repairService',
    MOT_SERVICE: 'MOTService',
    ADDITIONAL_SERVICE: 'additionalService',
    DELIVERY: 'Delivery',
    MAIN_SERVICE: 'mainService',
    VALUE_SERVICE: 'valueService',
    SERVICE_TYPE: '70',
    IMAGE_UPLOAD_SERVICE_TYPE: '71',
    FP_SERVICE_TYPE: '50',
    FP_IMAGE_UPLOAD_SERVICE_TYPE: '51',
    OSB_SITETERMS_REQUIRED: true,
    SUBTYPE_VALUE_SERVICE: 'VALUE',
    COMMENTS_MAX_LENGTH: 500,
    OTHER_SERVICE_DESC_MAX_LENGTH: 100,
    GENERAL_APPOINTMENT_SERVICE_KEY: 'general_appointment',
    SPECIFIC_SERVICE_KEY: 'specific_service',
    MOBILE_SERVICE_KEY: 'mobile_service',
    DEALER_SERVICE_KEY: 'dealer_service',
    CARD_TYPE_GENERAL_APPOINTMENT_SERVICE: 'General Appointment Service',
    CARD_TYPE_SPECIFIC_SERVICE: 'Specific Service',
    CARD_TYPE_MOBILE_SERVICE: 'Mobile Service',
    CARD_COMPONENT_TYPE: 'cardComponentType',
    GA_SERVICE_ID: '66666',
    SERVICE_SUBTYPE_REPAIR: 'REPAIR',
    SERVICE_SUBTYPE_MOT: 'MOT',
    SERVICE_SUBTYPE_VHA: 'VHA',
    SERVICE_SUBTYPE_OIL: 'OIL',
    SERVICE_SUBTYPE_RECALL: 'RECALL',
    SERVICE_SUBTYPE_NOTOUCH: 'NOTOUCH',
    EXTRA_SERVICE: 'extraService',
    IMAGE_UPLOAD_TYPE: 'IMAGE_UPLOAD',
    RETRY_INTERVAL: 1,
    SPECIFIC_SERVICE_SCREEN: 'specific_service_screen',
    PRESELECTED_SERVICES: 'preselectedServices',
    OTHER_CUSTOMSERVICE: 'CUSTOMSERVICE',
    OTHER_SERVICE_REMAIN_COMMENTS_CHAR: 'remainingCharactersMessageLabel',
    DEFAULT_IMAGE_EXT: ['png', 'jpeg', 'jpg'],
    DEFAULT_MAX_FILE_SIZE: '8',
    DEFAULT_MAX_FILE_COUNT: '5',
    SERVICE_CODE: 'serviceCode',
    PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE: 'PickUpAndDelivery',
    SERVICE_POR_OR_EMPTY: 'porOrEmpty',
    SERVICE_PRICE_OR_FREE: 'priceOrFree',
    SERVICE_ADD_BUTTON_LABEL: 'serviceAddButtonLabel',
    SERVICE_REMOVE_BUTTON_LABEL: 'serviceRemoveButtonLabel',
    SERVICE_ACCORDION_SHOW_LABEL: 'serviceAccordionShowLabel',
    SERVICE_ACCORDION_HIDE_LABEL: 'serviceAccordionHideLabel',
    ENABLE_NEW_SERVICE_DESIGN: 'enableNewServiceDesign',
};

export const DATE_TIME_STEP = {
    DEFAULT_CALENDAR_LOCALE: 'en-GB',
    MOBILE_VIEW_NARROW: 'narrow',
    MOBILE_VIEW_SHORT: 'short',
    PLEASE_SELECT_TEXT: 'pleaseSelectText',
    SELECT_DATE: 'selectDate',
    AVAILABLE_TIMES: 'availableTimes',
    MOBILE_TITLE: 'mobileTitle',
    TITLE: 'title',
    PLACEHOLDER_LABEL: 'placeHolderLabel',
    BACK_ON_CALENDAR: 'backButtonCalendar',
    CONTINUE_ON_CALENDAR: 'continueButtonCalendar',
    DATE_FORMAT_CALENDAR: 'dateFormatCalendar',
    SELECTED_DATE_LABEL: 'selectedDateLabel',
    SELECT_TIME_SLOT_DROPDOWN_LABEL: 'timeSlotDropdownLabel',
    AT_SIGN: 'atSignLabel',
    PICKUP_SELECT_DATE_AND_TIME_TITLE: 'pickupSelectDateAndTimeTitle',
    PICKUP_SERVICE_APPOINTMENT_TITLE: 'pickupServiceAppointmentTitle',
    PICKUP_EDIT_LINK_LABEL: 'pickupEditLinkLabel',
    PICKUP_TIME_AND_DATE_TITLE: 'pickupTimeAndDateTitle',
    PICKUP_TIME_AND_DATE_TEXT: 'pickupTimeAndDateText',
    PICKUP_DATE_PLACE_HOLDER_TEXT: 'pickupDatePlaceholderText',
    PICKUP_AVAILABLE_TIMES_TITLE: 'pickupAvailableTimesTitle',
    DROP_OFF_TITLE: 'dropOffTitle',
    PICKUP_DATE: 'pickup date',
    INITIAL_TIMESLOT_INTERVAL_IN_SECONDS: 'initialTimeSlotIntervalInSeconds',
    FIRST_DAY_OF_THE_WEEK: 1,
};

export const OSB_AEM_PROPERTY_KEYS = {
    DEFAULT_VEHICLE_MODEL: 'defaultVehicleModel',
    DEFAULT_VEHICLE_MODEL_REACT: 'NA',
    DEFAULT_VEHICLE_BUILD_YEAR: 'defaultVehicleBuildYear',
    DEFAULT_VEHICLE_MILEAGE: 'defaultVehicleMileage',
    DEFAULT_VEHICLE_BUILD_YEAR_REACT: '2001',
    DEFAULT_VEHICLE_MILEAGE_REACT: '28000',
    BACKGROUND_IMAGE_PATH_DESKTOP: 'backgroundImagePathDesktop',
    BACKGROUND_IMAGE_PATH_MOBILE: 'backgroundImagePathMobile',
    BACKGROUND_IMAGE_PATH_LARGE_DESKTOP: 'backgroundImagePathLargeDesktop',
    BACKGROUND_IMAGE_PATH_TABLET: 'backgroundImagePathTablet',
    POLYGON: 'polygon',
    NUMBER_OF_CHARS_TO_STRIP: 'numberOfLeadingCharactersToStrip',
    SERVICE_BOOKING_TITLE: 'serviceBookingTitle',
    PRICE_PREFIX: 'pricePrefix',
    PRICE_SUFFIX: 'priceSuffix',
    PRICE_PRECISION: 'pricePrecision',
    PRICE_SEPARATOR: 'priceSeparator',
    DECIMAL_SEPARATOR: 'decimalSeparator',
    VIN_REG_DATA_PATTERN: 'vinRegDataPattern',
    ALLOW_VINREG_SPECIAL_CHARACTERS: 'allowVinRegSpecialCharacters',
    ACTIVE_BOOKING_RTE_CONTENT: 'activeBookingRteContent',
    VEHICLE_NOT_FOUND_MESSAGE: 'vehicleNotFoundMessage',
    VIN_VEHICLE_NOT_FOUND_MESSAGE: 'vinVehicleNotFoundMessage',
    VEHICLE_SERVICE_ERROR_MESSAGE: 'vehicleServiceErrorMessage',
    VIN_REGISTRATION_LABEL: 'vinRegistrationLabel',
    VIN_REGISTRATION_PLACE_HOLDER: 'vinRegistrationPlaceholder',
    CHOOSE_YOUR_VEHICLE_LABEL: 'chooseYourVehicleLabel',
    VIN_OR_REG_ENTRY_FIELD_LABEL: 'vinOrRegEntryFieldLabel',
    ADD_VEHICLE_POPUP_HEADER_LABEL: 'addVehiclePopupTextHeader',
    ADD_VEHICLE_POPUP_BODY_LABEL: 'addVehiclePopupTextBody',
    ADD_VEHICLE_POPUP_BUTTON: 'addVehicleButton',
    CHOOSE_YOUR_VEHICLE_DROPDOWN_LABEL: 'chooseYourVehicleDropdownLabel',
    MILEAGE_LABEL: 'mileageLabel',
    MILEAGE_PLACEHOLDER: 'mileagePlaceholder',
    BOTTOM_TEXT: 'bottomText',
    SUBMIT_BUTTON_LABEL: 'submitButtonLabel',
    SUBMIT_VEHICLE_CALL_TYPE: 'SUBMIT_VEHICLE',
    OLOAD_VEHICLE_CALL_TYPE: 'ONLOAD',
    ACCORDION_TITLE: 'accordionTitle',
    MODAL_FIND_YOUR_VIN_TITLE: 'modalFindYourVINTitle',
    MODAL_FIND_YOUR_VIN_SUBTITLE: 'modalFindYourVINSubtitle',
    MODAL_DRIVER_SIDE_DOORFRAME: 'modalDriverSideDoorframe',
    MODAL_YOUR_VEHICLE_DOCUMENTATION_DESCRIPTION:
        'modalYourVehicleDocumentationDescription',
    MODAL_VISIBLE_THROUGH_WINDSHIELD_DESCRIPTION:
        'modalVisibleThroughWindshieldDescription',
    MODAL_FIND_YOUR_REGISTRATION_NUMBER_LABEL:
        'modalFindYourRegistrationNumberLabel',
    MODAL_REGISTRATION_NUMBER_DESCRIPTION: 'modalRegistrationNumberDescription',
    VIN_REG_VALIDATION_MESSAGE: 'vinRegValidationMessage',
    INVALID_VIN_ERROR_MESSAGE: 'invalidVinErrorMessage',
    INVALID_REG_ERROR_MESSAGE: 'invalidRegErrorMessage',
    MILEAGE_VALIDATION_MESSAGE: 'mileageValidationMessage',
    VEHICLE_STEP: 'vehicle-step',
    VEHICLE_STEP_MORE_ELEMENTS: 'vehicle-step-more-elements',
    SHOULD_OPEN_VEHICLE_SECTION_ACCORDION: 'shouldOpenVehicleSectionAccordion',
    BUILD_YEAR_LABEL: 'buildYearLabel',
    TRANSMISSION_LABEL: 'transmissionLabel',
    COLOUR_LABEL: 'colourLabel',
    REGISTRATION_LABEL: 'registrationLabel',
    MODAL_IS_THIS_YOUR_VEHICLE_LABEL: 'modalIsThisYourVehicleLabel',
    MODAL_CONFIRM_BUTTON_LABEL: 'modalConfirmButtonLabel',
    MODAL_NOT_YOUR_VEHICLE: 'ModalNotYourVehicle',
    MODAL_CHANGE_OR_REMOVE_LABEL: 'ModalChangeOrRemoveLabel',
    DEALER_STEP_LABEL: 'dealerHeaderLabel',
    SERVICE_STEP_LABEL: 'serviceHeaderLabel',
    DELIVERY_STEP_LABEL: 'deliveryHeaderLabel',
    LOCATION_STEP_LABEL: 'locationHeaderLabel',
    DATETIME_STEP_LABEL: 'dateTimeHeaderLabel',
    CONTACT_STEP_LABEL: 'personalDetailHeaderLabel',
    TIME_SUFFIX_LABEL: 'timeSuffixLabel',
    TWELVE_HRS_TIME_FORMAT: 'twelveHrsTimeFormat',
    AM_TIME_LABEL: 'amTimeLabel',
    PM_TIME_LABEL: 'pmTimeLabel',
    VIDEO_LINK_LABEL: 'videoLinkLabel',
    VIDEO_PLAYER_BACK_BUTTON_LABEL: 'videoPlayerBackButtonLabel',
    IS_VIDEO_LINK_ENABLED: 'isVideoLinkEnabled',
    IS_MANDATORY_VEHICLE_SECTION: 'isMandatoryVehicleSection',
    MANDATORY_VEHICLE_SECTION_ERROR_MESSAGE:
        'mandatoryVehicleSectionErrorMessage',
    OSB_THREE_LETTER_COUNTRY_CODE: 'osbThreeLetterCountryCode',
    OSB_TWO_LETTER_COUNTRY_CODE: 'osbTwoLetterCountryCode',
    APPLICATION_NAME: 'applicationName',
    ADD_VEHICLE_TO_ACCOUNT_SUCCESS_MESSAGE: 'addVehicleToAccountSuccessMessage',
    ADD_VEHICLE_TO_ACCOUNT_ERROR_MESSAGE: 'addVehicleToAccountErrorMessage',
    LOCAL_STORAGE_EXPIRY: 'localStorageExpiry',
    OSB_HOME_RETRIEVE_BOOKING_HEADER: 'osbHomeRetrieveBookingHeader',
    OSB_HOME_RETRIEVE_BOOKING_COPY_TEXT: 'osbHomeRetrieveBookingCopyText',
    OSB_HOME_RETRIEVE_BOOKING_BUTTON: 'osbHomeRetrieveBookingButtonLabel',
    ENABLE_OSB_HOME_RETRIEVE_BOOKING: 'enableOsbHomeRetrieveBooking',
    VEHICLE_LOADER_MESSAGE: 'vehicleLoaderMessage',
    ENABLE_IMAGE_UPLOAD: 'enableImageUpload',
    GEO_COUNTRY_CODE: 'geoCountryCode',
    DEALER_ADDRESS_ORDER: 'dealerAddressOrder',
    ENABLE_PICKUP_AND_DELIVERY_LOCATION_DATE_AND_TIME:
        'enablePickupNDeliveryLocationDateAndTime',
    INTERNAL_ERROR_MESSAGE: 'internalErrorMessage',
    VEHICLE_DISTANCE_LABEL: 'vehicleDistanceLabel',
    MILEAGE_SEPARATOR_LABEL: 'mileageSeparatorLabel',
    OSB_SHOW_TOOLTIP_ARIEA_LABEL: 'osbShowTooltipAriaLabel',
    OSB_DISABLE_TOOLTIP_ARIEA_LABEL: 'osbDismissTooltipAriaLabel',
    VOUCHER_SUCCESS_MESSAGE: 'voucherSuccessMessagelabel',
    OSB_ADDRESS_ORDER: 'houseNumber,street,town,postalCode',
    ENABLE_MOBILE_SERVICE: 'enableMobileService',
    DISPLAY_COUNTRY_DIAL_CODE_COMPONENT: 'displayCountryDialCodeComponent',
    ENABLE_NEW_LANDING_PAGE: 'enableNewLandingPage',
};

export const DEALER_STEP_KEYS = {
    DEALER_FILTER_DATE_TIME_SERVICES: 'dateTimeService',
    DEALER_FILTER_DATE_TIME_ACCORDIO_NAME: 'dateTimeAccordionLabel',
    SPECIAL_SERVICES_LIST: 'specialServicesList',
    NO_RECORDS_FOUND_LABEL: 'noRecordsFoundLabel',
    POSITION_UNAVAILABLE_LABEL: 'positionUnavailableLabel',
    TRY_AGAIN_LATER_LABEL: 'tryAgainLaterLabel',
    PERMISSION_DENIED_LABEL: 'permissionDeniedLabel',
    TIMEOUT_LABEL: 'timeoutLabel',
    UNKNOWN_LABEL: 'unknownLabel',
    SELECT_A_DEALER: 'selectADealer',
    TITLE: 'title',
    LOCATION_SEARCH_LABEL: 'locationSearchLabel',
    DEALER_NAME_SEARCH_LABEL: 'dealerNameSearchLabel',
    USE_MY_LOCATION_LABEL: 'useMyLocationLabel',
    NO_LOCATIONS_LABEL: 'noLocationsLabel',
    SEARCH_HINT_LABEL: 'searchHintLabel',
    LIGHT_JOURNEY_DISTANCE_LABEL: 'lightJourneyDistanceDescriptionLabel',
    SPECIAL_SERVICES_LIST_TITLE: 'specialServicesListTitle',
    LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT: 'lightJourneyEarliestAvailableSlot',
    STAR_RATINGS_LABEL: 'starRatingsLabel',
    CVP_LOGO_PATH: 'cvpLogoPath',
    CUSTOMER_VIEWPOINT_LOGO_LABEL: 'customerViewPointLogoLabel',
    LIGHT_JOURNEY_DEALER_MOREINFO_BUTTON: 'lightJourneyDealerMoreInfoButton',
    LIGHT_JOURNEY_SELECT_DEALER_BUTTON: 'lightJourneySelectDealerButton',
    BACK_BUTTON_LABEL: 'backButtonLabel',
    LIGHT_JOURNEY_DEALER_VIEWMORE_BUTTON: 'lightJourneyDealerViewMoreButton',
    DISTANCE_FROM_LOCATION: 'distanceFromLocation',
    RATING_LABEL: 'ratingLabel',
    REVIEW_PAGE_SHOWING_LABEL_LIGHT: 'reviewPageShowingLabel-light',
    REVIEW_PAGE_OF_LABEL_LIGHT: 'reviewPageOfLabel-light',
    REVIEW_PAGE_REVIEWS_LABEL_LIGHT: 'reviewPageReviewsLabel-light',
    REVIEW_PAGE_LABEL: 'reviewPageOfLabel',
    VIEW_MORE_LABEL: 'viewMoreLabel',
    CALL_DEALER_BUTTON_LABEL: 'callDealerButtonLabel',
    HIDE_OPENING_HOURS_SECTION: 'hideOpeningHoursSection',
    INITIAL_DEALERS_SHOWN: 'initialDealersShownLabel',
    MAX_DEALERS_SHOWN: 'maxDealersShownLabel',
    RADIUS_OPIONS: 'distance',
    DEFAULT_DISTANCE: 'defaultLabel',
    DISTANCE_UNIT: 'unitLabel',
    SEARCH_DEPTH_FOR_DEALER_DEEPLINK: '1:1',
    SORT_BY_PARAM_LABEL: 'sortByParamLabel',
    PAGE_SIZE_PARAM_LABEL: 'pageSizeParamLabel',
    TYPE_PARAM_LABEL: 'typeParamLabel',
    AUTOCOMPLETE_SEARCH_STRING_LENGTH: 'autocompleteSearchStringLength',
    DEALER_FILTER_LABEL: 'lightJourneyDealerFilterLabel',
    DEALER_FILTER_RESET_LABEL: 'lightJourneyDealerFilterResetLabel',
    DEALER_FILTER_APPLY_LABEL: 'lightJourneyDealerFilterApplyLabel',
    DEALER_FILTER_DELIVERY_SERVICES: 'dealerFilterDeliveryServices',
    DEALER_FILTER_REGULAR_SERVICES: 'dealerFilterRegularServices',
    DEALER_FILTER_DID_SERVICES: 'dealerFilterDIDServices',
    DEALER_FILTER_AVAILABLE_SERVICES: 'availableServices',
    DEALER_FILTER_DATE_TIME_SERVICES_VALUE: 'dateTimeServices',
    NO_DEALER_ERROR_MESSAGE: 'noDealerFoundErrorMessage',
    ENABLE_DEALER_FILTER_OPTIONS: 'enableDealerFilterOptions',
    DEALER_LOADER_MESSAGE: 'dealerLoaderMessage',
    DEALER_SEARCH_INVALID_INPUT_ERROR_CODE: 'ERR001',
    SEARCH_BY_LABEL: 'searchByLabel',
    SEARCH_BY_DEALER_NAME_LABEL: 'searchByDealerLabel',
    SEARCH_BY_LOCATION_LABEL: 'searchByLocationLabel',
    LOCATION: 'location',
    DEALER_NAME: 'dealername',
    SERVICE_BOOKING_TITLE: 'serviceBookingTitle',
    PREFERRED_DEALER_TITLE: 'preferredDealerTitle',
    CONTINUE_BUTTON_LABEL: 'continueButtonLabel',
    OR_LABEL: 'orLabel',
    LOGIN_OR_REGISTER_LABEL: 'loginOrRegisterButtonLabel',
    LOGIN_OR_REGISTER_DESC: 'loginOrRegiserDescription',
    DEALER_CAPABILITY_TITLE: 'dealerCapabilityTitle',
    OPENING_HOURS_TITLE: 'openingHoursTitleLabel',
    PASSANGER_SERVICE_VEHICLE_LABEL: 'passangerVehicleServiceLabel',
    COMMERCIAL_SERVICE_VEHICLE_LABEL: 'commercialVehicleServiceLabel',
    ELECTRIC_SERVICE_VEHICLE_LABEL: 'electricVehicleServiceLabel',
    ENABLE_DEALER_CAPABILITIES: 'enableDealerCapabilities',
    DISTANCE_TITLE: 'distanceTitle',
    PREFERRED_DEALER_CAPTION: 'preferredDealerCaption',
    MAP_VIEW_LABEL: 'mapViewLabel',
    LIST_VIEW_LABEL: 'listViewLabel',
    DEALER_CAPABILITIES_LIST: 'dealerCapabilitiesList',
};

export const SERVICE_DEPARTMENT_PV_KEY = 'serviceDepartmentPV';
export const SERVICE_DEPARTMENT_CV_KEY = 'serviceDepartmentCV';
export const ELECTRIC_SERVICE_KEY = 'electricService';

export const SERVICES_STEP_KEYS = {
    SELECT_AS_SERVICE_TITLE: 'selectAServiceTitle',
    TITLE: 'title',
    TITLE_FIELD: 'Title',
    DESCRIPTION: 'description',
    CTA_LABEL: 'ctaLabel',
    SPECIFIC_SERVICE_BACK_LABEL: 'specificServiceBackLabel',
    SPECIFIC_SERVICE_HEADER: 'specificServiceHeader',
    MOBILE_SERVICE_HEADER: 'mobileServiceHeader',
    SPECIFIC_SERVICE_VEHICLE_ACCORDION_LABEL:
        'specificServiceVehicleAccordionLabel',
    SERVICES_HEADER: 'servicesHeader',
    EXTRA_SERVICES_ACCORDION_LABEL: 'extraServicesAccordionLabel',
    CONTINUE_BUTTON_LABEL: 'continueButtonLabel',
    CONTINUE_BUTTON: 'continueButton',
    CARD_COMPONENT_TYPE: 'cardComponentType',
    CURRENCY_SYMBOL: 'currencySymbol',
    CARD_COMPONENT_FREE_PRICE_LABEL: 'cardComponentFreePriceLabel',
    CARD_COMPONENT_POA_LABEL: 'cardComponentPOALabel',
    CARD_COMPONENT_BACK_BUTTON: 'cardComponentBackButton',
    CARD_COMPONENT_CONTINUE_BUTTON: 'cardComponentContinueButton',
    PREFIX: 'osb-delivery-option-cards/osb-',
    SUFFIX: '-option-card',
    DELIVERY_CARD_SECTION: 'osb-delivery-options-section',
    DELIVERY_CARD_ORDER: 'deliveryCardOrder',
    GET_DROP_OFF_DELIVERY_OPTION_FROM_BACKEND_SERVICE:
        'getDropOffDeliveryOptionFromBackendService',
    DELIVERY_OPTIONS_TITLE: 'deliveryOptionsTitle',
    FREE_LABEL: 'freeLabel',
    PRICE_ON_REQUEST: 'priceOnRequest',
    PRICE_ON_REQUEST_TOOLTIP: 'priceOnRequestTooltipContent',
    SHOULD_OPEN_EXTRA_SERVICES: 'shouldOpenExtraServicesAccordion',
    ENABLE_IMAGE_UPLOAD: 'enableImageUpload',
    IMAGE_UPLOAD_HEADER_TITLE: 'imageUploadTitle',
    IMAGE_UPLOAD_SUB_TITLE: 'imageUplaodSubHeader',
    IMAGE_UPLOAD_DROP_ZONE_TEXT: 'imageDropZoneText',
    IMAGE_UPLOAD_SELECT_FILE_TEXT: 'ImageSelectFile',
    IMAGE_UPLOAD_DOCUMENT_TYPE: 'documentType',
    IMAGE_UPLOAD_DOCUMENT_TYPE_VALUE: 'typeOfImage',
    IMAGE_UPLOAD_MAX_SIZE: 'imageMaxSize',
    IMAGE_UPLOAD_MAX_COUNT: 'maxImageCount',
    IMAGE_UPLOAD_HEADER_INFO_ICON_TEXT: 'ImageUploadInfoMsg',
    IMAGE_REMOVE_LINK: 'removeImage',
    IMAGE_REMOVE_YES_LINK: 'yesConfirm',
    IMAGE_REMOVE_NO_LINK: 'NoConfirm',
    IMAGE_VIEW_LINK: 'imageViewLabel',
    IMAGE_UPLOAD_ERROR: 'imageUploadError',
    DROP_FILE_POPUP_LABEL: 'dropFilePopupLabel',
    IMAGE_ALLOWED_EXT_ARRAY: 'imageAllowedExt',
    IMAGE_UPLOADED_LABEL: 'imagesUploadedLabel',
    GET_SERVICES_MAX_RETRY_LABEL: 'getECATServicesMaxRetriesLabel',
    GET_SERVICES_RETRY_DELAY_IN_SECONDS_LABEL:
        'getECATServicesRetryDelayInSecondsLabel',
    OL_FEATURE_TYPE: 'OL',
    SM_FEATURE_TYPE: 'SM',
    DROP_OFF_DELIVERY_OPTION: 'drop-off',
    DROP_OFF_OPTION_CARD_NAME: 'osb-drop-off-option-card',
    DROP_OFF_OPTION_CARD_TITLE: 'Osb Drop Off Option Card',
    DESCRIPTION_FIELD: 'Description',
    CTA_TITLE_FIELD: 'CTA label',
    SELECT_BUTTON: 'selectButton',
};

export const FORD_ACCOUNT_KEYS = {
    FORD_ENABLE_FLAG: 'fordAccountEnable',
    FORD_ACCOUNT_LABEL: 'fordAccountLabel',
    FORD_ACCOUNT_LABEL_DESC: 'fordAccountLabelDesc',
    LOGIN_BUTTON_LABEL: 'loginButtonLabel',
    LOGIN_REDIRECT_URL: 'loginRedirectUrl',
    OR_LABEL: 'orLabel',
    REGISTER_LABEL: 'registerLabel',
    REGISTER_REDIRECT_URL: 'registerRedirectUrl',
};

export const DELIVERY_STEPS_KEYS = {
    DROP_OFF_DELIVERY_OPTION_CARD: 'Drop Off Delivery Option',
    OTHER_DELIVERY_OPTION_CARD: 'Other Delivery Options',
    DROP_OFF_SERVICE_TYPE: 'DropOff',
    FROM_DB: 'DB',
    FROM_AUTHORING: 'AUTHORED',
    CARD_COMPONENT_TYPE_FIELD: 'Card Component Type',
};

export const BOOKING_SUMMARY_CONTAINER_KEYS = {
    BOOKING_SUMMARY_HEADING: 'bookingSummary',
    BOOKING_ERROR: 'Booking_Error',
    DEALER_LABEL: 'Dealer_Label',
    AMEND_LABEL: 'Amend_Label',
    SERVICES_LABEL: 'Services_Label',
    DELIVERY_SERVICES_LABEL: 'deliveryServicesLabel',
    PICKUP_AND_DELIVERY_LOCATION_LABEL: 'pickUpAndDeliveryLocationLabel',
    SERVICES_WITHOUT_PRICE_LABEL: 'servicesWithoutPriceLabel',
    SPECIFIC_SERVICES_LABEL: 'specificServiceLabel',
    DROP_OFF_LABEL: 'dropOffLabel',
    GENERAL_APPOINTMENT_SERVICE_LABEL: 'generalAppointmentLabel',
    DATE_FORMAT_SUMMARY: 'dateFormatSummary',
    DATE_TIME_LABEL: 'DateTime_Label',
    TIME_AT_LABEL: 'Time_At_Label',
    TIME_BETWEEN_LABEL: 'Time_Between_Label',
    ADD_VOUCHER_LABEL: 'addVoucherLabel',
    ESTIMATED_COST_LABEL: 'Estimated_Cost_Label',
    VIEW_SUMMARY_LABEL: 'viewSummaryLabel',
    TOTAL_COST_LABEL: 'totalCostLabel',
    DATE_FORMAT_LOCALE: 'dateFormatLocale',
    hour12: 'HrsTimeFormat',
    TIME_SUFFIX_LABEL: 'Time_Suffix_Label',
    AM_TIME_INDICATIO_LABEL: 'AM_Time_Indicatio_Label',
    PM_TIME_INDICATION_LABEL: 'PM_Time_Indication_Label',
    IS_LASTNAME_FOLLOWED_BY_FIRSTNAME_ORDER: 'lastNameFollowedByFirstNameOrder',
    SERVICE_LOCATION_TITLE: 'serviceLocationTitle',
    MOBILE_SERVICE_TITLE: 'mobileServiceTitle',
    POR_LABEL: 'porLabel',
};

export const PERSONAL_DETAIL_STEP_KEYS = {
    FIRST_NAME_MANDATORY_FIELD: 'firstNameMandatoryField',
    LAST_NAME_MANDATORY_FIELD: 'lastNameMandatoryField',
    CONTACT_NUMBER_MANDATORY_FIELD: 'contactNumberMandatoryField',
    EMAIL_MANDATORY_FIELD: 'emailMandatoryField',
    REG_NUMBER_MANDATORY_FIELD: 'registrationNumberMandatoryField',

    FIRST_NAME_REGEX_PATTERN: 'firstNameRegex',
    LAST_NAME_REGEX_PATTERN: 'lastNameRegex',
    CONTACT_NUMBER_REGEX_PATTERN: 'contactNumberRegex',
    EMAIL_REGEX_PATTERN: 'emailRegex',
    REG_NUMBER_REGEX_PATTERN: 'registrationNumberRegex',

    FIRST_NAME_ERROR_MESSAGE: 'firstNameErrorMessage',
    LAST_NAME_ERROR_MESSAGE: 'lastNameErrorMessage',
    CONTACT_NUMBER_ERROR_MESSAGE: 'contactNumberErrorMessage',
    EMAIL_ERROR_MESSAGE: 'emailErrorMessage',
    REG_NUMBER_ERROR_MESSAGE: 'registrationNumberErrorMessage',

    FIRST_NAME_PLACEHOLDER: 'firstNamePlaceHolder',
    LAST_NAME_PLACEHOLDER: 'lastNamePlaceHolder',
    CONTACT_NUMBER_PLACEHOLDER: 'contactNumberPlaceHolder',
    EMAIL_PLACEHOLDER: 'emailPlaceHolder',
    REG_NUMBER_PLACEHOLDER: 'registrationNumberPlaceHolder',

    FIRST_NAME_LABEL: 'FirstName',
    LAST_NAME_LABEL: 'LastNameLabel',
    CONTACT_NUMBER_LABEL: 'contactNumberLabel',
    EMAIL_LABEL: 'email',
    REG_NUMBER_LABEL: 'registrationNumber',
    COUNTRY_DIAL_CODE_LABEL: 'countryDialCodeLabel',

    TEXT: 'text',
    HEADING: 'heading',
    AUTOFILL_TITLE_LABEL: 'Autofill_title_Lable',
    OPTIONS: 'options',

    SUBMIT_BUTTON: 'submitButtonLabel',
    BACK_BUTTON: 'backButtonLabel',

    ENABLE_FIRST_CONSENT_CHECKBOX: 'enableConsent1Checkbox',
    ENABLE_SECOND_CONSENT_CHECKBOX: 'enableConsent2Checkbox',
    CONSENT_FIRST_CHECKBOX_LABEL: 'consent1CheckboxLabel',
    CONSENT_SECOND_CHECKBOX_LABEL: 'consent2CheckboxLabel',
    FIRST_CONSENT_LINK_TEXT: 'consent1LinkText',
    SECOND_CONSENT_LINK_TEXT: 'consent2LinkText',
    FIRST_CONSENT_PRIVACY_TARGET_URL: 'consent1PrivacyTargetURL',
    SECOND_CONSENT_PRIVACY_TARGET_URL: 'consent2PrivacyTargetURL',
    SAVE_TO_MY_FORD_LABEL: 'saveToMyFordLabel',
};

export const STEP_PROGRESS_BAR_KEYS = {
    DEALER: 'dealer',
    SERVICE: 'service',
    DELIVERY: 'delivery',
    DATETIME: 'datetime',
    CONTACT: 'contact',
    LOCATION: 'location',
    MOBILE_MAX_STEP_COUNT: 5,
    STEP_NAME_LENGTH_BREAKPOINT_1: 16,
};

export const OSB_LIGHTT_VOUCHER_KEYS = {
    VOUCHER_FRAGMENT_NAME: 'osb-light-voucher',
    VOUCHER_TITLE: 'voucherTitle',
    VOUCHER_TEXTBOX_PLACEHOLDER: 'voucherTextBoxPlaeholder',
    VOUCHER_CODE_LIMIT_TEXT: 'voucherCodeLimitText',
    VOUCHER_APPLY_BUTTON: 'applyButton',
    VOUCHER_APPLIED_TEXT: 'appliedText',
    VOUCHER_INVALID_ERROR: 'invalidVoucherError',
    VOUCHER_ALREADY_USED: 'alreadyUsedVoucher',
    VOUCHER_REMOVE_LINK: 'removeVoucherLink',
    VOUCHER_CONFIRM_BUTTON: 'confirmButton',
    VOUCHER_APPLIED: 'voucherApplied',
    REMOVE_VOUCHER: 'removeVoucher',
    VOUCHER_DISCLAIMER: 'voucherDisclaimer',
    UNKNOWN_COMMON_ERROR: 'unknownCommonError',
    ERROR_MESSAGE_SORT_ORDER: 'errorMessageSortOrder',
};

export const DEFAULT_VOUCHER_ERROR_SORT_ORDER = [
    'invalidSourceTypeError',
    'invalidBookingDateError',
    'invalidVehicleBasedError',
    'invalidVINSpecificError',
    'invalidServiceSpecificError',
    'invalidTotalSpentError',
    'invalidMinimumSpendError',
    'invalidVehicleDeliveryTypeError',
    'invalidAppointmentDateError',
];

export const CANCEL_BOOKING_CONFIRMATION_KEYS = {
    BOOKING_CANCELLED_HEADING: 'bookingCancelledHeading',
    BOOKING_CANCELLED_TEXT: 'bookingCancelledText',
    CANCELLED_BOOKING_SUMMARY: 'cancelledBookingSummary',
    REBOOK_TITLE: 'rebookTitle',
    REBOOK_TEXT: 'rebookText',
};

export const RETRIEVE_ACCESS_CODE_KEYS = {
    EMAIL_REGEX: 'emailRegex',
    EMAIL_INVALID_ERROR: 'emailInvalidErrorMessage',
    RETRIEVE_ACCESS_CODE_SERVICE_ERROR: 'retrieveAccessCodeServiceError',
    RETRIEVE_ACCESS_CODE_HEADER: 'retrieveAccessCodeHeader',
    RETRIEVE_ACCESS_CODE_SUB_HEADER: 'retrieveAccessCodeSubHeader',
    EMAIL_TEXTBOX_LABEL: 'emailTextboxHeader',
    EMAIL_TEXTBOX_PLACEHOLDER: 'emailTextboxPlaceholder',
    EMAIL_INVALID_MESSAGE: 'emailInvalidErrorMessage',
    REMEMBER_MY_ACCESS_CODE_LINK: 'rememberMyAccessCodeLink',
    RETRIEVE_ACCESS_CODE_BUTTON: 'retrieveAccessCodeButton',
};

export const RETRIEVE_BOOKING_STEP_KEYS = {
    EMAIL_REGEX: 'emailRegex',
    EMAIL_ERROR: 'emailError',
    ACCESS_CODE_REGEX: 'accessCodeRegex',
    ACCESS_CODE_ERROR: 'accessCodeError',
    RETRIEVE_BOOKING_TITLE: 'title',
    FORGOT_ACCESS_CODE: 'forgotAccessSeccess',
    BOOKING_NOT_FOUND: 'bookingNotFound',
    EMAIL_ADDRESS_LABEL: 'emailAddress',
    ACCESS_CODE_LABEL: 'accessCode',
    FORGOT_ACCESS_CODE_LINK: 'forgottenAccessCode',
    RETRIEVE_BOOKING_BUTTON: 'retrieveBooking',
    OSB_NO_BOOKING_FOUND_ERROR: 'BOOKING_NOT_FOUND',
};

export const LIGHT_JOURNEY_ANALYTICS = {
    DEMO_BRAND: 'demo',
    FORD_BRAND: 'ford',
    EVENT_BOOK_SERVICE_SELECT_DEALER_SEARCH_START:
        'book-service-select-dealer-search-start',
    TARGET_TRIGGER_VIEW: 'target-trigger-view',
    EVENT_BOOK_SERVICE_SELECT_DEALER_RESULTS_MAP:
        'book-service-select-dealer-results-map',
    EVENT_BOOK_SERVICE_SELECT_DEALER_INFO: 'book-service-dealer-info',
    EVENT_BOOK_SERVICE_SELECT_SERVICE_SPECIFIC_SERVICE:
        'book-service-select-service-specific-service',
    EVENT_BOOK_SERVICE_VEHICLE_INFO: 'book-service-select-vehicle',
    EVENT_BOOK_SERVICE_VEHICLE_REVIEW: 'book-service-vehicle-review',
    EVENT_BOOK_SERVICE_VEHICLE_NOT_FOUND: 'book-service-vehicle-not-found',
    EVENT_BOOK_SERVICE_VEHICLE_INFO_ERROR: 'book-service-vehicle-info-error',
    EVENT_BOOK_SERVICE_VEHICLE_RECALL_FOUND:
        'book-service-vehicle-recall-found',
    EVENT_BOOK_SERVICE_VEHICLE_ACTIVE_BOOKING_EXISTS:
        'book-service-vehicle-active-booking-exists',
    EVENT_BOOK_SERVICE_SELECT_DEALER_CTA: 'book-service-select-dealer-cta',
    EVENT_BOOK_SERVICE_SELECT_PREFERRED_DEALER:
        'book-service-select-preferred-dealer',
    EVENT_BOOK_APPLY_VOUCHER_CTA: 'apply-voucher-light-journey-cta',
    EVENT_BOOK_REMOVE_VOUCHER_CTA: 'remove-voucher-light-journey-cta',
    EVENT_BOOK_APPLY_CONFIRM_VOUCHER_CTA:
        'apply-voucher-confirm-light-journey-cta',
    EVENT_BOOK_INVALID_VOUCHER: 'invalid-voucher-light-journey',
    EVENT_BOOK_ERROR_VOUCHER: 'error-voucher-light-journey',
    EVENT_BOOK_SERVICE_START_PAGE: 'book-service-common',
    EVENT_BOOK_SERVICE_SELECT_DATE_TIME: 'book-service-select-date-time',
    EVENT_BOOK_SERVICE_SELECT_DATE_TIME_ERROR:
        'book-service-select-date-time-error',
    EVENT_AMEND_SERVICE: 'book-service-amend-confirm-cta',
    EVENT_BOOK_SERVICE_SELECT_SERVICE: 'book-service-select-service',
    EVENT_BOOK_SERVICE_SELECT_DELIVERY_OPTION:
        'book-service-select-delivery-option',
    EVENT_BOOK_SERVICE_SELECT_DELIVERY_OPTION_OTHER:
        'book-service-select-delivery-option-other',
    EVENT_BOOK_SERVICE_BOOKING_REVIEW: 'book-service-review-summary',
    EVENT_BOOK_SERVICE_CONTACT_DETAILS: 'book-service-contact-details',
    EVENT_BOOK_SERVICE_BOOKING_COMPLETE: 'book-service-create-complete',
    EVENT_BOOK_SERVICE_PRINT_BOOKING: 'book-service-print-booking',
    DEALER_DEEP_LINKED: 'dealer deep-linked',
    NO_DEEP_LINKING: 'no deep-linking',
    VIN_DEEP_LINKED: 'vin deep-linked',
    VIN_AND_DEALER_DEEP_LINKED: 'vin & dealer deep-linked',
    PTEMPLATE_VALUE: 'none',
    SPECIFIC_SERVICE_CARD_TYPE: 'specific service',
    GENERAL_APPOINTMENT_CARD_TYPE: 'general appointment',
    MOBILE_SERVICE_CARD_TYPE: 'mobile service',
    DEALER_SERVICE_CARD_TYPE: 'dealer service',
    FORD_EXTRA_SERVICES_PANEL: 'ford extra services',
    FORD_VEHICLE_PANEL: 'ford vehicle section',
    EVENT_BOOK_SERVICE_SELECT_SERVICE_CARD: 'book-service-select-service-card',
    EVENT_BOOK_SERVICE_SPECIFIC_SERVICE_PANEL_EXPANSIONS:
        'book-service-specific-service-panel-expansions',
    EVENT_REVIEW_BOOKING_SUMMARY_ERROR: 'review-booking-summary-error',
    EVENT_BOOK_SERVICE_AMEND_REVIEW_BOOKING_SUMMARY:
        'book-service-amend-review-booking-summary',
    EVENT_BOOK_SERVICE_AMEND_SERVICE_ERROR: 'book-service-select-service-error',
    EVENT_BOOK_SERVICE_AMEND_DELIVERY_SERVICE_ERROR:
        'book-service-select-delivery-option-error',
    AMEND_DEALER: 'dealer',
    AMEND_VEHICLE: 'vehicle',
    AMEND_DATE_TIME: 'date & time',
    AMEND_SERVICES: 'service',
    AMEND_DELIVERY: 'delivery',
    AMEND_CONTACT_DETAILS: 'contact details',
    EVENT_BOOK_SERVICE_CALL_DEALER_CTA: 'book-service-call-dealer-cta',
    EVENT_BOOK_SERVICE_SEARCH_DEALER_ERROR:
        'book-service-select-dealer-search-error',
    APPLY_VOUCHER_CODE: 'apply voucher code',
    REMOVE_VOUCHER_CODE: 'remove voucher code',
    EVENT_SERVICE_BOOKING_RETRIEVE_REVIEW_SUMMARY:
        'service-booking-retrieve-review-summary',
    EVENT_SERVICE_BOOKING_RETRIEVE: 'service-booking-retrieve',
    EVENT_SERVICE_BOOKING_RETRIEVE_ERROR: 'service-booking-retrieve-error',
    EVENT_BOOK_SERVICE_CONTACT_DETAILS_ERROR:
        'book-service-contact-details-error',
    AMEND_FLOW_CONTENT: 'service booking:retrieve:amend',
    NO_AMEND_FLOW_CONTENT: 'service booking:book service',
    EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE:
        'book-service-retrieve-access-code',
    EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_ERROR:
        'book-service-retrieve-access-code-error',
    EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_LOAD: 'access code',
    EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_COMPLETED: 'access code:complete',
    EVENT_BOOK_SERVICE_BOOKING_SUMMARY_AND_PROGRESS_BAR_AMEND:
        'book-service-booking-summary-and-progress-bar-amend',
    BOOK_SUMMARY_CONTENT_TYPE: 'osb booking summary overlay',
    PROGRESS_BAR_CONTENT_TYPE: 'osb progress bar overlay',
    EVENT_RETRIEVE_SERVICE_BOOKING_CANCELLATION:
        'retrieve-service-booking-cancellation',
    EVENT_RETRIEVE_SERVICE_BOOKING_LOGOUT: 'retrieve-service-booking-logout',
    EVENT_SERVICE_BOOKING_DEALER_SEARCH_RESULTS_INITIATE_FILTERS:
        'service-booking-dealer-search-results-initiate-filter',
    EVENT_SERVICE_BOOKING_DEALER_SEARCH_RESULTS_FILTERS_REFINE:
        'service-booking-dealer-search-results-filter-refine',
    EVENT_BOOK_SERVICE_AUTH_JOURNEY_VEHICLE_MANUAL_ENTRY:
        'book-service-auth-journey-vehicle-manual-entry',
    EVENT_BOOK_SERVICE_AUTH_JOURNEY_SAVE_VIN:
        'book-service-auth-journey-save-vin',
    EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_START:
        'book-service-clear-communication-videos-start',
    EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_SEGMENT:
        'book-service-clear-communication-videos-segment',
    EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_COMPLETE:
        'book-service-clear-communication-videos-complete',
    EVENT_BOOK_SERVICE_IMAGE_UPLOAD: 'book-service-image-upload',
    EVENT_VIN_MILEAGE_TOOLTIP_ONCLICK: 'vin-mileage-tooltip-onclick',
    EVENT_SERVICE_INFO_TOOLTIP_ONCLICK: 'service-info-tooltip-onclick',
    EVENT_VEHICLE_CHANGE_REMOVE_CTA_ONCLICK:
        'vehicle-change-remove-cta-onClick',
    EVENT_STICKY_VIEW_SUMMARY_LINK_MOBILE_ONCLICK:
        'sticky-view-summary-link-onClick-mobile',
    EVENT_BOOK_SERVICE_GLOBAL_CTA_ONCLICK: 'book-service-global-cta-onclick',
    BACK_BTN_CTA_NAME: 'back',
    CONTINUE_BTN_CTA_NAME: 'continue',
    VIEW_MORE_BTN_CTA_NAME: 'view more',
    MORE_INFO_BTN_CTA_NAME: 'more info',
    CREAT_ACCOUNT_BTN_CTA_NAME: 'create an account',
    SIGN_IN_BTN_CTA_NAME: 'sign in',
    REVIEW_BOOKING_BTN_CTA_NAME: 'review booking',
    RETRIEVE_BOOKING_BTN_CTA_NAME: 'retrieve booking',
};

export const OSB_SERVICE_TYPE = {
    SERVICES: 'Services',
    EXTRA_SERVICES: 'Extra Services',
    DELIVERY: 'Delivery',
    DROPOFF: 'DropOff',
};

export const OSB_SERVICE_PRICE_TYPE = {
    FREE_SERVICE_PRICE: 0,
    POR_SERVICE_PRICE: -1,
    EMPTY_SERVICE_PRICE: -2,
};

export const OSB_SERVICE_PRICE_PROPERTY = {
    PRICE_AFTER_DISCOUNT: 'priceAfterDiscount',
    PRICE: 'price',
};

export const OSB_CLIENT_STORAGE_KEYS = {
    OSB_DEALER_STEP_KEY: 'osbdealerstepkey',
    OSB_SERVICE_STEP_KEY: 'osbservicestepkey',
    OSB_VEHICLE_STEP_KEY: 'osbvehiclestepkey',
    OSB_SERVICE_LOCATION_STEP_KEY: 'osbservicelocationstepkey',
    OSB_DELIVERY_STEP_KEY: 'osbdeliverystepkey',
    OSB_CALENDAR_STEP_KEY: 'osbcalendarstepkey',
    OSB_PERSONAL_DETAIL_STEP_KEY: 'osbpersonaldetailstepkey',
    OSB_REVIEW_STEP_KEY: 'osbreviewstepkey',
    OSB_RETRIEVE_STEP_KEY: 'osbretrievestepkey',
    OSB_PROGRESS_BAR_STEP_KEY: 'osbprogressbarstepkey',
    OSB_IMAGE_UPLOAD_KEY: 'osbimageuploadkey',
    OSB_PERSISTED_ROUTE_KEY: 'osbpersistedroutekey',
    OSB_CLIENT_STORAGE_EXPIRY_PERIOD_KEY: 'osbclientstorageexpiryperiodkey',
    OSB_STEP_KEY: 'osbstepkey',
    OSB_CLIENT_STORAGE_EXPIRY: 10, //default expiry is 10 minutes
    OSB_CAMPAIGN_STATE_STEP_KEY: 'osbcampaignstatestepkey',
};

export const SERVICE_LOCATION_STEP_KEYS = {
    BACK_LABEL: 'backLabel',
    SERVICE_LOCATION_TITLE: 'serviceLocationTitle',
    SERVICE_LOCATION_TEXT: 'serviceLocationText',
    PICKUP_SERVICE_LOCATION_TITLE: 'pickUpserviceLocationTitle',
    PICKUP_SERVICE_LOCATION_TEXT: 'pickUpserviceLocationText',
    STREET_NAME_LABEL: 'streetNameLabel',
    STREET_NAME_ERROR: 'streetNameError',
    STREET_NAME_MANDATORY: 'isStreetNameMandatory',
    HOUSE_NUMBER_LABEL: 'houseNumberLabel',
    HOUSE_NUMBER_ERROR: 'houseNumberError',
    HOUSE_NUMBER_MANDATORY: 'isHouseNumberMandatory',
    POSTCODE_LABEL: 'postcodeLabel',
    POSTCODE_ERROR: 'postcodeError',
    POSTCODE_MANDATORY: 'isPostCodeMandatory',
    CITY_LABEL: 'cityLabel',
    CITY_ERROR: 'cityError',
    CITY_MANDATORY: 'isCityMandatory',
    PARKING_TYPE_LABEL: 'parkingTypeLabel',
    PARKING_TYPE_ERROR: 'parkingTypeError',
    PARKING_TYPE_MANDATORY: 'isParkingTypeMandatory',
    PARKING_TYPE_OPTIONS: 'parkingTypeOptions',
    CONTINUE_BUTTON: 'continueButtonLabel',
    PLEASE_NOTE_TEXT: 'pleaseNoteText',
    POSTCODE_LENGTH: 'postcodeLength',
    OTHER_OPTIONS_TITLE: 'otherOptionsTitle',
    POSTCODE_AUTOCOMPLETE_SEARCH_STRING_LENGTH:
        'postcodeAutocompleteSearchStringLength',
    SEARCH_HINT_LABEL: 'searchHintLabel',
    SEARCH_PREDICTION_TYPE: 'postal_code',
    PICKUP_AND_DELIVERY_DISCLAIMER_TEXT: 'pickupAndDeliveryDisclaimerText',
    LOCATION_BOOKING_TYPE_MOBILE_SERVICE: 'MobileService',
    LOCATION_BOOKING_TYPE_VEHICLE_PICKUP: 'VehiclePickUp',
};

export const SERVICE_DELIVERY_TYPE = {
    WORKSHOP: 'Workshop',
    MOBILESERVICE: 'MobileService',
} as const;

export const API_REQUEST_SOURCE_TYPE = {
    WEB: 'Web',
    FORD_PASS: 'FordPass',
    FORD_PASS_PRO: 'FordPassPro',
} as const;

export const MEDIA_TYPE = {
    VIDEO: 'Video',
};

export const MEDIA_VIEWER_TYPE = {
    BRIGHTCOVE: 'BrightCove',
};

export const SERVICE_GROUP_TYPE = {
    ADDITIONAL: 'ADDITIONAL',
    MAIN: 'MAIN',
    REPAIR: 'REPAIR',
    VALUE: 'VALUE',
    MOT: 'MOT',
};

export const ACTIVITY_INDICATOR_KEYS = {
    DEFAULT_PROGRESS_PERCENT: '10',
};

export const CONTACT_OPTIONS = {
    EMAIL: 'Email',
    PHONE: 'Phone',
} as const;

export const REGEX_PATTERNS = {
    ALPHA_NUMERIC_PATTERN: '^([a-zA-Z0-9]{1,2147483647})$',
    ALLOW_ANYTHING_PATTERN: '(.*?){1,2147483647}',
    NUMERIC_PATTERN: '^[0-9]{1,2147483647}$',
};
