import {
    appConfigurationProd,
    appConfigurationQa,
    appConfigurationDev,
    appConfigurationLocal,
    appConfigurationUat,
    appConfigurationMock,
    AppConfiguration,
    AppEnvironmentConfiguration,
    fallbackAppConfiguration,
    appConfigurationMothersite,
} from '../../app-configuration/app-configuration';
import ServerSideService from '../server-side-service/server-side-service';
import { CacheService, STORE_TYPE } from '../cache-service/cache-service';
import { useContext } from 'react';
import ServerContext from '../../contexts/serverContext';
import { BRAND, REGIONAL_LOCALES } from '../../constants';
import { getRoot } from '../../components/utils/router-util/router-util';

export class AppConfigurationService {
    private win: Window | undefined;
    public appConfigurations: AppConfiguration[];
    private currentAppConfiguration: AppConfiguration;
    public currentLanguageCode: string;
    public currentCountryName: string;
    public currentCountryCode: string;
    public current3LetterCountryCode: string;
    public currentLangScript: string;
    public currentRegionCode: string;
    public currentLanguageRegionCode: string;
    public currentLanguage: string;
    public currentDomain: string;
    public currentRoot: string;
    public brand: string;
    public languageSetId: number;
    public enableDebugLogs?: boolean;
    public languageISOCode?: string;

    public constructor(env = process.env.REACT_APP_ACTIVE_PROFILE) {
        // the param env if meant for testing purposes; can be removed later
        let languageOverride;
        if (ServerSideService.isClientSide()) {
            this.win = window;

            (this.win as any).languageOverride = (
                languageName: string,
                langScript: string,
                languageRegionCode: string,
                countryCode: string,
                threeLetterCountryCode: string
            ) => {
                new CacheService(STORE_TYPE.LOCAL).putInCache('overrides', {
                    key: 'languageOverride',
                    data: {
                        languageName: languageName,
                        langScript: langScript,
                        languageRegionCode: languageRegionCode,
                        countryCode: countryCode,
                        threeLetterCountryCode: threeLetterCountryCode,
                    },
                });
            };

            languageOverride = new CacheService(STORE_TYPE.LOCAL).getFromCache(
                'overrides',
                'languageOverride'
            );
        }
        this.appConfigurations = [];
        switch (env) {
            case 'prod':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationProd,
                        env
                    )
                );
                break;
            case 'uat':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationUat,
                        env
                    )
                );
                break;
            case 'qa':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationQa,
                        env
                    )
                );
                break;
            case 'dev':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationDev,
                        env
                    )
                );
                break;
            case 'mock':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationMock,
                        env
                    )
                );
                break;
            case 'ms':
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationMothersite,
                        env
                    )
                );
                break;
            default:
                this.appConfigurations = this.appConfigurations.concat(
                    this.flattenEnvironmentConfiguration(
                        appConfigurationLocal,
                        'local'
                    )
                );
        }

        this.currentAppConfiguration = this.getAppConfiguration();
        this.currentDomain = this.currentAppConfiguration.domain;
        this.currentRoot = this.currentAppConfiguration.root;
        this.currentCountryCode = languageOverride
            ? languageOverride.countryCode
            : this.currentAppConfiguration.countryCode;
        this.current3LetterCountryCode = languageOverride
            ? languageOverride.threeLetterCountryCode
            : this.currentAppConfiguration.threeLetterCountryCode;
        this.currentLanguage = languageOverride
            ? languageOverride.languageName
            : this.currentAppConfiguration.languageName;
        this.currentLangScript = languageOverride
            ? languageOverride.langScript
            : this.currentAppConfiguration.langScript;
        this.currentLanguageRegionCode = languageOverride
            ? languageOverride.languageRegionCode
            : this.currentAppConfiguration.languageRegionCode;
        this.currentCountryName = this.currentAppConfiguration.countryName;
        this.currentLanguageCode = this.currentLanguageRegionCode.split('-')[0];
        this.currentRegionCode = this.currentLanguageRegionCode.split('-')[1];
        this.brand = this.currentAppConfiguration.brand;
        this.languageSetId = this.currentAppConfiguration.languageSetId;
        this.enableDebugLogs = this.currentAppConfiguration.enableDebugLogs;
        this.languageISOCode = this.currentAppConfiguration.languageISOCode;
    }

    private flattenEnvironmentConfiguration = (
        appEnvironmentConfiguration: AppEnvironmentConfiguration,
        environment: string
    ): AppConfiguration[] => {
        return appEnvironmentConfiguration.countryConfigurations.flatMap(cc =>
            cc.regionConfigurations.flatMap(rc => {
                const appConfiguration: AppConfiguration = {
                    environment: environment,
                    countryName: cc.name,
                    countryCode: cc.countryCode,
                    threeLetterCountryCode: cc.threeLetterCountryCode,
                    domain: rc.domain,
                    root: rc.root,
                    languageName: rc.language,
                    langScript: rc.langScript,
                    languageRegionCode: rc.languageRegionCode,
                    fmaUrl: cc.fmaUrl,
                    fmaRegion: cc.fmaRegion,
                    apimUrl: cc.apimUrl,
                    analyticsEmbedScriptUrl: cc.analyticsEmbedScriptUrl,
                    blockSearchIndexing: rc.blockSearchIndexing,
                    includeDomainsInLanguageSelector:
                        cc.includeDomainsInLanguageSelector,
                    brand: rc.brand,
                    languageSetId: rc.languageSetId,
                    mouseflowId: rc.mouseflowId,
                    medalliaCommentCardId: rc.medalliaCommentCardId,
                    enableDebugLogs: rc.enableDebugLogs,
                    languageISOCode: rc.languageISOCode,
                };
                return appConfiguration;
            })
        );
    };

    public getBrandRegionLanguage() {
        const ctx = useContext(ServerContext);
        return [
            ctx.brand || this.getAppConfiguration().brand,
            ctx.currentRegionCode ||
                this.getAppConfiguration().languageRegionCode,
            ctx.currentLanguageRegionCode ||
                this.getAppConfiguration().languageRegionCode,
        ];
    }

    private filterAppConfiguration(brand: string, locale: string, root = '/') {
        return this.appConfigurations.filter(
            ac =>
                ac.root === root &&
                ac.languageRegionCode === locale &&
                ac.brand === brand
        );
    }

    public rootToUse = (root: string, brand: string, locale: string) => {
        if (root) {
            if (brand && locale && root === `/${brand}/${locale}/`) return '/';
            else return root;
        } else return '/';
    };

    public getAppConfiguration(
        hostname?: string,
        root?: string,
        url?: string,
        brand?: string,
        locale?: string,
        log?: boolean
    ): AppConfiguration {
        let match: AppConfiguration = fallbackAppConfiguration;
        //give precedence to the hostname if available in request from proxy e.g. Akamai and return
        let appConfiguration: AppConfiguration[] = [];
        if (hostname) {
            log && console.info('getAppConfiguration case 0');
            appConfiguration = this.appConfigurations.filter(
                ac => ac.domain === hostname
            );
            if (appConfiguration.length > 1 && root) {
                log && console.info('getAppConfiguration case 0.1');
                appConfiguration = appConfiguration.filter(
                    ac => ac.root === root
                );
            }
        } else {
            if (brand && locale && root) {
                log && console.info('getAppConfiguration case 1');
                // brand, locale && root will always be passed from renderer.js when there is no referer-site header available in the request
                // and the environment is a proxy environment
                appConfiguration = this.filterAppConfiguration(
                    brand,
                    locale,
                    this.rootToUse(root, brand, locale)
                );
            } else if (ServerSideService.isClientSide()) {
                log && console.info('getAppConfiguration case 2');
                const serverContext = (window as any).SERVER_CONTEXT;
                const urlPath = window.location.pathname;
                let innerBrand: string, innerLocale: string, innerRoot: string;
                if (
                    serverContext &&
                    serverContext.brand &&
                    serverContext.currentLanguageRegionCode &&
                    serverContext.root
                ) {
                    // if no hostname, brand or locale as passed, try to derive them from window.SERVER_CONTEXT and then the request URL
                    log && console.info('getAppConfiguration case 2.1');
                    innerBrand = serverContext.brand;
                    innerLocale = serverContext.currentLanguageRegionCode;
                    innerRoot = this.rootToUse(
                        serverContext.root,
                        serverContext.brand,
                        serverContext.currentLanguageRegionCode
                    );
                } else if (urlPath) {
                    // if no hostname, brand or locale as passed, try to derive them from the request URL in the browser
                    log && console.info('getAppConfiguration case 2.2');
                    innerBrand = urlPath.substring(1).split('/')[0];
                    innerLocale = urlPath.substring(1).split('/')[1];
                    innerRoot = '/';
                } else {
                    log &&
                        console.log('For tests. getAppConfiguration case 2.3');
                    innerBrand = 'ford';
                    innerLocale = 'en-us';
                    innerRoot = '/';
                }

                // default for ford and en-us if the brand, locale are outside of the configured list
                if (!Object.keys(BRAND).find(key => key === innerBrand))
                    innerBrand = 'ford';
                if (!REGIONAL_LOCALES.includes(innerLocale))
                    innerLocale = 'en-us';
                appConfiguration = this.filterAppConfiguration(
                    innerBrand,
                    innerLocale,
                    innerRoot
                );
                if (
                    appConfiguration.length > 1 &&
                    appConfiguration[0].root.length > 1 &&
                    !urlPath.includes(appConfiguration[0].root.split('/')[1])
                )
                    appConfiguration[0].root = '/';
            } else {
                log &&
                    console.error(
                        'getAppConfiguration case 3. Please verify how the configuration is requested'
                    );
                // server side but no hostname, brand or locale passed to this method; default to ford and en-us
                // the control should not come here; if it does it can affect the behavior and needs to be looked at
                appConfiguration = this.filterAppConfiguration('ford', 'en-us');
            }
        }
        if (url && appConfiguration.length > 1) {
            if (!url.endsWith('/')) url = `${url}/`;
            const result = appConfiguration.filter(ac => ac.root === url);
            appConfiguration = result.length ? result : appConfiguration;
        }
        if (appConfiguration[0]) match = { ...appConfiguration[0] };
        match.root = getRoot(match.brand, match.languageRegionCode, match.root);
        return match;
    }

    public getLanguageRegionCode = (): string => {
        return this.currentAppConfiguration.languageRegionCode;
    };

    public get3LetterCountryCode = (): string => {
        return this.currentAppConfiguration.threeLetterCountryCode;
    };

    public get2LetterCountryCode = (): string => {
        return this.currentAppConfiguration.countryCode;
    };

    public getRegionCode() {
        return this.currentAppConfiguration.languageRegionCode.split('-')[1];
    }

    private getHostname(): string | undefined {
        return this.win?.location.hostname;
    }

    public getSupportedLanguages(): SupportedLanguage[] {
        const additionalDomainsToInclude: string[] | undefined = this
            .currentAppConfiguration.includeDomainsInLanguageSelector;
        let additionalAppConfigurationsToMap: AppConfiguration[] = [];
        if (additionalDomainsToInclude) {
            additionalAppConfigurationsToMap = additionalDomainsToInclude.map(
                (domain: string) => {
                    return this.getAppConfiguration(domain);
                }
            );
        }
        return this.appConfigurations
            .filter(
                ac =>
                    ac.countryName === this.currentCountryName &&
                    ac.environment ===
                        this.currentAppConfiguration.environment &&
                    ac.brand === this.brand &&
                    ac.languageSetId === this.languageSetId
            )
            .concat(additionalAppConfigurationsToMap)
            .map(ac => {
                return {
                    domain: ac.domain,
                    root: ac.root,
                    name: ac.languageName,
                    langScript: ac.langScript,
                    languageRegionCode: ac.languageRegionCode,
                };
            });
    }

    public getLanguageOverrides(): SupportedLanguageOverride[] {
        const allLanguages = this.appConfigurations
            .filter(
                configuration =>
                    configuration.countryCode === this.currentCountryCode &&
                    configuration.brand === this.brand &&
                    configuration.languageSetId === this.languageSetId
            )
            .map(configuration => {
                return {
                    languageName: `${configuration.languageName} (${configuration.languageRegionCode})`,
                    langScript: configuration.langScript,
                    languageRegionCode: configuration.languageRegionCode,
                    countryCode: configuration.countryCode,
                    threeLetterCountryCode:
                        configuration.threeLetterCountryCode,
                };
            });
        const distinctAllLanguages: any = [];
        allLanguages.forEach(language => {
            if (
                distinctAllLanguages.filter(
                    (filterLanguage: any) =>
                        filterLanguage.languageName === language.languageName &&
                        filterLanguage.languageRegionCode ===
                            language.languageRegionCode &&
                        filterLanguage.countryCode === language.countryCode &&
                        filterLanguage.threeLetterCountryCode ===
                            language.threeLetterCountryCode
                ).length === 0
            ) {
                distinctAllLanguages.push(language);
            }
        });
        return distinctAllLanguages;
    }

    public isRegionNA(): boolean {
        return this.currentAppConfiguration.fmaRegion === 'na';
    }

    public isRegionEU(): boolean {
        return this.currentAppConfiguration.fmaRegion === 'eu';
    }

    public isRegionAPA(): boolean {
        return this.currentAppConfiguration.fmaRegion === 'apa';
    }

    public isCountryZAF(): boolean {
        return this.currentAppConfiguration.threeLetterCountryCode === 'zaf';
    }
}

export interface SupportedLanguage {
    domain: string;
    root: string;
    name: string;
    langScript: string;
    languageRegionCode: string;
}

export interface SupportedLanguageOverride {
    languageName: string;
    langScript: string;
    languageRegionCode: string;
    countryCode: string;
    threeLetterCountryCode: string;
}

export default AppConfigurationService;
