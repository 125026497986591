import { useEffect } from 'react';
import { useContent } from '../../../../hooks/use-server-content';
import serverSideService from '../../../../services/server-side-service/server-side-service';

export interface TermsAndConditionsModalProps {
    hide: boolean;
    acceptTerms: string;
    declineTerms: string;
    modalNote: string;
    label1: string;
    text1: string;
    label2: string;
    text2: string;
    label3: string;
    text3: string;
}
export const useTermsAndConditionsModalContent = (): TermsAndConditionsModalProps | null => {
    const [content, getValueByName] = useContent(
        'common',
        'terms-and-conditions'
    );
    const decodeHtml = (html: string) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value.replace(/<p></g, '<').replace(/><\/p>/g, '/>');
    };
    let contentFragment: any;
    if (content) {
        contentFragment = {};
        content.elements.forEach(element => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
        if (contentFragment.hide || !contentFragment.text1) {
            contentFragment = null;
        } else {
            if (serverSideService.isClientSide()) {
                if (contentFragment.text1)
                    contentFragment.text1 = decodeHtml(contentFragment.text1);
                if (contentFragment.text2)
                    contentFragment.text2 = decodeHtml(contentFragment.text2);
                if (contentFragment.text3)
                    contentFragment.text3 = decodeHtml(contentFragment.text3);
            }
        }
    }
    const onAnchorClick = (event: Event) => {
        const anchor = event.target as HTMLAnchorElement;
        if (
            anchor &&
            anchor.attributes.getNamedItem('href')?.value.startsWith('#')
        ) {
            event.preventDefault();
            document
                .getElementById(anchor.href.split('#')[1])
                ?.scrollIntoView();
        }
    };
    useEffect(() => {
        const modalAnchorTags = Array.from(
            document.querySelectorAll('div.modal-body a')
        );
        modalAnchorTags.forEach(anchorTag =>
            anchorTag.addEventListener('click', onAnchorClick, false)
        );
        return () => {
            modalAnchorTags.forEach(anchorTag =>
                anchorTag.removeEventListener('click', onAnchorClick, false)
            );
        };
    }, [onAnchorClick]);
    return contentFragment as TermsAndConditionsModalProps;
};
