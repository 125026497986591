import { RouteDescription } from '../../../routes';

interface RouteAlias {
    path: string | string[];
    componentName: string;
}

export interface Config {
    brand: string;
    locale: string | string[];
    routes: Array<RouteAlias>;
}

export const findRouterConfig = (
    configuration: Array<Config>,
    currentBrand: string,
    currentLocale: string
): Config | undefined => {
    return configuration.find(config => {
        if (typeof config.locale !== 'string') {
            return (
                config.brand === currentBrand &&
                config.locale.includes(currentLocale)
            );
        } else
            return (
                config.brand === currentBrand && config.locale === currentLocale
            );
    });
};
const mergeRoutesWith = () => (
    defaultRegistry: Array<RouteDescription>,
    configuration: Array<Config>,
    currentDomain: string,
    currentBrand: string,
    currentLocale: string
) => {
    const overriddenComponentRegister = findRouterConfig(
        configuration,
        currentBrand,
        currentLocale
    );
    return (
        (overriddenComponentRegister &&
            defaultRegistry
                .filter((route: RouteDescription) =>
                    (
                        overriddenComponentRegister?.routes?.map(
                            ({ componentName }) => componentName
                        ) || []
                    ).includes(route.alias)
                )
                .map((route: RouteDescription) => {
                    const currentComponentName = route.alias;
                    const isComponentNameEqual = ({
                        componentName,
                    }: {
                        componentName?: string;
                    }) => componentName === currentComponentName;
                    const overriddenComponent:
                        | { path?: string | string[] }
                        | undefined = overriddenComponentRegister?.routes?.find(
                        isComponentNameEqual
                    );
                    const path = overriddenComponent?.path;
                    return path ? { ...route, path } : { ...route };
                })) ||
        defaultRegistry
    );
};

export const mergeRoutes = mergeRoutesWith();

export const findRouteByAlias = (
    alias: string,
    mergedRoutes: Array<RouteDescription>
) => mergedRoutes.find(route => route.alias === alias)?.path;

export const findAliasByPath = (
    path: string,
    mergedRoutes: Array<RouteDescription>
) => {
    return mergedRoutes.find(route => route.path === path)?.alias;
};

export const findRoute = (
    find: Function,
    defaultRegistry: Array<RouteDescription>,
    configuration: Array<Config>,
    domain: string,
    brand: string,
    locale: string
) => (alias: string) =>
    find(
        alias,
        mergeRoutes(defaultRegistry, configuration, domain, brand, locale)
    );
export const buildRoute = (getRoute: Function) => (
    alias: string,
    ...params: Array<string>
) => {
    const path = getRoute(alias);
    return params.reduce((acc, param) => acc.replace(/:\w+/, param), path);
};

export const transformRelativeUrlValidateRoot = (
    href: string,
    root: string
) => {
    if (href && href.startsWith('/')) {
        if (root.length > 1 && !href.startsWith(root)) return href.substring(1);
        else return href;
    } else return href;
};

export const getRoot = (
    brand: string,
    languageRegionCode: string,
    configRoot: string,
    routerBasename?: string
) => {
    return configRoot.length > 1
        ? configRoot
        : routerBasename
        ? `${routerBasename}/`
        : `/${brand}/${languageRegionCode}/`;
};
