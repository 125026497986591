import { useEffect, useRef, useCallback, useState } from 'react';

export interface SwipePoint {
    x: number;
    y: number;
}

export const useSwipe = (
    onSwipeStart: (swipeStartPoint: SwipePoint) => void,
    onSwipeMove: (swipeCurrentPoint: SwipePoint) => void,
    onSwipeEnd: () => void
): React.MutableRefObject<HTMLElement | null> => {
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const ref = useRef<HTMLElement | null>(null);

    function isPointerEvent(
        event: PointerEvent | TouchEvent | MouseEvent
    ): event is PointerEvent {
        return typeof (event as PointerEvent).pointerId !== 'undefined';
    }

    function isTouchEvent(
        event: PointerEvent | TouchEvent | MouseEvent
    ): event is TouchEvent {
        return Boolean((event as TouchEvent).touches);
    }

    function moreThanOneTouchPoint(event: TouchEvent): boolean {
        return event.touches && event.touches.length > 1;
    }

    function browserSupportsPointerEvents(): boolean {
        return !!window.PointerEvent;
    }

    function startCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    }

    function stopCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    }

    function addMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.addEventListener('mousemove', onMouseMove, true);
        document.addEventListener('mouseup', onMouseUp, true);
    }

    function removeMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.removeEventListener('mousemove', onMouseMove, true);
        document.removeEventListener('mouseup', onMouseUp, true);
    }

    const getEventPoint = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent): SwipePoint => {
            if (isTouchEvent(event)) {
                return {
                    x: event.targetTouches[0].clientX,
                    y: event.targetTouches[0].clientY,
                };
            } else {
                return {
                    x: event.clientX,
                    y: event.clientY,
                };
            }
        },
        []
    );

    const handleSwipeMove = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            if (isSwiping) {
                onSwipeMove(getEventPoint(event));
            }
        },
        [onSwipeMove, isSwiping, getEventPoint]
    );

    const handleSwipeEnd = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && event.touches.length > 0) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                stopCapturingPointerMovement(event);
            } else {
                removeMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(false);
            onSwipeEnd();
        },
        [onSwipeEnd, handleSwipeMove, setIsSwiping]
    );

    const handleSwipeStart = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && moreThanOneTouchPoint(event)) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                startCapturingPointerMovement(event);
            } else {
                addMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(true);
            onSwipeStart(getEventPoint(event));
        },
        [
            onSwipeStart,
            handleSwipeMove,
            handleSwipeEnd,
            setIsSwiping,
            getEventPoint,
        ]
    );

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line no-var
            var currentRef = ref.current;
            if (window.PointerEvent) {
                ref.current.addEventListener(
                    'pointerdown',
                    handleSwipeStart,
                    true
                );
                ref.current.addEventListener(
                    'pointermove',
                    handleSwipeMove,
                    true
                );
                ref.current.addEventListener('pointerup', handleSwipeEnd, true);
                ref.current.addEventListener(
                    'pointercancel',
                    handleSwipeEnd,
                    true
                );
            } else {
                ref.current.addEventListener(
                    'touchstart',
                    handleSwipeStart,
                    true
                );
                ref.current.addEventListener(
                    'touchmove',
                    handleSwipeMove,
                    true
                );
                ref.current.addEventListener('touchend', handleSwipeEnd, true);
                ref.current.addEventListener(
                    'touchcancel',
                    handleSwipeEnd,
                    true
                );
                ref.current.addEventListener(
                    'mousedown',
                    handleSwipeStart,
                    true
                );
            }
        }
        return () => {
            if (window.PointerEvent) {
                currentRef.removeEventListener(
                    'pointerdown',
                    handleSwipeStart,
                    true
                );
                currentRef.removeEventListener(
                    'pointermove',
                    handleSwipeMove,
                    true
                );
                currentRef.removeEventListener(
                    'pointerup',
                    handleSwipeEnd,
                    true
                );
                currentRef.removeEventListener(
                    'pointercancel',
                    handleSwipeEnd,
                    true
                );
            } else {
                currentRef.removeEventListener(
                    'touchstart',
                    handleSwipeStart,
                    true
                );
                currentRef.removeEventListener(
                    'touchmove',
                    handleSwipeMove,
                    true
                );
                currentRef.removeEventListener(
                    'touchend',
                    handleSwipeEnd,
                    true
                );
                currentRef.removeEventListener(
                    'touchcancel',
                    handleSwipeEnd,
                    true
                );
                currentRef.removeEventListener(
                    'mousedown',
                    handleSwipeStart,
                    true
                );
            }
        };
    }, [ref, handleSwipeStart, handleSwipeMove, handleSwipeEnd]);

    return ref;
};
