import { useContent } from '../../../../hooks/use-server-content';

export interface MmotaStatusModalProps {
    hide: boolean;
    acceptUpdateLinkPath: string;
    acceptUpdateLinkText: string;
    accessUnavailableStateDesc: string;
    accessUnavailableStateTitle: string;
    atTranslationText: string;
    automaticUpdatesOffStateDesc: string;
    automaticUpdatesOffStateTitle: string;
    connectToWifiLinkPath: string;
    connectToWifiLinkText: string;
    fridayText: string;
    getSupportLinkPath: string;
    getSupportLinkText: string;
    howToActivateLinkPath: string;
    howToActivateLinkText: string;
    howToChangeLinkPath: string;
    howToChangeLinkText: string;
    howToChangeOTASettingsLinkText: string;
    howToChangeOrSetLinkPath: string;
    howToChangeOrSetLinkText: string;
    howToChangeSettingsLinkPath: string;
    howToChangeSettingsLinkText: string;
    inProgressStateDesc: string;
    inProgressStateTitle: string;
    lastSuccessfulText: string;
    mondayText: string;
    otaSettingsText: string;
    automaticUpdatesOnText: string;
    automaticUpdatesOffText: string;
    recurringScheduleText: string;
    recurringScheduleNotSetText: string;
    retryStateDesc: string;
    retryStateTitle: string;
    saturdayText: string;
    setScheduleLinkPath: string;
    setScheduleLinkText: string;
    settingsDisabledStateDesc: string;
    settingsDisabledStateTitle: string;
    showInMeridiemTime: boolean;
    successStatusDesc: string;
    successStatusTitle: string;
    sundayText: string;
    thursdayText: string;
    tuesdayText: string;
    twentyFourHourText: string;
    upToDateStatusDesc: string;
    upToDateStatusTitle: string;
    updateNotesTitle: string;
    updatePendingStateDesc: string;
    updatePendingStateTitle: string;
    updateReminderStateDesc: string;
    updateReminderStateTitle: string;
    updateUnsuccessfulStateDesc: string;
    updateUnsuccessfulStateTitle: string;
    wednesdayText: string;
    releaseNotesDesc: string;
    inoperableStateTitle: string;
    inoperableStateDesc: string;
}

export const useMmotaStatusModalContent = (): MmotaStatusModalProps | null => {
    const [content, getValueByName] = useContent(
        'common',
        'mmota-status-modals'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as MmotaStatusModalProps;
};
