import React, { useEffect, useState } from 'react';
import { SendToDealer } from '../../components/sections/send-to-dealer/send-to-dealer';
import AuthenticationService from '../../services/authentication-service/authentication-service';

export const SendToDealerView = () => {
    const [authenticatedState, setAuthenticatedUser] = useState('');
    const authenticationService = new AuthenticationService();
    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    authenticationService
                        .onIsAuthenticated()
                        .then(authenticated => {
                            !authenticated && authenticationService.login();
                        });
                }
            });
    });

    return (
        <div className="send-to-dealer-main">
            {authenticatedState === 'loggedIn' && <SendToDealer />}
        </div>
    );
};
export default SendToDealerView;
