import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationProd: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Canada',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-25cd2421c068.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ca',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '3afb5226-d30b-4df2-b777-ba34dd4e038d',
                    languageISOCode: 'ENG',
                },
                {
                    domain: 'fr.ford.ca',
                    root: '/support/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '3afb5226-d30b-4df2-b777-ba34dd4e038d',
                    languageISOCode: 'FRA',
                },
                {
                    domain: 'www.lincolncanada.com',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                    languageISOCode: 'ENG',
                },
                {
                    domain: 'fr.lincolncanada.com',
                    root: '/support/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                    languageISOCode: 'FRA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 3,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 3,
                    languageISOCode: 'FRA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                    languageISOCode: 'FRA',
                },
            ],
        },
        {
            name: 'USA',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '62cf4a7b-667e-4752-b005-ea6c66c37d0a',
                    languageISOCode: 'ENG',
                },
                {
                    domain: 'es.ford.com',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-us',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '62cf4a7b-667e-4752-b005-ea6c66c37d0a',
                    languageISOCode: 'SPA',
                },
                {
                    domain: 'www.lincoln.com',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                    mouseflowId: 'a86fb5cf-3567-4fb6-97f1-b1cd7da23106',
                    languageISOCode: 'ENG',
                },
                {
                    domain: 'es.lincoln.com',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-us',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                    languageISOCode: 'SPA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 3,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-us',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 3,
                    languageISOCode: 'SPA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-us',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                    languageISOCode: 'SPA',
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.co.uk',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '7163ed16-4e15-46ec-b453-47f98c047a9e',
                    medalliaCommentCardId: 13891,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Switzerland',
            countryCode: 'ch',
            threeLetterCountryCode: 'che',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.de.ford.ch',
                    root: '/hilfe/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-ch',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.fr.ford.ch',
                    root: '/support/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ch',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.it.ford.ch',
                    root: '/supporto/',
                    language: 'Italian',
                    langScript: 'LATN',
                    languageRegionCode: 'it-ch',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-ch',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ch',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Italian',
                    langScript: 'LATN',
                    languageRegionCode: 'it-ch',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Germany',
            countryCode: 'de',
            threeLetterCountryCode: 'deu',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.de',
                    root: '/hilfe/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-de',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '3b248d71-a2ef-42c1-ab21-6646bb4a8215',
                    medalliaCommentCardId: 13855,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-de',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'France',
            countryCode: 'fr',
            threeLetterCountryCode: 'fra',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.fr',
                    root: '/support/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-fr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '36b9be37-efa2-4e9b-88be-532f965f6628',
                    medalliaCommentCardId: 13873,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-fr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Italy',
            countryCode: 'it',
            threeLetterCountryCode: 'ita',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.it',
                    root: '/supporto/',
                    language: 'Italian',
                    langScript: 'LATN',
                    languageRegionCode: 'it-it',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: 'c07dafe5-a29a-4fdd-a6b7-072b989c062e',
                    medalliaCommentCardId: 13882,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Italian',
                    langScript: 'LATN',
                    languageRegionCode: 'it-it',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Spain',
            countryCode: 'es',
            threeLetterCountryCode: 'esp',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.es',
                    root: '/soporte/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-es',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '906d6ee4-0fcb-4cef-8d9c-6789fb7bb388',
                    medalliaCommentCardId: 13864,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-es',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Norway',
            countryCode: 'no',
            threeLetterCountryCode: 'nor',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.no',
                    root: '/brukerstoette/',
                    language: 'Norwegian',
                    langScript: 'LATN',
                    languageRegionCode: 'no-no',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    // mouseflowId: '867bd7a6-642a-4630-adf4-fe8e3725428d',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Norwegian',
                    langScript: 'LATN',
                    languageRegionCode: 'no-no',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Nederland',
            countryCode: 'nl',
            threeLetterCountryCode: 'nld',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.nl',
                    root: '/ondersteuning/',
                    language: 'Dutch',
                    langScript: 'LATN',
                    languageRegionCode: 'nl-nl',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Dutch',
                    langScript: 'LATN',
                    languageRegionCode: 'nl-nl',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Finland',
            countryCode: 'fi',
            threeLetterCountryCode: 'fin',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.fi',
                    root: '/tuki/',
                    language: 'Finnish',
                    langScript: 'LATN',
                    languageRegionCode: 'fi-fi',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Finnish',
                    langScript: 'LATN',
                    languageRegionCode: 'fi-fi',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Brazil',
            countryCode: 'br',
            threeLetterCountryCode: 'bra',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.br',
                    root: '/support/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-br',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '43646e6c-5001-4c5f-a723-bd8c2c0859f2',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-br',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Mexico Ford',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.mx',
                    root: '/mi-ford/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: 'f774153b-3f5e-4768-9c79-499c567ccdb0',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Mexico Lincoln',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/9399f7284f40/launch-419982b1f3a1.min.js',
            regionConfigurations: [
                {
                    domain: 'www.lincoln.mx',
                    root: '/mi-lincoln/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Argentina',
            countryCode: 'ar',
            threeLetterCountryCode: 'arg',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.ar',
                    root: '/support/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ar',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: 'e834b456-2d08-4825-8e70-730834540e4a',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ar',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Poland',
            countryCode: 'pl',
            threeLetterCountryCode: 'pol',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.pl',
                    root: '/wsparcie/',
                    language: 'Polish',
                    langScript: 'LATN',
                    languageRegionCode: 'pl-pl',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Polish',
                    langScript: 'LATN',
                    languageRegionCode: 'pl-pl',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Portugal',
            countryCode: 'pt',
            threeLetterCountryCode: 'prt',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.pt',
                    root: '/suporte/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-pt',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-pt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Ireland',
            countryCode: 'ie',
            threeLetterCountryCode: 'irl',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ie',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ie',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ie',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Denmark',
            countryCode: 'dk',
            threeLetterCountryCode: 'dnk',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.dk',
                    root: '/support/',
                    language: 'Danish',
                    langScript: 'LATN',
                    languageRegionCode: 'da-dk',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Danish',
                    langScript: 'LATN',
                    languageRegionCode: 'da-dk',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Hungary',
            countryCode: 'hu',
            threeLetterCountryCode: 'hun',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.hu',
                    root: '/tamogatas/',
                    language: 'Hungarian',
                    langScript: 'LATN',
                    languageRegionCode: 'hu-hu',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Hungarian',
                    langScript: 'LATN',
                    languageRegionCode: 'hu-hu',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Romania',
            countryCode: 'ro',
            threeLetterCountryCode: 'rou',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ro',
                    root: '/suport/',
                    language: 'Romanian',
                    langScript: 'LATN',
                    languageRegionCode: 'ro-ro',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Romanian',
                    langScript: 'LATN',
                    languageRegionCode: 'ro-ro',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Belgium',
            countryCode: 'be',
            threeLetterCountryCode: 'bel',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.fr.ford.be',
                    root: '/support/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-be',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.nl.ford.be',
                    root: '/ondersteuning/',
                    language: 'Dutch',
                    langScript: 'LATN',
                    languageRegionCode: 'nl-be',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-be',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Dutch',
                    langScript: 'LATN',
                    languageRegionCode: 'nl-be',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Luxembourg',
            countryCode: 'lu',
            threeLetterCountryCode: 'lux',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.lu',
                    root: '/support/',
                    language: 'French',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-lu',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'French',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-lu',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Austria',
            countryCode: 'at',
            threeLetterCountryCode: 'aut',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.at',
                    root: '/hilfe/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-at',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-at',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Greece',
            countryCode: 'gr',
            threeLetterCountryCode: 'grc',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.gr',
                    root: '/support/',
                    language: 'Greek',
                    langScript: 'LATN',
                    languageRegionCode: 'el-gr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Greek',
                    langScript: 'LATN',
                    languageRegionCode: 'el-gr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Czech Republic',
            countryCode: 'cz',
            threeLetterCountryCode: 'cze',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.cz',
                    root: '/podpora/',
                    language: 'Czech',
                    langScript: 'LATN',
                    languageRegionCode: 'cs-cz',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Czech',
                    langScript: 'LATN',
                    languageRegionCode: 'cs-cz',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'South Korea',
            countryCode: 'kr',
            threeLetterCountryCode: 'kor',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Korean',
                    langScript: 'LATN',
                    languageRegionCode: 'ko-kr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.kr',
                    root: '/support/',
                    language: 'Korean',
                    langScript: 'LATN',
                    languageRegionCode: 'ko-kr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Korean',
                    langScript: 'LATN',
                    languageRegionCode: 'ko-kr',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
                {
                    domain: 'www.lincoln-korea.com',
                    root: '/support/',
                    language: 'Korean',
                    langScript: 'LATN',
                    languageRegionCode: 'ko-kr',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'New Zealand',
            countryCode: 'nz',
            threeLetterCountryCode: 'nzl',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-nz',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.nz',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-nz',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                    mouseflowId: '28964f94-a8f8-4360-a3dc-a5e62e5910b1',
                },
            ],
        },
        {
            name: 'Vietnam',
            countryCode: 'vn',
            threeLetterCountryCode: 'vnm',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Vietnamese',
                    langScript: 'LATN',
                    languageRegionCode: 'vi-vn',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.com.vn',
                    root: '/support/',
                    language: 'Vietnamese',
                    langScript: 'LATN',
                    languageRegionCode: 'vi-vn',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                    mouseflowId: 'cd542015-70a2-4a0f-8ea7-7f7557bdbf74',
                },
            ],
        },
        {
            name: 'Thailand',
            countryCode: 'th',
            threeLetterCountryCode: 'tha',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Thai',
                    langScript: 'THAI',
                    languageRegionCode: 'th-th',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.th',
                    root: '/support/',
                    language: 'Thai',
                    langScript: 'THAI',
                    languageRegionCode: 'th-th',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                    mouseflowId: '3f56a29e-51df-472b-ac6e-6a892c40883c',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-th',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.th',
                    root: '/en/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-th',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                    mouseflowId: '3f56a29e-51df-472b-ac6e-6a892c40883c',
                },
            ],
        },
        {
            name: 'Chile',
            countryCode: 'cl',
            threeLetterCountryCode: 'chl',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.cl',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cl',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cl',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Colombia',
            countryCode: 'co',
            threeLetterCountryCode: 'col',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.co',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-co',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '33fc6571-736c-4066-928e-14c8bd4cec4f',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-co',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Philippines',
            countryCode: 'ph',
            threeLetterCountryCode: 'phl',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ph',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.com.ph',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ph',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                    mouseflowId: '7497789d-639d-4bec-b6ad-01fedfc12e24',
                },
            ],
        },
        {
            name: 'Australia',
            countryCode: 'au',
            threeLetterCountryCode: 'aus',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.au',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-au',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '4660616a-e673-41c8-ad7b-fc9800c1a3bf',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-au',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Peru',
            countryCode: 'pe',
            threeLetterCountryCode: 'per',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.pe',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pe',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pe',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Sweden',
            countryCode: 'se',
            threeLetterCountryCode: 'swe',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Swedish',
                    langScript: 'LATN',
                    languageRegionCode: 'sv-se',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: 'www.seaccount.ford.com',
                    root: '/support/',
                    language: 'Swedish',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Puerto Rico Ford',
            countryCode: 'pr',
            threeLetterCountryCode: 'pri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.pr',
                    root: '/esp/support/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.com.pr',
                    root: '/en/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Puerto Rico Lincoln',
            countryCode: 'pr',
            threeLetterCountryCode: 'pri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/9399f7284f40/launch-419982b1f3a1.min.js',
            regionConfigurations: [
                {
                    domain: 'www.lincoln.com.pr',
                    root: '/esp/support/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 3,
                },
                {
                    domain: 'www.lincoln.com.pr',
                    root: '/en/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    languageSetId: 3,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 4,
                },
            ],
        },
        {
            name: 'Venezuela',
            countryCode: 've',
            threeLetterCountryCode: 'ven',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.ve',
                    root: '/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ve',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ve',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Antigua and Barbuda',
            countryCode: 'ag',
            threeLetterCountryCode: 'atg',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/atg/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ag',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ag',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Aruba',
            countryCode: 'aw',
            threeLetterCountryCode: 'abw',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/abw/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-aw',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-aw',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Bahamas',
            countryCode: 'bs',
            threeLetterCountryCode: 'bhs',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/bhs/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bs',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bs',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Barbados',
            countryCode: 'bb',
            threeLetterCountryCode: 'brb',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/brb/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bb',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bb',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Bermuda',
            countryCode: 'bm',
            threeLetterCountryCode: 'bmu',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/bmu/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bm',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Cayman Islands',
            countryCode: 'ky',
            threeLetterCountryCode: 'cym',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/cym/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ky',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ky',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Curacao',
            countryCode: 'cw',
            threeLetterCountryCode: 'cuw',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/cuw/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-cw',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-cw',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Dominica',
            countryCode: 'dm',
            threeLetterCountryCode: 'dma',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/dma/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-dm',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-dm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Dominican Republic',
            countryCode: 'do',
            threeLetterCountryCode: 'dom',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/es/dom/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-do',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-do',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Grenada',
            countryCode: 'gd',
            threeLetterCountryCode: 'grd',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/grd/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gd',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gd',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Haiti',
            countryCode: 'ht',
            threeLetterCountryCode: 'hti',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/hti/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ht',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ht',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Jamaica',
            countryCode: 'jm',
            threeLetterCountryCode: 'jam',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/jam/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-jm',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-jm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'St Lucia',
            countryCode: 'lc',
            threeLetterCountryCode: 'lca',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/lca/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-lc',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-lc',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'St Maarten',
            countryCode: 'mf',
            threeLetterCountryCode: 'maf',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/maf/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-mf',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-mf',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Suriname',
            countryCode: 'sr',
            threeLetterCountryCode: 'sur',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/sur/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-sr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-sr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Trinidad and Tobago',
            countryCode: 'tt',
            threeLetterCountryCode: 'tto',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.caribe.ford.com',
                    root: '/en/tto/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-tt',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-tt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'South Africa',
            countryCode: 'za',
            threeLetterCountryCode: 'zaf',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.co.za',
                    root: '/support/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-za',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                    mouseflowId: '5c59c42b-e0b2-4591-bdec-5e9788ee22d9',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-za',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Belice',
            countryCode: 'bz',
            threeLetterCountryCode: 'blz',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/blz/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-bz',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-bz',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Costa Rica',
            countryCode: 'cr',
            threeLetterCountryCode: 'cri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/cri/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cr',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'El Salvador',
            countryCode: 'sv',
            threeLetterCountryCode: 'slv',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/slv/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-sv',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-sv',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Guatemala',
            countryCode: 'gt',
            threeLetterCountryCode: 'gtm',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/gtm/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-gt',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-gt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Honduras',
            countryCode: 'hn',
            threeLetterCountryCode: 'hnd',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/hnd/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-hn',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-hn',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Nicaragua',
            countryCode: 'ni',
            threeLetterCountryCode: 'nic',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/nic/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ni',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ni',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Panama',
            countryCode: 'pa',
            threeLetterCountryCode: 'pan',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            regionConfigurations: [
                {
                    domain: 'www.centroamerica.ford.com',
                    root: '/es/pan/support/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pa',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pa',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 2,
                },
            ],
        },
    ],
};
