import { useEffect } from 'react';
import { useState } from 'react';
import { CategoryPage } from '../../../models/category-page';
import AstuteService from '../../../services/astute-service/astute-service';
import { BRAND_ATTRIBUTES } from '../../../support-constants';

export const useBbtCategories = (
    currentLanguageRegionCode: string,
    brand: string
) => {
    const [categories, setCategories] = useState<CategoryPage[] | null>(null);
    const bbtTag = (BRAND_ATTRIBUTES as any)[brand].bbt;
    useEffect(() => {
        if (categories === null)
            new AstuteService()
                .getAllCategories(
                    currentLanguageRegionCode,
                    bbtTag,
                    brand,
                    false,
                    true
                )
                .then(response => {
                    setCategories(response);
                })
                .catch(error =>
                    console.error('Error in useBbtCategories', error.message)
                );
    }, [categories]);

    return categories;
};
