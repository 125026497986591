import React, { useState, useEffect } from 'react';
import './vehicle-notifications.scss';
import { NotificationsModal } from './modal/notifications-modal';
import { ServiceRecord } from '../../../models/service-history';
import ServiceHandler from '../../../services/service-handler';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import { NotificationsFragment } from '../../../models/experiencefragments/vehicle-notifications';
import { SyncUpdatesNotificationObject } from './sync-updates/sync-updates';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { KEYBOARD_KEYS } from '../../../constants';
import { useAnalytics } from '../../../hooks/use-analytics';
import { useHandoverVehicleOrderStatusLookup } from '../handover/hooks/use-vehicle-handover-status';
import {
    SyncMapService,
    SyncMapTile,
} from '../../../services/sync-service/sync-map-service';
import RecallsService, {
    RecallItems,
    RecallsApiResponse,
} from '../../../services/recalls-service/recalls-service';
import { VhaServiceResponse } from '../../../services/vha-service/vha-service.interface';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import VhaService from '../../../services/vha-service/vha-service';
import MmotaStatusService from '../../../services/mmota-status-service/mmota-status-service';
import { ListItem } from './detailed-notification-item/detailed-notification-item';

interface Props {
    vehicleAttributes?: VehicleAttributes;
}

export enum LookUpType {
    LOOK_AHEAD = 1,
    NULL = 0,
    LOOK_BACK = -1,
}

function VehicleNotifications(props: Props) {
    const [fireEvents] = useAnalytics();
    const { vehicleAttributes } = props;
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [serviceHistoryRecords, setServiceHistoryRecords] = useState<any[]>(
        []
    );
    const [prognosticsData, setPrognosticsData] = useState<any>(null);
    const [recallDataForTile, setRecallDataForTile] = useState<
        RecallsApiResponse
    >();
    const [syncMapDataForTile, setSyncMapDataForTile] = useState<SyncMapTile>();
    const [vhaDataForTile, setVhaDataForTile] = useState<VhaServiceResponse>();
    const [mmotaDataForTile, setMmotaDataForTile] = useState<
        MMOTAStatusResponse
    >();

    let lookUpType = LookUpType.NULL;

    const [notificationsContent] = useExperienceContent<NotificationsFragment>(
        'vehicles',
        getVehicleDataSelector('vehicle-notifications'),
        'notifications',
        undefined,
        true
    );
    const displayModal = () => {
        fireEvents(
            'notifications-open-onclick-event',
            undefined,
            undefined,
            false
        );
        setIsModalVisible(true);
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };

    const isServiceRemindersEnabled = (): boolean => {
        return Boolean(
            notificationsContent?.serviceReminders?.enableServiceReminders
        );
    };

    const isPrognosticsAvailable = (): boolean => {
        return (
            prognosticsData?.oilLifeData?.featureType ||
            prognosticsData?.scheduleMaintenanceData?.featureType
        );
    };

    const getOLRCount = () => {
        const isOLREnabled =
            notificationsContent?.serviceReminders?.enableOilLifeReminders;
        const isOLRAvailable = prognosticsData?.oilLifeData?.shouldShow;
        if (isOLREnabled && isOLRAvailable) {
            return 1;
        }
        return 0;
    };

    const isSMNotificationDismissed = () => {
        const serviceReminderString: any = sessionStorage.getItem(
            'serviceReminderDismissed'
        );
        const smParsed = JSON.parse(serviceReminderString);
        let serviceReminderJson: Map<string, [string]> = new Map<
            string,
            [string]
        >();
        if (smParsed && Object.entries(smParsed).length > 0) {
            serviceReminderJson = new Map(smParsed);
        }
        if (vehicleAttributes?.vin && serviceReminderJson) {
            const vinEntry = serviceReminderJson?.get(
                vehicleAttributes?.vin
            ) || [''];
            if (vinEntry && vinEntry.indexOf('SM') != -1) {
                return true;
            }
        }
        return false;
    };

    const getSMCount = () => {
        const isScheduleMaintenanceEnabled =
            notificationsContent?.serviceReminders
                ?.enableScheduledMaintenanceReminders;
        const isScheduleMaintenanceAvailable =
            prognosticsData?.scheduleMaintenanceData?.shouldShow;
        if (
            isScheduleMaintenanceEnabled &&
            isScheduleMaintenanceAvailable &&
            !isSMNotificationDismissed()
        ) {
            return 1;
        }
        return 0;
    };

    const transformResponse = (response: {
        dealerServices: Array<ServiceRecord>;
    }) => {
        const dealerServicesData = response.dealerServices.map(
            (dealerData: { serviceDate: string }) => ({
                ...dealerData,
                serviceCategory: 'dealer',
                date: new Date(dealerData.serviceDate),
            })
        );
        return [...dealerServicesData].sort((a, b) => {
            if (a.date === b.date) return 0;
            if (a.date < b.date) return -1;
            else return 1;
        });
    };
    const getServiceHistoryRecords = () => {
        if (vehicleAttributes?.vin) {
            ServiceHandler.VehicleServiceHistory.getVehicleServiceHistory(
                vehicleAttributes?.vin
            ).then(value => {
                setServiceHistoryRecords(transformResponse(value));
            });
        }
    };
    const getVinsPrognosticsRecords = () => {
        const isOLREnabled =
            notificationsContent?.serviceReminders?.enableOilLifeReminders;
        const isScheduleMaintenanceEnabled =
            notificationsContent?.serviceReminders
                ?.enableScheduledMaintenanceReminders;

        if (
            vehicleAttributes?.vin &&
            (isOLREnabled || isScheduleMaintenanceEnabled)
        ) {
            ServiceHandler.VehiclePrognosticsService.getActivePrognosticsV2(
                vehicleAttributes?.vin
            )
                .then(result => {
                    const prognosticsData = result?.prognosticsData;
                    const tempPrognostics = {
                        oilLifeData: null,
                        scheduleMaintenanceData: null,
                    };
                    prognosticsData?.forEach((reminder: any) => {
                        if (reminder.featureType === 'OL') {
                            tempPrognostics.oilLifeData = reminder;
                        } else if (reminder.featureType === 'SM') {
                            tempPrognostics.scheduleMaintenanceData = reminder;
                        }
                    });
                    setPrognosticsData(tempPrognostics);
                })
                .catch(() => {
                    setPrognosticsData(null);
                });
        } else {
            setPrognosticsData(null);
        }
    };

    const getRecalls = () => {
        if (vehicleAttributes?.vin) {
            new RecallsService()
                .request(vehicleAttributes.vin)
                .then(response => {
                    if (response) {
                        setRecallDataForTile(response);
                    }
                });
        }
    };

    const getSyncMapData = () => {
        if (vehicleAttributes?.vin) {
            new SyncMapService()
                .request(vehicleAttributes.vin)
                .then(response => {
                    if (response) {
                        setSyncMapDataForTile(response);
                    }
                });
        }
    };

    const getVhaData = () => {
        if (vehicleAttributes?.vin) {
            new VhaService().request(vehicleAttributes.vin).then(response => {
                if (response) {
                    setVhaDataForTile(response);
                }
            });
        }
    };

    const getOtaData = () => {
        if (vehicleAttributes?.vin) {
            new MmotaStatusService()
                .getStatus(vehicleAttributes.vin)
                .then(response => {
                    if (response) {
                        setMmotaDataForTile(response);
                    }
                });
        }
    };

    useEffect(() => {
        getRecalls();
        getSyncMapData();
        getVhaData();
        getOtaData();
        getVinsPrognosticsRecords();
    }, [notificationsContent, props.vehicleAttributes?.vin]);

    useEffect(() => {
        if (!isPrognosticsAvailable() && isServiceRemindersEnabled()) {
            getServiceHistoryRecords();
        }
    }, [prognosticsData]);

    const getServiceRemindersCount = (): number => {
        if (!serviceHistoryRecords?.length) return 0;

        const dueDate = serviceHistoryRecords[0].nextService?.dueDate;
        if (!dueDate) return 0;

        const lookAheadDays =
            notificationsContent?.serviceReminders?.lookaheadDays || 30;
        const lookBackDays =
            notificationsContent?.serviceReminders?.lookbackDays || 5;

        const [day, month, year] = dueDate.split('/');
        const formattedDueDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (formattedDueDate >= currentDate) {
            formattedDueDate.setDate(
                formattedDueDate.getDate() - lookAheadDays
            );
        } else {
            formattedDueDate.setDate(formattedDueDate.getDate() + lookBackDays);
        }

        if (currentDate < formattedDueDate || formattedDueDate >= currentDate)
            return 0;

        lookUpType =
            formattedDueDate >= currentDate
                ? LookUpType.LOOK_AHEAD
                : LookUpType.LOOK_BACK;
        return 1;
    };

    const getOLRandSMCount = () => {
        const isOLREnabled =
            notificationsContent?.serviceReminders?.enableOilLifeReminders;
        const isScheduleMaintenanceEnabled =
            notificationsContent?.serviceReminders
                ?.enableScheduledMaintenanceReminders;
        const isOLRAvailable = prognosticsData?.oilLifeData?.shouldShow;
        const isScheduleMaintenanceAvailable =
            prognosticsData?.scheduleMaintenanceData?.shouldShow;
        if (
            (isOLREnabled && isOLRAvailable) ||
            (isScheduleMaintenanceEnabled &&
                isScheduleMaintenanceAvailable &&
                !isSMNotificationDismissed())
        ) {
            return 1;
        }
        return 0;
    };

    const getServiceReminderDetails = () => {
        let nextServiceItems: any = null;
        const serviceReminderCount = getServiceRemindersCount();
        const OLRCount = getOLRCount();
        const SMCount = getSMCount();
        if (serviceReminderCount === 1 || OLRCount === 1 || SMCount === 1) {
            if (
                Array.isArray(serviceHistoryRecords) &&
                serviceHistoryRecords[0]
            )
                nextServiceItems = serviceHistoryRecords[0].nextService;
            return {
                nextServiceItems,
                lookUpType: lookUpType,
                srCount: serviceReminderCount,
                olCount: OLRCount,
                smCount: SMCount,
                prognosticsData: prognosticsData?.oilLifeData,
                scheduleMaintenanceData:
                    prognosticsData?.scheduleMaintenanceData,
            };
        }
        return null;
    };

    const hasRecall = (): boolean => {
        return recallDataForTile
            ? recallDataForTile.nhtsa?.length +
                  recallDataForTile.recalls?.length >
                  0
            : false;
    };

    const getListItemDetails = (item: RecallItems): ListItem => {
        return {
            description: item.descriptionLang || item.description,
        };
    };
    const getRecallDetails = (): ListItem[] => {
        const nhtsa = recallDataForTile?.nhtsa?.map(item =>
            getListItemDetails(item)
        );
        const recalls = recallDataForTile?.recalls?.map(item =>
            getListItemDetails(item)
        );
        return [...(nhtsa || []), ...(recalls || [])];
    };

    const getSyncUpdates = (): SyncUpdatesNotificationObject => {
        if (!syncMapDataForTile) {
            return {
                lastUpdatedDate: '',
                count: 0,
            };
        }

        const {
            syncUpdateAvailable,
            lastUpdatedDate,
        } = syncMapDataForTile.syncTile;
        return {
            lastUpdatedDate,
            count: syncUpdateAvailable ? 1 : 0,
        };
    };

    const getOtaCount = () => {
        return notificationsContent &&
            notificationsContent.otau &&
            Object.values(notificationsContent.otau).find(o => o) &&
            mmotaDataForTile?.summary?.alertDescription
            ? 1
            : 0;
    };

    useHandoverVehicleOrderStatusLookup();

    const getVehicleNotifications = (): number => {
        let vhaCount = 0;
        let countEYF = 0;
        if (
            vhaDataForTile &&
            vhaDataForTile.vhaAlertResponseList &&
            vhaDataForTile.vhaAlertResponseList.length > 0 &&
            vhaDataForTile.vhaAlertResponseList[0].activeAlerts &&
            vhaDataForTile.vhaAlertResponseList[0].activeAlerts.length > 0
        )
            vhaCount +=
                vhaDataForTile.vhaAlertResponseList[0].activeAlerts.length;

        let showEYF = false;
        const supportModels = notificationsContent?.handover?.supportedModels;
        if (
            sessionStorage.getItem('isPostHandoverState') == 'false' &&
            supportModels &&
            vehicleAttributes?.model &&
            vehicleAttributes.year
        ) {
            for (let i = 0; i < supportModels?.length; i++) {
                if (
                    supportModels[i].modelAlias == vehicleAttributes.model &&
                    parseInt(supportModels[i].minYear) <= vehicleAttributes.year
                ) {
                    showEYF = true;
                    break;
                }
            }
            if (showEYF) {
                countEYF += 1;
            }
        }
        return (
            (hasRecall() ? 1 : 0) +
            getSyncUpdates().count +
            vhaCount +
            getOtaCount() +
            getServiceRemindersCount() +
            getOLRandSMCount() +
            countEYF
        );
    };

    const getIcon = (): string => {
        if (!notificationsContent?.notificationsBarIconPath) return '';
        return (
            process.env.REACT_APP_AEM_BASE_URL +
            notificationsContent?.notificationsBarIconPath
        );
    };

    const getHoverIcon = (): string => {
        if (!notificationsContent?.notificationsBarHoverIconPath) return '';
        return (
            process.env.REACT_APP_AEM_BASE_URL +
            notificationsContent?.notificationsBarHoverIconPath
        );
    };

    const handleKeyDown = (event: any) => {
        if (event.key === KEYBOARD_KEYS.ENTER) {
            displayModal();
        }
    };

    const handleMouseEnter = (event: any) => {
        if (event) {
            event.currentTarget.getElementsByTagName('img')[0].src =
                getHoverIcon() || getIcon();
        }
    };

    const handleMouseLeave = (event: any) => {
        if (event) {
            event.currentTarget.getElementsByTagName('img')[0].src = getIcon();
        }
    };

    return (
        <>
            {notificationsContent && !notificationsContent?.hide ? (
                <>
                    <div
                        className="vehicle-notification-banner"
                        role="alert"
                        aria-live="polite"
                    >
                        <button
                            onClick={displayModal}
                            onKeyDown={handleKeyDown}
                            tabIndex={0}
                            className="vehicle-notification-content"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <span className="vehicle-notification-title">
                                {notificationsContent?.notificationsBarTitle}
                            </span>
                            <span className="vehicle-notifications-bell">
                                <img src={getIcon()} alt="" />
                                {getVehicleNotifications() > 0 && (
                                    <span className="vehicle-notification-count">
                                        {getVehicleNotifications()}
                                    </span>
                                )}
                            </span>
                        </button>
                    </div>
                    <NotificationsModal
                        isVisible={isModalVisible}
                        content={notificationsContent}
                        closeModal={closeModal}
                        total={getVehicleNotifications()}
                        hasRecall={hasRecall()}
                        recallDetails={getRecallDetails()}
                        syncUpdates={getSyncUpdates()}
                        vin={vehicleAttributes?.vin}
                        modelName={vehicleAttributes?.model}
                        modelYear={vehicleAttributes?.year}
                        vhaData={vhaDataForTile}
                        otaData={mmotaDataForTile}
                        serviceReminderDetails={getServiceReminderDetails()}
                    />
                </>
            ) : (
                ''
            )}
        </>
    );
}

export default VehicleNotifications;
