import HttpService from '../http-service/http-service';
import { UserData } from '../../models/handover-data';

export class SaveFeatureToDatabaseService {
    public async saveFeaturesToDb(
        saveChecklistURL: string,
        userData: UserData
    ) {
        try {
            const url = `${saveChecklistURL}`;
            return HttpService.post<any>(
                url,
                JSON.parse(JSON.stringify(userData)),
                {
                    headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
                }
            ).then(response => {
                return response;
            });
        } catch (error) {
            return;
        }
    }
}

const saveFeatureService = new SaveFeatureToDatabaseService();
export default saveFeatureService;
