import { CalendarStep } from '../../../models/osb-model/osb-calendar-step';
import { ServiceData } from '../../../models/osb-model/osb-dealerservice-info';
import { OsbImageService } from '../../../models/osb-model/osb-image-upload';
import { LightDeliveryServiceStep } from '../../../models/osb-model/osb-light-delivery-service-step';
import { PersonalDetail } from '../../../models/osb-model/osb-personal-details';
import {
    ServiceLocationDetails,
    ServiceLocationDetailsForBookingPayload,
} from '../../../models/osb-model/osb-service-location';
import { ServiceStep } from '../../../models/osb-model/osb-service-step';
import {
    CONTACT_OPTIONS,
    DELIVERY_STEPS_KEYS,
    SERVICE_FLOW,
    SERVICE_LOCATION_STEP_KEYS,
} from './osb-constant';
import { ErrorResponse, getDefaultDeliveryOption } from './osb-utils';

export type ExtractValueType<T, V extends keyof T> = Pick<T, V>[keyof Pick<
    T,
    V
>];

export type Service = {
    id: string;
    name: string;
    comment?: string;
};

export type ContactOption = {
    type: typeof CONTACT_OPTIONS[keyof typeof CONTACT_OPTIONS];
    value: string;
};

export const isAPIResponseError = <TApiResponse>(
    response: TApiResponse | ErrorResponse
): response is ErrorResponse => {
    return (response as ErrorResponse).errorCode !== undefined;
};

export type Customer = {
    title?: ExtractValueType<PersonalDetail, 'title'>;
    firstName: ExtractValueType<PersonalDetail, 'firstName'>;
    fordId: ExtractValueType<PersonalDetail, 'fordId'>;
    lastName: ExtractValueType<PersonalDetail, 'lastName'>;
    contactOptions: ContactOption[];
};
const dateFns = require('date-fns');
export const getDateTime = (
    selectedDate: string,
    selectedTime: string
): string => {
    try {
        const date = new Date(`${selectedDate}T${selectedTime}`);
        return dateFns.format(date, "yyyy-MM-dd'T'HH:mm:ss").toString();
    } catch (e) {
        console.error('Date formatting invalid for `${yyyymmdd}T${hhmm}`');
        return '';
    }
};

const getServiceTypeObject = (service: ServiceData) => {
    return {
        id: service.type + ':' + service.serviceCode,
        name: service.serviceName,
    };
};

export const getSelectedServices = (
    osbServiceStep: ServiceStep,
    osbLightDeliveryServiceStep: LightDeliveryServiceStep,
    osbServiceLocationDetail: ServiceLocationDetails
) => {
    const bookedServicesList: Service[] = [
        ...osbServiceStep.selectedServices.map(service => {
            const item: Service = getServiceTypeObject(service);
            if (service.subType === SERVICE_FLOW.OTHER_CUSTOMSERVICE) {
                item.comment = osbServiceStep.otherServiceDesc;
            }
            return item;
        }),
        ...osbLightDeliveryServiceStep.selectedServices.map(service => {
            return getServiceTypeObject(service);
        }),
        ...(osbServiceStep.isMobileServiceSelected &&
        osbServiceLocationDetail.selectedOtherOptions?.length > 0
            ? osbServiceLocationDetail.selectedOtherOptions.map(
                  (service: ServiceData) => {
                      return getServiceTypeObject(service);
                  }
              )
            : (() => {
                  if (
                      osbServiceLocationDetail.selectedOtherOptions?.length > 0 // TODO: verify whether this condition is needed.
                  ) {
                      const defaultService: ServiceData | null = getDefaultDeliveryOption(
                          osbServiceStep?.dealerServiceInfo?.dealerServices
                              ?.additionalServices
                      );
                      if (
                          defaultService?.subType ===
                          DELIVERY_STEPS_KEYS.FROM_DB
                      ) {
                          return [getServiceTypeObject(defaultService)];
                      } else {
                          return [];
                      }
                  } else {
                      return [];
                  }
              })()),
    ];

    return bookedServicesList;
};

export const getCustomerDetails = (
    osbPersonalDetail: PersonalDetail
): Customer[] | null => {
    if (
        !osbPersonalDetail.firstName &&
        !osbPersonalDetail.lastName &&
        !osbPersonalDetail.email
    ) {
        return null;
    }
    const customer: Customer = {
        title: osbPersonalDetail.title,
        firstName: osbPersonalDetail.firstName,
        lastName: osbPersonalDetail.lastName,
        fordId: osbPersonalDetail.fordId,
        contactOptions: [
            {
                type: 'Email',
                value: osbPersonalDetail.email,
            },
            {
                type: 'Phone',
                value: osbPersonalDetail.contactNumber,
            },
        ],
    };

    return [customer];
};

export const getLocationDetails = (
    osbServiceLocationDetail: ServiceLocationDetails,
    osbCalendarStep: CalendarStep,
    isPickUpAndDeliverySelected: boolean
) => {
    if (
        !osbServiceLocationDetail.town &&
        !osbServiceLocationDetail.houseNumber &&
        !osbServiceLocationDetail.streetName &&
        !osbServiceLocationDetail.postalCode
    ) {
        return null;
    }
    const locationDetails: ServiceLocationDetailsForBookingPayload = {
        city: osbServiceLocationDetail.town,
        comment: osbServiceLocationDetail.comment,
        house: osbServiceLocationDetail.houseNumber,
        parking: osbServiceLocationDetail.parkingType,
        postCode: osbServiceLocationDetail.postalCode,
        street: osbServiceLocationDetail.streetName,
    };
    if (
        isPickUpAndDeliverySelected &&
        osbCalendarStep.selectedPickupDate &&
        osbCalendarStep.selectedPickupTime
    ) {
        locationDetails.locationType =
            SERVICE_LOCATION_STEP_KEYS.LOCATION_BOOKING_TYPE_VEHICLE_PICKUP;
        locationDetails.pickupDateTimeLocal = getDateTime(
            osbCalendarStep.selectedPickupDate,
            osbCalendarStep.selectedPickupTime
        );
    } else {
        locationDetails.locationType =
            SERVICE_LOCATION_STEP_KEYS.LOCATION_BOOKING_TYPE_MOBILE_SERVICE;
    }
    return [locationDetails];
};

export const getIntents = (osbImageUploadStep: OsbImageService) => {
    const intents: string[] =
        osbImageUploadStep.uploadedImage &&
        osbImageUploadStep.uploadedImage.length >= 1
            ? ['IMAGE_UPLOAD']
            : [];
    return intents;
};
