import { ContentResponse } from '../content-service/content-service.interface';
import { findByAlias } from '../../routes';
import {
    CategoryFeatureMap,
    HandoverResponse,
    VechicleHandOverFeature,
} from '../../models/handover-data';
import { ServerSideService } from '../server-side-service/server-side-service';
import HttpService from '../http-service/http-service';

// import getChecklistFeaturesService from './../../components/sections/handover/hooks/use-checklist-api-call';

export class HandoverService {
    private serverService = new ServerSideService();
    private MAXCOUNT = 5;

    public async getContent(
        brand: string,
        region: string,
        language: string
    ): Promise<HandoverResponse> {
        const url = `${process.env.REACT_APP_AEM_BASE_URL}/content/experience-fragments/global-owner/${brand}/${region}/${language}/common/handover/master/jcr:content/root/handover.model.json`;
        const response = HttpService.get(url, true);
        const responseData = await response;
        return responseData.data;
    }

    public async getHandoverModel(
        brand: string,
        region: string,
        language: string
    ): Promise<ContentResponse> {
        const url = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/${brand}/${region}/${language}/content-fragments/common/vehicle-handover.global-owner-model.json`;
        const response = HttpService.get(url, true);
        const responseData = await response;
        return responseData.data;
    }

    public async getSendToDealerModel(
        brand: string,
        region: string,
        language: string
    ): Promise<ContentResponse> {
        const url = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/${brand}/${region}/${language}/content-fragments/common/send-to-dealer.global-owner-model.json`;
        const response = HttpService.get(url, false);
        const responseData = await response;
        return responseData.data;
    }

    public isPostHandover(): boolean {
        const postHandover =
            typeof window === 'undefined'
                ? null
                : window.localStorage.getItem('HANDOVER_STATE');
        return (
            (postHandover
                ? postHandover.slice(
                      postHandover.indexOf('_') + 1,
                      postHandover.length
                  )
                : '') === 'POST-HANDOVER'
        );
    }

    private jsonToMap(cachedModelData: any) {
        const map: Map<string, string | boolean> = new Map<
            string,
            string | boolean
        >();
        for (const value in cachedModelData) {
            map.set(value, cachedModelData[value]);
        }
        return map;
    }

    private mapToJson(map: Map<string, string | boolean>) {
        return Object.fromEntries(map);
    }

    public setDataToCache(cacheModelToSave: string, i18nMap: any) {
        const formattedData = this.mapToJson(i18nMap);
        sessionStorage.setItem(cacheModelToSave, JSON.stringify(formattedData));
    }

    public getDataFromCache(
        modelName: string
    ): Map<string, string | boolean> | undefined {
        const data: string | null = sessionStorage.getItem(modelName);
        if (data) {
            return this.jsonToMap(JSON.parse(data));
        }
        return;
    }

    public fetchSelectedVin() {
        if (this.serverService.isClientSide()) {
            let vin = window.sessionStorage.getItem('selectedVin') as string;
            if (!vin || !vin.length) {
                vin = window.localStorage.getItem('selectedVin') as string;
            }

            return vin;
        }
    }

    public fetchPathName(pathname: string) {
        return findByAlias(pathname);
    }

    public getDataFromSessionStorage(dataName: string) {
        const data: any = this.serverService.isClientSide()
            ? sessionStorage.getItem(dataName)
            : '';

        try {
            if (data !== null) {
                return JSON.parse(data);
            }
        } catch (e) {
            return data ? data : null; // without parse
        }
    }

    public setDataToSessionStorage(dataName: string, dataItems: any) {
        if (this.serverService.isClientSide()) {
            window.sessionStorage.setItem(dataName, JSON.stringify(dataItems));
        }
    }

    public getFeatureName = () => {
        const categoryFeatureMap = this.getDataFromSessionStorage(
                'vinLookupResponse'
            ),
            featuresArray: any[] = [];

        if (categoryFeatureMap.features !== undefined) {
            Object.keys(categoryFeatureMap.features)
                .filter(
                    (category: any) =>
                        categoryFeatureMap.features[category].length > 0
                )
                .forEach((category: any) => {
                    categoryFeatureMap.features[category].forEach(
                        (feature: any) => {
                            const featurekey = feature.key
                                ? feature.key
                                : feature.id;
                            featuresArray.push({
                                id: featurekey,
                                name: feature.headline,
                                imgUrl: feature.handoverImage,
                                ytVideoID: feature.ytVideoID,
                            });
                        }
                    );
                });
        }
        return { featuresArray };
    };

    public removeFromSessionStorage(item: string) {
        if (this.serverService.isClientSide()) {
            sessionStorage.removeItem(item);
        }
    }

    public getFilteredCategoryMap(
        VechicleHandOverFeatureValue: VechicleHandOverFeature | undefined
    ): CategoryFeatureMap | undefined {
        const serverService = new ServerSideService();

        if (serverService.isClientSide() && VechicleHandOverFeatureValue) {
            Object.keys(VechicleHandOverFeatureValue).forEach(
                (category: any) => {
                    VechicleHandOverFeatureValue[category].forEach(
                        (value: any) => {
                            value.longDescription = value.longDescription
                                .replace(/\s\s+/g, ' ')
                                .replace(/(<p>&nbsp;<\/p>)/g, '')
                                .replace(/(<h4>&nbsp;<\/h4>)/g, '')
                                .replace(/(<span>&nbsp;<\/span>)/g, '')
                                .replace(
                                    'border-left-width:1px',
                                    'border:none !important'
                                )

                                .replace(
                                    'padding-bottom:56.25%',
                                    'padding-bottom:56.25px'
                                );
                            if (value.handoverVideo) {
                                value.ytVideoID =
                                    value.handoverVideo.parameters.videoId;
                            } else {
                                value.ytVideoID = '';
                            }
                        }
                    );
                }
            );
            const categoryFeatureMap: CategoryFeatureMap = VechicleHandOverFeatureValue;
            return categoryFeatureMap;
        }
    }

    public setSelectedFeatureForHandoverDetails(key: string) {
        this.removeFromSessionStorage('handover-details');
        const allCategories = this.getDataFromSessionStorage(
            'vinLookupResponse'
        ).features;
        const handoverOverDetailData: any = {
            selectedCategoryData: {
                cards: [],
            },
        };

        if (allCategories !== undefined) {
            Object.keys(allCategories).forEach((category: any) => {
                allCategories[category].forEach((feature: any) => {
                    // const itemkey = item.key ? item.key : item.id;
                    const featurekey = feature.key ? feature.key : feature.id;
                    if (featurekey.toLowerCase() === key.toLowerCase()) {
                        handoverOverDetailData.selectedCategoryData['data'] = {
                            key: key,
                            headline: feature.headline,
                            shortDescription: feature.shortDescription,
                            longDescription: feature.longDescription,
                            category: category,
                            wersCode: feature.wersCode,
                            handoverImage: feature.handoverImage,
                            ytVideoID: feature.ytVideoID,
                        };
                        handoverOverDetailData.selectedCategoryData[
                            'mycategory'
                        ] = category;
                        handoverOverDetailData.selectedCategoryData.cards =
                            allCategories[category];
                    }
                });
            });
        }
        this.setDataToSessionStorage(
            'handover-details',
            handoverOverDetailData
        );
    }

    public updateCheckboxById = (isChecked: boolean, inputId: string) => {
        const checkbox = document.getElementById(inputId) as HTMLInputElement;

        if (checkbox) {
            checkbox.checked = isChecked;
            this.MAXCOUNT = 5; //reset
        } else {
            //some times DOM not ready hence re-trying to fetch again.
            if (this.MAXCOUNT) {
                //try 5 times and avoid unfinite loop if checkbox not exists in DOM
                setTimeout(() => {
                    this.updateCheckboxById(isChecked, inputId);
                }, 1500);

                this.MAXCOUNT -= 1;
            } else {
                this.MAXCOUNT = 5; //reset
            }
        }
    };

    private checkImg(imgUrl: { url: any }, imageSet: Set<unknown>) {
        if (imgUrl.url !== '') {
            imageSet.add(imgUrl);
        }
    }

    public handleSelectedFeatureImage(
        imageName: string,
        imgPlaceHolder: string,
        imageUrl: string,
        checkboxstate: boolean,
        currentIndex: number
    ) {
        const selectedImagesJson = sessionStorage[imageName],
            selectedImgFeatures =
                selectedImagesJson !== undefined
                    ? JSON.parse(selectedImagesJson)
                    : { [imgPlaceHolder]: [] },
            selectedImagesSet = new Set(selectedImgFeatures[imgPlaceHolder]);
        const imageArr = { url: imageUrl };
        checkboxstate
            ? this.checkImg(imageArr, selectedImagesSet)
            : Array.from(selectedImagesSet).forEach((item: any, key) => {
                  if (item.url === imageArr.url) {
                      if (Array.from(selectedImagesSet).length == 1)
                          selectedImagesSet.delete(item);
                      if (Array.from(selectedImagesSet).length > 1) {
                          if (key == currentIndex)
                              selectedImagesSet.delete(item);
                      }
                  }
              });
        selectedImgFeatures[imgPlaceHolder] = Array.from(selectedImagesSet);
        sessionStorage.setItem(imageName, JSON.stringify(selectedImgFeatures));
    }

    public updateCheckboxStateOnLoad() {
        //this will provide all unselected and userseleceted features of all cateogries
        const visitedVehicles =
            this.getDataFromSessionStorage('visited-vehicles') || undefined;

        if (!visitedVehicles) return false;

        const vehicleVin = this.fetchSelectedVin() || '';
        const vinData = visitedVehicles[vehicleVin];
        if (!vinData) return false;
        const list = vinData['categoriesList'] || undefined;

        list &&
            list.map((item: any) => {
                for (const i in item) {
                    if (!item[i]['unSelectedFeatures'].length) {
                        this.updateCheckboxById(true, this.getId(i));
                    }
                    item[i]['userSelectedFeatures'] = this.uniqueFeaturesList(
                        item[i]['userSelectedFeatures']
                    );
                    item[i]['userSelectedFeatures'].map((item: any) => {
                        const itemkey = item.key ? item.key : item.id;
                        this.updateCheckboxById(true, itemkey);
                    });
                }
            });
    }

    public updateCheckboxStateOnHandoverDetail() {
        const handoverDetailCache =
            this.getDataFromSessionStorage('handover-details') || undefined;
        if (!handoverDetailCache) return;

        const category = handoverDetailCache.selectedCategoryData.mycategory;

        const visitedVehicles =
            this.getDataFromSessionStorage('visited-vehicles') || undefined;

        if (!visitedVehicles) return false;

        const vehicleVin = this.fetchSelectedVin() || '';
        const vinData = visitedVehicles[vehicleVin];
        if (!vinData) return false;
        const list = vinData['categoriesList'] || undefined;

        list &&
            list.map((item: any) => {
                for (const i in item) {
                    if (category.toUpperCase() === i.toUpperCase()) {
                        if (!item[i]['unSelectedFeatures'].length) {
                            this.updateCheckboxById(true, this.getId(i));
                        }

                        item[i]['unSelectedFeatures'] = this.uniqueFeaturesList(
                            item[i]['unSelectedFeatures']
                        );
                        item[i]['unSelectedFeatures'].map((item: any) => {
                            const itemkey = item.key ? item.key : item.id;
                            this.updateCheckboxById(false, itemkey);
                        });

                        item[i][
                            'userSelectedFeatures'
                        ] = this.uniqueFeaturesList(
                            item[i]['userSelectedFeatures']
                        );
                        item[i]['userSelectedFeatures'].map((item: any) => {
                            const itemkey = item.key ? item.key : item.id;
                            this.updateCheckboxById(true, itemkey);
                        });
                    }
                }
            });
    }

    public getSelectedVinCategoryList() {
        const visitedVehicleCached = this.getDataFromSessionStorage(
            'visited-vehicles'
        );
        const selectedVin = this.fetchSelectedVin() || '';
        const selectedVinCategoryList =
            visitedVehicleCached[selectedVin].categoriesList;

        if (!selectedVinCategoryList) return [];
        return [visitedVehicleCached, selectedVinCategoryList];
    }

    public getAllFeaturesListOf(category: string, featureListType: string) {
        const [
            visitedVehicle,
            selectedVinCategoryList,
        ] = this.getSelectedVinCategoryList();

        const cat = category.replace(/_/g, '').toUpperCase();
        let featuresList: any = [];

        selectedVinCategoryList &&
            selectedVinCategoryList.map((catItem: any) => {
                if (Object.prototype.hasOwnProperty.call(catItem, cat)) {
                    if (featureListType === 'unSelectedFeatures') {
                        catItem[
                            cat
                        ].unSelectedFeatures = this.uniqueFeaturesList(
                            catItem[cat].unSelectedFeatures
                        );
                        featuresList = catItem[cat].unSelectedFeatures;
                    } else if (featureListType === 'userSelectedFeatures') {
                        catItem[
                            cat
                        ].userSelectedFeatures = this.uniqueFeaturesList(
                            catItem[cat].userSelectedFeatures
                        );
                        featuresList = catItem[cat].userSelectedFeatures;
                    } else {
                        catItem[
                            cat
                        ].unSelectedFeatures = this.uniqueFeaturesList(
                            catItem[cat].unSelectedFeatures
                        );
                        catItem[
                            cat
                        ].userSelectedFeatures = this.uniqueFeaturesList(
                            catItem[cat].userSelectedFeatures
                        );
                        featuresList = [
                            ...catItem[cat].unSelectedFeatures,
                            ...catItem[cat].userSelectedFeatures,
                        ];
                    }
                }
            });
        return [featuresList, visitedVehicle];
    }

    private getCategoryListData(category: string) {
        //this function will provide unSelectedFeatures, userSelectedFeatures objects ONLY
        const [
            visitedVehicleCached,
            selectedVinCategoryList,
        ] = this.getSelectedVinCategoryList();
        const cat = category.replace(/_/g, '').toUpperCase();
        let unSelectedFeatures, userSelectedFeatures;

        selectedVinCategoryList.map((catItem: any) => {
            if (Object.prototype.hasOwnProperty.call(catItem, cat)) {
                catItem[cat].unSelectedFeatures = this.uniqueFeaturesList(
                    catItem[cat].unSelectedFeatures
                );
                catItem[cat].userSelectedFeatures = this.uniqueFeaturesList(
                    catItem[cat].userSelectedFeatures
                );
                unSelectedFeatures = catItem[cat].unSelectedFeatures;
                userSelectedFeatures = catItem[cat].userSelectedFeatures;
            }
        });
        return [visitedVehicleCached, unSelectedFeatures, userSelectedFeatures];
    }

    private removeFeatureFromList(feature: any, list: any) {
        let index = 0;
        list.map((item: any, idx: any) => {
            const itemkey = item.key ? item.key : item.id;
            const featurekey = feature.key ? feature.key : feature.id;
            if (itemkey.toLowerCase() === featurekey.toLowerCase()) index = idx;
        });
        list.splice(index, 1);
        return list;
    }

    public updateAddAllCheckbox(unSelectedFeatures: any, category: string) {
        unSelectedFeatures.length === 0
            ? this.updateCheckboxById(true, this.getId(category))
            : this.updateCheckboxById(false, this.getId(category));
    }

    public handleCheckboxStateOnClick(
        featureCard: any,
        isChecked: boolean,
        category: any
    ) {
        const visitedVehicles = this.getDataFromSessionStorage(
            'visited-vehicles'
        );
        const vehicleVin = this.fetchSelectedVin() || '';
        const vinData = visitedVehicles[vehicleVin];
        if (!vinData) return false;
        const list = vinData['categoriesList'] || undefined;
        const cat = category.replace(/_/g, '').toUpperCase();

        list &&
            list.map((catItem: any) => {
                if (Object.prototype.hasOwnProperty.call(catItem, cat)) {
                    catItem[cat].unSelectedFeatures = this.uniqueFeaturesList(
                        catItem[cat].unSelectedFeatures
                    );
                    catItem[cat].userSelectedFeatures = this.uniqueFeaturesList(
                        catItem[cat].userSelectedFeatures
                    );
                    const unSelectedFeatures = catItem[cat].unSelectedFeatures;
                    const userSelectedFeatures =
                        catItem[cat].userSelectedFeatures;

                    if (isChecked) {
                        this.removeFeatureFromList(
                            featureCard[0],
                            unSelectedFeatures
                        );
                        userSelectedFeatures.push(featureCard[0]);
                    } else {
                        this.removeFeatureFromList(
                            featureCard[0],
                            userSelectedFeatures
                        );
                        unSelectedFeatures.push(featureCard[0]);
                    }
                }
            });

        this.setDataToSessionStorage('visited-vehicles', visitedVehicles);
    }

    public removeCategoryFromStorage(
        storageName: string,
        placeholder: string,
        id: string
    ) {
        const selectedFeaturesJson = sessionStorage[storageName],
            selectedFeatures =
                selectedFeaturesJson !== undefined
                    ? JSON.parse(selectedFeaturesJson)
                    : { [placeholder]: [] },
            selectedFeaturesSet = new Set(selectedFeatures[placeholder]);
        if (selectedFeaturesSet.has(id)) {
            selectedFeaturesSet.delete(id);
        }
        selectedFeatures[placeholder] = Array.from(selectedFeaturesSet);
        this.setDataToSessionStorage(
            storageName,
            JSON.stringify(selectedFeatures)
        );
    }

    private extractFeaturesFromCat(category: any, data: any) {
        const obj = {
            [category.toUpperCase()]: {
                unSelectedFeatures: [...data],
                userSelectedFeatures: [],
            },
        };
        return obj;
    }

    public visitedVehiclesFactoryObj(selectedVin: string, data: any) {
        const factoryObj: any[] = [];
        Object.keys(data).map((category: any) => {
            if (data[category].length > 0) {
                factoryObj.push(
                    this.extractFeaturesFromCat(category, data[category])
                );
            }
        });

        return {
            [selectedVin]: {
                selectAllFeaturesFor: [] as any,
                selectedFeatures: [] as string[],
                categoriesList: [...factoryObj],
            },
        };
    }

    public getId(id: string) {
        return 'addAll' + id.toLowerCase().replace(/[ _]/g, '');
    }

    public smoothscroll() {
        if (!window) return;

        const currentScroll =
            document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.scrollTo(0, currentScroll - currentScroll / 1);
        }
    }

    private matchWithChecklistData(
        unSelectedFeatures: any,
        userSelectedFeatures: any,
        checklistdata: any,
        category: string
    ) {
        const copy = [...unSelectedFeatures];
        copy.forEach((item: any) => {
            const itemkey = item.key ? item.key : item.id;
            if (checklistdata.includes(itemkey)) {
                userSelectedFeatures.push(item);
                unSelectedFeatures.splice(unSelectedFeatures.indexOf(item), 1);
                this.updateCheckboxById(true, itemkey);
            }
        });
        if (unSelectedFeatures.length === 0) {
            // for updating Add All checkbox
            this.updateCheckboxById(true, this.getId(category));
        }
    }

    public updateCheckboxWithUserSelectedFeatures(userSelectedFeatures: any) {
        userSelectedFeatures.map((item: any) => {
            const itemkey = item.key ? item.key : item.id;
            this.updateCheckboxById(true, itemkey);
        });
    }

    public populateVisitedVehicleByCheckList(checklistdata: any) {
        //iterate over all categories and match & populate userselectedfeatures with checklist data
        if (Array.from(checklistdata).length === 0) return; //if not checklist data received

        let visitedVehicles = this.getDataFromSessionStorage(
            'visited-vehicles'
        );
        if (!visitedVehicles) {
            return;
        }

        const vehicleVin = this.fetchSelectedVin() || '';
        const vinData = visitedVehicles[vehicleVin];
        if (!vinData) return false;
        const list = vinData['categoriesList'] || undefined;
        let userSelectedFeatures: any = [];

        list &&
            list.map((item: any) => {
                const category = Object.keys(item)[0];

                for (const i in item) {
                    item[i]['unSelectedFeatures'] = this.uniqueFeaturesList(
                        item[i]['unSelectedFeatures']
                    );
                    item[i]['userSelectedFeatures'] = this.uniqueFeaturesList(
                        item[i]['userSelectedFeatures']
                    );
                    userSelectedFeatures = item[i]['userSelectedFeatures'];
                    this.matchWithChecklistData(
                        item[i]['unSelectedFeatures'],
                        item[i]['userSelectedFeatures'],
                        Array.from(checklistdata),
                        category
                    );
                }
            });

        visitedVehicles = {
            ...visitedVehicles,
            [vehicleVin]: {
                ...visitedVehicles[vehicleVin],
            },
        };
        this.setDataToSessionStorage('visited-vehicles', visitedVehicles);
        this.updateCheckboxWithUserSelectedFeatures(userSelectedFeatures);
        return visitedVehicles;
    }

    private selectedFeatureFactoryReset() {
        return { vinPlaceholder: [] } as any;
    }

    public createCacheonFirstVisit(mappedData: any) {
        const visitedVehicles = this.visitedVehiclesFactoryObj(
            this.fetchSelectedVin() || '',
            mappedData
        );
        this.setDataToSessionStorage('visited-vehicles', visitedVehicles);
    }

    public getSelectedFeaturesCount(getList?: boolean) {
        //iterate through visited-vehicle[selectedVin]
        // find all userSelectedFeatures
        // return count
        let selectedFeaturesLength = 0;
        const visitedVehicleCached =
            this.getDataFromSessionStorage('visited-vehicles') || undefined;
        if (!visitedVehicleCached) {
            return selectedFeaturesLength;
        }

        const selectedVin = this.fetchSelectedVin() || '',
            selectedVinData = visitedVehicleCached[selectedVin] || undefined,
            categoriesList =
                (selectedVinData && selectedVinData['categoriesList']) || [];
        let selectedFeaturesArray: any = [];

        if (!selectedVinData || !categoriesList) return selectedFeaturesLength; //if new vin creating is in progress

        categoriesList.length > 0 &&
            categoriesList.map((item: any) => {
                for (const i in item) {
                    item[i]['userSelectedFeatures'] = this.uniqueFeaturesList(
                        item[i]['userSelectedFeatures']
                    );

                    selectedFeaturesLength +=
                        item[i]['userSelectedFeatures'].length;

                    selectedFeaturesArray = [
                        ...selectedFeaturesArray,
                        ...item[i]['userSelectedFeatures'],
                    ];
                }
            });

        return getList ? selectedFeaturesArray : selectedFeaturesLength;
    }

    public getAllSelectedFeaturesKeys() {
        const featuresArray = this.getSelectedFeaturesCount(true);
        const keysArray: string[] = [];

        featuresArray.length &&
            featuresArray.map((item: any) => {
                keysArray.push(item.key);
            });
        return keysArray;
    }

    public updateAddAllOnClick(category: string, isChecked: boolean) {
        let visitedVehicleCached =
            this.getDataFromSessionStorage('visited-vehicles') || undefined;

        const selectedVin = this.fetchSelectedVin() || '',
            selectedVinData = visitedVehicleCached[selectedVin] || undefined,
            categoriesList =
                (selectedVinData && selectedVinData['categoriesList']) || [];

        categoriesList.length > 0 &&
            categoriesList.map((item: any) => {
                for (const i in item) {
                    if (i.toLowerCase() === category.toLowerCase()) {
                        item[i].userSelectedFeatures = this.uniqueFeaturesList(
                            item[i].userSelectedFeatures
                        );
                        item[i].unSelectedFeatures = this.uniqueFeaturesList(
                            item[i].unSelectedFeatures
                        );

                        if (isChecked) {
                            item[i].userSelectedFeatures = [
                                ...item[i].userSelectedFeatures,
                                ...item[i].unSelectedFeatures,
                            ];
                            item[i].unSelectedFeatures = [];
                            item[i].userSelectedFeatures.map((item: any) => {
                                const itemkey = item.key ? item.key : item.id;
                                this.updateCheckboxById(true, itemkey);
                            });
                            this.updateCheckboxById(true, this.getId(category));
                        } else {
                            item[i].unSelectedFeatures = [
                                ...item[i].unSelectedFeatures,
                                ...item[i].userSelectedFeatures,
                            ];
                            item[i].userSelectedFeatures = [];
                            item[i].unSelectedFeatures.map((item: any) => {
                                const itemkey = item.key ? item.key : item.id;
                                this.updateCheckboxById(false, itemkey);
                            });
                            this.updateCheckboxById(
                                false,
                                this.getId(category)
                            );
                        }
                    }
                }
            });

        visitedVehicleCached = {
            ...visitedVehicleCached,
            [selectedVin]: {
                ...visitedVehicleCached[selectedVin],
            },
        };
        this.setDataToSessionStorage('visited-vehicles', visitedVehicleCached);
    }

    public uniqueFeaturesList(list: any) {
        const uniqueFeatures = Array.from(
            new Set(list.map((a: any) => a.key))
        ).map((key: any) => {
            return list.find((a: any) => a.key === key);
        });
        return uniqueFeatures;
    }
}

const handoverService = new HandoverService();
export default handoverService;
