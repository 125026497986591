import HttpService from '../http-service/http-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';

export class GetChecklistFeatureservice {
    public async getCreateChecklistservice(vin: string | any): Promise<any> {
        const url = `${DSL_BASE_URL + DSL_API_PATH.CHECKLIST}`;

        return HttpService.get<any>(url, false, {
            headers: {
                vin,
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            },
        }).then((response: any) => {
            return response.data;
        });
    }
}
