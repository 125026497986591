import React, { useEffect, useRef } from 'react';
import './../../main.scss';
import './fm-modal.scss';
import FMButton from '../fm-button/fm-button';
import { FMModalProps } from '../../types/fm-modal.types';
import { KEYBOARD_KEYS } from '../vehicleSelector/vehicleSelector.constants';

const FMModal = (props: FMModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const focusableElementsSelector =
        'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    const closeModal = (e: Event | React.MouseEvent) => {
        if (props.preventClose !== true) {
            const [modal, closeIcon] = [modalRef.current, closeIconRef.current];
            if (modal && (e.target === modal || e.currentTarget === closeIcon))
                modal.style.display = 'none';
            props.onClose && props.onClose();
        }
    };
    const setDefaultFocus = () => {
        if (props.preventClose) {
            const focusableElements = modalRef.current?.querySelectorAll(
                focusableElementsSelector
            );
            if (focusableElements && focusableElements.length > 0) {
                const first = focusableElements.item(0) as HTMLElement;
                first && first.focus();
            }
        } else closeIconRef?.current?.focus();
    };
    const showModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element && (element.style.display = 'block');
        setDefaultFocus();
    };

    const hideModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element && (element.style.display = 'none');
    };

    const onDocumentClicked = (event: MouseEvent) => {
        if (
            modalContentRef.current &&
            modalContentRef.current.contains(event.target as Node)
        ) {
            return;
        }
        closeModal(event);
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeModal(event);
        }
        if (event.key === KEYBOARD_KEYS.TAB && modalRef.current) {
            const focusableElements: HTMLElement[] = Array.from(
                modalRef.current.querySelectorAll(focusableElementsSelector)
            );
            const first = focusableElements[0];
            const last = focusableElements[focusableElements.length - 1];
            const active = document.activeElement;
            const index = focusableElements.indexOf(
                focusableElements.filter((el) => el == active)[0]
            );
            if (!event.shiftKey) {
                if (index === focusableElements.length - 1) {
                    event.preventDefault();
                    first.focus();
                }
            } else {
                if (index === 0) {
                    event.preventDefault();
                    last.focus();
                }
            }
        }
    };
    useEffect(() => {
        if (props.isVisible === true) {
            showModal();
            setTimeout(() => {
                window.addEventListener('click', onDocumentClicked, false);
                window.addEventListener('keydown', onKeyDown, false);
            });
        }
        return () => {
            hideModal();
            window.removeEventListener('click', onDocumentClicked, false);
            window.removeEventListener('keydown', onKeyDown, false);
        };
    }, [props.isVisible]);
    return (
        <div
            ref={modalRef}
            className={`fm-modal ${props.name}`}
            data-testid={props.name}
            style={{ display: 'none' }}
            aria-label={props['aria-label']}
        >
            <div className='fm-modal-content' ref={modalContentRef}>
                {props.preventClose !== true && (
                    <div className={'fm-modal-header'}>
                        {props.title}
                        <button
                            ref={closeIconRef}
                            className='fmc-dialog__close fds-icon fds-font--ford-icons__clear'
                            aria-label={props.closeButtonAriaLabel}
                            data-testid='closeButton'
                            onClick={closeModal}
                        ></button>
                    </div>
                )}
                {props.subtitle && (
                    <div className={'fm-modal-subtitle'}>{props.subtitle}</div>
                )}
                {props.children && (
                    <div
                        className={'fm-modal-slot-component'}
                        onScroll={props.onBodyScroll}
                    >
                        {props.children}
                    </div>
                )}
                {props.ctaLabel && (
                    <div className={'fm-modal-button-wrapper'}>
                        {props.ctaLabel && (
                            <FMButton
                                type={props.ctaType}
                                label={props.ctaLabel}
                                chevron={props.ctaChevron}
                                ariaLabel={props.ctaAriaLabel}
                                target={props.ctaTarget}
                                onClick={props.onCtaClick}
                            ></FMButton>
                        )}
                        {props.cta2Label && (
                            <FMButton
                                type={props.cta2Type}
                                label={props.cta2Label}
                                chevron={props.cta2Chevron}
                                ariaLabel={props.cta2AriaLabel}
                                target={props.cta2Target}
                                onClick={props.onCta2Click}
                            ></FMButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FMModal;
