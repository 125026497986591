import React from 'react';

import { FdsChevron } from '../../../common/chevron/fds-chevron';
import { HandoverCheckbox } from '../handover-checkbox/handover-checkbox';
import { VehicleFeature } from '../../../../models/handover-data';

import '../handover.scss';
import playButton from '../../../../assets/play.png';
import { KEYBOARD_KEYS } from '../../../../constants';

interface Props {
    i18n: Map<string, string | boolean>;
    data: CustomCardData;
    handleChangeCheckBox: (
        newValue: number,
        checkboxstate: boolean,
        id: string
    ) => void;
    value: number;
    mycategory: string;
    cards: VehicleFeature[];
    key: number;
    handoverState: boolean | undefined;
    vehicleData?: any;
    handleClick: () => void;
}

export interface CustomCardData {
    category: string;
    handoverImage: string;
    headline: string;
    longDescription: string;
    shortDescription: string;
    ytVideoID: string;
    cards: VehicleFeature[];
    wersCode: string | undefined;
    key: string;
}

export const FeatureCard = (props: Props) => {
    const postHandover = props.handoverState;
    const {
        handoverImage,
        headline = 'Head Line',
        shortDescription = 'Description',
        ytVideoID,
        wersCode,
        key,
    } = props.data;

    const addToHandover = props.i18n.get('addToHandover');

    const keyBoardEnter = (event: any) => {
        if (
            event.key === KEYBOARD_KEYS.ENTER ||
            event.key === KEYBOARD_KEYS.SPACE
        ) {
            props.handleClick();
        }
    };

    let imageSrc;
    if (handoverImage) {
        imageSrc = handoverImage.startsWith('http')
            ? handoverImage
            : process.env.REACT_APP_AEM_BASE_URL + handoverImage;
    } else {
        imageSrc = 'https://img.youtube.com/vi/' + ytVideoID + '/mqdefault.jpg';
    }

    return (
        <div className="handover">
            <div className={'handover-wrap-eyf'}>
                <div className={'handover-tile-eyf'}>
                    <div
                        role="button"
                        aria-label="Card in feature list"
                        tabIndex={0}
                        onClick={props.handleClick}
                        onKeyDown={e => keyBoardEnter(e)}
                        className="video-thumbnail"
                    >
                        <img
                            className="card-img"
                            alt="feature-image"
                            src={imageSrc}
                        />
                        {ytVideoID ? (
                            <div className="video-thumbnail-icon">
                                <img
                                    className="playButton"
                                    src={playButton}
                                    alt="PlayIcon"
                                />
                            </div>
                        ) : null}
                    </div>

                    <>
                        <div className="handover-title-eyf">
                            <h3 className="handover-header">{headline}</h3>
                        </div>
                        <p className="short-desc">{shortDescription}</p>
                    </>

                    <div className="handover-cta">
                        <a
                            data-testid="learnMoreCta"
                            tabIndex={0}
                            onClick={props.handleClick}
                            onKeyDown={e => keyBoardEnter(e)}
                        >
                            {props.i18n.get('learnMoreCta')}
                            <FdsChevron type="filled" direction="right" />
                        </a>
                    </div>
                </div>

                {!postHandover ? (
                    <HandoverCheckbox
                        label={addToHandover}
                        type="add"
                        id={key}
                        category={props.mycategory}
                        featuresFromCategory={props.cards}
                        onChange={props.handleChangeCheckBox}
                        value={props.value}
                        wersCode={wersCode}
                        imgSrc={imageSrc}
                    />
                ) : null}
            </div>
        </div>
    );
};
export default FeatureCard;
