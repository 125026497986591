import './handover-tracker.scss';

interface HandoverTrackerProps {
    trackerData: any[];
}
interface Props {
    step: number;
    filled: boolean;
    active: boolean;
    text: string;
    ind: number;
}

const TrackerButtonSection = (props: Props) => {
    const { step, filled, active, text, ind } = props;

    const getButtonCss = () => {
        if (active)
            return 'fds-tracker__button fds-tracker__button--active-eyf';
        if (!active && filled)
            return 'fds-tracker__button fds-tracker__button--filled';
        if (!active && !filled) return 'fds-tracker__button';
    };

    const getLabelTextCss = () => {
        if (active) return 'fds-tracker__label-text-white';
        if (!active && filled) return 'fds-tracker__label-text-no-display';
        if (!active && !filled) return 'fds-tracker__label-text-gray';
    };

    const grideArea = getNumeric(ind + 1);

    function getTitleArea(index: number) {
        if (index === 0) {
            return 'four';
        } else if (index === 1) {
            return 'five';
        } else {
            return 'eight';
        }
    }

    const titleArea = getTitleArea(ind);
    function getNumeric(index: number) {
        switch (index) {
            case 1:
                return 'one';
            case 2:
                return 'two';
            case 3:
                return 'three';
        }
    }

    return (
        <>
            <button
                aria-label={`Step ${step}` + ' ' + `${text}`}
                aria-live="polite"
                className={getButtonCss()}
                style={{
                    gridArea: grideArea,
                }}
            >
                <span
                    className={`fds-tracker__position-label${
                        !active && !filled
                            ? ' fds-tracker__position-label--white'
                            : ''
                    }`}
                >
                    <span className={getLabelTextCss()} aria-hidden="true">
                        {step}
                    </span>
                </span>
            </button>
            <p
                className="fds-tracker__title_eyf"
                style={{
                    gridArea: titleArea,
                }}
            >
                <span className="fds-tracker__text">{text}</span>
            </p>
        </>
    );
};

export const HandoverTracker = (props: HandoverTrackerProps) => {
    const { trackerData } = props;

    return (
        <div className="handover-tracker-main">
            <div className="js-fds-tracker fds-tracker fds-color__bg--light">
                <div className="fds-tracker__button-list">
                    {trackerData != undefined &&
                        trackerData.map((data, index) => {
                            return (
                                <TrackerButtonSection
                                    key={data.step}
                                    {...data}
                                    ind={index}
                                />
                            );
                        })}

                    {trackerData != undefined &&
                        trackerData.map((data, index, array) => {
                            if (index < array.length - 1) {
                                return (
                                    <div
                                        key={data.step}
                                        className="fds-tracker__progress-line-wrapper-eyf fds-tracker__progress-line-wrapper--filled"
                                        style={{
                                            gridArea:
                                                index === 0 ? 'nine' : 'ten',
                                        }}
                                    >
                                        <span className="fds-tracker__progress-line"></span>
                                    </div>
                                );
                            }
                        })}
                </div>
            </div>
        </div>
    );
};
