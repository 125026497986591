import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../common';
import './light-review-booking-lincoln.scss';
import ServiceHandler from '../../../../../services/service-handler';
import {
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
    DELIVERY_STEPS_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    ACTIVITY_INDICATOR_KEYS,
} from '../../osb-constant';
import {
    usePersonalDetailStep,
    useServiceStep,
    useDealerStep,
    useReviewBookingStep,
    useHttp,
    useOSBStep,
    useStepProgressBar,
    useLightDeliveryOptionServiceStep,
    useOsbImageUpload,
    useVehicleStep,
    usePageRefresh,
    useViewport,
    useServiceLocationStep,
} from '../../../../../hooks/owners-osb';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import { LightRetrieveBookingDetails } from '../../retrieve-booking-details/light-journey/light-retrieve-booking-details';
import {
    shouldSkipPersonalDetailsStep,
    checkOSBStepExists,
    removeAllClientStorage,
    buildNavigationUrl,
    isAuthenticationActive,
    getGoMainHeaderHeight,
} from '../../osb-utils';
import { OSBSecondaryButton } from '../../common/osb-secondary-button/osb-secondary-button';
import parse from 'html-react-parser';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import {
    triggerReviewBookingLoadAnalytics,
    triggerReviewBookingErrorAnalytics,
} from '../../analytics/osb-analytics';
import {
    OsbPathBookingConfirmationStep,
    OsbPathCalenderStep,
    OsbPathPersonalDetailsStep,
} from '../../osb-controller';
import { AcceptedFileInfo } from '../../common/osb-file-uploader/osb-file-uploader';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import AuthenticationService from '../../../../../services/authentication-service/authentication-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import {
    Bookable,
    useAllOSBState,
} from '../../../../../hooks/owners-osb/use-all-osb-state';
import GoogleMapService from '../../../../../services/osb-service/google-map-service/google-map-service';

interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}
const METER = 'meter';
export const LightReviewBooking = (props: Props) => {
    const { osbServiceLocationDetail } = useServiceLocationStep();
    const [isServiceRefreshed, setIsServiceRefreshed] = useState(false);
    const { osbStepProgressBar } = useStepProgressBar();
    const { osbPersonalDetail } = usePersonalDetailStep();
    const { osbServiceStep } = useServiceStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const { osbDealerStep } = useDealerStep();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const bookable = useAllOSBState();
    const [serviceError, setServiceError] = useState(false);
    const [fireEvents] = useAnalytics();
    const [loading, setLoading] = useState(false);
    const { osbVehicleStep } = useVehicleStep();
    const { setOSBReviewDetail, callContentService } = useReviewBookingStep();
    const { osbImageUploadStep } = useOsbImageUpload();
    const { httpState, dispatch } = useHttp();
    const [isRequiredComments, setIsRequiredComments] = useState(false);
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >(ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT);
    const [progressPercentArray] = useState<number[]>([]);
    const [
        isDisableRequestBookingButton,
        setIsDisableRequestBookingButton,
    ] = useState(false);
    const history = useHistory();
    const { isMobileView } = useViewport();

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const goToPersonalDetailsStep = () => {
        invalidateAuthentication();
        if (
            shouldSkipPersonalDetailsStep(
                osbStep.isAuthenticatedFlow,
                osbPersonalDetail
            ) &&
            !checkOSBStepExists(
                osbStepProgressBar.progressBarDetails,
                STEP_PROGRESS_BAR_KEYS.CONTACT
            )
        ) {
            history.push(
                buildNavigationUrl(
                    OsbPathCalenderStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else {
            history.push(
                buildNavigationUrl(
                    OsbPathPersonalDetailsStep(),
                    osbStep.UrlQueryParams
                )
            );
        }
    };

    const [voucherCode] = useState<string[]>([]);
    if (voucherCode.length <= 0 && osbServiceStep.voucherCode) {
        voucherCode.push(osbServiceStep.voucherCode);
    }
    const handleSummaryRefresh = () => {
        setIsServiceRefreshed(!isServiceRefreshed);
    };

    const [reviewBookingContent, setReviewBookingContent] = useState<
        OsbContentResponse
    >();
    usePageRefresh();

    const getReviewBookingContent = async () => {
        await callContentService('osb-review-booking')
            .then(results => {
                setReviewBookingContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    useEffect(() => {
        getReviewBookingContent();
        triggerReviewBookingLoadAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
        );
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, []);

    useEffect(() => {
        dispatch({ type: 'RESPONSE' });

        if (
            osbServiceStep.selectedServices.filter(
                item =>
                    item.serviceOption ==
                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE
            ).length === 1 &&
            osbLightDeliveryServiceStep.selectedServices.filter(
                item =>
                    item.serviceOption ===
                    DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD
            ).length === 1 &&
            osbServiceStep.comment.length === 0
        ) {
            setIsRequiredComments(true);
        } else {
            setIsRequiredComments(false);
        }
    }, [isServiceRefreshed]);

    const createBookingConfirmationDetails = (results: any) => {
        setOSBReviewDetail({
            accessCode: results.value.accessCode,
            bookingReferenceNumber: results.value.bookingReferenceNumber,
        });
    };

    function fetchProgressPercentForImageUpload(progressPercent: number) {
        if (
            osbImageUploadStep?.uploadedImage &&
            osbImageUploadStep.uploadedImage.length > 1
        ) {
            progressPercentArray.push(progressPercent);
            if (
                progressPercentArray?.length ===
                osbImageUploadStep.uploadedImage.length
            ) {
                setProgressPercentToDisplay(
                    Math.min(...progressPercentArray).toString()
                );
                progressPercentArray.length = 0;
            }
        } else {
            setProgressPercentToDisplay(progressPercent.toString());
        }
    }

    function fetchProgressPercentForCreateBooking(progressPercent: number) {
        setProgressPercentToDisplay(progressPercent.toString());
    }

    const imageUpload = async (
        accessCode: string,
        bookingReferenceNumber: string,
        email: string,
        uploadedImages: AcceptedFileInfo[]
    ) => {
        const bookingFileType = SERVICE_FLOW.IMAGE_UPLOAD_TYPE;
        await Promise.all(
            uploadedImages.map(uploadedImage => {
                return ServiceHandler.OsbImageUploadService.uploadImage(
                    accessCode,
                    email.toLowerCase(),
                    bookingReferenceNumber,
                    bookingFileType,
                    uploadedImage,
                    fetchProgressPercentForImageUpload
                )
                    .then()
                    .catch();
            })
        );
    };

    const amendCompleteBooking = (
        identifier: string,
        bookingReferenceNumber: string,
        accessCode: string
    ) => {
        const { dealerCode, ...booingWithoutDealerCode } = bookable;
        booingWithoutDealerCode.bookingAuthorization = {
            accessCode,
            identifier,
        };
        ServiceHandler.OSBAmendBooking.amendServiceBooking({
            dealerCode,
            bookingRefNo: bookingReferenceNumber,
            serviceBookingRequest: booingWithoutDealerCode,
            isMobileServiceSelected:
                osbDealerStep.hasMobileService &&
                osbServiceStep.isMobileServiceSelected,
            source: osbStep.source,
            callback:
                osbImageUploadStep.uploadedImage &&
                osbImageUploadStep.uploadedImage.length > 0
                    ? undefined
                    : fetchProgressPercentForCreateBooking,
        });
    };
    const createBooking = async (bookingRequest: Bookable) => {
        try {
            const results = await ServiceHandler.OsbServiceBooking.createServiceBooking(
                {
                    serviceBookingRequest: bookingRequest,
                    callback:
                        osbImageUploadStep.uploadedImage &&
                        osbImageUploadStep.uploadedImage.length > 0
                            ? undefined
                            : fetchProgressPercentForCreateBooking,
                    DSLAPIVersion: osbStep.DSLAPIVersion,
                }
            );

            if (
                osbImageUploadStep.uploadedImage &&
                osbImageUploadStep.uploadedImage.length > 0
            ) {
                await imageUpload(
                    results.value.accessCode,
                    results.value.bookingReferenceNumber,
                    osbPersonalDetail.email,
                    osbImageUploadStep.uploadedImage
                );

                amendCompleteBooking(
                    osbPersonalDetail.email,
                    results.value.bookingReferenceNumber,
                    results.value.accessCode
                );
                progressPercentArray.length = 0;
                setProgressPercentToDisplay('100');
            }

            setLoading(false);
            createBookingConfirmationDetails(results);
            removeAllClientStorage();
            history.push(
                buildNavigationUrl(
                    OsbPathBookingConfirmationStep(),
                    osbStep.UrlQueryParams
                )
            );
        } catch (error) {
            setIsDisableRequestBookingButton(false);
            setLoading(false);
            if (error) {
                setServiceError(true);
            }
            triggerReviewBookingErrorAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents
            );
        }
    };
    const handleBookingSubmission = async () => {
        function hasValue(str: string): boolean {
            // Remove all commas from the string
            const trimmedString = str.replace(/,/g, '').trim();
            // Check if the trimmed string is empty
            return trimmedString.length > 0;
        }
        setLoading(true);
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
        setIsDisableRequestBookingButton(true);
        setProgressPercentToDisplay(
            ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT
        );

        const bookingRequest = bookable;
        const pickupAddress = `${osbServiceLocationDetail.houseNumber} ${osbServiceLocationDetail.street}, ${osbServiceLocationDetail.town}, ${osbServiceLocationDetail.postalCode}`;
        const dealerAddress = `${osbDealerStep.selectedDealerProfile.street}, ${osbDealerStep.selectedDealerProfile.town}, ${osbDealerStep.selectedDealerProfile.postalCode}`;
        if (hasValue(pickupAddress.trim()) && dealerAddress.trim()) {
            try {
                const distance = await GoogleMapService.findDistanceBetweenAddresses(
                    osbStep.geoCountryCode,
                    pickupAddress,
                    dealerAddress
                );
                if (distance !== null && bookingRequest.vehicleLocations) {
                    if (bookingRequest.vehicleLocations[0]) {
                        bookingRequest.vehicleLocations[0].distanceDealer = {
                            distance: distance,
                            unit: METER,
                        };
                    } else {
                        console.log(`No vehicle location found`);
                    }
                } else {
                    console.log(
                        `Distance between addresses is null or vehicle locations are undefined`
                    );
                }
            } catch (error) {
                console.error(`Error finding distance: ${error}`);
            }
        }

        await createBooking(bookingRequest);
    };
    const submitBooking = async () => {
        invalidateAuthentication();

        if (
            !osbStep.isAuthenticatedFlow ||
            (osbStep.isAuthenticatedFlow && isAuthenticationActive(osbStep))
        ) {
            if (!isRequiredComments) {
                await handleBookingSubmission();
            }
        } else if (!isAuthenticationActive(osbStep)) {
            new AuthenticationService().login();
        }
    };

    return (
        <div className="osb-light-review-booking-component-container">
            {loading && (
                <OsbLoader
                    osbLoaderMessage={` ${
                        osbImageUploadStep.uploadedImage &&
                        osbImageUploadStep.uploadedImage?.length > 0
                            ? parse(
                                  reviewBookingContent?.elements
                                      .find(
                                          e =>
                                              e.name ===
                                              'loaderMessageForImageUpload'
                                      )
                                      ?.value?.toString() ?? ''
                              )
                            : parse(
                                  reviewBookingContent?.elements
                                      .find(e => e.name === 'loaderMessage')
                                      ?.value?.toString() ?? ''
                              )
                    }`}
                    progressPercent={progressPercentToDisplay}
                />
            )}
            {serviceError && (
                <div className="error">
                    {parse(
                        reviewBookingContent?.elements
                            .find(e => e.name === 'Booking_Error')
                            ?.value.toString() ?? ''
                    )}
                </div>
            )}

            {(osbStep.isWebViewJourney || !isMobileView) && (
                <div className="light-review-booking-back">
                    <OSBSecondaryButton
                        type="svg"
                        direction="left"
                        text={
                            !httpState.isLoading
                                ? reviewBookingContent?.elements.find(
                                      e => e.name === 'Back_Button_Label'
                                  )?.value + ''.toString()
                                : ''
                        }
                        onClickHandler={goToPersonalDetailsStep}
                    />
                </div>
            )}
            <div className="light-review-booking-title">
                {!httpState.isLoading ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                reviewBookingContent?.elements.find(
                                    e => e.name === 'Booking_Summary'
                                )?.value + ''.toString(),
                        }}
                    />
                ) : (
                    <div></div>
                )}
            </div>
            <div>
                <LightRetrieveBookingDetails
                    refreshBookingSummary={handleSummaryRefresh}
                    loadVoucherAlertMessage={loadVoucherAlertMessage}
                />
            </div>

            <div className="light-request-booking-button">
                <PrimaryButton
                    role="link"
                    color={'dark'}
                    fill={'fill'}
                    chevron={false}
                    style={{ float: 'right' }}
                    onClick={submitBooking}
                    disabled={isDisableRequestBookingButton}
                >
                    {!httpState.isLoading
                        ? reviewBookingContent?.elements.find(
                              e => e.name === 'Submit_Button_Label'
                          )?.value + ''.toString()
                        : ''}
                </PrimaryButton>
            </div>
        </div>
    );
};
