import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SwipePoint, useSwipe } from '../../hooks/use-swipe';
import FMButton from '../fm-button/fm-button';
import {
    FMCarouselProps,
    FMCarouselState,
} from '../../types/fm-carousel.types';

import './fm-carousel.scss';

const MOBILE_BREAKING_POINT = 992;
const DESKTOP_MAX_SCREEN_SIZE = 1440;
const isServer = typeof window === 'undefined';

let initialState = {
    adjustedTranslate: 0,
    currentCardIndex: 0,
    transparentCards: [],
    showIndicators: false,
    leftNavigationArrow: false,
    rightNavigationArrow: true,
} as FMCarouselState;

const FMCarousel = <T,>(props: FMCarouselProps<T>) => {
    const {
        items,
        isDualMode,
        carouselTheme = 'light',
        isUniMode = false,
        reactNode,
        instanceName,
    } = props;

    const [isMobile, setIsMobile] = useState<boolean>(
        isServer ? false : window?.innerWidth < MOBILE_BREAKING_POINT
    );
    initialState = {
        ...initialState,
        className: isDualMode
            ? 'fm-carousel__items-dual'
            : 'fm-carousel__items',
    };
    const itemsRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);

    const [carouselState, setCarouselState] =
        useState<FMCarouselState>(initialState);

    const {
        adjustedTranslate,
        currentCardIndex,
        transparentCards,
        className,
        showIndicators,
        leftNavigationArrow,
        rightNavigationArrow,
    } = carouselState;

    const itemsLength = items?.length;
    const maxCardIndex = itemsLength - 1;

    const headerTransitionPadding =
        currentCardIndex === 0 ? '' : 'header-transition-padding';
    const carouselTransitionPadding =
        currentCardIndex === 0 ? '' : 'carousel-transition-padding';

    const leftArrowClassName = [
        carouselTheme === 'dark'
            ? 'fm-carousel__indicator-left-dark'
            : 'fm-carousel__indicator-left',
        leftNavigationArrow ? '' : 'hide-arrow animate',
    ]
        .join(' ')
        .trim();

    const rightArrowClassName = [
        carouselTheme === 'dark'
            ? 'fm-carousel__indicator-right-dark'
            : 'fm-carousel__indicator-right',
        rightNavigationArrow ? '' : 'hide-arrow animate',
    ]
        .join(' ')
        .trim();
    const opacityClassName = [
        carouselTheme === 'dark' ? 'opacity-dark' : 'opacity',
    ]
        .join(' ')
        .trim();
    const carouselContainerClass = [
        'fm-carousel__container',
        carouselTheme,
        isUniMode ? 'unimode' : '',
        carouselTransitionPadding,
    ]
        .join(' ')
        .trim();

    const generateId = (instanceName: string, id: number) =>
        `${instanceName}-${id}`;

    const carouselIndicatorsClassName = ['fm-carousel__indicators']
        .join(' ')
        .trim();
    const carouselTitleClassName = [
        'fm-carousel__title',
        headerTransitionPadding,
    ]
        .join(' ')
        .trim();
    const carouselSubtitleClassName = [
        'fm-carousel__subcopy',
        headerTransitionPadding,
    ]
        .join(' ')
        .trim();

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        if (itemsRef.current) {
            for (let cardIndex = 0; cardIndex <= maxCardIndex; cardIndex++) {
                const parentLeft =
                    itemsRef.current.getBoundingClientRect().left;
                const itemLeft = itemsRef.current
                    .getElementsByClassName('fm-carousel__items-item')
                    .item(cardIndex)
                    ?.getBoundingClientRect().left;
                if (itemLeft) {
                    const cardPosX = itemLeft - parentLeft;
                    if (
                        Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                        Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
                    ) {
                        closestCardIndex = cardIndex;
                        closestCardX = cardPosX;
                    }
                }
            }

            setSwipeStartX(null);
            setSwipeOffsetX(0);
            setCarouselState({
                ...carouselState,
                currentCardIndex: closestCardIndex,
            });
        }
    }, [
        itemsRef,
        swipeOffsetX,
        maxCardIndex,
        cardsPositionX,
        currentCardIndex,
        isMobile,
    ]);
    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX]
    );
    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            if (isMobile || !isUniMode) {
                setSwipeStartX(swipePoint.x);
            }
        },
        [setSwipeStartX, isMobile, isUniMode]
    );
    const wrapperRef = useSwipe(
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );
    const updateCardPositions = useCallback(() => {
        if (itemsRef.current) {
            const parentLeft = itemsRef.current.getBoundingClientRect().left;
            const itemLeft = itemsRef.current
                ?.getElementsByClassName('fm-carousel__items-item')
                .item(currentCardIndex)
                ?.getBoundingClientRect().left;
            if (itemLeft) {
                const offset = itemLeft - parentLeft;
                setCardsPositionX(-offset);
            }
        }
    }, [itemsRef, currentCardIndex, cardsPositionX]);
    const updateCarouselState = () => {
        if (!isServer) {
            let transparentCards = items.map((_item, index) => index);
            let showIndicators = true;
            let adjustedTranslate = 0;
            let numberOfVisibleCards = 0;
            let _className = 'fm-carousel__items';

            const wrapper = carouselRef?.current
                ?.getElementsByClassName('fm-carousel__wrapper')
                .item(0) as HTMLDivElement;

            const { width } = wrapper?.getBoundingClientRect() || {
                width: 0,
            };
            const wrapperWidth = Math.min(width, DESKTOP_MAX_SCREEN_SIZE);
            const gap = window?.innerWidth < MOBILE_BREAKING_POINT ? 8 : 16;
            const teaser = window?.innerWidth < MOBILE_BREAKING_POINT ? 32 : 56;
            const itemsTranslateLayer =
                wrapper?.firstElementChild as HTMLDivElement;
            const divs =
                Array.from(
                    wrapper?.firstElementChild?.childNodes || [],
                    (item) => item as HTMLDivElement
                ) || [];

            if (wrapperWidth && itemsTranslateLayer && divs) {
                // in this section the divs width are managed dynamically
                divs.forEach((div) => (div.style.width = `unset`));
                if (window?.innerWidth < MOBILE_BREAKING_POINT) {
                    // in this section the divs are set to 0.9 of the wrapper width + gap
                    // we determine the transparent cards with transparentCards array,
                    // and we set the adjustedTranslate to center the cards,
                    // and we set the number of visible cards to 1

                    const width =
                        wrapperWidth -
                        (currentCardIndex === 0 ? teaser : 42 + gap);
                    divs.forEach((div) => {
                        div.style.width = `${Math.round(width)}px`;
                    });
                    _className = 'fm-carousel__items';
                    showIndicators = false;

                    numberOfVisibleCards = 1;
                    transparentCards = items
                        ?.map((_item, index) => index + currentCardIndex)
                        .splice(
                            0,
                            numberOfVisibleCards === 0
                                ? 1
                                : numberOfVisibleCards
                        );
                    adjustedTranslate =
                        (wrapperWidth - numberOfVisibleCards * width) / 2;
                } else {
                    if (isDualMode) {
                        // in this section the divs are set to (0.9 of the wrapper width ) / 2
                        // to be able to show 2 cards at the time
                        // we determine the transparent cards with transparentCards array,
                        // and we set the adjustedTranslate to center the cards,
                        // and we set the number of visible cards to 1

                        const width =
                            currentCardIndex === 0
                                ? wrapperWidth - (teaser + gap)
                                : wrapperWidth - gap - 2 * teaser;
                        divs.forEach((div) => {
                            div.style.width = `${Math.round(width / 2)}px`;
                        });
                        _className =
                            currentCardIndex === 0
                                ? 'fm-carousel__items-dual'
                                : 'fm-carousel__items-dual dual-intial';
                        numberOfVisibleCards = 2;
                        transparentCards = items
                            .map((_item, index) => index)
                            .splice(currentCardIndex, 2);
                        adjustedTranslate =
                            currentCardIndex === 0
                                ? (teaser - gap) / 2
                                : teaser;
                    } else {
                        // in this section the divs are set to (card width from the props )
                        // we determine the transparent cards with transparentCards array,
                        // and we set the adjustedTranslate to center the cards,
                        // and we set the number of visible cards to the floor of the wrapper width / card width
                        _className = 'fm-carousel__items';
                        numberOfVisibleCards = 3;
                        const width =
                            currentCardIndex === 0
                                ? (wrapperWidth - teaser - 3 * gap) / 3
                                : (wrapperWidth - 2 * teaser - 3 * gap) / 3;

                        divs.forEach((div) => {
                            div.style.width = `${width}px`;
                        });
                        transparentCards = items
                            .map((_item, index) => index)
                            .splice(currentCardIndex, 3);
                        adjustedTranslate =
                            currentCardIndex === 0
                                ? teaser
                                : (2 * teaser + gap) / 2;

                        if (isUniMode) {
                            // in this section the divs are set to (wrapper - (itemsLength-1 ) )
                            // we determine the transparent cards with transparentCards array,
                            // and we set the adjustedTranslate to center the cards,
                            // and we set the number of visible cards to the floor of the wrapper width / card width
                            divs.forEach((div) => {
                                div.style.width = `${
                                    (wrapperWidth - (itemsLength - 1) * gap) /
                                    itemsLength
                                }px`;
                            });
                            numberOfVisibleCards = itemsLength;
                            adjustedTranslate = 0;
                            _className = 'fm-carousel__items unimode';
                            showIndicators = false;
                            transparentCards = items.map(
                                (_item, index) => index
                            );
                        }
                    }
                }
                const rightNavigationArrow =
                    items?.length - numberOfVisibleCards !== currentCardIndex &&
                    transparentCards?.includes(currentCardIndex) &&
                    currentCardIndex !== maxCardIndex;

                const leftNavigationArrow =
                    transparentCards?.includes(currentCardIndex) &&
                    currentCardIndex !== 0;

                setCarouselState({
                    ...carouselState,
                    adjustedTranslate,
                    currentCardIndex,
                    className: _className,
                    showIndicators,
                    leftNavigationArrow,
                    rightNavigationArrow,
                    numberOfVisibleCards,
                    transparentCards: [...transparentCards],
                });
                updateCardPositions();
            }
        }
    };
    const moveToNextCard = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (currentCardIndex <= maxCardIndex) {
            setCarouselState({
                ...carouselState,
                currentCardIndex: currentCardIndex + 1,
            });
        }
    };
    const moveToPreviousCard = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (currentCardIndex > 0) {
            e.preventDefault();
            setCarouselState({
                ...carouselState,
                currentCardIndex: currentCardIndex - 1,
            });
        }
        updateCardPositions();
    };

    useEffect(() => {
        if (!isServer) {
            setIsMobile(window?.innerWidth < MOBILE_BREAKING_POINT);
            window?.addEventListener('resize', () => updateCarouselState());
        }
        return () => {
            if (!isServer) {
                window?.removeEventListener('resize', updateCarouselState);
            }
        };
    }, []);

    useEffect(() => {
        if (!isServer) {
            setIsMobile(window?.innerWidth < MOBILE_BREAKING_POINT);
            updateCarouselState();
        }
    }, [currentCardIndex, itemsLength, isMobile, className]);

    return (
        <section className={carouselContainerClass} ref={carouselRef}>
            {props.title && (
                <h3 className={carouselTitleClassName}>
                    {props.titleWithDisclaimer || props.title}
                </h3>
            )}
            {props.subtitle && (
                <p className={carouselSubtitleClassName}>
                    {props.subtitleWithDisclaimer || props.subtitle}
                </p>
            )}
            {reactNode && (
                <section className='fm-carousel__reactnode'>
                    {reactNode}
                </section>
            )}
            {showIndicators && (
                <nav className={carouselIndicatorsClassName}>
                    <div className={leftArrowClassName}>
                        <FMButton
                            type={'secondary'}
                            label={React.Fragment}
                            onClick={moveToPreviousCard}
                            ariaLabel={props.rightButtonAriaLabel || 'next'}
                        />
                    </div>
                    <div className={rightArrowClassName}>
                        <FMButton
                            type={'secondary'}
                            label={React.Fragment}
                            onClick={moveToNextCard}
                            ariaLabel={props.leftButtonAriaLabel || 'previous'}
                        />
                    </div>
                </nav>
            )}
            <section
                className='fm-carousel__wrapper'
                ref={(instance: HTMLDivElement) =>
                    (wrapperRef.current = instance)
                }
            >
                <section
                    ref={itemsRef}
                    className={className}
                    style={{
                        transform: `translateX(${
                            cardsPositionX +
                            swipeOffsetX +
                            (currentCardIndex === 0 ? 0 : adjustedTranslate)
                        }px)`,
                        transition: swipeStartX
                            ? 'none'
                            : 'transform 300ms linear',
                    }}
                >
                    {items.map((item, opaqueCardIndex) => {
                        return (
                            <div
                                key={generateId(instanceName, opaqueCardIndex)}
                            >
                                <div
                                    className={[
                                        'fm-carousel__items-item',
                                        transparentCards?.includes(
                                            opaqueCardIndex
                                        )
                                            ? ''
                                            : opacityClassName,
                                    ]
                                        .join(' ')
                                        .trim()}
                                >
                                    {props.render(item)}
                                </div>
                            </div>
                        );
                    })}
                </section>
            </section>
        </section>
    );
};

export default FMCarousel;
