import React from 'react';

import { VehicleSelectorFds, ViewTemplate } from '../../components/sections';
import { useLocation } from 'react-router-dom';

import './vehicle-not-found-view.scss';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useHomeContent } from '../home-page/hooks/use-home-content';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import { PageType } from '../../components/sections/view-template/page-type';
import useVehicleNotFoundViewContent from './use-vehicle-not-found-view-content';

export const VehicleNotFoundView = () => {
    const page: PageType = 'Vehicle Not Found';
    const content = useVehicleNotFoundViewContent();
    const homeContent = useHomeContent();
    const appInformationContent = useAppInformationContent();
    const location = useLocation();
    const getTitle = () => {
        const params = new URLSearchParams(location.search);
        const failedVin = params.get('vin');
        const year = params.get('year');
        const make = params.get('make');
        const model = params.get('model');

        if (year && make && model) return `${year} ${make} ${model}`;
        if (failedVin) {
            if (failedVin.length < 17 && content.regNoLabel)
                return `${content.regNoLabel} ${failedVin}`;
            else return `${content.vinLabel} ${failedVin}`;
        }
    };

    const VehicleNotFoundCtaText = () => (
        <>
            <div className="vehicle-not-found-cta-text">
                <h1 className="title">{getTitle()}</h1>
                <p className="paragraph">
                    {content.vehicleNotFoundDescription}
                </p>
            </div>
        </>
    );

    const VehicleNotFoundCTAVehicleSelector = () =>
        homeContent && (
            <VehicleSelectorFds
                labels={{
                    vinHeader: homeContent.vehicleSelectorVinSubtitle,
                    vinPlaceholder: homeContent.vehicleSelectorVinInput,
                    yearDropdown: homeContent.vehicleSelectorYearDropdownLabel,
                    modelDropdown:
                        homeContent.vehicleSelectorModelDropdownLabel,
                    submit: homeContent.vehicleSelectorSubmitButtonLabel,
                    disclaimer: homeContent.disclaimerLabel,
                    header: homeContent.vehicleSelectorModelSubtitle,
                }}
            />
        );

    return (
        <>
            {content && homeContent && appInformationContent ? (
                <ViewTemplate
                    seoConfig={{
                        pageTitle: getTitle() || '',
                        applicationName: appInformationContent.applicationName,
                    }}
                    page={page}
                    breadcrumb={content.breadcrumbLabel}
                    ctaTopComponent={<VehicleNotFoundCtaText />}
                    ctaBottomComponent={<VehicleNotFoundCTAVehicleSelector />}
                    backgroundImageDesktop={
                        process.env.REACT_APP_AEM_BASE_URL +
                        content.backgroundImageDesktop
                    }
                    backgroundImageMobile={
                        process.env.REACT_APP_AEM_BASE_URL +
                        content.backgroundImageMobile
                    }
                />
            ) : (
                <ActivityIndicator />
            )}
        </>
    );
};
export default VehicleNotFoundView;
