import React, { useEffect, useState } from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import getChecklistFeaturesService from '../../sections/handover/hooks/use-checklist-api-call';
import { createUrl } from '../../sections/handover/hooks/use-vehicle-handover-status';
import VehicleOrderStatus from '../../../services/handover/vehicle-order-status-service';
interface Props {
    ctaTextPreHandover: string;
    iconPathPreHandover: string;
    preHandoverActionNeededSubcopy: string;
    ctaPathPreHandover: string;
    ctaTextPostHandover: string;
    iconPathPostHandover: string;
    postHandoverStaticSubcopy: string;
    ctaPathPostHandover: string;
    preHandoverNoActionNeededSubcopy: string;
    modelSupportedStatus: boolean;
    isChecklistSaved: boolean;
    isModelSupported: boolean;
    tileTitle: string;
}
const VehicleChecklistHandover = (
    props: Props & VehicleChecklistContent & VehicleAttributes
) => {
    const [subCopy, setSubCopy] = useState<string>('');
    const [iconPath, setIconPath] = useState<string>('');
    const [ctaTitle, setCtaTitle] = useState<string>(props.ctaTextPreHandover);
    const [ctaPath, setCtaPath] = useState<string>(props.ctaPathPreHandover);

    let isPostHandover = false;
    const setUpCard = () => {
        if (!isPostHandover) {
            setIconPath(props.iconPathPreHandover);
            setSubCopy(props.preHandoverNoActionNeededSubcopy);
            setCtaTitle(props.ctaTextPreHandover);
            setCtaPath(props.ctaPathPreHandover);
        } else {
            setIconPath(props.iconPathPostHandover);
            setSubCopy(props.postHandoverStaticSubcopy);
            setCtaTitle(props.ctaTextPostHandover);
            setCtaPath(props.ctaPathPostHandover);
        }
    };
    useEffect(() => {
        new VehicleOrderStatus()
            .getVehicleOrder(createUrl(), props.vin!)
            .then(res => {
                if (res !== null) isPostHandover = res.postHandover;
                else {
                    // if response is null, the handover state is considered as a post handover
                    isPostHandover = true;
                }
            })
            .then(() => {
                setUpCard();
                if (!isPostHandover) {
                    getChecklistFeaturesService
                        .useChecklistService()
                        .then((response: any) => {
                            if (Array.from(response).length) {
                                setSubCopy(
                                    props.preHandoverNoActionNeededSubcopy
                                );
                            } else {
                                setSubCopy(
                                    props.preHandoverActionNeededSubcopy
                                );
                            }
                        })
                        .catch(() => {
                            setSubCopy(props.preHandoverActionNeededSubcopy);
                        });
                }
            });
    }, [props.vin]);

    return (
        <VehicleChecklist
            ctaAriaLabel={props.ctaAriaLabel}
            type={props.cardTypes}
            ctaPath={ctaPath}
            ctaTargetBlank={props.ctaTargetBlank}
            ctaTitle={ctaTitle}
            description={subCopy}
            header={props.tileTitle}
            iconPath={iconPath}
        />
    );
};

export default VehicleChecklistHandover;
