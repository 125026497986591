import React, { useContext, useEffect, useState } from 'react';
import './trailer-checklist.scss';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import SupportFdsWrapper from '../../../support/components/support-fds-wrapper/support-fds-wrapper';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import { PrimaryButton } from '../../common';
import { PageType } from '../view-template/page-type';
import { useHistory, useLocation } from 'react-router-dom';
import {
    TrailerChecklistLandingProps,
    TrailerType,
} from '../../../models/experiencefragments/trailer-checklist';
import { findByAlias } from '../../../routes';
import { ServiceAndOffers } from './service-and-offers/service-and-offers';
import ServerContext from '../../../contexts/serverContext';
import { ViewTemplate } from '../view-template/view-template';
import { AemArticles } from '../../../models/experiencefragments/category-view';
import isWebviewCheck from '../../utils/webviewCheck/webviewCheck';
import { useAnalytics } from '../../../hooks/use-analytics';
import { getIsBevVehicle } from '../../utils/getVin-util/getVin-util';

interface Props {
    page: PageType;
    trailerChecklistLanding: TrailerChecklistLandingProps;
    serviceOffersSection: AemArticles;
}
export const TrailerCheckList = (props: Props) => {
    const {
        trailerChecklistHeadline,
        trailerChecklistDescription,
        conventionalTrailerCTALabel,
        fifthWheelTrailerCTALabel,
        gooseneckTrailerCTALabel,
    } = props.trailerChecklistLanding;
    const [breadcrumbToggle, setBreadCrumbToggle] = useState(false);
    const [bevState, setBevState] = useState<boolean | undefined>(false);
    const isEU = new AppConfigurationService().isRegionEU();
    const history = useHistory();
    const serverContext = useContext(ServerContext);
    const selectedVin =
        serverContext.webviewData?.vin ||
        window.sessionStorage.getItem('selectedVin');
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';

    const isWebview = isWebviewCheck();
    const [fireEvents] = useAnalytics();

    const openDetailsPage = (e: MouseEvent, path: string) => {
        e.preventDefault();
        history.push(path);
    };

    const { pathname } = useLocation();
    const replacedpath = pathname?.split('/').join('');

    const triggerAnalytics = () => {
        let triggerName = pathname.substring(19);
        if (pathname.charAt(pathname.length - 1) == '/') {
            triggerName = triggerName.slice(0, -1);
        } else {
            triggerName = triggerName.replace('/', '-');
        }
        fireEvents([triggerName], triggerName, undefined, false);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        triggerAnalytics();
    }, [replacedpath]);

    useEffect(() => {
        if (selectedVin) {
            const getIsBevValue = async () => {
                const isBev = await getIsBevVehicle(selectedVin);
                setBevState(isBev);
            };
            getIsBevValue();
            isWebview && sessionStorage.setItem('selectedVin', selectedVin);
        }
    }, [selectedVin]);
    const mappingAemContent = (ctaContext: string) => {
        switch (true) {
            case ctaContext === conventionalTrailerCTALabel:
                return conventionalTrailerCTALabel
                    .split(' ')
                    .join('')
                    .toLowerCase();
            case ctaContext === gooseneckTrailerCTALabel:
                return gooseneckTrailerCTALabel
                    .split(' ')
                    .join('')
                    .toLowerCase();
            case ctaContext === fifthWheelTrailerCTALabel:
                return fifthWheelTrailerCTALabel
                    .split(' ')
                    .join('')
                    .toLowerCase();
        }
    };
    const triggerTrailerChecklistCTAOnClickEvent = (ctaContext: string) => {
        const data = mappingAemContent(ctaContext);
        if (ctaContext) {
            fireEvents(
                ['trailer-checklist-landing-cta-onclick-event'],
                undefined,
                {
                    trailerChecklistCtaContext: data,
                },
                false
            );
        }
    };

    const renderTrailerCheckListCTA = (
        ctalabel: string,
        trailerType: TrailerType
    ) => {
        const path = `${findByAlias(trailerType)}${
            isWebview ? '?webview=true' : ''
        }`;

        if (ctalabel) {
            return (
                <PrimaryButton
                    className="trailer-checklist-cta-button"
                    onClick={(e: MouseEvent) => {
                        openDetailsPage(e, path);
                        triggerTrailerChecklistCTAOnClickEvent(ctalabel);
                    }}
                    href={`${currentRoot}${path}`}
                    ariaLabel={ctalabel}
                    color="light"
                    fill="fill"
                    chevron={true}
                >
                    <>{ctalabel}</>
                </PrimaryButton>
            );
        }
    };

    return (
        <ViewTemplate
            seoConfig={{
                pageTitle: props.page,
                applicationName: '',
            }}
            page={props.page}
            className="trailer-checklist-view"
            hideLinksList={true}
            hideSearchBar={isWebview}
            showDisclosureContent={isWebview ? false : true}
        >
            <>
                <SupportFdsWrapper>
                    <div
                        className={`breadcrumbAccordion ${breadcrumbToggle &&
                            'expanded'}`}
                    >
                        <Breadcrumbs
                            currentPage={trailerChecklistHeadline}
                            hashId="support"
                            hideFirstBreadcrumb={isWebview}
                        ></Breadcrumbs>
                        {!isEU && (
                            <div
                                onClick={() =>
                                    setBreadCrumbToggle(!breadcrumbToggle)
                                }
                            >
                                <FdsChevron
                                    direction={breadcrumbToggle ? 'up' : 'down'}
                                    type={'unfilled'}
                                ></FdsChevron>
                            </div>
                        )}
                    </div>
                </SupportFdsWrapper>
                <section className="trailer-checklist-page">
                    <section className="trailer-checklist-heading-section">
                        <h1 className="trailer-checklist-header">
                            {trailerChecklistHeadline}
                        </h1>
                        {trailerChecklistDescription && (
                            <div
                                className="trailer-checklist-description"
                                dangerouslySetInnerHTML={{
                                    __html: trailerChecklistDescription,
                                }}
                            />
                        )}
                    </section>

                    <div className="trailer-checklist-cta-button-wrapper">
                        {renderTrailerCheckListCTA(
                            conventionalTrailerCTALabel,
                            TrailerType.CONVENTIONAL
                        )}
                        {!bevState && (
                            <>
                                {renderTrailerCheckListCTA(
                                    gooseneckTrailerCTALabel,
                                    TrailerType.GOOSENECK
                                )}
                                {renderTrailerCheckListCTA(
                                    fifthWheelTrailerCTALabel,
                                    TrailerType.FIFTHWHEEL
                                )}
                            </>
                        )}
                    </div>
                    {isWebview ? (
                        ''
                    ) : (
                        <ServiceAndOffers
                            serviceOffersSection={props.serviceOffersSection}
                        />
                    )}
                </section>
            </>
        </ViewTemplate>
    );
};
