import React, { useRef, useEffect } from 'react';
import './how-to-modal.scss';
import closeIcon from '../../../../../assets/close.svg';
import { KEYBOARD_KEYS } from '../../../../../constants';

interface Props extends React.HTMLProps<HTMLDivElement> {
    name: string;
    preventClose?: boolean;
    onClose?: () => void;
    role?: string;
    'aria-label'?: string;
    isVisible?: boolean;
    description: string;
}

export const HowToModal = (props: Props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const modalBodyRef = useRef<HTMLDivElement>(null);

    const closeModal = (e: Event | React.MouseEvent) => {
        if (props.preventClose !== true) {
            const [modal, closeIcon] = [modalRef.current, closeIconRef.current];
            if (modal && (e.target === modal || e.currentTarget === closeIcon))
                modal.style.display = 'none';
            props.onClose && props.onClose();
        }
    };

    const focusPreviousControl = (
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (event.key === 'Tab' && event.shiftKey) {
            if (cancelRef && cancelRef.current !== null) {
                event.preventDefault();
                cancelRef.current.focus();
            }
        }
    };

    const focusCloseIcon = () => {
        closeIconRef?.current?.focus();
    };

    const showModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element.style.display = 'block';
        focusCloseIcon();
    };

    const hideModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element && (element.style.display = 'none');
    };

    const onDocumentClicked = (event: MouseEvent) => {
        if (
            modalContentRef.current &&
            modalContentRef.current.contains(event.target as Node)
        ) {
            return;
        }
        closeModal(event);
    };

    const onEscape = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeModal(event);
        }
    };
    const focusNextControl = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === KEYBOARD_KEYS.TAB) {
            if (
                closeIconRef &&
                closeIconRef.current !== null &&
                modalBodyRef.current
            ) {
                event.preventDefault();
                closeIconRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (props.isVisible === true) {
            showModal();
            document.addEventListener('click', onDocumentClicked, false);
            document.addEventListener('keydown', onEscape, false);
        }
        return () => {
            hideModal();
            document.removeEventListener('click', onDocumentClicked, false);
            document.removeEventListener('keydown', onEscape, false);
        };
    }, [closeModal, props.isVisible]);

    return (
        <div
            ref={modalRef}
            className={`dyf-modal ${props.name}`}
            data-testid={props.name}
            role={props.role}
            style={{ display: 'none' }}
            aria-label={props['aria-label']}
        >
            <div className="dyf-modal-content" ref={modalContentRef}>
                <div className="dyf-modal-header">
                    {props.preventClose !== true && (
                        <button
                            ref={closeIconRef}
                            data-testid="test-close"
                            onClick={closeModal}
                            tabIndex={-1}
                            aria-label="Close Dialog"
                            className="dyf-modal-close"
                            onKeyDown={focusPreviousControl}
                        >
                            <img src={closeIcon} alt="" />
                        </button>
                    )}
                </div>
                <div className="dyf-modal-body">{props.children}</div>
                <h3
                    className="dyf-modal-footer"
                    ref={modalBodyRef}
                    onKeyDown={focusNextControl}
                    tabIndex={0}
                >
                    {props.description}
                </h3>
            </div>
        </div>
    );
};
