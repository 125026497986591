import React, { useEffect, useState } from 'react';
import {
    SnackbarButtonProps,
    SnackbarDismissProps,
    SnackbarLinkProps,
    SnackbarProps,
} from '../../types/snackbar.types';
import './snackbar.scss';

const Snackbar = (props: SnackbarProps) => {
    const {
        content,
        open,
        defaultOpen = false,
        fixed = false,
        fixedClassName = '',
        children,
        snackbarCtaText,
        snackbarCtaLink,
        snackbarCtaLinkTarget,
        showDismissButton = false,
        dismissibleSnackbar,
        dismissibleSnackbarTimer,
        dismissButtonAriaLabel,
        snackbarClassName,
        fixedCssStyleObj = {},
        snackbarCssStyleObj = {},
        onOpen,
        onClose,
    } = props;

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(
        open || defaultOpen
    );

    const closeSnackBar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (typeof open === 'boolean') {
            setSnackbarOpen(open);
        }
    }, [open]);

    useEffect(() => {
        if (snackbarOpen) {
            onOpen && onOpen(snackbarOpen);
        } else {
            onClose && onClose(snackbarOpen);
        }
    }, [snackbarOpen]);

    useEffect(() => {
        if (snackbarOpen && dismissibleSnackbar && dismissibleSnackbarTimer) {
            const snackbarTimer = setTimeout(() => {
                setSnackbarOpen(false);
            }, dismissibleSnackbarTimer);
            return () => {
                clearTimeout(snackbarTimer);
            };
        }
    }, [snackbarOpen, dismissibleSnackbar, dismissibleSnackbarTimer]);

    function SnackbarDismissButton(props: SnackbarDismissProps) {
        return (
            <button
                className='fmc-snackbar__dismiss fds-icon fds-font--ford-icons__clear'
                aria-label={props.ariaLabel}
                onClick={props.onClick}
            />
        );
    }

    function SnackbarCta(props: SnackbarLinkProps & SnackbarButtonProps) {
        return props.snackbarCtaText ? (
            props.snackbarCtaLink ? (
                <a
                    {...props}
                    rel='noopener noreferrer'
                    href={props.snackbarCtaLink}
                    className='fmc-snackbar__cta'
                >
                    {props.snackbarCtaText}
                </a>
            ) : (
                <button {...props} className='fmc-snackbar__cta'>
                    {props.snackbarCtaText}
                </button>
            )
        ) : (
            <></>
        );
    }

    const renderSnackbar = () => {
        if (children && typeof children === 'function') {
            return children(
                snackbarOpen,
                SnackbarCta,
                SnackbarDismissButton,
                setSnackbarOpen
            );
        } else if (children) {
            return <div className='fmc-snackbar__content'>{children}</div>;
        } else {
            return (
                <>
                    <div className='fmc-snackbar__content'>{content}</div>
                    <SnackbarCta
                        snackbarCtaText={snackbarCtaText}
                        snackbarCtaLink={snackbarCtaLink}
                        target={snackbarCtaLinkTarget}
                    />
                    {showDismissButton && (
                        <SnackbarDismissButton
                            onClick={closeSnackBar}
                            ariaLabel={dismissButtonAriaLabel}
                        />
                    )}
                </>
            );
        }
    };

    return snackbarOpen ? (
        <div
            className={`${
                fixed
                    ? `fmc-snackbar-wrapper fmc-snackbar-wrapper--fixed ${fixedClassName}`
                    : ''
            }`}
            style={fixedCssStyleObj}
        >
            <div
                className={`fmc-snackbar ${snackbarClassName || ''}`}
                style={snackbarCssStyleObj}
            >
                {renderSnackbar()}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Snackbar;
