import React from 'react';
import './connected-vehicle-fuel-level.scss';
import ConnectedVehicle, {
    HeaderType,
} from '../connected-vehicle/connected-vehicle';
import {
    Fuel,
    UomDistance,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import { FuelLevelContent } from '../../../models/experiencefragments/connected-vehicle-bar';

interface Props {
    vin: string;
    connectedVehicleInfo: Vehiclestatus;
    isBev: boolean;
}

const ConnectedVehicleFuelLevel = (props: Props & FuelLevelContent) => {
    const fuel: Fuel = props.connectedVehicleInfo.fuel;
    const uomDistance: UomDistance = props.connectedVehicleInfo.uomDistance;
    const { isBev } = props;
    const others = () => {
        let fillLevelIcon = '';
        let headerType: HeaderType = 'none';
        if (fuel) {
            if (fuel.quarterRange === 'FULL') {
                fillLevelIcon = props.fullIconPath;
            } else if (fuel.quarterRange === 'THREEFOURTH') {
                fillLevelIcon = props.threeFourthIconPath;
            } else if (fuel.quarterRange === 'HALF') {
                fillLevelIcon = props.halfIconPath;
            } else if (fuel.quarterRange === 'QUARTER') {
                fillLevelIcon = props.quarterIconPath;
                headerType = 'moderate';
            }
        }
        return { headerType, fillLevelIcon };
    };

    return (
        <>
            {fuel && !isBev && (
                <ConnectedVehicle
                    className="fuel-level"
                    header={props.title}
                    iconPath={others().fillLevelIcon}
                    number={
                        Math.round(fuel.distanceToEmpty).toLocaleString() +
                        (fuel.distanceToEmpty.toLocaleString() === '0'
                            ? ''
                            : uomDistance === 1
                            ? ' MI'
                            : ' KM')
                    }
                ></ConnectedVehicle>
            )}
        </>
    );
};

export default ConnectedVehicleFuelLevel;
