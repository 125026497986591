import {
    CategoryFeatureMap,
    VechicleHandOverFeature,
} from '../../../../models/handover-data';
import handoverService from '../../../../services/handover/handover-service';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import { useState, useEffect } from 'react';
import vinHandoverService from '../../../../services/handover/vin-handover-service';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../../../constants';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';

function getFilteredCategoryMap(
    VechicleHandOverFeatureValue: VechicleHandOverFeature | undefined
): CategoryFeatureMap | undefined {
    if (ServerSideService.isClientSide() && VechicleHandOverFeatureValue) {
        Object.keys(VechicleHandOverFeatureValue).forEach(category => {
            VechicleHandOverFeatureValue[category].forEach(processValue);
        });

        removeInvalidItems(VechicleHandOverFeatureValue);

        const categoryFeatureMap: CategoryFeatureMap = VechicleHandOverFeatureValue;
        return categoryFeatureMap;
    }
}

function processValue(value: any) {
    setFeatureDisable(value);
    cleanLongDescription(value);
    setVideoId(value);
}

function setFeatureDisable(value: any) {
    if (
        value.isFeatureDisable === null ||
        value.isFeatureDisable === undefined
    ) {
        value.isFeatureDisable = false;
    }
}

function cleanLongDescription(value: any) {
    if (value.longDescription) {
        value.longDescription = value.longDescription
            .replace(/\s\s+/g, ' ')
            .replace(/(<p>&nbsp;<\/p>)/g, '')
            .replace(/(<h4>&nbsp;<\/h4>)/g, '')
            .replace(/(<span>&nbsp;<\/span>)/g, '')
            .replace('border-left-width:1px', 'border:none !important')
            .replace('padding-bottom:56.25%', 'padding-bottom:56.25px');
    }
}

function setVideoId(value: any) {
    if (value.handoverVideo) {
        value.ytVideoID = value.handoverVideo.parameters.videoId;
    } else {
        value.ytVideoID = '';
    }
}

function removeInvalidItems(VechicleHandOverFeatureValue: any) {
    for (const item in VechicleHandOverFeatureValue) {
        for (let i = 0; i < VechicleHandOverFeatureValue[item].length; i++) {
            if (isInvalid(VechicleHandOverFeatureValue[item][i])) {
                VechicleHandOverFeatureValue[item].splice(i, 1);
                i--;
            }
        }
    }
}

function isInvalid(item: any) {
    return (
        item.headline === '' ||
        item.headline === null ||
        item.headline === undefined ||
        item.longDescription === '' ||
        item.longDescription === null ||
        item.longDescription === undefined ||
        item.handoverImage === '' ||
        item.handoverImage === null ||
        item.handoverImage === undefined ||
        item.isFeatureDisable === undefined ||
        item.isFeatureDisable === null ||
        item.isFeatureDisable === true
    );
}
const appConfig = new AppConfigurationService();

function createUrl() {
    const url = `${DSL_BASE_URL +
        DSL_API_PATH.VIN_LOOKUP_NEWSERVICE}?language=${
        appConfig.currentLanguageRegionCode
    }&brand=${appConfig.brand}`;
    return url;
}

export function useHandoverFeatures(
    selectedVin: string,
    profileData: ProfileWithVehiclesResponse | undefined
) {
    const [categoryData, setCategoryData] = useState<any>();

    async function loadVinContent() {
        if (
            selectedVin != undefined &&
            selectedVin != '' &&
            profileData != undefined
        ) {
            const response = await vinHandoverService.getVinContent(
                createUrl(),
                selectedVin
            );
            const data = {
                vehicleDetails: {
                    vin: selectedVin,
                    modelDescription: response.modelDescription,
                    modelYear: response.modelYear,
                    vehicleImage: response.vehicleImage,
                },
                features: getFilteredCategoryMap(response.features),
            };

            handoverService.removeFromSessionStorage('vinLookupResponse');
            handoverService.setDataToSessionStorage('vinLookupResponse', data);

            setCategoryData(data.features);
        }
    }
    useEffect(() => {
        if (profileData != undefined && selectedVin == '') {
            const localStoreVin = localStorageWrapper.getItem('USER_VIN') || '';
            selectedVin = localStoreVin;
        }
        loadVinContent();
    }, [selectedVin, profileData]);

    return categoryData;
}
