import React, { useEffect, KeyboardEvent, FormEvent, useState } from 'react';
import playButton from '../../../../assets/play.png';
import { KEYBOARD_KEYS } from '../../../../constants';

interface Props {
    videoDetails: {
        ytVideoID: string;
        handoverImage: string;
        headline: string;
    };
}

declare global {
    interface Window {
        YT: any;
        onYouTubeIframeAPIReady: any;
    }
}

export const HandoverVideo = (props: Props) => {
    const { videoDetails } = props;
    const [playVideo, setPlayVideo] = useState(false);
    const [initVideo, setInitVideo] = useState(false);
    const [videoId, setVideoId] = useState(videoDetails.ytVideoID);

    const [player, setPlayer] = useState<any | null>(null);

    let videoPlayById = '';

    if (videoId.indexOf('#') != -1) {
        videoPlayById = videoId.substring(0, videoId.indexOf('#'));
    } else {
        videoPlayById = videoId;
    }

    if (videoId !== videoDetails.ytVideoID) {
        setVideoId(videoDetails.ytVideoID);
    }
    const initYTApi = () => {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        if (firstScriptTag && firstScriptTag.parentNode) {
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    };

    const onPlayerError = () => false;

    const onPlayerReady = () => false;

    const createPlayer = () => {
        return new window.YT.Player('player', {
            videoId: videoPlayById,
            events: {
                onError: onPlayerError,
                onReady: onPlayerReady,
            },
        });
    };

    const attachPlayerEvents = () => {
        setPlayer(createPlayer);
        setInitVideo(true);
    };

    const waitForYTApi = () => {
        if (window.YT && window.YT.loaded) {
            attachPlayerEvents();
        } else {
            window.onYouTubeIframeAPIReady = attachPlayerEvents;
        }
    };

    const destroyPlayer = () => {
        if (player && player.stopVideo) {
            player.stopVideo();
            player.destroy();
        }
        setPlayVideo(false);
    };

    const handlePlayButton = (event: FormEvent) => {
        if (videoId) {
            event.preventDefault();
            setPlayVideo(true);
            player?.playVideo();
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ENTER) {
            handlePlayButton(event);
        }
    };

    const handoverImgUrl = videoDetails.handoverImage.startsWith('http')
        ? videoDetails.handoverImage
        : process.env.REACT_APP_AEM_BASE_URL + videoDetails.handoverImage;

    const handoverImgSrc = videoDetails.handoverImage
        ? handoverImgUrl
        : 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';

    useEffect(() => {
        initYTApi();
        waitForYTApi();
        return () => destroyPlayer();
    }, [videoId]);

    useEffect(() => {
        if (player && initVideo && player.cueVideoById) {
            player.cueVideoById(videoId);
        }
    }, [videoId, player, initVideo]);

    return (
        <>
            <div
                className={`video-thumbnail ${
                    playVideo ? 'hide-video-asset' : ''
                }`}
            >
                <img
                    alt={videoDetails.headline + 'image'}
                    className={'detail-image-eyf'}
                    src={handoverImgSrc}
                />
                <div
                    className={`video-thumbnail-icon ${videoId ? '' : 'hide'}`}
                    onClick={handlePlayButton}
                    onKeyUp={handleKeyUp}
                >
                    <img src={playButton} alt="video-play-icon" />
                </div>
            </div>

            <div
                className={`dash-video-iframe dash-embed-responsive ${
                    !playVideo ? 'hide-video-asset' : ''
                }`}
            >
                <div id="player" />
            </div>
        </>
    );
};

export default HandoverVideo;
