export interface AppEnvironmentConfiguration {
    countryConfigurations: CountryConfiguration[];
}

export interface CountryConfiguration {
    name: string;
    countryCode: string;
    threeLetterCountryCode: string;
    fmaUrl: string;
    fmaRegion: string;
    apimUrl: string;
    analyticsEmbedScriptUrl: string;
    regionConfigurations: RegionConfiguration[];
    includeDomainsInLanguageSelector?: string[];
}

export interface RegionConfiguration {
    domain: string;
    root: string;
    language: string;
    langScript: string;
    languageRegionCode: string;
    blockSearchIndexing: boolean;
    brand: string;
    languageSetId: number;
    mouseflowId?: string;
    medalliaCommentCardId?: number;
    enableDebugLogs?: boolean;
    languageISOCode?: string;
}

export interface AppConfiguration {
    environment: string;
    countryName: string;
    countryCode: string;
    threeLetterCountryCode: string;
    domain: string;
    root: string;
    languageName: string;
    langScript: string;
    languageRegionCode: string;
    fmaUrl: string;
    fmaRegion: string;
    apimUrl: string;
    analyticsEmbedScriptUrl: string;
    blockSearchIndexing: boolean;
    includeDomainsInLanguageSelector?: string[];
    brand: string;
    languageSetId: number;
    mouseflowId?: string;
    medalliaCommentCardId?: number;
    enableDebugLogs?: boolean;
    languageISOCode?: string;
}

export const fallbackAppConfiguration: AppConfiguration = {
    environment: 'fallback',
    countryName: 'USA',
    countryCode: 'us',
    threeLetterCountryCode: 'usa',
    domain: 'localhost:8080',
    root: '/',
    languageName: 'English',
    langScript: 'LATN',
    languageRegionCode: 'en-us',
    fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
    apimUrl: 'https://stg.api.mps.ford.com',
    analyticsEmbedScriptUrl:
        '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
    fmaRegion: 'na',
    blockSearchIndexing: true,
    brand: 'ford',
    languageSetId: 1,
    enableDebugLogs: true,
    languageISOCode: 'ENG',
};

export { appConfigurationProd } from './environments/app-configuration-prod';
export { appConfigurationQa } from './environments/app-configuration-qa';
export { appConfigurationDev } from './environments/app-configuration-dev';
export { appConfigurationLocal } from './environments/app-configuration-local';
export { appConfigurationUat } from './environments/app-configuration-uat';
export { appConfigurationMock } from './environments/app-configuration-mock';
export { appConfigurationMothersite } from './environments/app-configuration-ms';
