import React, { useEffect, useState } from 'react';
import {
    RedeemRebatesOptions,
    RedeemRebatesBanner,
    ViewTemplate,
    RedeemRebatesBannerDetails,
    RedeemRebatesTabs,
    Breadcrumbs,
} from '../../components/sections';
import { useRedeemRebatesTopBannerContent } from '../../components/sections/redeem-rebates/hooks/use-redeem-rebates-top-banner-content';
import { SecondaryButton } from '../../components/common';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useLiveChatHelpCardsContent } from '../../components/sections/tirefinder/hooks/use-livechat-helpcard';
import { LiveChatSearchBar } from '../../components/sections/search-bar/live-chat-search-bar';

import './redeem-rebates-view.scss';
import { useAnalytics } from '../../hooks/use-analytics';

export const RedeemRebatesView = () => {
    const [isFord, setFord] = useState(true);
    const searchContent = useSearchContent();
    const helpCardsContent = useLiveChatHelpCardsContent();
    const rebatesContent = useRedeemRebatesTopBannerContent();
    const appInformationContent = useAppInformationContent();
    const page: any = 'REDEEM REBATES';
    const [fireEvents] = useAnalytics();
    useEffect(() => {
        const { brand } = new AppConfigurationService();
        if (brand === 'lincoln') {
            setFord(false);
        }
    }, []);
    return (
        <div className="whole-rebates">
            {appInformationContent && rebatesContent && (
                <ViewTemplate
                    seoConfig={{
                        pageTitle: 'REDEEM REBATES',
                        applicationName: appInformationContent.applicationName,
                    }}
                    page={page}
                    hideSearchBar={true}
                    hideLinksList={true}
                >
                    <div className="redeem-rebates-whole">
                        <Breadcrumbs
                            currentPage="Redeem Rebates"
                            type="common"
                        />
                        <div className="redeem-rebates-banner">
                            <RedeemRebatesBanner
                                topComponent={
                                    <RedeemRebatesBannerDetails
                                        bannerTitle={rebatesContent.bannerTitle}
                                        bannerDescription={
                                            rebatesContent.bannerDescription
                                        }
                                        href={rebatesContent.ctaLink}
                                        linkText={rebatesContent.linkText}
                                    />
                                }
                                backgroundImageDesktop={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    rebatesContent.redeemRebatesBackgroundImageDesktop
                                }
                                backgroundImageMobile={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    rebatesContent.redeemRebatesBackgroundImageMobile
                                }
                            />
                        </div>
                        <div className="redeem-rebates-method">
                            <p>{rebatesContent.redeemInfo}</p>
                        </div>
                        <RedeemRebatesOptions
                            onwebimage={rebatesContent.onWebImage}
                            onmailimage={rebatesContent.onMailImage}
                            onwebtitle={rebatesContent.onWebTitle}
                            onwebdescription={rebatesContent.onWebDescription}
                            onmailtitle={rebatesContent.onMailTitle}
                            onmaildescription={rebatesContent.onMailDescription}
                        />
                        <div className="redeem-rebates-us">
                            <p>{rebatesContent.usOffersDescription}</p>
                        </div>
                        <div className="buttons-secondary-building-blo">
                            <SecondaryButton
                                link={rebatesContent.viewCouponsOffersLink}
                                onClick={() => {
                                    fireEvents(
                                        'redeem-rebates-cta-onclick-event',
                                        undefined,
                                        {
                                            ctaType:
                                                'view all coupons and offers',
                                        },
                                        false
                                    );
                                }}
                            >
                                {rebatesContent.viewCouponsOffersText}
                            </SecondaryButton>
                        </div>
                        <RedeemRebatesTabs />
                    </div>
                    {isFord && searchContent && helpCardsContent && (
                        <LiveChatSearchBar
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                </ViewTemplate>
            )}
        </div>
    );
};
export default RedeemRebatesView;
