import ServiceHandler from '../service-handler';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import { notify } from '../slack-message-service/SlackMessageService';
import { RECALLS } from '../../support/support-constants';
import { getAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import {
    LANG_CODES_CARIBBEAN,
    LANG_CODES_CENTRAL_AMERICA,
} from '../../../src/support/support-constants';
import { AxiosResponse } from 'axios';

export interface RecallItems {
    description: string;
    descriptionLang: string;
    fsaNumber: string;
    nhtsaRecallNumber: string;
    remedyDescriptionLang?: string;
    safetyRiskDescriptionLang?: string;
    manufacturerRecallNumber: string;
    recallDescriptionLang: string;
    recallDate: string;
    remedyAvailable: string;
}

export interface RecallsApiResponse {
    successfulResponse: boolean;
    error: string;
    totalFsaCount: number;
    recallsCount: number;
    cspCount: number;
    vehicleDetails: {
        vin: string;
        make: string;
        model: string;
        year: number;
    };
    state: string;
    country: string;
    language: string;
    langScript: string;
    region: string;
    recalls: RecallItems[];
    fsa: RecallItems[];
    nhtsa: RecallItems[];
    ymm?: CampaignIdApiResponse[];
}

export interface CampaignIdApiResponse {
    localFsa: string;
    fsaType: string;
    fsaTypeDtl: string;
    fsaDesc: string;
    localFsaDescInEnglish: string;
    localFsaLaunchDate: string;
    localFsaDescLocal: string | null;
    govtRecallNumber: string;
    govtRecallDate: string;
    govtRecallDescEnglish: string;
    govtRecallSafetyRiskDescEnglish: string;
    govtRecallRemedyDescEnglish: string;
    govtRecallRemedyStatusFlag: string | null;
    govtRecallDescLocal: string | null;
    govtRecallSafetyRiskDescLocal: string | null;
    govtRecallRemedyDescLocal: string | null;
    govtRecallManufacturingDescLocal: string | null;
    vehicleLineCdAndYear: {};
    hub: string | null;
    error?: string;
}

export interface TradeInLinkResponse {
    tradeInLink: string;
}

export default class RecallsService {
    async request(vin: string): Promise<RecallsApiResponse> {
        const {
            current3LetterCountryCode,
            currentLangScript,
            currentLanguageCode,
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;

        const languageCodes = LANG_CODES_CARIBBEAN.CARIBBEAN_MARKETS_ENGLISH.includes(
            currentLanguageRegionCode
        )
            ? 'en-us'
            : currentLanguageRegionCode;

        const checkForLanguageCode =
            LANG_CODES_CENTRAL_AMERICA.CENTRAL_AMERICA_MARKETS_SPANISH.includes(
                currentLanguageRegionCode
            ) ||
            LANG_CODES_CARIBBEAN.CARIBBEAN_MARKETS_SPANISH.includes(
                currentLanguageRegionCode
            );

        const languageRegionCode = checkForLanguageCode
            ? 'es-mx'
            : languageCodes;
        const params = {
            country: current3LetterCountryCode,
            langscript: currentLangScript,
            language: currentLanguageCode,
            region: languageRegionCode,
        };
        const headers = { vin: vin };
        const recallsUrl = DSL_BASE_URL + DSL_API_PATH.RECALLS_VIN;
        const response = HttpService.get<RecallsApiResponse>(recallsUrl, true, {
            params,
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });

        return response.then(response => {
            let sendSlackAlert = false;
            sendSlackAlert = this.nhtsaResponse(
                response,
                sendSlackAlert,
                currentLanguageCode
            );
            response.data.recalls.forEach(recalls => {
                if (
                    recalls.descriptionLang === null ||
                    !recalls.descriptionLang
                ) {
                    recalls.descriptionLang = recalls.description;
                    sendSlackAlert = currentLanguageCode !== 'en';
                }
            });
            response.data.fsa.forEach(fsa => {
                if (fsa.descriptionLang === null || !fsa.descriptionLang) {
                    fsa.descriptionLang = fsa.description;
                    sendSlackAlert = currentLanguageCode !== 'en';
                }
            });
            sendSlackAlert &&
                notify(
                    `Recalls Description Language is blank for ${currentLanguageRegionCode}`,
                    RECALLS.SLACK_ALERT_RECALLS_AEM_API
                );
            return response.data;
        });
    }

    async getCampaignId(campaignId: string): Promise<CampaignIdApiResponse> {
        const {
            current3LetterCountryCode,
            currentLanguageRegionCode,
            brand,
        } = ServiceHandler.AppConfigurationService;
        const params = {
            campaignId,
            country: current3LetterCountryCode,
            brand: brand,
            region: currentLanguageRegionCode,
        };

        const campaignIdUrl = DSL_BASE_URL + DSL_API_PATH.CAMPAIGN_ID;
        const response = HttpService.get<CampaignIdApiResponse>(
            campaignIdUrl,
            true,
            {
                params,
            }
        );
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(response => {
            return response.data;
        });
    }

    async requestYmm(
        modelSeoKey: string,
        modelYr: string
    ): Promise<RecallsApiResponse> {
        const {
            currentRegionCode,
            current3LetterCountryCode,
            currentLanguageRegionCode,
            brand,
        } = ServiceHandler.AppConfigurationService;
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            modelYr,
            modelSeoKey,
            'SEO_KEY'
        );
        const model = vehicleYmm?.model;
        const params = {
            model,
            modelYr,
            country: current3LetterCountryCode.toUpperCase(),
            brand: brand,
            region: currentLanguageRegionCode,
        };
        const ymmUrl = DSL_BASE_URL + DSL_API_PATH.RECALLS_YMM;
        const response = HttpService.get<RecallsApiResponse>(ymmUrl, true, {
            params,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(response => {
            return response.data;
        });
    }

    async getRecallsTradeInUrl(
        currentDomain: string
    ): Promise<TradeInLinkResponse> {
        const tradeInUrl = DSL_BASE_URL + DSL_API_PATH.TRADE_IN_LINK;

        const {
            currentCountryCode,
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const params = {
            countryCode: currentCountryCode,
            language: currentLanguageRegionCode,
            referrerUrl: currentDomain,
        };

        const response = HttpService.get<TradeInLinkResponse>(
            tradeInUrl,
            true,
            {
                params,
            }
        );

        response.catch(error => {
            console.error(error);
            return;
        });

        return response.then(response => {
            return response.data;
        });
    }

    private nhtsaResponse(
        response: AxiosResponse<RecallsApiResponse>,
        sendSlackAlert: boolean,
        currentLanguageCode: string
    ) {
        response.data.nhtsa.forEach(nhtsa => {
            if (nhtsa.descriptionLang === null || !nhtsa.descriptionLang) {
                nhtsa.descriptionLang = nhtsa.description;
                sendSlackAlert = currentLanguageCode !== 'en';
            }
        });
        return sendSlackAlert;
    }
}
