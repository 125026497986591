import React, { useEffect, useState, useContext } from 'react';
import './trailer-checklist-detail.scss';
import {
    TrailerChecklistDetail,
    TrailerType,
} from '../../../../models/experiencefragments/trailer-checklist';
import { AemArticles } from '../../../../models/experiencefragments/category-view';
import { ServiceAndOffers } from '../service-and-offers/service-and-offers';
import { Accordion, PrimaryButton, SecondaryButton } from '../../../common';
import ServerContext from '../../../../contexts/serverContext';
import { Breadcrumbs } from '../../breadcrumbs/breadcrumbs';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import SupportFdsWrapper from '../../../../support/components/support-fds-wrapper/support-fds-wrapper';
import { useTrailerChecklistExperienceContent } from '.././hooks/use-trailer-checklist-experience-content';
import { findByAlias } from '../../../../routes';

import { ViewTemplate } from '../../view-template/view-template';
import { CacheService } from '../../../../services/cache-service/cache-service';
import isWebviewCheck from '../../../utils/webviewCheck/webviewCheck';
import { useAnalytics } from '../../../../hooks/use-analytics';

interface Props {
    pageType: TrailerType;
    trailerChecklistDetail: TrailerChecklistDetail;
    serviceOffersSection: AemArticles;
}

interface BreadCrumbProps {
    currentRoot: string;
    path: string;
    breadCrumbTitle: string;
}
const TrailerCheckListDetailBreadCrumb = (props: BreadCrumbProps) => {
    return (
        <>
            <a
                className="breadcrumb-link"
                href={`${props.currentRoot}${props.path}`}
                aria-label={`Click to goto ${props.breadCrumbTitle} page`}
            >
                <span>{props.breadCrumbTitle}</span>
            </a>
            <span className="back-slash">{'>'}</span>
        </>
    );
};

export const TrailerCheckListDetail = (props: Props) => {
    const {
        pageTitle,
        accordionList,
        accordionMarkCompleteCTA,
        lightCheckAccordionTitle,
        lightCheckDescription,
        lightCheckSubDescription,

        lightCheckAccordionImagePath,
        resetCTA,
    } = props.trailerChecklistDetail;
    const trailerChecklistContent = useTrailerChecklistExperienceContent();
    const landing = trailerChecklistContent?.trailerChecklist?.landing;
    const [breadcrumbToggle, setBreadCrumbToggle] = useState(false);
    const serverContext = useContext(ServerContext);
    const path = `${findByAlias('TrailerChecklistView')}${
        serverContext.webview === 'true' ? '?webview=true' : ''
    }`;
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const trailerChecklistDetailBreadCrumb = landing?.trailerChecklistHeadline?.toLowerCase();
    const trailerChecklistDetailBreadCrumbTitle = trailerChecklistDetailBreadCrumb?.replace(
        /(?<=^|\s)\w/g,
        letter => letter.toUpperCase()
    );
    const cacheService = new CacheService();
    const TRAILER_CHECKLIST_CACHE = 'trailerChecklist';
    const page = 'Trailer Checklist Detail';
    const accordionStorage = (
        cacheService.getFromCache(TRAILER_CHECKLIST_CACHE, props.pageType) || []
    ).sort();
    const [accordionsChecked, setAccordionsChecked] = useState<string[]>(
        accordionStorage
    );
    const getOpenAccordion = () => {
        if (!Array.isArray(accordionList) || accordionList?.length <= 0) {
            return 0;
        }
        let i = 0;
        while (accordionStorage.indexOf((i++).toString()) >= 0);
        return i - 1;
    };
    const isWebview = isWebviewCheck();

    const [nextAccordion, setNextAccordion] = useState<number>(
        getOpenAccordion()
    );
    const [fireEvents] = useAnalytics();

    const triggerMarkCompleteOnClickEvent = (ctaContext: string) => {
        const markCompleteName = ctaContext
            .split(' ')
            .join('')
            .toLowerCase();

        switch (pageTitle) {
            case 'Conventional Trailer':
                fireEvents(
                    ['trailer-checklist-conventional-cta-onclick-event'],
                    undefined,
                    {
                        trailerChecklistConventionalCta: markCompleteName,
                    },
                    false
                );
                break;
            case 'Gooseneck Trailer':
                fireEvents(
                    ['trailer-checklist-gooseneck-cta-onclick-event'],
                    undefined,
                    {
                        trailerChecklistGooseneckCta: markCompleteName,
                    },
                    false
                );
                break;
            case 'Fifth Wheel Trailer':
                fireEvents(
                    ['trailer-checklist-fifthwheel-cta-onclick-event'],
                    undefined,
                    {
                        trailerChecklistFifthWheelCta: markCompleteName,
                    },
                    false
                );
                break;
            default:
                break;
        }
    };

    const triggerResetOnClickEvent = () => {
        switch (pageTitle) {
            case 'Conventional Trailer':
                fireEvents(
                    ['trailer-checklist-conventional-reset-onclick-event'],
                    undefined,
                    {
                        trailerChecklistConventionalReset: resetCTA.toLowerCase(),
                    },
                    false
                );
                break;
            case 'Gooseneck Trailer':
                fireEvents(
                    ['trailer-checklist-gooseneck-reset-onclick-event'],
                    undefined,
                    {
                        trailerChecklistGooseneckReset: resetCTA.toLowerCase(),
                    },
                    false
                );
                break;
            case 'Fifth Wheel Trailer':
                fireEvents(
                    ['trailer-checklist-fifthwheel-reset-onclick-event'],
                    undefined,
                    {
                        trailerChecklistFifthWheelReset: resetCTA.toLowerCase(),
                    },
                    false
                );
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isMarked = (index: number) => {
        return accordionsChecked?.indexOf(index.toString()) >= 0;
    };
    const getChecklistHeader = (
        trailerCheckcklistIndex: number,
        trailerCheckcklistHeader: string
    ) => {
        return (
            <div className={'trailer-checklist-accordion-header'}>
                <span
                    className={`trailer-checklist-accordion-circle ${
                        isMarked(trailerCheckcklistIndex) ? 'tick' : 'number'
                    }`}
                >
                    <span className={'trailer-checklist-accordion-number'}>
                        {trailerCheckcklistIndex + 1}
                    </span>
                </span>
                <span className={'trailer-checklist-accordion-title'}>
                    {trailerCheckcklistHeader}
                </span>
            </div>
        );
    };
    const accordionCollapse = (index: number) => {
        const expandPanel = `trailer-checklist-panel-trailer-checklist-accordion-${index} `;
        document.getElementById(expandPanel)?.classList.remove('expanded');
        const chevron = document.getElementById(
            `accordion-trailer-checklist-accordion-${index}`
        );
        chevron?.querySelector('.fds-chevron')?.classList.remove('up');
        chevron?.querySelector('.fds-chevron')?.classList.add('down');
    };

    const markComplete = (index: number, accordionName: string) => {
        const accordionIndexes = [
            ...(accordionsChecked || []),
            index.toString(),
        ];
        cacheService.putInCache(TRAILER_CHECKLIST_CACHE, {
            key: props.pageType,
            data: accordionIndexes,
        });
        setAccordionsChecked(accordionIndexes);
        setNextAccordion(index + 1);
        accordionCollapse(index);
        triggerMarkCompleteOnClickEvent(accordionName);
    };

    const getLandingPageAccordionDetails = (
        accordionpanelimage: any,
        index: number,
        accordionName: string
    ) => {
        return (
            <>
                <div className="trailer-checklist-panel-details">
                    <img
                        alt={`${pageTitle}-accordion-panel-image`}
                        src={`${process.env.REACT_APP_AEM_BASE_URL}${accordionpanelimage}`}
                        className={'accordion-panel-image'}
                        aria-label={`${pageTitle}-accordion-panel-image`}
                        loading="lazy"
                    />
                    <PrimaryButton
                        color={'light'}
                        fill={'fill'}
                        disabled={isMarked(index)}
                        chevron={false}
                        onClick={() => markComplete(index, accordionName)}
                        className="trailer-checklist-mark-button"
                    >
                        {accordionMarkCompleteCTA}
                    </PrimaryButton>
                </div>
            </>
        );
    };

    const renderLightCheckPanelDetails = () => {
        return (
            <>
                <div className="trailer-checklist-panel-details">
                    <img
                        alt={`${pageTitle}-accordion-panel-image`}
                        src={`${process.env.REACT_APP_AEM_BASE_URL}${lightCheckAccordionImagePath}`}
                        className="light-check-image"
                        aria-label={`${pageTitle}-accordion-panel-image`}
                    />
                    <div
                        className="light-check-description"
                        dangerouslySetInnerHTML={{
                            __html: lightCheckDescription,
                        }}
                    />
                    <div
                        className="light-check-sub-description"
                        dangerouslySetInnerHTML={{
                            __html: lightCheckSubDescription,
                        }}
                    />
                </div>
            </>
        );
    };
    const lightCheckHeader = () => {
        return (
            <div className={'trailer-checklist-accordion-header'}>
                <img
                    alt={`${pageTitle}-accordion-panel-image`}
                    src={`${process.env.REACT_APP_AEM_BASE_URL}${lightCheckAccordionImagePath}`}
                    aria-label={`${pageTitle}-accordion-panel-image`}
                    className={'light-check-circle'}
                />
                <span className={'trailer-checklist-accordion-title'}>
                    {lightCheckAccordionTitle}
                </span>
            </div>
        );
    };
    const renderTrailerCheckAccordion = () => {
        return (
            <>
                <Accordion
                    expandPanel={accordionList.length === nextAccordion}
                    key={accordionList.length}
                    header={lightCheckHeader()}
                    panel={renderLightCheckPanelDetails()}
                    className={'trailer-checklist-accordion'}
                    index={accordionList.length.toString()}
                    expandMultiplePanels={false}
                    borderBottom={true}
                    accordionName={'trailer-checklist-panel'}
                />
            </>
        );
    };

    const trailerchecklistReset = () => {
        setAccordionsChecked([]);
        setNextAccordion(0);
        cacheService.evictFromCache(TRAILER_CHECKLIST_CACHE, props.pageType);
        triggerResetOnClickEvent();
    };
    return (
        <ViewTemplate
            seoConfig={{
                pageTitle: page,
                applicationName: '',
            }}
            page={page}
            className="trailer-checklist-view"
            hideLinksList={true}
            hideSearchBar={isWebview}
            showDisclosureContent={isWebview ? false : true}
        >
            <>
                <SupportFdsWrapper>
                    <div
                        className={`breadcrumbAccordion ${breadcrumbToggle &&
                            'expanded'}`}
                    >
                        <Breadcrumbs
                            currentPage={pageTitle}
                            hashId="support"
                            hideFirstBreadcrumb={isWebview}
                        >
                            {
                                <TrailerCheckListDetailBreadCrumb
                                    currentRoot={currentRoot}
                                    path={path}
                                    breadCrumbTitle={
                                        trailerChecklistDetailBreadCrumbTitle
                                    }
                                />
                            }
                        </Breadcrumbs>
                        {
                            <div
                                onClick={() =>
                                    setBreadCrumbToggle(!breadcrumbToggle)
                                }
                            >
                                <FdsChevron
                                    direction={breadcrumbToggle ? 'up' : 'down'}
                                    type={'unfilled'}
                                ></FdsChevron>
                            </div>
                        }
                    </div>
                </SupportFdsWrapper>
                <section className="trailer-checklist-detail-page">
                    <section className="trailer-checklist-heading-section">
                        <h1 className="trailer-checklist-header">
                            {pageTitle}
                        </h1>
                        {resetCTA && (
                            <div className="reset-cta-container">
                                <SecondaryButton
                                    color={'light'}
                                    noChevron={true}
                                    disabled={accordionsChecked.length === 0}
                                    className={`trailer-checklist-reset-button`}
                                    onClick={trailerchecklistReset}
                                >
                                    {resetCTA}
                                </SecondaryButton>
                            </div>
                        )}
                    </section>
                    {accordionList.map((item, index) => {
                        const expandAccordion =
                            nextAccordion === index && !isMarked(index);
                        return (
                            <Accordion
                                expandPanel={expandAccordion}
                                key={item.accordionItemTitle}
                                header={getChecklistHeader(
                                    index,
                                    item.accordionItemTitle
                                )}
                                panel={getLandingPageAccordionDetails(
                                    item.imagePath,
                                    index,
                                    item.accordionItemTitle
                                )}
                                className={'trailer-checklist-accordion'}
                                index={index.toString()}
                                borderBottom={true}
                                accordionName={'trailer-checklist-panel'}
                            />
                        );
                    })}
                    <div
                        id="trailer-light-check"
                        onClick={() =>
                            triggerMarkCompleteOnClickEvent(
                                lightCheckAccordionTitle
                            )
                        }
                    >
                        {renderTrailerCheckAccordion()}
                    </div>
                </section>
                {isWebview ? (
                    ''
                ) : (
                    <ServiceAndOffers
                        serviceOffersSection={props.serviceOffersSection}
                    />
                )}
            </>
        </ViewTemplate>
    );
};
