import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { CacheService } from '../cache-service/cache-service';
import AuthenticationService from '../authentication-service/authentication-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { SESSION_KEY_API_CACHE } from '../../constants';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { ServerSideService } from '../server-side-service/server-side-service';
import { AppConfiguration } from '../../app-configuration/app-configuration';
import axiosRetry from 'axios-retry';
import { BrandUtil } from '../../components/utils/brand-util/brand-util';

export default class HttpService {
    private static cacheService: CacheService = new CacheService();
    private static appConfigService: AppConfigurationService = new AppConfigurationService();
    private static axiosCustomInstance(
        cachingEnabled: boolean,
        ttlMillis?: number,
        retryApiFlag = true
    ): AxiosInstance {
        const axiosInstance = Axios.create();
        axiosInstance.interceptors.request.use((config: any) => {
            if (
                new ServerSideService().isServerSide() &&
                (config.url!.includes('dsl') ||
                    config.url!.includes('digitalservices'))
            ) {
                if (process.env.REACT_APP_FORD_NO_PROXY !== 'true') {
                    const httpsAgent = new HttpsProxyAgent(
                        process.env.http_proxy || 'http://internet.ford.com:83'
                    );
                    config.httpsAgent = httpsAgent;
                }
                config.headers = Object.assign(config.headers, {
                    Origin: 'ford.com',
                });
            }

            return config;
        });

        if (cachingEnabled) {
            axiosInstance.interceptors.response.use((data: AxiosResponse) => {
                let key = data.config.url;
                const vin = data?.config?.headers?.vin ?? '';

                if (data.config.params) {
                    key += this.buildQueryParams(data.config.params);
                }
                key = vin && !key?.includes(`${vin}`) ? `${key}~${vin}` : key;

                if (key) {
                    HttpService.cacheService.putInCache(
                        SESSION_KEY_API_CACHE,
                        {
                            key,
                            data: data.data,
                        },
                        ttlMillis
                    );
                }
                return data;
            });
        }
        retryApiFlag && axiosRetry(axiosInstance, { retries: 3 });
        return axiosInstance;
    }

    static axios(): AxiosInstance {
        return this.axiosCustomInstance(false);
    }

    static get<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        cacheable: boolean,
        config?: AxiosRequestConfig,
        ttlMillis?: number,
        retryApiFlag?: boolean
    ): Promise<R> {
        if (cacheable) {
            let url = config?.params
                ? apiUrl + this.buildQueryParams(config.params)
                : apiUrl;
            if (
                config?.headers?.vin &&
                !url?.includes(`${config?.headers?.vin}`)
            ) {
                url += `~${config?.headers?.vin}`;
            }
            const cachedResponse = HttpService.cacheService.getFromCache(
                SESSION_KEY_API_CACHE,
                url,
                ttlMillis
            );

            return cachedResponse
                ? Promise.resolve({ data: cachedResponse } as any)
                : this.axiosCustomInstance(
                      cacheable,
                      ttlMillis,
                      retryApiFlag
                  ).get(apiUrl, config);
        } else {
            return this.axiosCustomInstance(
                cacheable,
                ttlMillis,
                retryApiFlag
            ).get(apiUrl, config);
        }
    }

    static post<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance(false).post(apiUrl, data, config);
    }

    static delete<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance(false).delete(apiUrl, config);
    }

    static put<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance(false).put(apiUrl, data, config);
    }

    static buildQueryParams(params: any) {
        const qs: string[] = [];
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                qs.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        params[key]
                    )}`
                );
            }
        }
        return `?${qs.join('&')}`;
    }

    static getAppIdRequestHeaders(appConfig?: AppConfiguration) {
        return this.buildConsumerKeyHeader(appConfig);
    }

    static getAppIdAndAuthTokenRequestHeaders() {
        const authService = new AuthenticationService();
        return Object.assign(
            {
                'Auth-Token': authService.getCatBundle()?.access_token,
            },
            this.buildConsumerKeyHeader()
        );
    }

    private static buildConsumerKeyHeader(appConfig?: AppConfiguration) {
        const config = appConfig || this.appConfigService.getAppConfiguration();
        const brandUtil = new BrandUtil();
        const { brand, fmaRegion } = config;
        return {
            'Consumer-Key': Buffer.from(
                `go-${fmaRegion || 'na'}-${brandUtil.getBrandName(brand)}`
            ).toString('base64'),
        };
    }
}
