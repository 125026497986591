import React, { useContext, useEffect, useState } from 'react';
import './handover-details.scss';
import { AdditionalFeatures } from '../handover-details/Additional-Features';

import { Footerchecklist } from '../footer-checklist/footer-checklist';
import { HandoverCheckbox } from '../handover-checkbox/handover-checkbox';
import { HandoverVideo } from '../handover-video/handover-video';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { useHandoverModel } from '../hooks/use-vehicle-handover-model';
import { useParams } from 'react-router-dom';
import { VehicleFeature } from '../../../../models/handover-data';
import Disclosure from '../disclosure/disclosure';
import handoverService from '../../../../services/handover/handover-service';
import ClientContext from '../../../../contexts/clientContext';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import { Handover_Constants } from '../handover-constants';
import { HandoverBreadCrumbs } from '../handover-breadcrumbs/handover-breadcrumbs';

export const HandoverDetails = () => {
    const profileData = useContext(ClientContext)
        .profile as ProfileWithVehiclesResponse;
    let dataFromCache: any;
    const postHandover = handoverService.getDataFromSessionStorage(
        'isPostHandoverState'
    );
    const vinLookupResponse = handoverService.getDataFromSessionStorage(
        'vinLookupResponse'
    );
    const vehicleDetails = vinLookupResponse
        ? vinLookupResponse.vehicleDetails
        : [];
    const { view } = useParams<{ view: string }>();
    const selectedVin = handoverService.fetchSelectedVin();
    const [isScrollTop, setisScrollTop] = useState<boolean>(true);
    const result = handoverService.getSelectedFeaturesCount() || [];
    const featureLength: number = result;
    const [featureFlag, setFeatureFlag] = useState(result > 0 ? result : 0);
    const [otherFeaturesList, setOtherFeaturesList] = useState<any>([]);
    const [selectedFeature, setSelectedFeature] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [categoryCards, setCategoryCards] = useState<any>([]);
    const [categoryDataFromCache, setCategoryDataFromCache] = useState<any>();

    const [fireEvents] = useAnalytics(),
        triggerLoadAnalytics = (vehicleData: any) => {
            fireEvents(
                ['target-trigger-view', 'Eyf-details-page-load'],
                'Eyf-details-page-load',
                {
                    ymm: Promise.resolve(vehicleData),
                    categoryDisplayName: dataFromCache.selectedCategoryData.mycategory
                        .replace(/_/g, '')
                        .toUpperCase(),
                    featureName: dataFromCache.selectedCategoryData.data.headline.toUpperCase(),
                    namePlateValue: vehicleData.model,
                    fieldName: postHandover ? 'post handover' : 'pre handover',
                }
            );
        };

    useEffect(() => {
        if (isScrollTop) {
            window.scrollTo(0, 0);
        }
    }, [isScrollTop]);

    const handleChange = (
            newValue: number,
            checkboxstate: boolean,
            _id: string,
            category: string,
            featureNameByCategory: string
        ) => {
            setisScrollTop(false);
            setFeatureFlag(newValue);
            let vehicleModel;
            dataFromCache = handoverService.getDataFromSessionStorage(
                'handover-details'
            );
            const vehicleData = dataFromCache ? dataFromCache.vehicleData : '';
            if (vehicleData) {
                vehicleModel = vehicleData[0].model;
            } else {
                if (profileData && selectedVin && selectedVin !== '') {
                    const selectedVehicleData: any = profileData.vehicles.find(
                        vehicle => vehicle.vin === selectedVin
                    );
                    if (selectedVehicleData) {
                        vehicleModel = selectedVehicleData.modelName;
                    } else {
                        vehicleModel = vehicleDetails.modelDescription;
                    }
                }
            }

            fireEvents(
                ['handover-details-checkbox'],
                undefined,
                {
                    categoryDisplayName: category
                        .replace(/_/g, '')
                        .toUpperCase(),
                    featureName: featureNameByCategory.toUpperCase(),
                    detailsCheckboxState: checkboxstate
                        ? 'add to checklist'
                        : 'remove from checklist',
                    namePlateValue: vehicleModel,
                },
                false
            );
        },
        i18n = useHandoverModel(),
        addToHandover = i18n.get('addToHandover');

    window.addEventListener('popstate', () => {
        handoverService.removeFromSessionStorage('handover-details');
    });

    const renderDetailsSection = (featureSelectByUser: VehicleFeature) => {
        return (
            <div className="handover-details">
                <div className="details-wrapper">
                    <HandoverVideo videoDetails={featureSelectByUser} />

                    <div className="video-description">
                        <h4
                            data-testid="feature-title"
                            className="short-description-eyf"
                        >
                            {featureSelectByUser.headline}
                        </h4>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: featureSelectByUser.longDescription,
                            }}
                        />

                        {postHandover == 'false' || !postHandover ? (
                            <section className="handover-checkbox">
                                <HandoverCheckbox
                                    label={addToHandover}
                                    type="add"
                                    id={featureSelectByUser.key}
                                    featuresFromCategory={[featureSelectByUser]}
                                    category={featureSelectByUser.category}
                                    onChange={handleChange}
                                    value={featureFlag}
                                    imgSrc={''}
                                    wersCode={
                                        featureSelectByUser.wersCode
                                            ? featureSelectByUser.wersCode
                                            : ''
                                    }
                                    detailsPage={true}
                                    featureName={featureSelectByUser.headline}
                                />
                            </section>
                        ) : null}
                    </div>
                </div>
                {featureFlag > 0 &&
                    (postHandover == 'false' || !postHandover) && (
                        <Footerchecklist
                            i18n={i18n}
                            featureLength={featureLength}
                            page={'featureDetails'}
                        />
                    )}
            </div>
        );
    };

    const updateCache = (updatedFeature: any) => {
        if (categoryDataFromCache) {
            handoverService.setDataToSessionStorage(
                Handover_Constants.Handover_Clicked_Feature_Data,
                updatedFeature
            );
            handoverService.setDataToSessionStorage('handover-details', {
                ...categoryDataFromCache,
                selectedCategoryData: {
                    ...categoryDataFromCache.selectedCategoryData,
                    data: updatedFeature,
                },
            });

            dataFromCache = handoverService.getDataFromSessionStorage(
                'handover-details'
            );
            const vehicleData = dataFromCache ? dataFromCache.vehicleData : '';
            if (vehicleData) {
                triggerLoadAnalytics(vehicleData[0]);
            } else {
                callToTriggerLoadAnalytics();
            }
        }
    };

    const callToTriggerLoadAnalytics = () => {
        if (profileData && selectedVin && selectedVin !== '') {
            const selectedVehicleData: any = profileData.vehicles.find(
                vehicle => vehicle.vin === selectedVin
            );

            if (selectedVehicleData) {
                const data = {
                    year: selectedVehicleData.modelYear,
                    make: selectedVehicleData.vehicleMake,
                    model: selectedVehicleData.modelName,
                    vin: selectedVehicleData.vin,
                };
                triggerLoadAnalytics(data);
            } else {
                const data = {
                    year: vehicleDetails.modelYear,
                    make: 'Ford',
                    model: vehicleDetails.modelDescription,
                    vin: vehicleDetails.vin,
                };
                triggerLoadAnalytics(data);
            }
        }
    };

    const getCategoryListOnViewChange = () => {
        const otherFeatures: any = [],
            decodedKey = decodeURIComponent(view);

        categoryCards.length &&
            categoryCards.map((feature: any) => {
                if (
                    feature.key.trim().toLowerCase() ===
                    decodedKey.toLowerCase()
                ) {
                    setSelectedFeature('');
                    setOtherFeaturesList([]);
                    setSelectedFeature(feature);
                } else {
                    otherFeatures.push(feature);
                }
            });
        otherFeatures.length > 0 && setOtherFeaturesList(otherFeatures);
    };

    const setOtherFeatureData = (data: any, key: string) => {
        const list: any = [];
        data.map((item: any) => {
            if (item.key.toLowerCase() !== key.toLowerCase()) {
                list.push(item);
            }
        });
        setOtherFeaturesList(list);
    };

    useEffect(() => {
        dataFromCache = handoverService.getDataFromSessionStorage(
            'handover-details'
        );
        if (dataFromCache && dataFromCache.selectedCategoryData) {
            setCategoryDataFromCache(dataFromCache);
            setCategoryCards(dataFromCache.selectedCategoryData.cards);
            setSelectedCategory(dataFromCache.selectedCategoryData.mycategory);
            setSelectedFeature(dataFromCache.selectedCategoryData.data);
            setOtherFeatureData(
                dataFromCache.selectedCategoryData.cards,
                dataFromCache.selectedCategoryData.data.key
            );
        }
    }, []);

    useEffect(() => {
        getCategoryListOnViewChange();
    }, [view]);
    useEffect(() => {
        updateCache(selectedFeature);
    }, [selectedFeature]);

    useEffect(() => {
        handoverService.updateCheckboxStateOnHandoverDetail();
    }, [renderDetailsSection]);

    useEffect(() => {
        const vehicleData = dataFromCache ? dataFromCache.vehicleData : '';
        if (vehicleData) {
            triggerLoadAnalytics(vehicleData[0]);
        } else {
            callToTriggerLoadAnalytics();
        }
    }, [dataFromCache]);

    return (
        <>
            <HandoverBreadCrumbs
                currentPage="featureDetails"
                featureName={selectedFeature ? selectedFeature.headline : ''}
                originFromVD={postHandover == '' ? false : postHandover}
            />

            {selectedFeature && renderDetailsSection(selectedFeature)}

            {selectedFeature && otherFeaturesList.length > 0 && (
                <>
                    <div className={'Other-features-eyf'}>
                        <AdditionalFeatures
                            selectedCategory={selectedCategory}
                            selectedCategoryList={categoryCards}
                            selectedFeature={selectedFeature.headline}
                            otherFeatures={otherFeaturesList}
                        />
                    </div>
                </>
            )}
            {selectedFeature && (
                <Disclosure
                    i18n={i18n}
                    featureDisclosure={selectedFeature.disclosure}
                />
            )}
        </>
    );
};

export default HandoverDetails;
