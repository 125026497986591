export const routesValues = [
    {
        path: '/service-maintenance/coupons-offers-rebates/',
        categoryName: '',
        filter: '',
    },
    {
        path: '/service-maintenance/coupons-offers-rebates/battery-coupons/',
        categoryName: 'Batteries',
        filter: 'Batteries',
    },
    {
        path: '/service-maintenance/coupons-offers-rebates/brake-coupons/',
        categoryName: 'Brakes',
        filter: 'Brakes',
    },

    {
        path:
            '/service-maintenance/coupons-offers-rebates/oil-change-and-maintenance-coupons/',
        categoryName: 'Oil change and maintenance',
        filter: 'Check-Up/Inspection/Seasonal',
    },
    {
        path: '/service-maintenance/coupons-offers-rebates/tire-coupons/',
        categoryName: 'Tires',
        filter: 'Tires',
    },
    {
        path:
            '/service-maintenance/coupons-offers-rebates/wiper-blade-coupons/',
        categoryName: 'Wiper Blades',
        filter: 'Wipers',
    },
];
export interface RewardsApiResponse {
    coupons: CouponTile[];
}

export interface CouponTile {
    couponId: string;
    offer: string;
    imageURLXLarge: string;
    disclaimer: string;
    commodityDescription: string;
    expiryDate: string;
    subCopy: string;
    displayPriority: string;
}

export interface CouponSeoAttributes {
    couponId: string;
    disclaimer: string;
    expiryDate: string;
    subCopy: string;
    offer: string;
    commodityDescription: string;
    pageTitle: string;
    sanitizedOffer: string;
    categoryName: string;
    metaDescription: string;
    staticTitlePlaceholder: string;
    staticTitleText: string;
}

export const subcategoryTitle = (
    details: string,
    title: any,
    value?: any,
    flag?: boolean
) => {
    switch (details) {
        case 'Brakes':
            title = 'Brakes';
            value = 'Brake coupons';
            break;
        case 'Battery':
            title = 'Batteries';
            value = 'Battery Coupons';
            break;
        case 'Wipers':
            title = 'Wiper Blades';
            value = 'Wiper Blades Coupons';
            break;
        case 'Tires':
            title = 'Tires';
            value = 'Tire Deals';
            break;
        case 'Works':
            title = 'Oil change and maintenance';
            value = 'Oil Change Coupons';
            break;
        default:
            title = ' ';
            break;
    }
    return flag ? [title, value] : title;
};
export const catagoryNameForURL = (details: string, value?: any) => {
    switch (details) {
        case 'Brakes':
            value = 'brake-coupons';
            break;
        case 'Battery':
            value = 'battery-coupons';
            break;
        case 'Wipers':
            value = 'wiper-blade-coupons';
            break;
        case 'Tires':
            value = 'tire-coupons';
            break;
        case 'Works':
            value = 'oil-change-and-maintenance-coupons';
            break;
        default:
            value = 'all-savings';
            break;
    }
    return value;
};
export const transform = (node: any) => {
    if (node.type === 'tag' && node.name === 'script') {
        return null;
    }
};
export const sanitizeHtmlTextOptions = {
    decodeEntities: true,
    transform,
};
