import React from 'react';
import { BreadcrumbWrapper } from '../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import { ViewTemplate } from '../../components/sections';
import { DiagnosticsDetails } from '../../components/sections/vehicle-health/components/diagnostics/diagnostics-details';
import { useVehicleHealthPageComponents } from '../../components/sections/vehicle-health/components/vehicle-health-page-components';
import { useDiagnosticsDetailsContent } from '../../components/sections/vehicle-health/hook/use-diagnostics-details-content';
import { useLocation } from 'react-router-dom';

interface BreadCrumbProps {
    vehicleHealthComponent: any;
    currentRoute: string;
}

const VhaAlertBreadCrumb = (props: BreadCrumbProps) => {
    const vhaUrl = props.currentRoute.endsWith('/')
        ? props.currentRoute.substring(0, props.currentRoute.length - 1)
        : props.currentRoute;
    const vhaLink = vhaUrl.substring(0, vhaUrl.lastIndexOf('/'));
    return (
        <>
            <a className="breadcrumb-link" href={`${vhaLink}`}>
                <span>{props.vehicleHealthComponent?.vehicleHealthTitle}</span>
            </a>
            <span className="back-slash">{'>'}</span>
        </>
    );
};

export const DiagnosticsDetailsView = () => {
    const currentRoute = window.location.pathname;
    const categoryId = new URLSearchParams(useLocation().search)?.get(
        'categoryId'
    );
    const getCategoryName = () => {
        if (categoryId === '0') {
            return 'powertrain';
        } else if (categoryId === '1') {
            return 'brakesAndSuspension';
        } else if (categoryId === '2') {
            return 'fluidsAndFilters';
        } else if (categoryId === '3') {
            return 'restraintsAndDriverAssistance';
        }
        return '';
    };
    const diagnsticsContent = useDiagnosticsDetailsContent(getCategoryName());
    const [vehicleHealthComponent] = useVehicleHealthPageComponents();

    return (
        <>
            {diagnsticsContent && (
                <ViewTemplate page={'PowerTrain Details'}>
                    <BreadcrumbWrapper
                        currentPage={
                            diagnsticsContent.diagnosticsDetails
                                .diagnosticsDetailsBreadcrum
                        }
                        hashId="support"
                        title={
                            <VhaAlertBreadCrumb
                                vehicleHealthComponent={vehicleHealthComponent}
                                currentRoute={currentRoute}
                            />
                        }
                    />
                    <DiagnosticsDetails
                        content={diagnsticsContent}
                        categoryId={categoryId}
                    />
                </ViewTemplate>
            )}
        </>
    );
};

export default DiagnosticsDetailsView;
