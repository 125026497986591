import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import { Breadcrumbs, ViewTemplate } from '../../components/sections';
import { VideoContent } from './htv-video-component';
import './htv-video-page.scss';
import { HowToPopularVideoTiles } from '../../components/sections/how-to-banner/components/popular-videos/how-to-popular-video-tiles';
import { useHowToVideoContent } from '../how-to-video-view/hooks/use-how-to-video-content';
import { Video } from '../../services/brightcove-video-service/brightcove-video-data';
import { FdsChevron } from '../../components/common/chevron/fds-chevron';
import ServerContext from '../../contexts/serverContext';
import RoadBlockPage from '../road-block-page/road-block-view';
import PopularVideoService from '../how-to-video-view/service/popular-video-service';
import useSeoConfig from '../../hooks/use-seo-config';
import { getAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import { useAnalytics } from '../../hooks/use-analytics';
import { useServerData } from '../../hooks/use-server-data';
import serverSideService from '../../services/server-side-service/server-side-service';
import useBrightcoveScriptBuilder from '../../hooks/owners-osb/use-brightcove-script';
import siteMapService from '../../support/services/site-map-service/site-map-service';
import { filterXSS } from 'xss';
import { useDyfQueryParams } from '../../hooks/dyf/use-dyf-query-params/use-dyf-query-params';

export const HowToViewVideo = () => {
    const appInformationContent = useAppInformationContent();
    const howToVideoContent = useHowToVideoContent();
    const page = 'How To Video';

    const {
        queryCategory,
        queryModel,
        queryYear,
        queryVideoId,
    } = useDyfQueryParams();

    const serverContext = useContext(ServerContext);
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        root,
    } = serverContext;
    const [breadcrumbToggle, setBreadCrumbToggle] = useState(false);

    const baseRoot = root ? root.substring(0, root.length - 1) : '';

    const [
        categoryVideosResponseError,
        setCategoryVideosResponseError,
    ] = useState<boolean>(false);

    const [fireEvents] = useAnalytics();

    const category = queryCategory.replace(/-/gm, ' ').toLowerCase();
    const upperCaseCategory = category.toUpperCase();

    const changeFirstCharToUpperCase = (serviceName: string) => {
        if (serviceName == undefined) return '';
        serviceName = serviceName.toLowerCase();
        if (serviceName.toLowerCase() == 'fordpass') return 'FordPass';
        return serviceName.charAt(0).toUpperCase() + serviceName.slice(1);
    };

    const getUpdatedCanonical = () => {
        const baseUrl = siteMapService.getBaseUrl(
            currentLanguageRegionCode,
            brand
        );

        return baseUrl.domain
            .concat(baseUrl.root)
            .concat(
                siteMapService
                    .getPathFromRouterConfigs(
                        currentLanguageRegionCode,
                        brand,
                        'HowToViewVideo',
                        [queryModel, queryYear, queryCategory, queryVideoId]
                    )
                    ?.toString() || ''
            )
            .slice(0, -1);
    };

    serverContext.canonical = filterXSS(getUpdatedCanonical());

    const serverDataCallbackVideoDetails = async () => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            queryYear,
            queryModel,
            'SEO_KEY'
        );
        if (vehicleYmm && vehicleYmm.marketName) {
            const categoryVideos = await new PopularVideoService().getVideosByCategories(
                queryYear,
                brand,
                vehicleYmm.marketName,
                currentLanguageRegionCode
            );
            if (categoryVideos) {
                const index = categoryVideos[upperCaseCategory].findIndex(
                    (video: Video) => video.id == queryVideoId
                );
                for (const key in categoryVideos) {
                    categoryVideos[key].forEach(videoData => {
                        videoData.seoKey = queryModel;
                        videoData.custom_fields.year = queryYear;
                        videoData.custom_fields.model = vehicleYmm.marketName;
                    });
                }
                if (index != undefined) {
                    return Promise.resolve(
                        categoryVideos[upperCaseCategory][index]
                    );
                } else {
                    setCategoryVideosResponseError(true);
                    return Promise.resolve({
                        id: '',
                        name: '',
                        account_id: '',
                        description: '',
                        images: {
                            thumbnail: {
                                src: '',
                            },
                        },
                        duration: null,
                        created_at: '',
                    });
                }
            }
        }
    };
    const [videoDetails] = useServerData(
        'videoDetails',
        serverDataCallbackVideoDetails,
        [queryVideoId]
    );
    const serverDataCallbackMarketName = async () => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            queryYear,
            queryModel,
            'SEO_KEY'
        );
        if (vehicleYmm) return Promise.resolve(vehicleYmm.model);
        else return Promise.resolve('');
    };
    const [marketName] = useServerData<string>(
        'marketName',
        serverDataCallbackMarketName,
        [queryVideoId, queryYear, queryModel]
    );
    const serverDataCallbackCategoryVideos = async () => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            queryYear,
            queryModel,
            'SEO_KEY'
        );
        if (vehicleYmm && vehicleYmm.marketName) {
            const categoryVideos = await new PopularVideoService().getVideosByCategories(
                queryYear,
                brand,
                vehicleYmm.marketName,
                currentLanguageRegionCode
            );
            if (categoryVideos) {
                const index = categoryVideos[upperCaseCategory].findIndex(
                    (video: Video) => video.id == queryVideoId
                );
                for (const key in categoryVideos) {
                    categoryVideos[key].forEach(videoData => {
                        videoData.seoKey = vehicleYmm.seoKey;
                        videoData.custom_fields.year = queryYear;
                        videoData.custom_fields.model = vehicleYmm.marketName;
                    });
                }
                const otherVideosData: Video[] = [];
                if (index != undefined) {
                    otherVideosData.push(
                        ...categoryVideos[upperCaseCategory].slice(
                            index + 1,
                            categoryVideos[upperCaseCategory].length
                        )
                    );
                    if (otherVideosData.length < 3) {
                        otherVideosData.push(
                            ...categoryVideos[upperCaseCategory].slice(0, index)
                        );
                    }
                    categoryVideos[upperCaseCategory][
                        index
                    ].custom_fields.year = vehicleYmm.year.toString();
                    categoryVideos[upperCaseCategory][
                        index
                    ].custom_fields.model = vehicleYmm.marketName;
                }
                return Promise.resolve(otherVideosData.slice(0, 3));
            } else {
                setCategoryVideosResponseError(true);
                return Promise.resolve([]);
            }
        }
    };
    const [categoryVideos] = useServerData(
        'videoCategoriesResponse',
        serverDataCallbackCategoryVideos,
        [queryVideoId]
    );

    const isEnoughCategoryVideos = () => {
        return !(categoryVideos?.data && categoryVideos?.data.length < 1);
    };
    const capitalizeYmm = (str: string) => {
        return str
            .split(' ')
            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
            .join(' ');
    };

    const playerSource = useBrightcoveScriptBuilder(
        videoDetails?.data?.account_id || ''
    );

    const formattedVideoDuration = useCallback(() => {
        if (videoDetails?.data?.duration) {
            const videoDurationInSeconds = videoDetails?.data?.duration / 1000;
            const seconds = Math.floor(videoDurationInSeconds % 60).toString();
            const minutes = Math.floor(videoDurationInSeconds / 60).toString();
            const hours = Math.floor(videoDurationInSeconds / 3600).toString();
            return `PT${hours}H${minutes}M${seconds}S`;
        }
    }, [videoDetails?.data?.duration]);

    const videoSchemaScript = useMemo(
        () => `<script type="application/ld+json">{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "${videoDetails?.data?.name}",
        "description": "${videoDetails?.data?.description}",
        "duration": "${formattedVideoDuration()}",
        "thumbnailUrl": "${videoDetails?.data?.images?.thumbnail?.src}",
        "contentUrl": "${playerSource.src}?videoId=${videoDetails?.data?.id}",
        "embededUrl": "${playerSource.src}?videoId=${videoDetails?.data?.id}",
        "uploadDate": "${videoDetails?.data?.created_at}",
    }</script>`,
        [videoDetails, playerSource, formattedVideoDuration]
    );

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            window.scrollTo(0, 0);
        }
    }, [queryVideoId, videoDetails]);

    useEffect(() => {
        if (marketName && queryYear && brand)
            fireEvents(['owner-htv-video-page'], undefined, {
                videoName: videoDetails?.data?.name,
                ymm: {
                    year: parseInt(queryYear),
                    model: marketName?.data || '',
                    make: brand,
                },
            });
    }, [marketName, queryYear, brand]);

    useSeoConfig('htv-video-page', 'dyf', [
        {
            fieldName: 'ymm',
            fieldValue:
                capitalizeYmm(
                    `${queryYear} ` +
                        capitalizeYmm(brand) +
                        ' ' +
                        capitalizeYmm(marketName?.data || '')
                ) || '',
        },
        {
            fieldName: 'video-name',
            fieldValue: capitalizeYmm(
                videoDetails?.data?.name.toLowerCase() || ''
            ),
        },
        {
            fieldName: 'category-name',
            fieldValue: capitalizeYmm(category),
        },
        {
            fieldName: 'video-desc',
            fieldValue: videoDetails?.data?.description || '',
        },
    ]);

    const breadcrumbModelYear = useMemo(() => {
        if (currentLanguageRegionCode === 'fr-ca' && brand === 'lincoln') {
            return `${changeFirstCharToUpperCase(
                marketName?.data || ''
            )} ${queryYear}`;
        } else
            return `${queryYear} ${changeFirstCharToUpperCase(
                marketName?.data || ''
            )}`;
    }, [currentLanguageRegionCode, brand, marketName, queryYear]);

    const HTVVideoBanner = () =>
        howToVideoContent &&
        videoDetails && (
            <div className="htv-content-wrapper">
                <div className="video-page-title">
                    <h1>{videoDetails.data?.name} </h1>
                </div>
                <div>
                    <VideoContent
                        videoId={videoDetails.data?.id || ''}
                        account={videoDetails.data?.account_id || ''}
                        description={videoDetails.data?.description || ''}
                        videoTitle={videoDetails.data?.name}
                    />
                    <HTVVideoCategoryBanner />
                </div>
            </div>
        );

    const HTVVideoCategoryBanner = () =>
        howToVideoContent && isEnoughCategoryVideos() && categoryVideos ? (
            <div className="how-to-video-popular-videos">
                <HowToPopularVideoTiles
                    popularHowToVideosTitle={
                        howToVideoContent.viewMoreVideos?.includes('%category%')
                            ? `${howToVideoContent.viewMoreVideos.replace(
                                  '%category%',
                                  category.toUpperCase()
                              )}`
                            : category.toUpperCase()
                    }
                    className="category-video"
                    popularVideoItems={categoryVideos.data || []}
                />
            </div>
        ) : (
            <div className="no-video" />
        );

    const VideoPageBreadCrumb = () => {
        return howToVideoContent ? (
            <>
                <a
                    className="breadcrumb-link"
                    href={`${baseRoot}/how-to-videos`}
                >
                    <span>
                        {howToVideoContent.howToVideosBreadcrumbHowToVideos}
                    </span>
                </a>
                <span className="back-slash">{'>'}</span>
                <a
                    className="breadcrumb-link"
                    href={`${baseRoot}/vehicle/${queryModel}/${queryYear}/how-to-videos/video-library/`}
                >
                    <span>{breadcrumbModelYear}</span>
                </a>
                <span className="back-slash">{'>'}</span>
                <a
                    className="breadcrumb-link"
                    href={`${baseRoot}/vehicle/${queryModel}/${queryYear}/how-to-videos/video-library/${category
                        .split(' ')
                        .join('-')}`}
                >
                    {changeFirstCharToUpperCase(category)}
                </a>
                <span className="back-slash">{'>'}</span>
            </>
        ) : (
            <></>
        );
    };
    return (
        <div className="htv-video-page">
            {howToVideoContent && appInformationContent && videoDetails && (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: videoSchemaScript || '',
                        }}
                    />
                    <div
                        className={`breadcrumbAccordion ${breadcrumbToggle &&
                            'expanded'}`}
                    >
                        <Breadcrumbs
                            currentPage={changeFirstCharToUpperCase(
                                videoDetails?.data?.name.toLowerCase() || ''
                            )}
                            breadcrumbName={
                                howToVideoContent.howToVideosBreadcrumbSupport
                            }
                            type="common"
                        >
                            <VideoPageBreadCrumb />
                        </Breadcrumbs>
                        <div
                            className="breadcrumb-accordion-chevron"
                            onClick={() =>
                                setBreadCrumbToggle(!breadcrumbToggle)
                            }
                        >
                            <FdsChevron
                                direction={breadcrumbToggle ? 'up' : 'down'}
                                type={'unfilled'}
                            />
                        </div>
                    </div>

                    <ViewTemplate
                        className="video-details"
                        hideLinksList={true}
                        page={page}
                        ctaBottomComponent={<HTVVideoBanner />}
                    />
                </>
            )}
            {categoryVideosResponseError && <RoadBlockPage />}
        </div>
    );
};
export default HowToViewVideo;
