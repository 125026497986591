import React from 'react';
import FMButton from '../fm-button/fm-button';
import { FMCardProps } from '../../types/fm-card.types';
import { FMButtonProps } from '../../types/fm-button.types';
import './fm-card.scss';
import FMUtility from '../../fm-utils/fm-util';

const FMCard = (props: FMCardProps) => {
    const {
        borderless,
        cardTheme,
        imageSrc,
        imageAlt,
        displayImage,
        hoverTextHeading,
        hoverTextQuestions = '',
        titleWithDisclaimer,
        title,
        description,
        descriptionWithDisclaimer,
        children,
    } = props;
    const [hover, setHover] = React.useState(false);
    const containerClass = borderless
        ? ['fm-card__container', cardTheme === 'dark' ? 'dark' : '']
              .join(' ')
              .trim()
        : ['fm-card__container', cardTheme === 'dark' ? 'dark' : '', 'border']
              .join(' ')
              .trim();

    const cta1 = {
        type: props.ctaType,
        target: props.ctaTarget,
        label: props.ctaLabel,
        ariaLabel: props.ctaAriaLabel,
        chevron: props.ctaChevron,
        href: props.ctaHref,
        theme: props.ctaTheme,
        onClick: props.ctaOnClick,
    } as FMButtonProps;

    const cta2 = props.cta2Label
        ? ({
              type: props.cta2Type,
              target: props.cta2Target,
              label: props.cta2Label,
              ariaLabel: props.cta2AriaLabel,
              chevron: props.cta2Chevron,
              href: props.cta2Href,
              theme: props.cta2Theme,
              onClick: props.cta2OnClick,
          } as FMButtonProps)
        : null;

    const isHover = hoverTextHeading || hoverTextQuestions;
    const nodeTitle = titleWithDisclaimer || title;
    const nodeDescription = descriptionWithDisclaimer || description;

    const hoverClass = ['fm-card-header-hover', hover ? 'show-text' : '']
        .join(' ')
        .trim();

    return (
        <article className={containerClass}>
            <header
                className='fm-card__header'
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {imageSrc && !displayImage && (
                    <img
                        className='fm-card__img'
                        src={imageSrc}
                        alt={imageAlt}
                    />
                )}
                {isHover && (
                    <section className={hoverClass}>
                        <h2 className='fm-card-header-text-head'>
                            {hoverTextHeading}
                        </h2>
                        <div
                            className='fm-card-header-text-body'
                            dangerouslySetInnerHTML={{
                                __html: hoverTextQuestions,
                            }}
                        />
                    </section>
                )}
            </header>

            <section className='fm-card__content--container'>
                {nodeTitle != null &&
                    (FMUtility.isMarkup(nodeTitle.toString()) ? (
                        <h3
                            className='fm-card__title'
                            dangerouslySetInnerHTML={{
                                __html: nodeTitle?.toString()?.trim(),
                            }}
                        />
                    ) : (
                        <h3 className='fm-card__title'>{nodeTitle}</h3>
                    ))}

                {nodeDescription != null &&
                    (FMUtility.isMarkup(nodeDescription.toString()) ? (
                        <div
                            className='fm-card__description'
                            dangerouslySetInnerHTML={{
                                __html: nodeDescription?.toString()?.trim(),
                            }}
                        />
                    ) : (
                        <div className='fm-card__description'>
                            {nodeDescription}
                        </div>
                    ))}

                {children && <div className='fm-card__body'>{children}</div>}
            </section>
            <footer className='fm-card__button--container'>
                <FMButton {...cta1} />
                {cta2 && <FMButton {...cta2} />}
            </footer>
        </article>
    );
};

export default FMCard;
