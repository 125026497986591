import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import ReactDOM from 'react-dom';
import './floating-panel.scss';
import { BrandUtil } from '../../utils/brand-util/brand-util';

interface Props {
    className?: string;
    parent: HTMLElement;
    children: ReactNode;
    hidden: boolean;
    align?: 'left' | 'center' | 'right';
    disableFullWidthOnMobile?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    ref?: React.RefObject<HTMLDivElement>;
}

interface Position {
    top: number;
    left: number;
    pointerTop: number;
    pointerLeft: number;
    pointerReverse: boolean;
    hidePointer: boolean;
}

export const FloatingPanel = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => {
        const { hidden, parent } = props;
        const { brandName } = new BrandUtil();
        const styleBrandName = brandName;
        const panelRef = useRef<HTMLDivElement>(null);
        let handleEscapeClick: (e: KeyboardEvent) => void;
        const [calculatedPosition, setCalculatedPosition] = useState<
            Position
        >();

        const positionModal = () => {
            const parentRect = parent.getBoundingClientRect();
            const panelRect = panelRef.current?.getBoundingClientRect();
            if (parentRect && panelRect) {
                let top, left, pointerTop, pointerReverse;
                let hidePointer = false;
                let pointerLeft = parentRect.width / 2 - 12;
                if (
                    parentRect.top + panelRect.height + 12 <
                    window.innerHeight
                ) {
                    top = parentRect.height + 12;
                    pointerTop = parentRect.height;
                    pointerReverse = false;
                } else {
                    top = (panelRect.height + 12) * -1;
                    pointerTop = -24;
                    pointerReverse = true;
                }

                if (window.innerWidth < 500) {
                    left = 0;
                    top = 0;
                    pointerTop = 0;
                    pointerLeft = 0;
                    hidePointer = true;
                    setCalculatedPosition({
                        top,
                        left,
                        pointerTop,
                        pointerLeft,
                        pointerReverse,
                        hidePointer,
                    });
                    return;
                } else {
                    if (
                        props.align === 'center' &&
                        parentRect.left + panelRect.width / 2 <
                            window.innerWidth
                    ) {
                        left = (panelRect.width / 2) * -1;
                    } else if (
                        props.align === 'right' &&
                        parentRect.left - panelRect.width + 36 > 0
                    ) {
                        left = panelRect.width * -1 + 36;
                    } else if (
                        parentRect.left + panelRect.width - 36 <
                        window.innerWidth
                    ) {
                        left = -36;
                    } else if (parentRect.left - panelRect.width + 36 > 0) {
                        left = panelRect.width * -1 + 36;
                    } else {
                        left =
                            (parentRect.left +
                                panelRect.width -
                                window.innerWidth +
                                48) *
                            -1;
                    }
                }

                setCalculatedPosition({
                    top: Math.round(top + parentRect.top + window.scrollY),
                    left: Math.round(
                        left +
                            parentRect.left +
                            parentRect.width / 2 +
                            window.scrollX
                    ),
                    pointerTop: Math.round(
                        pointerTop + parentRect.top + window.scrollY
                    ),
                    pointerLeft: Math.round(
                        pointerLeft + parentRect.left + window.scrollX
                    ),
                    pointerReverse: pointerReverse,
                    hidePointer: hidePointer,
                });
            }
        };

        const closeToolTipHandler = () => {
            setCalculatedPosition(undefined);
            window.removeEventListener('resize', positionModal);
            if (props.onClose) {
                props.onClose();
            }
        };

        const openToolTipHandler = () => {
            positionModal();
            document.addEventListener(
                'keyup',
                (handleEscapeClick = (e: KeyboardEvent) => {
                    if (e.key === KEYBOARD_KEYS.ESC) {
                        closeToolTipHandler();
                        document.removeEventListener(
                            'keyup',
                            handleEscapeClick
                        );
                    }
                })
            );
            window.addEventListener('resize', () => {
                closeToolTipHandler();
                positionModal();
            });
            if (props.onOpen) {
                props.onOpen();
            }
        };

        useEffect(() => {
            positionModal();
            if (!hidden) {
                openToolTipHandler();
            } else if (calculatedPosition) {
                closeToolTipHandler();
            }
        }, [hidden]);

        const TooltipModal = () => {
            return (
                <div ref={ref}>
                    <div
                        className={'floating-panel__wrapper'}
                        ref={panelRef}
                        aria-modal="true"
                        role="dialog"
                        aria-labelledby="tooltip__header-content"
                        aria-describedby="tooltip-content-div"
                        style={{
                            top: calculatedPosition?.top,
                            left: calculatedPosition?.left,
                            visibility: calculatedPosition
                                ? 'visible'
                                : 'hidden',
                        }}
                    >
                        <div className="floating-panel__content">
                            {props.children}
                        </div>
                    </div>
                    <div
                        className={[
                            'floating-panel__pointer',
                            calculatedPosition?.pointerReverse
                                ? 'floating-panel__pointer--top'
                                : '',
                        ]
                            .join(' ')
                            .trim()}
                        style={{
                            visibility:
                                calculatedPosition &&
                                !calculatedPosition.hidePointer
                                    ? 'visible'
                                    : 'hidden',
                            top: calculatedPosition?.pointerTop,
                            left: calculatedPosition?.pointerLeft,
                        }}
                    ></div>
                </div>
            );
        };

        return (
            <>
                {!hidden &&
                    ReactDOM.createPortal(
                        <TooltipModal />,
                        document.querySelector(`.brand-${styleBrandName}`)!
                    )}
            </>
        );
    }
);
FloatingPanel.displayName = 'FloatingPanel';
