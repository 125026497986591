import ServiceHandler from '../../../services/service-handler';
import {
    VehicleData,
    VehicleStep,
} from '../../../models/osb-model/osb-vehicle-step';

import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import {
    DELIVERY_STEPS_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
    OSB_SERVICE_PRICE_TYPE,
    OSB_SERVICE_TYPE,
    SERVICE_DELIVERY_TYPE,
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
} from '../../../components/sections/owners-osb/osb-constant';
import { OSBStep } from '../../../models/osb-model/osb-step';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import {
    DealerServices,
    DealerServicesArray,
    ServiceData,
    ServiceInfo,
} from '../../../models/osb-model/osb-dealerservice-info';
import { PersonalDetail } from '../../../models/osb-model/osb-personal-details';
import { StepProgressBarDetail } from '../../../models/osb-model/osb-progress-bar';
import {
    ProfileWithVehiclesResponse,
    UserVehicleInfo,
    VehicleDetail,
} from '../../../models/profile-with-vehicles';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { ServiceLocationDetails } from '../../../models/osb-model/osb-service-location';
import { ServiceStep } from '../../../models/osb-model/osb-service-step';
import { VehicleRecallInfo } from '../../../models/osb-model/vehicle-recall-info';
import { DealerProfileInfo } from '../../../models/osb-model/osb-dealer-info';
import { AvailableDates } from '../../../models/osb-model/dealer-calendar-details';
import {
    ContentElements,
    ContentObject,
} from '../../../models/osb-model/osb-content-details';

const DROP_OFF_SUBTYPE = 'DropOff';
const dateFns = require('date-fns');

export const getPreviousDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
};
export const formatDate = (date: string) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};
export const isValidVin = (regVin: string, vinRegDataPattern: string) => {
    if (!regVin) {
        return false;
    }
    const vinRegistrationRegExp =
        (vinRegDataPattern && vinRegDataPattern.trim()) ||
        '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,9}[a-zA-Z0-9])$';
    const vinRegex = new RegExp(vinRegistrationRegExp);
    return vinRegex.test(regVin);
};

export const getLightVehicleData = (osbVehicleStep: VehicleStep) => {
    let vehicleData: VehicleData;
    if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.modelName !==
            osbVehicleStep.defaultModelName &&
        osbVehicleStep.vehicleDetails.vin &&
        osbVehicleStep.vehicleDetails.vin.length > 0
    ) {
        vehicleData = {
            vin: osbVehicleStep.vehicleDetails.vin,
            mileage: osbVehicleStep.vinMileage,
        };
    } else if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.modelName !==
            osbVehicleStep.defaultModelName &&
        osbVehicleStep.vehicleDetails.registrationNumber &&
        osbVehicleStep.vehicleDetails.registrationNumber.length > 0
    ) {
        vehicleData = {
            registrationNumber:
                osbVehicleStep.vehicleDetails.registrationNumber,
            mileage: osbVehicleStep.vinMileage,
        };
    } else {
        vehicleData = {
            modelName: osbVehicleStep.defaultModelName,
            buildYear: osbVehicleStep.defaultBuildYear,
            mileage: osbVehicleStep.defaultMileage,
        };
    }
    return vehicleData;
};

export const getLightVehicleDataForBookingPayload = (
    osbVehicleStep: VehicleStep,
    osbPersonalDetail: PersonalDetail
) => {
    let vehicleData: VehicleData;
    if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.modelName !==
            osbVehicleStep.defaultModelName &&
        osbVehicleStep.vehicleDetails.registrationNumber &&
        osbVehicleStep.vehicleDetails.registrationNumber.length > 0
    ) {
        vehicleData = {
            vin: osbVehicleStep.vehicleDetails.vin,
            registrationNumber:
                osbVehicleStep.vehicleDetails.registrationNumber,
            mileage: osbVehicleStep.vinMileage,
        };
    } else if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.modelName !==
            osbVehicleStep.defaultModelName &&
        osbVehicleStep.vehicleDetails.vin &&
        osbVehicleStep.vehicleDetails.vin.length > 0
    ) {
        vehicleData = {
            vin: osbVehicleStep.vehicleDetails.vin,
            mileage: osbVehicleStep.vinMileage,
        };
    } else if (
        osbPersonalDetail.registrationNumber &&
        osbPersonalDetail.registrationNumber.length > 0
    ) {
        vehicleData = {
            registrationNumber: osbPersonalDetail.registrationNumber,
            modelName: osbVehicleStep.defaultModelName,
            buildYear: osbVehicleStep.defaultBuildYear,
            mileage: osbVehicleStep.defaultMileage,
        };
    } else {
        vehicleData = {
            modelName: osbVehicleStep.defaultModelName,
            buildYear: osbVehicleStep.defaultBuildYear,
            mileage: osbVehicleStep.defaultMileage,
        };

        if (
            isValidVin(
                osbVehicleStep.vinRegNo,
                osbVehicleStep.vinRegDataPattern
            )
        ) {
            if (osbVehicleStep.vinRegNo.length === 17) {
                vehicleData = { ...vehicleData, vin: osbVehicleStep.vinRegNo };
            } else {
                vehicleData = {
                    ...vehicleData,
                    registrationNumber: osbVehicleStep.vinRegNo,
                };
            }
        }
    }
    return vehicleData;
};

export const getVehicleData = (osbVehicleStep: VehicleStep) => {
    let vehicleData: VehicleData;
    if (osbVehicleStep.vehicleStepFlow === 'manual') {
        vehicleData = {
            modelName: osbVehicleStep.model,
            buildYear: osbVehicleStep.modelYear,
            mileage: osbVehicleStep.manualMileage,
        };
    } else {
        const vinRegNumber = osbVehicleStep.vinRegNo;
        if (isThisVIN(vinRegNumber)) {
            vehicleData = {
                vin: formatRegVinNumber(
                    osbVehicleStep.vinRegNo,
                    osbVehicleStep.allowSpecialCharacters
                ),
                mileage: osbVehicleStep.vinMileage,
            };
        } else {
            vehicleData = {
                registrationNumber: formatRegVinNumber(
                    osbVehicleStep.vinRegNo,
                    osbVehicleStep.allowSpecialCharacters
                ),
                mileage: osbVehicleStep.vinMileage,
            };
        }
    }
    return vehicleData;
};

const callContentService = async (modelName: string) => {
    return await ServiceHandler.OsbContentService.getOsbContentModel(
        OsbUtilService.getAppConfiguration().brand,
        OsbUtilService.getAppConfiguration().countryCode,
        OsbUtilService.getAppConfiguration().languageRegionCode,
        modelName
    );
};
export const getServiceDetailsContent = async (modelName: string) => {
    return await callContentService(modelName);
};

const comparer = <T extends { serviceUniqueId?: string; serviceCode?: string }>(
    listToCompare: T[]
) => {
    return function(objectToCompare: T) {
        return (
            listToCompare.filter(item => {
                if (
                    'serviceUniqueId' in item &&
                    'serviceUniqueId' in objectToCompare
                ) {
                    return (
                        item.serviceUniqueId == objectToCompare.serviceUniqueId
                    );
                } else if (
                    'serviceCode' in item &&
                    'serviceCode' in objectToCompare
                ) {
                    return item.serviceCode == objectToCompare.serviceCode;
                } else {
                    return [];
                }
            }).length == 0
        );
    };
};

//Compare the current and new list and check whether any change in the list
export const isListSelectionChanged = <
    T extends { serviceUniqueId?: string; serviceCode?: string }
>(
    currentList: T[],
    newList: T[]
): boolean => {
    const currentListChange = currentList.filter(comparer(newList));
    const newListChange = newList.filter(comparer(currentList));
    const concatChangeList = currentListChange.concat(newListChange);

    return concatChangeList.length > 0;
};

export const getOsbAppContent = async (modelName: string) => {
    return await ServiceHandler.OsbContentService.getOsbContentModel(
        OsbUtilService.getAppConfiguration().brand,
        OsbUtilService.getAppConfiguration().countryCode,
        OsbUtilService.getAppConfiguration().languageRegionCode,
        modelName
    );
};

export const getObjectFromAEMJson = (
    key: string,
    data: any[] | ContentElements[]
) => {
    if (data) {
        const item = data.filter(part => {
            return part.name === key;
        });
        if (
            item &&
            item.length > 0 &&
            Object.prototype.hasOwnProperty.call(item[0], 'value')
        ) {
            return item[0].value === null ? '' : item[0].value;
        }
    }
    return '';
};

export const getObjectFromAEMJsonArray = (
    key: string,
    contentArray: ContentObject
) => {
    return contentArray ? contentArray[key] : '';
};

export const isPOROrNoPriceServiceSelected = (
    selectedServicesList: ServiceData[],
    selectedOtherOptionsServices: ServiceData[]
) => {
    return (
        selectedServicesList.some(
            obj =>
                (Number(obj.priceAfterDiscount) ===
                    OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
                    Number(obj.priceAfterDiscount) ===
                        OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE) &&
                obj.serviceOption !==
                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE
        ) ||
        selectedOtherOptionsServices.some(
            obj =>
                (Number(obj.priceAfterDiscount) ===
                    OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
                    Number(obj.priceAfterDiscount) ===
                        OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE) &&
                obj.serviceType !== OSB_SERVICE_TYPE.DROPOFF
        )
    );
};

export const hasAutomaticVoucherApplied = (
    totalDiscountPrice: string,
    totalPrice: string
) => {
    return Number(totalDiscountPrice) < Number(totalPrice);
};

function getTotalServicePrice(selectedServices: ServiceData[]) {
    if (
        selectedServices.filter(
            (service: ServiceData) => Number(service.price) > 0
        ).length > 0
    ) {
        return Number(
            selectedServices.length > 0 &&
                selectedServices
                    .map(item =>
                        Number(item.price) ===
                            OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE ||
                        Number(item.price) ===
                            OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE // calculate total price by adding all service prices, if 'empty' (-2), considering as zero (0)
                            ? 0
                            : Number(item.price)
                    )
                    .reduce((a, b) => a + b)
        );
    } else {
        // checking free service length > 0 and PoR, empty service length is 0 to display FREE in total
        if (
            selectedServices.filter(
                (service: ServiceData) => Number(service.price) === 0
            ).length > 0 &&
            selectedServices.filter(
                (service: ServiceData) => Number(service.price) < 0
            ).length === 0
        ) {
            return OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE;
        } else {
            return OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE;
        }
    }
}

export const getFormattedPrice = (
    price: string,
    osbStep: OSBStep,
    serviceCode?: string
) => {
    if (
        serviceCode &&
        serviceCode === SERVICE_FLOW.GA_SERVICE_ID &&
        (Number(price) === OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
            Number(price) <= OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE ||
            Number(price) === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE)
    ) {
        // GA service - if price is 0,-1,-2 then display empty price
        return '';
    }
    if (Number(price) <= 0) {
        if (Number(price) === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) {
            return osbStep.freeLabel;
        } else if (
            Number(price) === OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
        ) {
            return '';
        } else {
            return osbStep.porLabel;
        }
    }

    const priceWithPrecision = OsbUtilService.addPrecision(
        price,
        osbStep.pricePrecision
    );
    let priceWithPrecisionAndSeparators = OsbUtilService.addSeparators(
        priceWithPrecision,
        osbStep.priceSeparator
    );
    if (osbStep.pricePrecision > 0) {
        priceWithPrecisionAndSeparators = OsbUtilService.addDecimalSeparator(
            priceWithPrecisionAndSeparators,
            osbStep.decimalSeparator
        );
    }
    return (
        osbStep.pricePrefix +
        priceWithPrecisionAndSeparators +
        osbStep.priceSuffix
    );
};
export const calculateTotalPriceSummary = (
    osbStep: OSBStep,
    osbServiceStep: ServiceStep,
    selectedDeliveryService: ServiceData[],
    selectedDeliveryMobileService: ServiceData[],
    potentialSavings?: number
) => {
    // selected specific services
    const selectedSpecificService: ServiceData[] =
        osbServiceStep.selectedServices;

    const totalPrice = getTotalServicePrice([
        ...selectedSpecificService,
        ...selectedDeliveryService,
        ...selectedDeliveryMobileService,
    ]);

    const finalPrice = potentialSavings
        ? totalPrice - potentialSavings
        : totalPrice;

    return getFormattedPrice(finalPrice.toFixed(2), osbStep);
};

export const getBrand = () => {
    return OsbUtilService.getAppConfiguration().brand ===
        LIGHT_JOURNEY_ANALYTICS.DEMO_BRAND
        ? LIGHT_JOURNEY_ANALYTICS.FORD_BRAND
        : OsbUtilService.getAppConfiguration().brand;
};

export function getConnectedVehicles(data: UserVehicleInfo[]) {
    return data.filter(
        (item: any) =>
            item.tcuEnabled === 1 &&
            item.vehicleAuthorizationIndicator === 1 &&
            item.ngSdnManaged === 1
    );
}

export function getVehicleFromVin(
    vehiclesList: UserVehicleInfo[],
    vin: string
) {
    return vehiclesList.find((item: any) => item.vin === vin);
}

export const getVinVehicleResponse = async (vin: string, year?: number) => {
    return await ServiceHandler.ConnectedVehicleService.getVehicleStatus(
        vin,
        year
    );
};

export const getVehicleAttributesForAnalytics = (
    osbVehicleStep: VehicleStep
) => {
    let defaultVehicle: VehicleAttributes;
    if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.vin &&
        osbVehicleStep.vehicleDetails.vin.length > 0
    ) {
        defaultVehicle = {
            year: Number(osbVehicleStep.vehicleDetails.buildDate),
            model: osbVehicleStep.vehicleDetails.modelName,
            make: getBrand(),
        };
    } else {
        defaultVehicle = {
            year: Number(' '),
            model: ' ',
            make: ' ',
        };
    }
    return defaultVehicle;
};

export const getVehicleHealthAlerts = async (vin: string) => {
    return await ServiceHandler.VhaService.request(vin);
};

export const sortOrderComparer = (
    service: ServiceInfo,
    nextService: ServiceInfo
) => {
    const serviceSortOrder = service?.additionalInfo?.sortOrder?.trim()
        ? Number(service.additionalInfo.sortOrder)
        : -1;
    const nextServiceSortOrder = nextService?.additionalInfo?.sortOrder?.trim()
        ? Number(nextService.additionalInfo.sortOrder)
        : -1;

    if (serviceSortOrder < nextServiceSortOrder) {
        return -1;
    } else if (serviceSortOrder > nextServiceSortOrder) {
        return 1;
    }

    return 0;
};

export const setServiceUniqueId = (serviceList: DealerServices) => {
    const additionalServiceList = serviceList?.additionalServices || [];
    const oldServiceList = serviceList?.oldServices || [];
    const mainServiceList = serviceList?.mainServices || [];
    const combinedServiceList = [
        ...additionalServiceList,
        ...oldServiceList,
        ...mainServiceList,
    ];
    combinedServiceList.forEach(service => {
        if (!service.serviceUniqueId) {
            service.serviceUniqueId =
                service.serviceId +
                (service.additionalInfo?.subType || service.subType || '');
        }
    });
    return combinedServiceList;
};

export function filterObjectsBySectionType(
    selectedServicesList: ServiceData[],
    sectionType: string,
    dropOff?: boolean
): ServiceData[] {
    if (selectedServicesList.length > 0) {
        if (sectionType === 'priceOrFree') {
            if (dropOff) {
                return selectedServicesList.filter(
                    obj =>
                        obj.serviceType === OSB_SERVICE_TYPE.DROPOFF ||
                        (Number(obj.priceAfterDiscount) >= 0 &&
                            obj.serviceOption !==
                                SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE)
                );
            }
            return selectedServicesList.filter(
                obj =>
                    Number(obj.priceAfterDiscount) >= 0 &&
                    obj.serviceOption !==
                        SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE
            );
        } else {
            return selectedServicesList.filter(
                obj =>
                    obj.serviceOption !==
                        SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE &&
                    (Number(obj.priceAfterDiscount) === -1 ||
                        Number(obj.priceAfterDiscount) === -2)
            );
        }
    } else {
        return [];
    }
}

export const getDeliveryOptionServiceFromDealerServices = (
    dealerServices: DealerServices
) => {
    const updatedServiceList = setServiceUniqueId(dealerServices);
    return updatedServiceList.filter(
        service =>
            service.additionalInfo?.serviceType?.toUpperCase() ===
                OSB_SERVICE_TYPE.DELIVERY.toUpperCase() ||
            service.additionalInfo?.serviceType?.toUpperCase() ===
                DROP_OFF_SUBTYPE.toUpperCase()
    );
};

export const getDropoffServiceFromDealerServices = (
    dealerServices: DealerServices
) => {
    const updatedServiceList = setServiceUniqueId(dealerServices);
    return updatedServiceList.filter(
        service =>
            service.additionalInfo?.serviceType?.toUpperCase() ===
            DROP_OFF_SUBTYPE.toUpperCase()
    );
};

export const IsDealerHasDeliveryOption = (dealerServices: DealerServices) => {
    const deliveryOptionList = getDeliveryOptionServiceFromDealerServices(
        dealerServices
    );
    return deliveryOptionList && deliveryOptionList.length > 0;
};
export const IsDealerHasDeliveryOptionOtherThanDropOff = (
    dealerServices: DealerServices
) => {
    const updatedServiceList = setServiceUniqueId(dealerServices);
    if (
        updatedServiceList.filter(
            service =>
                service.additionalInfo?.serviceType?.toUpperCase() ===
                OSB_SERVICE_TYPE.DELIVERY.toUpperCase()
        ).length > 0
    ) {
        return true;
    }
    return false;
};
export const getVoucherAppliedSelectedServices = (
    dealerServices: DealerServices,
    selectedServices: ServiceData[],
    isVoucherApplied = false
) => {
    dealerServices?.additionalServices.forEach((item: any) => {
        for (let i = 0; i < selectedServices.length; i++) {
            if (item.serviceId.toString() === selectedServices[i].serviceCode) {
                selectedServices[i].priceAfterDiscount = isVoucherApplied
                    ? item.priceAfterDiscount
                    : item.price;
                selectedServices[i].price = item.price;
            }
        }
    });
    dealerServices?.oldServices.forEach((item: any) => {
        for (let i = 0; i < selectedServices.length; i++) {
            if (item.serviceId.toString() === selectedServices[i].serviceCode) {
                selectedServices[i].priceAfterDiscount = isVoucherApplied
                    ? item.priceAfterDiscount
                    : item.price;
                selectedServices[i].price = item.price;
            }
        }
    });
    dealerServices?.mainServices.forEach((item: any) => {
        for (let i = 0; i < selectedServices.length; i++) {
            if (item.serviceId.toString() === selectedServices[i].serviceCode) {
                selectedServices[i].priceAfterDiscount = isVoucherApplied
                    ? item.priceAfterDiscount
                    : item.price;
                selectedServices[i].price = item.price;
            }
        }
    });

    return selectedServices;
};

export const getVoucherAppliedSelectedDeliveryOptions = (
    dealerServices: DealerServices,
    selectedDeliveryOptions: ServiceData[],
    isVoucherApplied = false
) => {
    dealerServices?.additionalServices.forEach((item: any) => {
        for (let i = 0; i < selectedDeliveryOptions.length; i++) {
            if (
                item.serviceId.toString() ===
                selectedDeliveryOptions[i].serviceCode
            ) {
                selectedDeliveryOptions[i].priceAfterDiscount = isVoucherApplied
                    ? item.priceAfterDiscount
                    : item.price;
                selectedDeliveryOptions[i].price = item.price;
            }
        }
    });

    return selectedDeliveryOptions;
};

export const formatMobileNumber = (mobile: any) => {
    return mobile.replace(/^(\+)|\D/g, '');
};

export const shouldSkipPersonalDetailsStep = (
    isAuthenticatedFlow: boolean,
    osbPersonalDetail: PersonalDetail
) => {
    //TODO: Do we need isAunthenticatedFlow value in this helper function?
    return (
        isAuthenticatedFlow &&
        Object.values(osbPersonalDetail).every(value => value)
    );
};

export const userProfileData = (userProfile: any) => {
    const customerDetails: PersonalDetail = {
        title: userProfile.title,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        fordId: userProfile.memberId,
        contactNumber:
            userProfile.phoneNumber ||
            userProfile.phoneNumberExtension ||
            userProfile.alternatePhoneNumber ||
            userProfile.mobilePhoneNumber ||
            '',
        email: userProfile.email,
        registrationNumber: '',
        isFirstConsentEnabled: false,
        isSecondConsentEnabled: false,
        countryDialCodeObject: {
            countryDisplayName: '',
            countryRegex: '',
            countryIconPath: '',
            countryDialCode: '',
            countryCode: '',
        },
    };

    return customerDetails;
};

export const checkOSBStepExists = (
    progressBarDetails: StepProgressBarDetail[],
    stepKey: string
) => {
    const osbStep = progressBarDetails.find(step => step.key === stepKey);
    if (osbStep) {
        return true;
    } else {
        return false;
    }
};

export const showServiceLocationDetails = (
    isMobileServiceSelected: boolean,
    osbServiceLocationDetail: ServiceLocationDetails
) => {
    if (
        isMobileServiceSelected &&
        osbServiceLocationDetail.streetName &&
        osbServiceLocationDetail.houseNumber &&
        osbServiceLocationDetail.postalCode &&
        osbServiceLocationDetail.town &&
        osbServiceLocationDetail.parkingType
    ) {
        return true;
    }
    return false;
};

export const setClientStorage = (key: string, value: string) => {
    if (serverSideService.isClientSide()) {
        sessionStorage.setItem(key, value);
    }
};

export const getClientStorage = (key: string) => {
    if (serverSideService.isClientSide()) {
        return sessionStorage.getItem(key);
    }
    return null;
};

export const removeClientStorage = (key: string) => {
    if (serverSideService.isClientSide()) {
        sessionStorage.removeItem(key);
    }
};

export const removeAllClientStorage = () => {
    if (serverSideService.isClientSide()) {
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_DEALER_STEP_KEY);
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_STEP_KEY);
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_VEHICLE_STEP_KEY);
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_DELIVERY_STEP_KEY
        );
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY
        );
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_CALENDAR_STEP_KEY
        );
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_PERSONAL_DETAIL_STEP_KEY
        );
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_REVIEW_STEP_KEY);
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_RETRIEVE_STEP_KEY
        );
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_PROGRESS_BAR_STEP_KEY
        );
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_IMAGE_UPLOAD_KEY);
        sessionStorage.removeItem(
            OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY
        );
        sessionStorage.removeItem(OSB_CLIENT_STORAGE_KEYS.OSB_STEP_KEY);
    }
};

export const isClientStorageExpired = () => {
    const expiryPeriod = getClientStorage(
        OSB_CLIENT_STORAGE_KEYS.OSB_CLIENT_STORAGE_EXPIRY_PERIOD_KEY
    );
    return expiryPeriod !== null && new Date().getTime() > Number(expiryPeriod);
};

export const setClientStorageValue = (
    key: string,
    value: Record<string, unknown>,
    clientStorageExpiry: number,
    skipExpiry = false
) => {
    if (serverSideService.isClientSide()) {
        try {
            if (!skipExpiry && clientStorageExpiry > 0) {
                const expiryPeriodToSet =
                    new Date().getTime() + clientStorageExpiry * 60000;
                sessionStorage.setItem(
                    OSB_CLIENT_STORAGE_KEYS.OSB_CLIENT_STORAGE_EXPIRY_PERIOD_KEY,
                    expiryPeriodToSet.toString()
                );
            }
            const dataToPersist = {
                value: JSON.stringify(value),
            };
            sessionStorage.setItem(key, JSON.stringify(dataToPersist));
        } catch (e) {
            console.error(`Failed to write to ${key} session storage.`);
            if (
                e instanceof DOMException &&
                ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(
                    e.name
                )
            ) {
                console.error('sessionStorage size limit exceeded.');
            }
        }
    }
};

export const getClientStorageValue = (key: string) => {
    if (serverSideService.isClientSide()) {
        if (isClientStorageExpired()) {
            // If the item is expired, delete the item from storage
            removeAllClientStorage();
            return null;
        }
        const storedData = sessionStorage.getItem(key);
        if (storedData !== null) {
            const persistedData = JSON.parse(storedData);
            //It looks like you are trying to parse a JSON string twice.
            //If storedData is a JSON string, you can directly parse it once and access the value property without parsing it again.
            //it seems that the issue might be related to the structure of the storedData or persistedData objects.
            //If storedData is a JSON string and value is also a JSON string within it, then parse both the storedData and persistedData.value might be necessary.
            //need to investigate further.
            return JSON.parse(persistedData.value);
        }
    }
    return null;
};

export const setRouteToPersist = (
    key: string,
    value: string,
    clientStorageExpiry: number
) => {
    setClientStorageValue(key, { routePath: value }, clientStorageExpiry);
};

export const getPersistedRoute = (key: string) => {
    const persistedRoute = getClientStorageValue(key);
    return persistedRoute?.routePath;
};

export const getVoucherAppliedSelectedLocationOptions = (
    dealerServices: DealerServices,
    selectedLocationOptions: ServiceData[],
    isVoucherApplied = false
) => {
    dealerServices?.additionalServices.forEach((item: any) => {
        for (let i = 0; i < selectedLocationOptions.length; i++) {
            if (
                item.serviceId.toString() ===
                selectedLocationOptions[i].serviceCode
            ) {
                selectedLocationOptions[i].priceAfterDiscount = isVoucherApplied
                    ? item.priceAfterDiscount
                    : item.price;
                selectedLocationOptions[i].price = item.price;
            }
        }
    });
    return selectedLocationOptions;
};

export const frameServiceData = (
    serviceType: string,
    serviceOption: string,
    service: any
) => {
    const serviceData: ServiceData = {
        id: service.id,
        serviceType: serviceType,
        serviceName: service.name,
        serviceCode: service.serviceId.toString(),
        priceAfterDiscount: service.priceAfterDiscount,
        price: service.price,
        type: service.type,
        subType: service.subType
            ? service.subType
            : service.additionalInfo.subType,
        serviceOption: serviceOption,
        serviceUniqueId: service.serviceUniqueId,
        brightCoveAccountId: service?.additionalInfo?.brightCoveAccountId || '',
        brightCoveVideoId: service?.additionalInfo?.brightCoveVideoId || '',
        brightCovePlayerId: service?.additionalInfo?.brightCovePlayerId || '',
        brightCoveVideoName: service?.additionalInfo?.brightCoveVideoName || '',
        convenienceOptionTypes: service?.convenienceOptionTypes || '',
    };
    return serviceData;
};

export const getRefreshedServicesData = (
    serviceDetails: any,
    selectedServices: ServiceData[],
    hasVIN: boolean
) => {
    const selectedServiceList: ServiceData[] = [];
    serviceDetails.filter((item: any) => {
        for (const selectedService of selectedServices) {
            if (item.name === selectedService.serviceName) {
                const serviceType = item.additionalInfo?.subType?.toUpperCase();
                if (
                    serviceType &&
                    (serviceType === SERVICE_FLOW.SERVICE_SUBTYPE_VHA ||
                        serviceType === SERVICE_FLOW.SERVICE_SUBTYPE_RECALL ||
                        serviceType === SERVICE_FLOW.SERVICE_SUBTYPE_OIL)
                ) {
                    if (hasVIN) {
                        selectedServiceList.push(item);
                    }
                } else {
                    selectedServiceList.push(item);
                }
            }
        }
    });

    return selectedServiceList;
};

export const getRefreshedOtherOptionServicesData = (
    serviceDetails: any,
    selectedServices: ServiceData[]
) => {
    const selectedServiceList: ServiceData[] = [];
    const tempServicesArray = selectedServices;
    serviceDetails.filter((item: any) => {
        for (let i = 0; i < tempServicesArray.length; i++) {
            if (item.name === tempServicesArray[i].serviceName) {
                selectedServiceList.push(item);
            }
        }
    });

    return selectedServiceList;
};

export const isServiceFoundInSelectedList = (
    service: ServiceInfo,
    selectedServices: ServiceData[]
) => {
    if (
        selectedServices.filter(
            item => item.serviceCode.toString() === service.serviceId.toString()
        ).length === 1
    ) {
        return true;
    }
    return false;
};

export const buildNavigationUrl = (
    pathToGo: string,
    queryParams: string,
    stateValue?: string
) => {
    if (stateValue && stateValue?.length > 0) {
        return {
            pathname: pathToGo,
            search: queryParams,
            state: { stateValue },
        };
    } else {
        return {
            pathname: pathToGo,
            search: queryParams,
        };
    }
};

export const getActualPrice = (
    priceAfterDiscount: string,
    price: string,
    osbStep: OSBStep
) => {
    return (
        Number(priceAfterDiscount) < Number(price) &&
        getFormattedPrice(price, osbStep)
    );
};

export const getTotalPriceAfterDiscount = (selectedServices: any) => {
    return selectedServices
        .map(
            (result: { priceAfterDiscount: number }) =>
                result.priceAfterDiscount >= 0 && result.priceAfterDiscount
        )
        .reduce((total: number, currentPrice: number) =>
            currentPrice > 0 ? total + currentPrice : total
        );
};

export const getTotalPrice = (selectedServices: any) => {
    return selectedServices
        .map((result: { price: number }) => result.price >= 0 && result.price)
        .reduce((total: number, currentPrice: number) =>
            currentPrice > 0 ? total + currentPrice : total
        );
};

export const formatRegVinNumber = (
    regVin: string,
    allowSpecialCharacters: boolean
) => {
    if (!allowSpecialCharacters) {
        return regVin
            .replace(/ /g, '')
            .replace(/-/g, '')
            .toUpperCase();
    }
    return regVin.toUpperCase();
};

export const processDeliveryStepProgressbarDisplay = (
    results: DealerServicesArray,
    manageOsbStepVisibility: (
        currentStepKey: string,
        showStep: boolean,
        previousStepKey?: string
    ) => void
) => {
    if (!IsDealerHasDeliveryOptionOtherThanDropOff(results.dealerServices)) {
        manageOsbStepVisibility(STEP_PROGRESS_BAR_KEYS.DELIVERY, false);
        return false;
    } else {
        manageOsbStepVisibility(
            STEP_PROGRESS_BAR_KEYS.DELIVERY,
            true,
            STEP_PROGRESS_BAR_KEYS.SERVICE
        );
        return true;
    }
};

/**
 * @param  {string} serviceType
 * @param  {string} deeplinkedServices
 * @param  {DealerServicesArray} services
 * @description check wheather dealer service response containts deeplinked services of a specific service type, delivery (or) specific service page services.
 */
export const hasServicesPresentInDeeplinkedServices = (
    serviceType: string,
    deeplinkedServices: string,
    services: DealerServicesArray
) => {
    const preSelectedServices = deeplinkedServices.split(',');
    let matchFound = false;
    if (preSelectedServices?.length > 0) {
        if (serviceType === OSB_SERVICE_TYPE.DELIVERY) {
            //delivery service
            for (const service of services.dealerServices.additionalServices) {
                if (
                    preSelectedServices.includes(
                        service.serviceId.toString()
                    ) &&
                    service.additionalInfo?.serviceType
                        ?.trim()
                        .toUpperCase() === serviceType.trim().toUpperCase()
                ) {
                    matchFound = true;
                    break;
                }
            }
        } else {
            //main service
            for (const service of services.dealerServices.mainServices) {
                if (
                    preSelectedServices.includes(
                        service?.serviceId?.toString()
                    ) &&
                    service?.serviceId?.toString() != SERVICE_FLOW.GA_SERVICE_ID
                ) {
                    matchFound = true;
                    break;
                }
            }
            // old service
            if (!matchFound) {
                for (const service of services.dealerServices.oldServices) {
                    if (
                        preSelectedServices.includes(
                            service?.serviceId?.toString()
                        ) &&
                        service?.subType?.trim().toUpperCase() ===
                            SERVICE_FLOW.SUBTYPE_VALUE_SERVICE.toUpperCase()
                    ) {
                        matchFound = true;
                        break;
                    }
                }
            }
            // extra service
            if (!matchFound) {
                for (const service of services.dealerServices
                    .additionalServices) {
                    if (
                        preSelectedServices.includes(
                            service?.serviceId?.toString()
                        ) &&
                        service?.additionalInfo?.serviceType
                            ?.trim()
                            .toUpperCase() ===
                            OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                        // excluding RECALL & VHA services, and deeplinking these services make no sense
                        service?.additionalInfo?.subType
                            ?.trim()
                            .toUpperCase() !=
                            SERVICE_FLOW.SERVICE_SUBTYPE_RECALL &&
                        service?.additionalInfo?.subType
                            ?.trim()
                            .toUpperCase() != SERVICE_FLOW.SERVICE_SUBTYPE_VHA
                    ) {
                        matchFound = true;
                        break;
                    }
                }
            }
        }
    }
    return matchFound;
};

export const getDefaultDeliveryOption = (additionalServices: ServiceInfo[]) => {
    // fetching dropoff from DB service response
    if (additionalServices && additionalServices.length > 0) {
        const dropOffService: any = additionalServices?.find(
            service => service.additionalInfo.serviceType === DROP_OFF_SUBTYPE
        );
        if (dropOffService) {
            const serviceData: ServiceData = {
                id: dropOffService.id,
                serviceType: dropOffService.additionalInfo?.serviceType,
                serviceName:
                    dropOffService.name ||
                    dropOffService.additionalInfo?.offer ||
                    '',
                serviceCode: dropOffService.serviceId.toString(),
                summaryServiceName: dropOffService.name || '',
                priceAfterDiscount: dropOffService.priceAfterDiscount,
                price: dropOffService.price,
                type: dropOffService.type,
                subType: DELIVERY_STEPS_KEYS.FROM_DB,
                serviceOption:
                    DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD,
            };
            return serviceData;
        }
    }
    return null;
};

export const getFromSessionStorage = (key: string) => {
    if (serverSideService.isClientSide()) {
        return sessionStorage.getItem(key);
    }
    return null;
};

export const isAuthenticationActive = (osbStep: OSBStep) => {
    return (
        (osbStep.isAuthenticatedFlow &&
            JSON.parse(getFromSessionStorage('fmaAuthenticated') || 'false')) ||
        osbStep.isWebViewJourney
    );
};

export const getGoMainHeaderHeight = () => {
    return document.getElementById('euheaderId')?.clientHeight || 0;
};

/* Update the preselected service deeplink when a general appointment or 
drop off card is selected. Removes the service ids from deeplink based on
the default card selection. */
export const updateDeeplinkedPreSelectedServices = (
    deeplinkedPreSelectedServices: string,
    selectedServices: ServiceData[]
) => {
    const preSelectedServices = deeplinkedPreSelectedServices.split(',');

    for (const selectedService of selectedServices) {
        const removeServiceIndex = preSelectedServices.indexOf(
            selectedService.serviceCode
        );
        preSelectedServices.splice(removeServiceIndex, 1);
    }

    return preSelectedServices?.join(',');
};
export const getVehicleDashboardDefaultVin = (vehicles: VehicleDetail[]) => {
    const vehicleList = [...vehicles].sort(
        (vehicle1, vehicle2) =>
            parseInt(vehicle2.modelYear, 10) - parseInt(vehicle1.modelYear, 10)
    );
    return vehicleList[0].vin;
};

export const replaceTextKeyword = (
    textToReplace: string,
    keywords: { [key: string]: string }
) => {
    let replacedText = textToReplace;

    for (const key in keywords) {
        if (!Object.prototype.hasOwnProperty.call(keywords, key)) {
            continue;
        }

        replacedText = replacedText.replace(key, keywords[key]);
    }

    return replacedText;
};
// To frame the recall service disclaimer for vin with recall services
export const getRecallServiceDescription = (
    vehicleRecallInfo: VehicleRecallInfo
) => {
    const recallDescription: string[] = [];
    if (vehicleRecallInfo.recalls && vehicleRecallInfo.recalls.length > 0) {
        vehicleRecallInfo.recalls.forEach(item => {
            if (item.fsaNumber?.trim() && item.description?.trim()) {
                recallDescription.push(
                    item.fsaNumber.trim() + ' - ' + item.description.trim()
                );
            } else if (item.fsaNumber?.trim() && !item.description?.trim()) {
                recallDescription.push(item.fsaNumber.trim());
            }
        });
    }
    return recallDescription;
};
export interface ErrorResponse {
    errorCode?: string;
    errorMessage: string;
    statusCode?: number;
}

export const transformErrorResponse = (errorResponse: any) => {
    const transformedErrorResponse = {} as ErrorResponse;
    if (errorResponse?.response?.data?.error?.dataErrors?.length > 0) {
        transformedErrorResponse.errorCode =
            errorResponse?.response.data.error.dataErrors[0]?.name?.trim() ||
            errorResponse?.response?.data?.error?.errorCode;
        transformedErrorResponse.errorMessage =
            errorResponse?.response.data.error.dataErrors[0]?.message;
        transformedErrorResponse.statusCode =
            errorResponse?.response.data.status;
        return transformedErrorResponse;
    } else {
        return errorResponse;
    }
};

export const isServiceSelected = (valueToCheck: any, arrayObj: Array<any>) => {
    const itemFound = arrayObj.some((item: any) => {
        return item.serviceCode === valueToCheck;
    });
    if (itemFound) {
        return true;
    }
    return false;
};

export const isConvenienceOptionTypePresent = (
    valueToCheck: any,
    arrayObj: Array<any>
) => {
    const itemFound = arrayObj.some((item: any) => {
        if (item?.convenienceOptionTypes) {
            return item?.convenienceOptionTypes
                .map((optionType: string) => optionType.toUpperCase())
                .includes(valueToCheck.toUpperCase());
        }
    });
    if (itemFound) {
        return true;
    }
    return false;
};

export const addMileageSeparators = (mileage: string, separator: string) => {
    return mileage
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, separator !== '' ? separator : ',');
};

/**
 * this method checks the recommended number of characters in the VIN present or not in the input
 * @param  {string} vinRegNo
 */
export const isThisVIN = (vinRegNo: string) => {
    if (vinRegNo.length >= 10) {
        return true;
    }
    return false;
};

export const isConvenienceOptionIncluded = (
    optionTypeList: Array<any>,
    valueToCheck: string
) => {
    const itemFound = optionTypeList
        ?.map((optionType: string) => optionType.toUpperCase())
        .includes(valueToCheck.toUpperCase());

    if (itemFound) {
        return true;
    }
    return false;
};

/**
 * return a dealer profile object based on a dealer id from an array of the market object
 * return undefined in case not found
 * @param  {string} dealerId
 * @param  {DealerProfileInfo[]} dealerProfiles
 */
export const findDealerFromMarketDealers = (
    dealerId: string,
    dealerProfiles: DealerProfileInfo[]
) => {
    return dealerProfiles.find(dealer => {
        return dealer.dealerCode.toUpperCase() === dealerId.toUpperCase();
    });
};

/**
 * return true, if the dealer supports mobile service, else false
 * @param  {string[]} service
 */
export const hasMobileService = (service: string[]) => {
    if (service == null || service.length <= 0) {
        return false;
    } else {
        return service.includes(SERVICE_DELIVERY_TYPE.MOBILESERVICE);
    }
};

/**
 * Returns dealers from market dealers based on dealerGroup
 * @param dealerGroup
 * @param dealerProfiles
 */
export const getDealersByDealerGroup = (
    dealerGroup: string,
    dealerProfiles: DealerProfileInfo[]
) => {
    return dealerProfiles.filter(dealer => {
        return dealer.dealerGroup === dealerGroup;
    });
};

/**
 * @param timeSlot
 * @param minutes
 * @returns newTimeSlot
 * It takes two arguments: a timeSlot string in the format HH:mm:ss and a minutes number to subtract from the time slot.
 * It returns a new string in the same HH:mm:ss format.
 */
export function subtractMinutesFromTimeSlot(
    timeSlot: string,
    minutes: number
): string {
    //extract the hours, minutes, and seconds from the timeSlot string.
    //It first splits the string by the : separator and then maps each component to a number using the Number function.
    const [hours, minutesStr, seconds] = timeSlot.split(':').map(Number);
    //calculates the total number of minutes in the timeSlot by multiplying the hours by 60,
    //adding the minutes, and adding the seconds divided by 60.
    const totalMinutes = hours * 60 + minutesStr + seconds / 60;
    //subtracts the minutes argument from the totalMinutes calculated above to get the new total number of minutes.
    const newTotalMinutes = totalMinutes - minutes;
    //calculates the number of hours in the new time slot
    const newHours = Math.floor(newTotalMinutes / 60);
    //calculates the number of minutes in the new time slot
    const newMinutes = Math.floor(newTotalMinutes % 60);
    const newSeconds = seconds;
    //constructs the new time slot string in the HH:mm:ss format using template literals.
    //The padStart() method is used to ensure that each component has two digits, with leading zeros added if necessary.
    const newTimeSlot = `${String(newHours).padStart(2, '0')}:${String(
        newMinutes
    ).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
    return newTimeSlot;
}

/**
 * @param selectedDate
 * @param selectedTimeSlot
 * @param initialTimeSlotIntervalInSeconds
 * @returns
 */
export function getPickupDeliveryDateAndTimeSlots(
    selectedDate: string,
    selectedTimeSlot: string,
    initialTimeSlotIntervalInSeconds: number,
    availableDatesWithTimeslots: AvailableDates[]
): AvailableDates[] {
    //finds the index of the selectedDate within the availableDatesWithTimeslots array.
    //The findIndex method is used to return the index of the first element that matches the specified condition.
    const selectedDateIndex = availableDatesWithTimeslots.findIndex(
        slot => slot.date === selectedDate
    );
    //retrieves the AvailableDates object from the availableDatesWithTimeslots array at the selectedDateIndex position.
    const selectedDateObj = availableDatesWithTimeslots[selectedDateIndex];
    //checks if the selectedDateObj is undefined, and if so, returns an empty array.
    if (!selectedDateObj) {
        return [];
    }
    //finds the index of the selectedTimeSlot within the availableTimeSlots array of the selectedDateObj object.
    const selectedTimeSlotIndex = selectedDateObj.availableTimeSlots?.findIndex(
        slot => slot === selectedTimeSlot
    );
    //this if statement checks if the selectedTimeSlotIndex is 0, which means the selected time slot is the first one available for the selected date.
    //it then finds the index of the previous date object and retrieves it from the availableDatesWithTimeslots array.
    if (selectedTimeSlotIndex === 0) {
        const selectedTimeSlots: string[] | undefined = [];
        //initializes firstTimeSlot with the first time slot from the selectedDateObj object's availableTimeSlots array.
        const firstTimeSlot = selectedDateObj.availableTimeSlots?.[0];
        //if firstTimeSlot is not null or undefined, then subtracts initialTimeSlotIntervalInSeconds (which is a number of seconds)
        //from the firstTimeSlot using the subtractMinutesFromTimeSlot function and assigns it to the first element of the selectedTimeSlots array.
        if (firstTimeSlot) {
            selectedTimeSlots[0] = subtractMinutesFromTimeSlot(
                firstTimeSlot,
                initialTimeSlotIntervalInSeconds
            );
        }
        const previousDateIndex = selectedDateIndex - 1;
        const previousDateObj = availableDatesWithTimeslots[previousDateIndex];
        //if the previousDateObj is not undefined and retrieves its available time slots.
        if (previousDateObj) {
            const previousDateTimeSlots =
                previousDateObj.availableTimeSlots || [];
            //returns an array of two objects.
            //the first object contains the date property of previousDateObj and the availableTimeSlots property of previousDateTimeSlots.
            //the second object contains the date property of selectedDateObj and the availableTimeSlots property of selectedTimeSlots.
            return [
                {
                    date: previousDateObj.date,
                    availableTimeSlots: previousDateTimeSlots,
                },
                {
                    date: selectedDateObj.date,
                    availableTimeSlots: selectedTimeSlots,
                },
            ];
        } else {
            return [
                {
                    date: selectedDateObj.date,
                    availableTimeSlots: selectedTimeSlots,
                },
            ];
        }
        //if selectedTimeSlotIndex is greater than 0, this code block initializes the selectedTimeSlots array with a slice of the availableTimeSlots array of the selectedDateObj object.
        //The slice includes elements from the beginning of the array up to the element at index selectedTimeSlotIndex.
    } else if (selectedTimeSlotIndex && selectedTimeSlotIndex > 0) {
        const selectedTimeSlots:
            | string[]
            | undefined = selectedDateObj.availableTimeSlots?.slice(
            0,
            selectedTimeSlotIndex
        );
        //it returns an array of a single object containing the date property of selectedDateObj and the availableTimeSlots property of selectedTimeSlots.
        return [
            {
                date: selectedDateObj.date,
                availableTimeSlots: selectedTimeSlots,
            },
        ];
    }
    //if none of the conditions are met, the function returns an empty array.
    return [];
}

export const isAuthenticatedFlow = () => {
    const fmaAuthenticated = getFromSessionStorage('fmaAuthenticated');
    return fmaAuthenticated === 'true';
};

export const getCustomerPreferredDealers = (
    profile: ProfileWithVehiclesResponse | undefined,
    numberOfLeadingCharactersToStrip: number,
    allOSBDealers: DealerProfileInfo[]
): string[] => {
    return profile?.vehicles
        ? profile.vehicles.reduce((acc: string[], item: VehicleDetail) => {
              if (item.preferredDealer) {
                  const preferredDealerCode = item.preferredDealer.substring(
                      numberOfLeadingCharactersToStrip
                  );
                  if (
                      allOSBDealers.some(
                          dealer => dealer.dealerCode === preferredDealerCode
                      )
                  ) {
                      return [...acc, preferredDealerCode];
                  }
              }
              return acc;
          }, [])
        : [];
};

export const getVehiclePreferredDealer = (
    vehicles: VehicleDetail[],
    vinOrIndex: string | number,
    numCharacters: number,
    allOSBDealers: DealerProfileInfo[],
    includeStripping: boolean
): string => {
    const vehicle =
        typeof vinOrIndex === 'string'
            ? vehicles.find(v => v.vin === vinOrIndex)
            : vehicles[vinOrIndex];
    const preferredDealer = vehicle ? vehicle.preferredDealer || '' : '';
    if (preferredDealer.trim().length <= 0) {
        return '';
    }
    const preferredDealerWithStripping =
        preferredDealer.length >= numCharacters
            ? preferredDealer.slice(numCharacters)
            : preferredDealer;

    const matchingDealer = allOSBDealers.find(
        dealer => dealer.dealerCode === preferredDealerWithStripping
    );

    if (matchingDealer) {
        return includeStripping ? matchingDealer.dealerCode : preferredDealer;
    } else {
        console.log(
            `Found preferred dealer ${preferredDealer} but not OSB enabled`
        );
        return '';
    }
};

export const isPreferredDealer = (
    vin: string,
    vehicles: VehicleDetail[],
    dealer: DealerProfileInfo,
    leadingCharsToStrip: number
): boolean => {
    if (!vehicles || vehicles.length === 0) {
        return false;
    }
    const vehicleDetail = vehicles.find(vehicle => vehicle.vin === vin);
    if (vehicleDetail && vehicleDetail.preferredDealer) {
        const strippedPreferredDealer = vehicleDetail.preferredDealer.substring(
            leadingCharsToStrip
        );
        return strippedPreferredDealer === dealer.dealerCode;
    }
    return false;
};

export const formatDateWithIsoFormat = (
    isPickUpDate: boolean,
    formattedDateAndTime: string,
    isoFormat: string
) => {
    if (isPickUpDate) {
        return formattedDateAndTime;
    } else {
        return `${formattedDateAndTime}.${isoFormat}`;
    }
};

export const constructAppointmentTime = (
    selectedDate: string,
    selectedTime: string,
    isMobileServiceSelected: boolean,
    isPickUpDate: boolean
) => {
    const isMobileService = isMobileServiceSelected || false;
    if (selectedDate.length > 1 || selectedTime.length > 1) {
        const isoFormat = new Date().toJSON().split('.')[1];
        let selectedTimeFormat = [];
        let formattedDateAndTime = '';
        if (isMobileService) {
            selectedTimeFormat = selectedTime.split('-');
            const appointmentDateAndTime = new Date(
                selectedDate + 'T' + selectedTimeFormat[0].trim()
            );
            formattedDateAndTime = dateFns
                .format(appointmentDateAndTime, "yyyy-MM-dd'T'HH:mm:ss")
                .toString();
        } else {
            const appointmentDateAndTime = new Date(
                selectedDate + 'T' + selectedTime
            );
            formattedDateAndTime = dateFns
                .format(appointmentDateAndTime, "yyyy-MM-dd'T'HH:mm:ss")
                .toString();
        }
        return formatDateWithIsoFormat(
            isPickUpDate,
            formattedDateAndTime,
            isoFormat
        );
    } else {
        return '';
    }
};
