import React from 'react';
import { NotificationsFragment } from '../../../../models/experiencefragments/vehicle-notifications';
import { DetailedNotificationItem } from '../detailed-notification-item/detailed-notification-item';
import { useAnalytics } from '../../../../hooks/use-analytics';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { PreImagineNotificationItem } from '../notification-item/pre-imagine-notification-item';

interface Props {
    content?: NotificationsFragment | null;
    vin?: string;
}

export const HandoverNotifications = (props: Props) => {
    const { content } = props;
    const [fireEvents] = useAnalytics();

    const triggerOnClickAnalytics = () => {
        fireEvents(
            ['notifications-cta-onclick-event'],
            undefined,
            {
                notificationCategory: 'eyf',
                notificationCtaName: content?.handover?.ctaTitle?.toLowerCase(),
            },
            false
        );
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;

    return (
        <div id="eyf-notification">
            {currentVersion ? (
                <DetailedNotificationItem
                    headText={content?.handover?.handoverTitle}
                    subText={content?.handover?.description}
                    iconPath={content?.handover?.iconPath}
                    handover={content?.handover}
                    handoverOnclickAnalytics={triggerOnClickAnalytics}
                />
            ) : (
                <PreImagineNotificationItem
                    headText={content?.handover?.handoverTitle}
                    subText={content?.handover?.description}
                    iconPath={content?.handover?.iconPath}
                    handover={content?.handover}
                    handoverOnclickAnalytics={triggerOnClickAnalytics}
                />
            )}
        </div>
    );
};
