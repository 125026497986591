import React, { useEffect, useState } from 'react';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import './handover-disclosure.scss';

interface Props {
    defaultStateOpen?: boolean | null;
    i18n: Map<string, string | boolean>;
}

export const HandoverDisclosure = (props: Props) => {
    const showHandoverDisclosure =
        !!props.i18n.get('mainPageImportantInfo') &&
        !!props.i18n.get('mainPageImportantInfoHeader');

    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
    useEffect(() => {
        if (props?.defaultStateOpen) {
            setPanelIsOpen(!panelIsOpen);
        }
    }, [props?.defaultStateOpen]);

    return showHandoverDisclosure ? (
        <div className="handover-disclosure-button-section">
            <div className="handover-disclosure-container">
                <div className="handover-disclosure-button">
                    <button
                        className="handover-disclosure-button-link"
                        onClick={() => {
                            setPanelIsOpen(!panelIsOpen);
                        }}
                        tabIndex={0}
                    >
                        {String(props.i18n.get('mainPageImportantInfoHeader'))}
                        <FdsChevron
                            type="unfilled"
                            direction={panelIsOpen ? 'up' : 'down'}
                        />
                    </button>
                    {panelIsOpen && (
                        <div
                            className="handover-disclosure-content"
                            tabIndex={0}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: String(
                                        props.i18n.get('mainPageImportantInfo')
                                    ),
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    );
};

export default HandoverDisclosure;
