import React from 'react';
import { BreadcrumbWrapper } from '../../../common/breadcrumb-wrapper/breadcrumb-wrapper';

interface MaintenanceScheduleBreadcrumbProps {
    breadcrumbTitle: string;
    maintenanceLandingPath: string;
    currentRoot: string;
    vehicleName: string;
    isWebview: boolean;
}

interface MaintanenceScheduleLandingBreadCrumbProps {
    breadcrumbText: string;
    currentRoot: string;
    maintenanceLandingPath: string;
}

const MaintanenceScheduleLandingBreadCrumb: React.FC<MaintanenceScheduleLandingBreadCrumbProps> = ({
    breadcrumbText,
    currentRoot,
    maintenanceLandingPath,
}) => {
    return (
        <>
            <a
                className="breadcrumb-link"
                href={`${currentRoot}${maintenanceLandingPath}`}
                aria-label={`Click to goto ${breadcrumbText} page`}
                onClick={() => sessionStorage.removeItem('selectedVin')}
            >
                <span>{breadcrumbText}</span>
            </a>
            <span className="back-slash">{'>'}</span>
        </>
    );
};

const MaintenanceScheduleBreadcrumb: React.FC<MaintenanceScheduleBreadcrumbProps> = ({
    breadcrumbTitle,
    maintenanceLandingPath,
    currentRoot,
    vehicleName,
    isWebview,
}) => {
    const breadcrumbText = breadcrumbTitle
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ');

    return (
        <div>
            {isWebview ? (
                ''
            ) : (
                <BreadcrumbWrapper
                    currentPage={vehicleName}
                    hashId="support"
                    hideFirstBreadcrumb={isWebview}
                    title={
                        isWebview ? (
                            ''
                        ) : (
                            <MaintanenceScheduleLandingBreadCrumb
                                breadcrumbText={breadcrumbText}
                                currentRoot={currentRoot}
                                maintenanceLandingPath={maintenanceLandingPath}
                            />
                        )
                    }
                />
            )}
        </div>
    );
};

export default MaintenanceScheduleBreadcrumb;
