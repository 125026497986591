import React from 'react';
import { findByAlias, findByPath } from '../../../routes';
import {
    HandoverView,
    HandoverDetailsView,
    CreateCheckListView,
    SendToDealerView,
} from '../../../views';
import { useParams, useLocation } from 'react-router-dom';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import { getFromSessionStorage } from '../owners-osb/osb-utils';

export const HandoverPage = (): string => {
    return findByAlias('HandoverViewRoute');
};

export const HandoverDetailsPage = (): string => {
    return findByAlias('HandoverDetailRoute');
};

export const HandoverCreateChecklistPage = (): string => {
    return findByAlias('CreatChecklistRoute');
};

export const HandoverSendToDealerPage = (): string => {
    return findByAlias('SendToDealerRoute');
};

const HandoverViewSelector = (componentName: string) => {
    switch (componentName) {
        case 'HandoverViewRoute':
            return <HandoverView />;
        case 'HandoverDetailRoute':
            return <HandoverDetailsView />;
        case 'CreatChecklistRoute':
            return <CreateCheckListView />;
        case 'SendToDealerRoute':
            return <SendToDealerView />;
        default:
            return <HandoverView />;
    }
};

export const HandoverWrapper: React.FunctionComponent = (): React.JSX.Element => {
    const isAuthenticated = JSON.parse(
        getFromSessionStorage('fmaAuthenticated') || 'false'
    );
    if (!isAuthenticated) {
        const authenticationService = new AuthenticationService();
        authenticationService.onIsAuthenticated().then(authenticated => {
            !authenticated && authenticationService.login();
        });
    }

    const { view: viewPath } = useParams<{ view: string }>();
    const location = useLocation();
    let basePath: string;
    let splitViewPath: any = [];
    if (viewPath) {
        splitViewPath = location.pathname.split('/');
    }

    const b: string | string[] = findByAlias('HandoverWrapper');
    if (typeof b == 'string') {
        basePath = b;
    } else {
        if (splitViewPath.length >= 4) {
            basePath = b[3].split(':')[0];
        } else {
            basePath = b[0];
        }
    }
    let component;
    if (viewPath) {
        if (splitViewPath.length >= 4) {
            component = findByPath(basePath + ':feature');
        } else {
            component = findByPath(basePath + '/' + viewPath);
        }
    } else {
        component = 'HandoverViewRoute';
    }
    return <>{HandoverViewSelector(component)}</>;
};
export default HandoverWrapper;
