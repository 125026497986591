import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import {
    RidesQueryParams,
    UnAssignedRidesResponse,
    AssignedRidesResponse,
    SaveAssignedRidesEntryData,
    BusinessRideResponse,
    ReducerInitialState,
} from '../../models/ford-rides-trips-service-model';
import { AxiosRequestConfig } from 'axios';
import ServiceHandler from '../service-handler';

export default class FordRidesTripsService {
    async getUnassignedRides(
        unassignedQueryParams?: RidesQueryParams,
        vin?: any
    ) {
        const unassignedRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/cat/unassigned`;
        return await HttpService.get<UnAssignedRidesResponse>(
            unassignedRidesUrl,
            false,
            {
                params: {
                    ...unassignedQueryParams,
                    country: (
                        unassignedQueryParams?.country ??
                        ServiceHandler.AppConfigurationService
                            .current3LetterCountryCode
                    )?.toUpperCase(),
                },
                headers: {
                    'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                        'Consumer-Key'
                    ],
                    'Auth-Token':
                        HttpService.getAppIdAndAuthTokenRequestHeaders()[
                            'Auth-Token'
                        ] ?? '',
                    Vin: vin,
                },
            }
        ).then(({ data }) => data);
    }

    async getAssignedRides(assignedQueryParams?: RidesQueryParams, vin?: any) {
        const assignedRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/adfs/assigned`;
        return await HttpService.get<AssignedRidesResponse>(
            assignedRidesUrl,
            false,
            {
                params: {
                    ...assignedQueryParams,
                    country: (
                        assignedQueryParams?.country ??
                        ServiceHandler.AppConfigurationService
                            .current3LetterCountryCode
                    )?.toUpperCase(),
                    userGuid: assignedQueryParams?.userGuid,
                },
                headers: {
                    'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                        'Consumer-Key'
                    ],
                    Vin: vin,
                },
            }
        ).then(({ data }) => data);
    }

    async saveAssignedRides(
        saveRidesData?: ReducerInitialState,
        config?: AxiosRequestConfig
    ) {
        const saveRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/adfs/save`;
        return await HttpService.post<BusinessRideResponse>(
            saveRidesUrl,
            {
                ...(saveRidesData as SaveAssignedRidesEntryData),
                tripCountry:
                    saveRidesData?.tripCountry ??
                    ServiceHandler.AppConfigurationService.current3LetterCountryCode.toUpperCase(),
                tripGuid: saveRidesData?.tripGuid,
            },
            {
                headers: { 'Content-Type': 'application/json' },
                ...config,
            }
        ).then(({ data }) => data);
    }
}
