import React from 'react';
import './dealer-step-view.scss';
import { LightDealerFlow } from '../../../components/sections/owners-osb/dealer-step/light-journey/light-dealer-flow/light-dealer-flow';
import {
    useDealerStep,
    useOSBStep,
    useViewport,
} from '../../../hooks/owners-osb';
import { OsbLoader } from '../../../components/common/osb-loader/osb-loader';
import { Accordion } from '../../../components/common';
import { buildNavigationUrl } from '../../../components/sections/owners-osb/osb-utils';
import { useHistory } from 'react-router-dom';
import { OsbPathBookingRetrieveStep } from '../../../components/sections/owners-osb/osb-controller';
import { Button } from '@own/fds-react';

export function DealerStepView() {
    const { osbStep } = useOSBStep();
    const { isMobileView } = useViewport();
    const history = useHistory();
    const { osbDealerStep } = useDealerStep();

    const accordionContent = () => {
        return (
            <div className="retrieve-flow-cta">
                <p>{osbStep.osbHomeRetrieveBookingCopyText}</p>
                <div className="retrieve-btn">
                    <Button
                        aria-label="retrieve-a-booking"
                        aria-labelledby="Retrieve a booking cta"
                        data-test-id={`RetrieveABooking`}
                        href=""
                        label={osbStep.osbHomeRetrieveBookingButtonLabel}
                        noShadow
                        type="standard"
                        onClick={() => {
                            history.push(
                                buildNavigationUrl(
                                    OsbPathBookingRetrieveStep(),
                                    ''
                                )
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {osbStep.isMainWrapperInitialized ? (
                <>
                    {osbStep.enableNewLandingPage ? (
                        <>
                            <div className="new-dealer-step-view-container">
                                <LightDealerFlow />
                            </div>
                            {osbDealerStep.dealerProfiles.length === 0 &&
                                osbStep.enableOsbHomeRetrieveBooking &&
                                !osbStep.isWebViewJourney && (
                                    <>
                                        {!isMobileView && accordionContent()}
                                        {isMobileView && (
                                            <>
                                                <div className="mobile-retrieve-container">
                                                    <div className="mobile-retrieve-section">
                                                        <Accordion
                                                            index="1"
                                                            className=""
                                                            header={
                                                                osbStep.osbHomeRetrieveBookingHeader
                                                            }
                                                            panel={accordionContent()}
                                                            expandMultiplePanels={
                                                                true
                                                            }
                                                            expandPanel={false}
                                                            borderBottom={false}
                                                            chevronText=" "
                                                            fdsChevronType={
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                        </>
                    ) : (
                        <div className="dealer-step-view-container">
                            <LightDealerFlow />
                        </div>
                    )}
                </>
            ) : (
                <OsbLoader />
            )}
        </>
    );
}
