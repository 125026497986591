import HttpService from '../http-service/http-service';
import { VehicleOrderStatusResponse } from '../../models/handover-data';

export default class VehicleOrderStatus {
    public async getVehicleOrder(
        vehicleOrderStatusLookUpURL: string,
        vin: string
    ): Promise<VehicleOrderStatusResponse | any> {
        try {
            const url = `${vehicleOrderStatusLookUpURL}`;
            const lookup = HttpService.get(url, false, {
                headers: {
                    vin,
                    ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
                },
            });
            const responseData = await lookup;

            const response: VehicleOrderStatusResponse = responseData.data;

            return response;
        } catch (error) {
            return null;
        }
    }
}
