import { BreadcrumbWrapper } from '../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import { ViewTemplate } from '../../components/sections';
import React from 'react';
import { useVehicleHealthPageComponents } from '../../components/sections/vehicle-health/components/vehicle-health-page-components';
import { SlowTireLeakDetails } from '../../components/sections/vehicle-health/components/slow-tire-leak/slow-tire-leak-details';
import { useSlowTireLeakDetailsContent } from '../../components/sections/vehicle-health/hook/use-slow-tire-leak-details-content';
import serverSideService from '../../services/server-side-service/server-side-service';
export const SlowTireLeakDetailsView = () => {
    const slowTireLeakDetailsContent: any = useSlowTireLeakDetailsContent();
    const [vehicleHealthComponent] = useVehicleHealthPageComponents();
    const VhaAlertBreadCrumb = () => {
        let vhaUrl = '';
        if (serverSideService.isClientSide()) {
            const currentRoute = window.location.pathname;
            vhaUrl = currentRoute.endsWith('/')
                ? currentRoute.substring(0, currentRoute.length - 1)
                : currentRoute;
        }
        return (
            <>
                <a
                    className="breadcrumb-link"
                    href={`${vhaUrl.substr(0, vhaUrl.lastIndexOf('/'))}`}
                >
                    <span>{vehicleHealthComponent?.vehicleHealthTitle}</span>
                </a>
                <span className="back-slash">{'>'}</span>
            </>
        );
    };
    return (
        <>
            {slowTireLeakDetailsContent && (
                <ViewTemplate
                    page={
                        slowTireLeakDetailsContent?.slowTireLeak
                            ?.slowTireLeakBreadcrum
                    }
                >
                    <BreadcrumbWrapper
                        currentPage={
                            slowTireLeakDetailsContent?.slowTireLeak
                                .slowTireLeakBreadcrum
                        }
                        hashId="support"
                        title={<VhaAlertBreadCrumb />}
                    />
                    <SlowTireLeakDetails />
                </ViewTemplate>
            )}
        </>
    );
};

export default SlowTireLeakDetailsView;
