import React, { useState, useEffect } from 'react';
import './createChecklists.scss';
import { GetFeatureName } from '../../utils/FeatureKey-to-Name-Mapping/get-feature-name-using-key';
import { HandoverPage } from '../handover/handover-controller';
import { HandoverNotification } from '../handover/handover-notification/handover-notification';
import { PageType } from '../view-template/page-type';
import { PDFCreation } from './pdfCreation';
import { useAnalytics } from '../../../hooks/use-analytics';
import { useHandoverModel } from '../handover/hooks/use-vehicle-handover-model';
import { useHistory } from 'react-router-dom';
import { useSendToDealerModel } from '../handover/hooks/use-sendToDealer-model';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import Disclosure from '../handover/disclosure/disclosure';
import Footerchecklist from '../handover/footer-checklist/footer-checklist';
import handoverService from '../../../services/handover/handover-service';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { PrimaryButton } from '../../../components/common';
import { HandoverTracker } from './handover-tracker/handover-tracker';
import { HandoverBreadCrumbs } from '../handover/handover-breadcrumbs/handover-breadcrumbs';
import { GetChecklistFeatureservice } from '../../../services/handover/get-checklist-feature-service';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';

function createTrackerData(
    step: string | boolean | undefined | number,
    filled = true,
    active = true,
    text = ''
) {
    return { step, filled, active, text };
}
interface Props {
    vehicleAttributes: VehicleAttributes;
    page: PageType;
}

export const CreateCheckList = (props: Props) => {
    const userSelectedVin: string = handoverService.fetchSelectedVin() || '';
    const { vehicleAttributes, page } = props,
        [selectedData, setSelectedData] = useState([] as any),
        [selectedkeyFeatures, setSelectedkeyFeatures] = useState<object[]>([]),
        [featFlag, setFeatFlag] = useState(true),
        [fireEvents] = useAnalytics();
    const [counter, setCounter] = useState(0);
    const [checkFeatureString, setCheckFeatureString] = useState('');
    const [
            showSuccessNotificationBar,
            setShowSuccessNotificationBar,
        ] = useState(false),
        [selectedCount, setSelectedCount] = useState(0);
    const isPostHandover = handoverService.getDataFromSessionStorage(
        'isPostHandoverState'
    );
    const isFromReviewPage = handoverService.getDataFromSessionStorage(
        'isFromReviewPage'
    );

    const history = useHistory();

    useEffect(() => {
        handleReviewPage();
        handlePostHandover();
    }, [isPostHandover]);

    function handleReviewPage() {
        if (isFromReviewPage) {
            setShowSuccessNotificationBar(true);
            handoverService.removeFromSessionStorage('isFromReviewPage');
        }
    }

    function handlePostHandover() {
        if (isPostHandover) {
            handleClickEYF();
        } else fetchChecklistFeatures();
    }

    function fetchChecklistFeatures() {
        new GetChecklistFeatureservice()
            .getCreateChecklistservice(userSelectedVin)
            .then(handleChecklistResponse);
    }

    function handleChecklistResponse(response: any) {
        if (
            response &&
            (response.message || response.checkedFeatures.length == 0)
        ) {
            handleClickEYF();
        }
        const checkFeatureStringMap = response?.checkedFeatures?.map(
            (el: { key: any }) => el.key
        );
        setCheckFeatureString(checkFeatureStringMap);
        handoverService.setDataToSessionStorage(
            'submitted-features',
            Array.from(checkFeatureStringMap)
        );

        handoverService.setDataToSessionStorage('create-checklist-features', {
            vinPlaceholder: checkFeatureStringMap,
        });
        const customerNotes = [
            {
                customerNotesValue: response.customerNotes,
                vin: userSelectedVin,
            },
        ];
        handoverService.setDataToSessionStorage('customerNotes', customerNotes);
    }

    let trackerDataEyfIdeal: any;

    const featureData = GetFeatureName().featuresArray;
    if (featureData != undefined && featureData.length > 0 && featFlag) {
        setSelectedData(featureData);
        setFeatFlag(false);
    }

    const i18n: any = useHandoverModel(),
        i18nSendToDealer = useSendToDealerModel();
    let customerNotes: any;

    const cachedCustomerNotes = handoverService.getDataFromSessionStorage(
        'customerNotes'
    );
    if (cachedCustomerNotes) {
        cachedCustomerNotes.map((item: any) => {
            if (item.vin == userSelectedVin) {
                customerNotes = item.customerNotesValue;
            }
        });
    }

    const triggerLoadAnalytics = () => {
        /**On Page Load */
        fireEvents(
            ['Eyf-create-checklist-page-load'],
            undefined,
            {
                ymm: Promise.resolve(vehicleAttributes),
                namePlateValue: vehicleAttributes.model,
            },
            false
        );
    };

    useEffect(() => {
        triggerLoadAnalytics();
    }, []);

    if (i18nSendToDealer.size > 0 && i18n.size > 0) {
        trackerDataEyfIdeal = [
            createTrackerData(
                i18n.get('handoverStepOne'),
                true,
                false,
                i18n.get('exploreFeaturesTitle')
            ),
            createTrackerData(
                i18n.get('handoverStepTwo'),
                true,
                false,
                i18n.get('reviewLabel')
            ),
            createTrackerData(
                i18n.get('handoverStepThree'),
                true,
                false,
                i18nSendToDealer.get('sendToDealer') &&
                    i18nSendToDealer.get('sendChecklistToDealer')
                    ? i18nSendToDealer.get('sendToDealer')
                    : i18n.get('createChecklistTitle')
            ),
        ];
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {
        const selectedFeature = getSelectedFeature();
        const vinPlaceholder = getVinPlaceholder(selectedFeature);
        updateSelectedKeyFeatures(vinPlaceholder);
    }, [checkFeatureString, selectedData]);

    function getSelectedFeature() {
        return ServerSideService.isClientSide()
            ? handoverService.getDataFromSessionStorage(
                  'create-checklist-features'
              )
            : '';
    }

    function getVinPlaceholder(selectedFeature: any) {
        const { vinPlaceholder = [] } = selectedFeature || '{}';
        return vinPlaceholder;
    }

    function updateSelectedKeyFeatures(vinPlaceholder: any[]) {
        if (selectedData != undefined && selectedData.length > 0) {
            const filterFeaturesArray = selectedData.filter((i: any) =>
                vinPlaceholder.includes(i.id)
            );
            setSelectedkeyFeatures(filterFeaturesArray);
        }
    }

    useEffect(() => {
        updateSelectedCount();
    }, [checkFeatureString, Footerchecklist]);

    function updateSelectedCount() {
        try {
            if (ServerSideService.isClientSide()) {
                const vinPlaceholder = getVinPlaceholderVal();
                setSelectedCount(vinPlaceholder.length);
            }
        } catch (e) {
            return;
        }
    }

    function getVinPlaceholderVal() {
        const { vinPlaceholder = [] } =
            handoverService.getDataFromSessionStorage(
                'create-checklist-features'
            ) || '{}';
        return vinPlaceholder;
    }

    useEffect(() => {
        setCounter(counter === undefined ? 1 : counter + 1);
    }, []);

    function extractHeadline(length: number) {
        const heading = String(i18n.get('checklistHeadline')) || '';
        const index = heading.indexOf('FEATURE_COUNT');
        const lastIndex = 'FEATURE_COUNT'.length;
        const str1 = heading.substring(0, index - 2);
        const str2 = heading.substring(index + (lastIndex + 2));
        const res = `${str1}${length}${' '}${str2}`;

        return res;
    }

    const checklistHeadline = extractHeadline(selectedCount);
    const onCloseClick = () => {
        setShowSuccessNotificationBar(false);
    };

    const handleClickEYF = () => {
        localStorage.setItem('USER_VIN', userSelectedVin);
        history.push(HandoverPage());
    };

    return selectedkeyFeatures.length > 0 ? (
        <div className="checklist-wrapper-ideal" id="scrollView">
            <span className="notification-print">
                {showSuccessNotificationBar &&
                    i18nSendToDealer.get('sendToDealer') &&
                    i18nSendToDealer.get('sendChecklistToDealer') && (
                        <HandoverNotification
                            notificationMessage={String(
                                i18nSendToDealer.get('checkListSuccessMessage')
                            )}
                            notificationStatus="successBar"
                            handler={onCloseClick}
                        />
                    )}
            </span>
            <HandoverBreadCrumbs currentPage="confirm" originFromVD={false} />
            <div className="checklist-description checklist-description-extra">
                <div className="checklist-info">
                    <HandoverTracker trackerData={trackerDataEyfIdeal} />
                    <div className="checklist-heading-content">
                        <h2>
                            {i18nSendToDealer.get('sendToDealer') &&
                            i18nSendToDealer.get('sendChecklistToDealer')
                                ? i18n.get('checklistSubHeading') + '!'
                                : i18n.get('handoverSubHeading')}
                        </h2>
                    </div>
                    <div
                        className="checklist-subheading-content"
                        dangerouslySetInnerHTML={{
                            __html: checklistHeadline,
                        }}
                    />

                    <div className="checklist-selected-features">
                        <h3 className="feature-header">
                            {String(i18n.get('featuresChosen'))}
                        </h3>
                        <div className="feature-group-main">
                            {selectedkeyFeatures &&
                                selectedkeyFeatures.map((text: any) => (
                                    <p
                                        key={text.id}
                                        className={`feature-group-${text.name}`}
                                    >
                                        {text.name}
                                    </p>
                                ))}
                            <div className="checklist-request">
                                <h3>
                                    {' '}
                                    {i18nSendToDealer.get(
                                        'specialRequestSubHeadline'
                                    )}
                                </h3>
                                <span className="checklist-favourite-request">
                                    {customerNotes}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="edit-selections">
                        <h3>{i18n.get('changedYourMindText')}</h3>
                        <div className="edit-selections-btn">
                            <PrimaryButton
                                color="dark"
                                fill="outline"
                                chevron={true}
                                onClick={handleClickEYF}
                                ariaLabel={i18n.get('editSelectionCTAText')}
                            >
                                {i18n.get('editSelectionCTAText')}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>

            <Disclosure i18n={i18n} showHeader={true} />
            <div className="checklist-button-pdf">
                <PDFCreation
                    vehicleAttributesData={vehicleAttributes}
                    selectedFeatures={selectedkeyFeatures}
                    page={page}
                    i18n={i18n}
                    downloadLabel={String(i18n.get('downloadChecklistAsPDF'))}
                />
            </div>
        </div>
    ) : (
        <ActivityIndicator className="full-height" />
    );
};

export default CreateCheckList;
