import { Breadcrumbs } from '@own/fds-react';
import { useHandoverModel } from '../hooks/use-vehicle-handover-model';
import './handover-breadcrumbs.scss';

interface HandoverBreadCrumbsProps {
    currentPage: string;
    originFromVD: boolean;
    featureName?: string;
}

export const HandoverBreadCrumbs = (props: HandoverBreadCrumbsProps) => {
    const i18n = useHandoverModel();

    const breadcrumbsItems = [];

    if (props.originFromVD) {
        breadcrumbsItems.push({
            name: String(i18n.get('backToVehicleDashboardLabel')),
            href: String(i18n.get('backToVehicleDashboardLink')),
        });
    } else {
        breadcrumbsItems.push({
            name: String(i18n.get('backToAccountLabel')),
            href: String(i18n.get('backToAccountLink')),
        });
    }
    breadcrumbsItems.push({
        name: String(i18n.get('backToEYFLabel')),
        href: String(i18n.get('backToEYFLink')),
    });

    if (props.currentPage === 'featureDetails') {
        breadcrumbsItems.push({
            name: `${props.featureName}`,
            href: '',
        });
    }

    return <Breadcrumbs items={breadcrumbsItems} />;
};
