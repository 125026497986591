interface Props {
    breadcrumbUrl: string;
    breadcrumbTitle: string;
}

export const TireFinderBreadCrumb = (props: Props) => {
    return (
        <>
            <a className="breadcrumb-link" href={props.breadcrumbUrl}>
                <span>{props.breadcrumbTitle}</span>
            </a>
            <span className="back-slash">{'>'}</span>
        </>
    );
};
