import React from 'react';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import MmotaModalLink from '../mmota-modal-link/mmota-modal-link';
import { Modal } from '../../../common/modal/modal';
import { MMOTAStatusResponse } from '../../../../services/mmota-status-service/mmota-status.interface';
import { DSL_MMOTA_STATUS, MMOTAUtil } from '../mmota-util';
import './mmota-modal.scss';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { FMButton, FMModal } from '@own/fds-react';

interface Props {
    vin?: string;
    isVisible: boolean;
    onClose: () => void;
    dynamicCTAPath?: string;
    otaStatus: MMOTAStatusResponse;
}

const MMOTAModal = (props: Props) => {
    let header;
    let description: string | undefined = '';
    let parsedRecurringDateTime;
    let parsedLatestDateTime;
    let updateDisplayTime = '45';
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const {
        summary,
        mmotaAlertsDetails,
        tappsData,
        ccsStatus,
        releaseNotes,
        error,
        status,
    } = props.otaStatus;

    const mmotaUtil = new MMOTAUtil();
    const mmotaStatusModalContent = mmotaUtil.mmotaStatusModalContent;
    const dateTimeUtil = new DateTimeUtil();
    const mmotaAlertsDetail = mmotaAlertsDetails && mmotaAlertsDetails[0];
    let currentStatus = summary?.alertDescription;
    if (!currentStatus) {
        currentStatus = status?.errorDescription;
    }
    if (mmotaAlertsDetail?.dateTimeStamp) {
        parsedLatestDateTime = dateTimeUtil.parseDateTime(
            mmotaAlertsDetail?.dateTimeStamp,
            true
        );
    }
    const { date, time } = parsedLatestDateTime || {};

    const closeModal = () => {
        props.onClose();
    };

    const createLink = (href: string) => {
        const link = document.createElement('a');
        link.href = href;
        link.target = '_blank';
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        }, 100);
    };

    const primaryBtnClick = () => {
        if (props.vin && props?.dynamicCTAPath) {
            createLink(props.dynamicCTAPath.replace('%vin', props.vin));
            closeModal();
        }
    };

    const secondaryBtnClick = () => {
        if (props?.dynamicCTAPath) {
            createLink(props.dynamicCTAPath);
            closeModal();
        }
    };

    if (mmotaAlertsDetail?.updateDisplayTime) {
        updateDisplayTime = `${mmotaAlertsDetail?.updateDisplayTime}`;
    }

    if (tappsData?.scheduleType) {
        if (tappsData?.scheduleType?.toLowerCase() === 'none') {
            parsedRecurringDateTime =
                mmotaStatusModalContent?.recurringScheduleNotSetText ??
                'Not Set';
        }
        if (tappsData?.scheduleType.toLowerCase() === 'recurring') {
            parsedRecurringDateTime = mmotaUtil.getParsedRecurringDateTime(
                tappsData,
                mmotaStatusModalContent?.showInMeridiemTime
            );
        }
    }

    const isAccessUnavailable = error?.message === 'UserDisallowed';

    let releaseNotesAvailable = true;
    let updateNotesDescription = '';
    if (releaseNotes) {
        updateNotesDescription = releaseNotes;
    } else {
        updateNotesDescription = mmotaStatusModalContent?.releaseNotesDesc as string;
    }

    function headerDescription() {
        if (tappsData)
            return mmotaStatusModalContent?.updateReminderStateDesc?.replace(
                '%date%',
                mmotaUtil.getParsedRecurringDateTime(
                    tappsData,
                    mmotaStatusModalContent?.showInMeridiemTime
                )
            );
    }

    if (mmotaStatusModalContent) {
        if (isAccessUnavailable) {
            header = mmotaStatusModalContent.accessUnavailableStateTitle;
            description = mmotaStatusModalContent.accessUnavailableStateDesc;
            releaseNotesAvailable = false;
        } else if (currentStatus === DSL_MMOTA_STATUS.upToDate) {
            header = mmotaStatusModalContent.upToDateStatusTitle;
            description = mmotaStatusModalContent.upToDateStatusDesc;
            releaseNotesAvailable = false;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateSuccessful) {
            header = mmotaStatusModalContent.successStatusTitle;
            description = mmotaStatusModalContent.successStatusDesc;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateWillRetry) {
            header = mmotaStatusModalContent.retryStateTitle;
            description = mmotaStatusModalContent.retryStateDesc;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateInProgress) {
            header = mmotaStatusModalContent.inProgressStateTitle;
            description = mmotaStatusModalContent.inProgressStateDesc?.replace(
                '%time%',
                updateDisplayTime
            );
        } else if (currentStatus === DSL_MMOTA_STATUS.updateReminder) {
            header = mmotaStatusModalContent.updateReminderStateTitle;
            description = headerDescription()?.replace(
                '%time%',
                updateDisplayTime
            );
        } else if (currentStatus === DSL_MMOTA_STATUS.updatePending) {
            header = mmotaStatusModalContent.updatePendingStateTitle;
            description = mmotaStatusModalContent?.updatePendingStateDesc?.replace(
                '%date%',
                [date, mmotaStatusModalContent?.atTranslationText, time]
                    .join(' ')
                    .trim()
            );
        } else if (
            currentStatus ===
                DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended ||
            currentStatus === DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain
        ) {
            header = mmotaStatusModalContent.updateUnsuccessfulStateTitle;
            description = mmotaStatusModalContent.updateUnsuccessfulStateDesc;
        } else if (currentStatus === DSL_MMOTA_STATUS.vehicleInoperable) {
            header = mmotaStatusModalContent.inoperableStateTitle;
            description = mmotaStatusModalContent.inoperableStateDesc;
        } else if (
            ccsStatus?.ccsVehicleData?.toLowerCase() !== 'on' ||
            tappsData?.asuSettingsState?.toLowerCase() !== 'on'
        ) {
            header = mmotaStatusModalContent.settingsDisabledStateTitle;
            description = mmotaStatusModalContent.settingsDisabledStateDesc;
            releaseNotesAvailable = false;
        }
    }
    let settingsState;
    if (tappsData?.asuSettingsState?.toLowerCase() === 'on') {
        settingsState = mmotaStatusModalContent?.automaticUpdatesOnText ?? 'On';
    } else {
        settingsState =
            mmotaStatusModalContent?.automaticUpdatesOffText ?? 'Off';
    }
    return (
        <>
            {currentVersion ? (
                <Modal
                    name="mmota-modal"
                    primaryBtnLabel=""
                    secondaryBtnLabel=""
                    onClose={props.onClose}
                    isVisible={props.isVisible}
                    onPrimaryBtnClick={primaryBtnClick}
                    onSecondaryBtnClick={secondaryBtnClick}
                >
                    <h2 className="mmota-modal-header">{header}</h2>
                    <div
                        data-testid="mmota-header-description"
                        className="mmota-list-body"
                        dangerouslySetInnerHTML={{
                            __html: description || '',
                        }}
                    />
                    {mmotaStatusModalContent && (
                        <>
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updatePending && (
                                <>
                                    <MmotaModalLink
                                        class="inline first"
                                        href={
                                            mmotaStatusModalContent.connectToWifiLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                    />

                                    <MmotaModalLink
                                        class="inline middle"
                                        href={
                                            mmotaStatusModalContent.acceptUpdateLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                    />

                                    <MmotaModalLink
                                        class="inline last"
                                        href={
                                            mmotaStatusModalContent.setScheduleLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                    />
                                </>
                            )}
                            <MmotaModalLink
                                href={
                                    mmotaStatusModalContent.getSupportLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusModalContent.getSupportLinkText
                                }
                                content={
                                    mmotaStatusModalContent.getSupportLinkText
                                }
                            />
                            {isAccessUnavailable && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToActivateLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                />
                            )}
                            <hr />
                            {settingsState &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.otaSettingsText}
                                ${settingsState}`}
                                    </p>
                                )}
                            {parsedRecurringDateTime &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.recurringScheduleText}
                                ${parsedRecurringDateTime}`}
                                    </p>
                                )}
                            {(currentStatus === DSL_MMOTA_STATUS.upToDate ||
                                currentStatus ===
                                    DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended ||
                                currentStatus ===
                                    DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain ||
                                currentStatus ===
                                    DSL_MMOTA_STATUS.updateReminder) && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeOrSetLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeOrSetLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeOrSetLinkText
                                    }
                                />
                            )}
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updateWillRetry && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeOrSetLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeLinkText
                                    }
                                />
                            )}
                            {ccsStatus?.ccsVehicleData?.toLowerCase() !==
                                'on' &&
                                !isAccessUnavailable && (
                                    <MmotaModalLink
                                        href={
                                            mmotaStatusModalContent.howToChangeSettingsLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                        }
                                    />
                                )}
                            {ccsStatus?.ccsVehicleData?.toLowerCase() !==
                                'on' &&
                                !isAccessUnavailable && (
                                    <MmotaModalLink
                                        href={
                                            mmotaStatusModalContent.howToChangeSettingsLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeSettingsLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.howToChangeSettingsLinkText
                                        }
                                    />
                                )}
                            {
                                <>
                                    {currentStatus ===
                                        DSL_MMOTA_STATUS.updateSuccessful &&
                                        date &&
                                        time && (
                                            <p className="top-padding">
                                                {
                                                    mmotaStatusModalContent.lastSuccessfulText
                                                }
                                                <br />
                                                {date}
                                            </p>
                                        )}

                                    {releaseNotesAvailable && (
                                        <div>
                                            <h3 className="mmota-updates__header">
                                                {
                                                    mmotaStatusModalContent.updateNotesTitle
                                                }
                                            </h3>
                                            <hr className="mmota-updates__header-hr" />
                                            <div
                                                className="mmota-updates__notes"
                                                dangerouslySetInnerHTML={{
                                                    __html: updateNotesDescription,
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    )}
                </Modal>
            ) : (
                <FMModal
                    name="mmota-modal"
                    ctaLabel=""
                    cta2Label=""
                    onClose={props.onClose}
                    isVisible={props.isVisible}
                    onCtaClick={primaryBtnClick}
                    onCta2Click={secondaryBtnClick}
                    closeButtonAriaLabel={'close'}
                    title={header}
                    subtitle={
                        <div
                            data-testid="mmota-header-description"
                            dangerouslySetInnerHTML={{
                                __html: description || '',
                            }}
                        />
                    }
                >
                    {mmotaStatusModalContent && (
                        <>
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updatePending && (
                                <div
                                    className={'fm-update-pending-btn-wrapper'}
                                >
                                    <FMButton
                                        className={'inline-first'}
                                        href={
                                            mmotaStatusModalContent.connectToWifiLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        type={'tertiary'}
                                    />

                                    <FMButton
                                        className={'inline-middle'}
                                        href={
                                            mmotaStatusModalContent.acceptUpdateLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        type={'tertiary'}
                                    />

                                    <FMButton
                                        className={'inline-last'}
                                        href={
                                            mmotaStatusModalContent.setScheduleLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                </div>
                            )}
                            {isAccessUnavailable && (
                                <FMButton
                                    href={
                                        mmotaStatusModalContent.howToActivateLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    label={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    type={'tertiary'}
                                />
                            )}
                            <div className={'fm-mmota-button-wrapper'}>
                                {(currentStatus === DSL_MMOTA_STATUS.upToDate ||
                                    currentStatus ===
                                        DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended ||
                                    currentStatus ===
                                        DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain ||
                                    currentStatus ===
                                        DSL_MMOTA_STATUS.updateReminder) && (
                                    <FMButton
                                        href={
                                            mmotaStatusModalContent.howToChangeOrSetLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeOrSetLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.howToChangeOrSetLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                )}
                                {currentStatus ===
                                    DSL_MMOTA_STATUS.updateWillRetry && (
                                    <FMButton
                                        href={
                                            mmotaStatusModalContent.howToChangeOrSetLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.howToChangeLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                )}
                                {ccsStatus?.ccsVehicleData?.toLowerCase() !==
                                    'on' &&
                                    !isAccessUnavailable && (
                                        <FMButton
                                            href={
                                                mmotaStatusModalContent.howToChangeSettingsLinkPath
                                            }
                                            ariaLabel={
                                                mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                            }
                                            label={
                                                mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                            }
                                            type={'tertiary'}
                                        />
                                    )}
                                {ccsStatus?.ccsVehicleData?.toLowerCase() !==
                                    'on' &&
                                    !isAccessUnavailable && (
                                        <FMButton
                                            href={
                                                mmotaStatusModalContent.howToChangeSettingsLinkPath
                                            }
                                            ariaLabel={
                                                mmotaStatusModalContent.howToChangeSettingsLinkText
                                            }
                                            label={
                                                mmotaStatusModalContent.howToChangeSettingsLinkText
                                            }
                                            type={'tertiary'}
                                        />
                                    )}
                                <FMButton
                                    href={
                                        mmotaStatusModalContent.getSupportLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.getSupportLinkText
                                    }
                                    label={
                                        mmotaStatusModalContent.getSupportLinkText
                                    }
                                    type={'tertiary'}
                                />
                            </div>
                            <hr />
                            {settingsState &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.otaSettingsText}
                                ${settingsState}`}
                                    </p>
                                )}
                            {parsedRecurringDateTime &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.recurringScheduleText}
                                ${parsedRecurringDateTime}`}
                                    </p>
                                )}
                            {
                                <>
                                    {currentStatus ===
                                        DSL_MMOTA_STATUS.updateSuccessful &&
                                        date &&
                                        time && (
                                            <p className="top-padding">
                                                {
                                                    mmotaStatusModalContent.lastSuccessfulText
                                                }
                                                <br />
                                                {date}
                                            </p>
                                        )}

                                    {releaseNotesAvailable && (
                                        <div
                                            className={
                                                'fm-update-notes-wrapper'
                                            }
                                        >
                                            <h3 className="mmota-updates__header">
                                                {
                                                    mmotaStatusModalContent.updateNotesTitle
                                                }
                                            </h3>
                                            <hr className="mmota-updates__header-hr" />
                                            <div
                                                className="mmota-updates__notes"
                                                dangerouslySetInnerHTML={{
                                                    __html: updateNotesDescription,
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    )}
                </FMModal>
            )}
        </>
    );
};

export default MMOTAModal;
