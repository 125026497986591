import React, { useContext } from 'react';
import './quick-links-common.scss';
import { QuickLinksContent } from '../../../models/experiencefragments/quick-links';
import SecondaryButton from '../../common/secondary-button/secondary-button';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useAnalytics } from '../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { TileOnClickCtaInfo } from '../../../services/shortcode-service/shortcode-service';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';
import ServerContext from '../../../contexts/serverContext';
import { FMListItem } from '@own/fds-react';
import { BRAND } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { URLUtil } from '../../utils/url-util/url-util';

const QuickLinksCommon = (props: QuickLinksContent & VehicleAttributes) => {
    const [fireEvents] = useAnalytics();
    const analyticsName = props.analyticsName;
    const tileOnClickCtaInfo: TileOnClickCtaInfo = {
        analyticsName,
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const context = useContext(ServerContext);

    const replacer = {
        '%vin': props.vin || '',
        '%year': props?.year?.toString() || '',
        '%make': props.make?.toLowerCase() || '',
        '%model':
            props.seoKey?.toLowerCase() ||
            props?.model
                ?.replace(/[^a-zA-Z0-9]/g, ' ')
                .trim()
                .replace(/\s+/g, '-')
                .toLowerCase() ||
            '',
    };
    const ctaHref = (): string => {
        if (props.dynamicCTAPath) {
            const url = props.dynamicCTAPath.replace(
                /%vin|%year|%make|%model/gi,
                matched => {
                    return replacer[matched as keyof typeof replacer];
                }
            );
            return URLUtil.omitEmptyParams(url);
        }
        return props.staticCTAPath;
    };
    async function handleEvents() {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaHref()
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('quicklinks-onclick-event', undefined, {
                tileOnClickCtaInfo,
            });
        }
    }

    return currentVersion ? (
        <div className="quick-links-card">
            <img
                className="icon"
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
            />
            <SecondaryButton
                className={'quick-links-cta'}
                link={transformRelativeUrlValidateRoot(ctaHref(), context.root)}
                aria-label={props.ctaAriaLabel}
                noChevron={true}
                onClick={() => {
                    handleEvents();
                }}
            >
                {props?.tileTitle?.toUpperCase()}
            </SecondaryButton>
        </div>
    ) : (
        <FMListItem
            title={props?.tileTitle || ''}
            imgPath={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
            type={'no-description'}
            ctaPath={transformRelativeUrlValidateRoot(ctaHref(), context.root)}
            showChevron={false}
            description={''}
            onClick={() => {
                handleEvents();
            }}
        />
    );
};
export default QuickLinksCommon;
