import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ServerContext from '../../contexts/serverContext';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';

export const RewardsView = () => {
    const applicationId = useContext(ServerContext).applicationId;
    const route = useLocation().pathname;
    const params = new URLSearchParams(useLocation().search);
    const appConfiguration = new AppConfigurationService().getAppConfiguration();
    const bundleBasePath = appConfiguration.root.concat(
        route.startsWith('/rewards')
            ? 'rewards'
            : appConfiguration.brand === 'lincoln'
            ? 'lincoln-way-app/lincoln-access-rewards'
            : 'fordpass/fordpass-rewards'
    );
    console.debug(bundleBasePath);

    useEffect(() => {
        const script = document.createElement('script');

        const rewardProgram = appConfiguration.brand === 'ford' ? 'fpr' : 'lar';

        const src = `${process.env.REACT_APP_LOYALTY_BUNDLE_URL}/bundle-${rewardProgram}.js`;
        const country = ['usa', 'can'].includes(
            appConfiguration.threeLetterCountryCode
        )
            ? appConfiguration.threeLetterCountryCode
            : 'eu';

        const modifier = params.get('stealth') ? '-stealth' : `-${country}`;

        script.src = src.replace('{modifier}', modifier);
        script.defer = true;
        script.setAttribute('data-loyalty-script', '');
        script.setAttribute(
            'data-aem-base-url',
            process.env.REACT_APP_AEM_BASE_URL!
        );
        script.setAttribute('data-lps-base-url', appConfiguration.apimUrl);
        script.setAttribute(
            'data-language-code',
            appConfiguration.languageRegionCode
        );
        script.setAttribute(
            'data-program-country',
            appConfiguration.threeLetterCountryCode
        );
        script.setAttribute('data-app-basepath', bundleBasePath);
        if (applicationId) {
            script.setAttribute('data-app-id', applicationId);
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div>
            <div id={'loyalty-webpages-container'}></div>
        </div>
    );
};
export default RewardsView;
