import React from 'react';
import { FMButtonProps } from '../../types/fm-button.types';
import { CHEVRON } from '../../types/button.types';
import FMUtility from '../../fm-utils/fm-util';
import './../../main.scss';
import './fm-button.scss';

const FMButton = (props: FMButtonProps) => {
    const onClick = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (props.onClick) {
            props.preventDefault && e.preventDefault();
            props.onClick(e);
        }
    };
    const chevron = (direction: CHEVRON) => {
        return (
            <span
                className={`fds-icon fds-font--ford-icons__chevron-${direction} fds-icon--offset-${direction}`}
            />
        );
    };

    function fmButtonClasses() {
        return [
            'fm-btn',
            `fm-btn--${props.type}`,
            `${props.disabled ? 'fm-btn__disabled' : ''}`,
            `${props.theme === 'dark' ? 'fm-btn__dark' : 'fm-btn__light'}`,
            `${props.className ? props.className : ''}`,
        ]
            .join(' ')
            .trim();
    }

    return (
        <>
            {props?.href && props.label != null && (
                <a
                    className={fmButtonClasses()}
                    onClick={(e) => onClick(e)}
                    href={props.href}
                    aria-label={props.ariaLabel}
                    target={props.target || '_self'}
                >
                    {props?.chevron === 'left' && chevron('left')}
                    {FMUtility.isMarkup(props?.label?.toString()) ? (
                        <span
                            className={'fm-btn__text'}
                            dangerouslySetInnerHTML={{
                                __html: props?.label?.toString(),
                            }}
                        />
                    ) : (
                        <span className={'fm-btn__text'}>{props?.label}</span>
                    )}

                    {props?.chevron === 'right' && chevron('right')}
                </a>
            )}
            {!props?.href && props.label != null && (
                <button
                    className={fmButtonClasses()}
                    onClick={(e) => onClick(e)}
                    aria-label={props.ariaLabel}
                >
                    {props?.chevron === 'left' && chevron('left')}
                    {FMUtility.isMarkup(props?.label?.toString()) ? (
                        <span
                            className={'fm-btn__text'}
                            dangerouslySetInnerHTML={{
                                __html: props?.label?.toString(),
                            }}
                        />
                    ) : (
                        <span className={'fm-btn__text'}>{props?.label}</span>
                    )}

                    {props?.chevron === 'right' && chevron('right')}
                </button>
            )}
        </>
    );
};

export default FMButton;
