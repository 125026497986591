import HttpService from '../http-service/http-service';
import { VinHandoverResponse } from '../../models/handover-data';

export class VinHandoverService {
    public async getVinContent(
        vinLookUpURL: string,
        vin: string
    ): Promise<VinHandoverResponse> {
        const url = `${vinLookUpURL}`;
        const lookup = HttpService.get(url, true, {
            headers: {
                vin,
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            },
        });
        return lookup
            .then(responseData => {
                const response: VinHandoverResponse = {
                    features: responseData.data.features,
                    modelDescription: responseData.data.modelDescription,
                    modelYear: responseData.data.modelYear,
                    vehicleImage: responseData.data.vehicleImage,
                };

                return response;
            })
            .catch(error => {
                throw error.response.status;
            });
    }
}

const vinHandoverService = new VinHandoverService();
export default vinHandoverService;
