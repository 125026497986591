import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    useCalendarStep,
    useLightDeliveryOptionServiceStep,
    usePersonalDetailStep,
    useServiceLocationStep,
    useServiceStep,
    useStepProgressBar,
} from '../../../../hooks/owners-osb';
import { useDealerStep } from '../../../../hooks/owners-osb/use-dealer-step';
import { useHttp } from '../../../../hooks/owners-osb/use-http';
import { useOSBStep } from '../../../../hooks/owners-osb/use-osb-step';
import { useVehicleStep } from '../../../../hooks/owners-osb/use-vehicle-step';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { ServiceInfo } from '../../../../models/osb-model/osb-dealerservice-info';
import { VehicleData } from '../../../../models/osb-model/osb-vehicle-step';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../services/service-handler';
import { InfoIconToolTip } from '../../../common/info-icon-tooltip/info-icon-tooltip';
import PrimaryButton from '../../../common/primary-button/primary-button';
import {
    OSB_SERVICE_PRICE_TYPE,
    OSB_SERVICE_TYPE,
    SERVICE_DELIVERY_TYPE,
    SERVICE_FLOW,
} from '../../owners-osb/osb-constant';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';
import {
    PRICE_CALCULATOR_ANALYTICS,
    PRICE_CALCULATOR_RESULT_CONSTANTS,
} from '../price-calculator-constant';
import './service-card.scss';
import { triggerInternalCtaAnalytics } from '../analytics/price-calculator-analytics';
import { ShortcodeProviders } from '../../../../services/shortcode-service/shortcode-service';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { useAllOSBState } from '../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    dealerProfile: DealerProfileInfo;
    vehicleData: VehicleData;
    priceCalculatorResultContent: any;
    errorServicesDetails: (error: string) => void;
    fireEvents: (
        eventNames: string | string[],
        targetViewName?: string | undefined,
        shortcodeProviders?: ShortcodeProviders | undefined,
        clearDigitaldata?: boolean | undefined
    ) => void;
}
export const ServiceCard = (props: Props) => {
    const { httpState, dispatch } = useHttp();
    const [mainServicesData, setMainServicesData] = useState<ServiceInfo[]>([]);
    const history = useHistory();
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const { setOSBDealerStepPayload, resetDealerStep } = useDealerStep();
    const { setOSBVehicleStepPayload } = useVehicleStep();
    const { resetStepProgressBar } = useStepProgressBar();
    const { resetPersonalDetailStep } = usePersonalDetailStep();
    const {
        resetLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { resetServiceLocationStep } = useServiceLocationStep();
    const { resetCalendarStep } = useCalendarStep();
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >('');
    const { osbServiceStep, resetServiceStep } = useServiceStep();
    const bookable = useAllOSBState();
    const loadDealerService = async () => {
        const maxRetries =
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.GET_SERVICES_MAX_RETRY_LABEL,
                props.priceCalculatorResultContent
            ) || PRICE_CALCULATOR_RESULT_CONSTANTS.SERVICES_MAX_RETRY_COUNT;
        const retryDelayInSeconds =
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.GET_SERVICES_RETRY_DELAY_IN_SECONDS_LABEL,
                props.priceCalculatorResultContent
            ) || SERVICE_FLOW.RETRY_INTERVAL;
        const retryInterval = retryDelayInSeconds * 1000;
        let isValidResponse = false;
        const progressIncrement = Math.floor(100 / maxRetries);
        let progressCounter = 0;
        for (
            let retries = maxRetries;
            retries > 0 && !isValidResponse;
            retries--
        ) {
            if (retries !== maxRetries) {
                await new Promise(r => setTimeout(r, retryInterval));
            }
            progressCounter += progressIncrement;
            if (progressCounter > 99) {
                progressCounter = 99;
            }
            setProgressPercentToDisplay(progressCounter.toString());
            await ServiceHandler.OsbDealerServices.getServicesList({
                dealerCode: props.dealerProfile.dealerCode,
                vehicleData: props.vehicleData,
                serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                    ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                    : SERVICE_DELIVERY_TYPE.WORKSHOP,
                requestSourceType: osbStep.source,
                bookable: bookable,
                DSLAPIVersion: osbStep.DSLAPIVersion,
            })
                .then(result => {
                    const mainServices = result.value.dealerServices.mainServices.filter(
                        service =>
                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                OSB_SERVICE_TYPE.SERVICES.toUpperCase() &&
                            service.serviceId.toString() !==
                                SERVICE_FLOW.GA_SERVICE_ID
                    );
                    isValidResponse = !mainServices.some(
                        service =>
                            service.serviceId.toString() ===
                            PRICE_CALCULATOR_RESULT_CONSTANTS.SCHEDULED_SERVICE
                    );
                    if (isValidResponse || retries === 1) {
                        setMainServicesData(mainServices);
                        dispatch({ type: 'RESPONSE' });
                    }
                })
                .catch((error: any) => {
                    if (retries === 1) {
                        dispatch({
                            type: 'ERROR',
                            error: error.message,
                        });
                        props.errorServicesDetails(
                            getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.COMMON_SERVICE_API_ERROR,
                                props.priceCalculatorResultContent
                            )
                        );
                    }
                });
        }
    };

    const getFormattedPrice = (price: string) => {
        if (
            Number(price) === OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
            Number(price) === OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
        ) {
            return getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_POR_LABEL,
                props.priceCalculatorResultContent
            );
        }
        if (Number(price) === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) {
            return getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_FREE_LABEL,
                props.priceCalculatorResultContent
            );
        }
        const priceWithPrecision = OsbUtilService.addPrecision(
            price,
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_PRECISION,
                props.priceCalculatorResultContent
            )
        );
        const priceWithPrecisionAndSeparators = OsbUtilService.addSeparators(
            priceWithPrecision,
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_SEPARATOR,
                props.priceCalculatorResultContent
            )
        );
        return (
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_PREFIX,
                props.priceCalculatorResultContent
            ) +
            OsbUtilService.addDecimalSeparator(
                priceWithPrecisionAndSeparators,
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_SEPARATOR,
                    props.priceCalculatorResultContent
                )
            ) +
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.PRICE_POSTFIX,
                props.priceCalculatorResultContent
            )
        );
    };

    const clearStoreData = () => {
        resetDealerStep();
        resetServiceStep();
        resetLightDeliveryServiceStep();
        resetServiceLocationStep();
        resetCalendarStep();
        resetPersonalDetailStep();
        resetStepProgressBar();
    };

    const gotoOSB = (service: ServiceInfo) => {
        triggerInternalCtaAnalytics(
            props.fireEvents,
            PRICE_CALCULATOR_ANALYTICS.BOOKNOW_LABEL
        );
        // If PC to OSB flow already started, clear global store and proceed
        if (osbStep.isPcFlow) {
            clearStoreData();
        }
        setOSBStepPayload({ isPcFlow: true });
        setOSBDealerStepPayload({
            selectedDealerId: props.dealerProfile.dealerCode,
        });
        setOSBVehicleStepPayload({
            vinRegNo:
                props.vehicleData.vin ||
                props.vehicleData.registrationNumber ||
                '',
        });
        setOSBVehicleStepPayload({
            vinMileage: props.vehicleData.mileage,
            manualMileage: props.vehicleData.mileage,
        });
        setOSBStepPayload({
            preSelectedServices: service.serviceId.toString(),
        });

        history.push(
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.OSB_HOME_PAGE_STEP,
                props.priceCalculatorResultContent
            )
        );
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        loadDealerService();
    }, [props.dealerProfile]);

    return (
        <>
            <div>
                {httpState.isLoading ? (
                    <ActivityIndicator
                        progressPercent={progressPercentToDisplay}
                    />
                ) : (
                    <>
                        <div>
                            {mainServicesData.map(service => {
                                return (
                                    <div
                                        key={service.serviceId}
                                        className="dealer-service-card-container"
                                    >
                                        <div className="service-name-wrapper">
                                            <div className="service-name-with-offer">
                                                {service.offer &&
                                                service.offer?.length > 1 ? (
                                                    <div className="pc-offer-label">
                                                        {service.offer}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                <div className="service-name-prefix">
                                                    {
                                                        service.intervalServiceNamePrefix
                                                    }
                                                </div>
                                            </div>
                                            {service.intervalServiceNameSuffix && (
                                                <div className="service-type-wrapper">
                                                    <div className="service-name-suffix">
                                                        {
                                                            service.intervalServiceNameSuffix
                                                        }
                                                    </div>
                                                    {service.description && (
                                                        <span
                                                            id={
                                                                service.serviceId +
                                                                '-tooltip'
                                                            }
                                                        >
                                                            <InfoIconToolTip
                                                                osbServiceTooltip={
                                                                    true
                                                                }
                                                                tooltipInfoIconClass={
                                                                    'dark'
                                                                }
                                                                tooltipContent={
                                                                    service.description
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {Number(service.price.toString()) <
                                        0 ? (
                                            <div className="dealer-service-por-price">
                                                {getFormattedPrice(
                                                    service.price.toString()
                                                )}
                                            </div>
                                        ) : (
                                            <div className="dealer-service-price">
                                                {getFormattedPrice(
                                                    service.price.toString()
                                                )}
                                            </div>
                                        )}
                                        <div className="book-now-button">
                                            <PrimaryButton
                                                role="link"
                                                color={'dark'}
                                                fill={'fill'}
                                                chevron={false}
                                                aria-label="select-dealer-cta"
                                                aria-labelledby="select dealer cta"
                                                onClick={() => gotoOSB(service)}
                                            >
                                                {getObjectFromAEMJson(
                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.BOOK_NOW_BUTTON,
                                                    props.priceCalculatorResultContent
                                                )}
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
