import { BreadcrumbWrapper } from '../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import { ViewTemplate } from '../../components/sections';
import { OilLifeDetails } from '../../components/sections/vehicle-health/components/oil-life/oil-life-details';
import React from 'react';
import { useOilLifeDetailsContent } from '../../components/sections/vehicle-health/hook/use-oil-life-details-content';
import { useVehicleHealthPageComponents } from '../../components/sections/vehicle-health/components/vehicle-health-page-components';

export const OilLifeDetailsView = () => {
    const oilLifeDetailsContent: any = useOilLifeDetailsContent();
    const [vehicleHealthComponent] = useVehicleHealthPageComponents();
    const currentRoute = window.location.pathname;
    const VhaAlertBreadCrumb = () => {
        const vhaUrl = currentRoute.endsWith('/')
            ? currentRoute.substring(0, currentRoute.length - 1)
            : currentRoute;
        return (
            <>
                <a
                    className="breadcrumb-link"
                    href={`${vhaUrl.substr(0, vhaUrl.lastIndexOf('/'))}`}
                >
                    <span>{vehicleHealthComponent?.vehicleHealthTitle}</span>
                </a>
                <span className="back-slash">{'>'}</span>
            </>
        );
    };
    return (
        <>
            {oilLifeDetailsContent && (
                <ViewTemplate
                    page={
                        oilLifeDetailsContent?.oilLifeDetails?.oilLifeBreadcrum
                    }
                >
                    <BreadcrumbWrapper
                        currentPage={
                            oilLifeDetailsContent.oilLifeDetails
                                .oilLifeBreadcrum
                        }
                        hashId="support"
                        title={<VhaAlertBreadCrumb />}
                    />
                    <OilLifeDetails />
                </ViewTemplate>
            )}
        </>
    );
};

export default OilLifeDetailsView;
