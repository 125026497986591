import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    OSB_AEM_PROPERTY_KEYS,
    SERVICES_STEP_KEYS,
    DEALER_STEP_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    STEP_PROGRESS_BAR_KEYS,
    OSB_CLIENT_STORAGE_KEYS,
    FORD_ACCOUNT_KEYS,
    REGEX_PATTERNS,
    API_REQUEST_SOURCE_TYPE,
} from '../../../components/sections/owners-osb/osb-constant';
import {
    useServiceStep,
    useOSBStep,
    useDealerStep,
    useVehicleStep,
    usePersonalDetailStep,
    useHttp,
    useStepProgressBar,
    useOsbImageUpload,
    useLightDeliveryOptionServiceStep,
    useServiceLocationStep,
    useCalendarStep,
} from '../../../hooks/owners-osb';
import { ViewportContextProvider } from '../../../contexts/owners-osb';
import {
    getFromSessionStorage,
    getPersistedRoute,
    getVehicleFromVin,
    getVehicleHealthAlerts,
    getVinVehicleResponse,
    isClientStorageExpired,
    removeClientStorage,
    buildNavigationUrl,
    shouldSkipPersonalDetailsStep,
    userProfileData,
    getVehicleDashboardDefaultVin,
    getClientStorageValue,
    removeAllClientStorage,
    findDealerFromMarketDealers,
    hasMobileService,
    getObjectFromAEMJson,
    getGoMainHeaderHeight,
} from '../../../components/sections/owners-osb/osb-utils';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import useUserProfile from '../../../hooks/use-user-profile';
import { useAnalytics } from '../../../hooks/use-analytics';
import { clearDigitalData } from '../../../components/sections/owners-osb/analytics/osb-analytics';
import {
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathPersonalDetailsStep,
    OsbPathServicesStep,
    OsbPathStartStep,
    OsbPathVehicleStep,
    OsbPathServiceLocationStep,
    OsbPathBookingConfirmationStep,
    OsbPathBookingCancelStep,
} from '../../../components/sections/owners-osb/osb-controller';
import {
    UserVehicleInfo,
    VehicleDetail,
} from '../../../models/profile-with-vehicles';
import ServiceHandler from '../../../services/service-handler';
import { LogService } from '../../../services/log-service/log-service';
import './main-wrapper-view.scss';
import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import OSBBanner from '../../../components/sections/owners-osb/osb-banner/osb-banner';
import useSeoConfig from '../../../hooks/use-seo-config';
import ProfileService from '../../../services/profile-service/profile-service';
import { OSBStepProgressBar } from '../../../components/sections/owners-osb/common/osb-step-progress-bar/osb-step-progress-bar';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { VehicleData } from '../../../models/osb-model/osb-vehicle-step';
import VehicleTypeService from '../../../services/vehicle-type-service/vehicle-type-service';
import isValidVinOrReg from '../../../services/valid-vin-service/ValidVinService';
import ServerContext from '../../../contexts/serverContext';
import { useMarketDealerInfoService } from '../../../hooks/owners-osb/use-market-dealerInfo-service';

export function MainWrapperView(props: any) {
    //This hook is to add meta data title and description which is created in experience fragment in seo folder
    useSeoConfig('osb-metadata-title', 'osb');
    const { httpState, dispatch } = useHttp();
    const history = useHistory();
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const [vehicleData, setVehicleData] = useState<VehicleData>();
    const isWebView = deepLinkParams.get('webview') === 'true' ? true : false;
    const { setOSBServiceStepPayload, resetServiceStep } = useServiceStep();
    const { setOSBImageServiceStepPayload } = useOsbImageUpload();
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const {
        manageOsbStepVisibility,
        osbStepProgressBar,
        updateProgressBarStatus,
        resetStepProgressBar,
    } = useStepProgressBar();
    const {
        osbVehicleStep,
        setOSBVehicleStepPayload,
        callContentService,
    } = useVehicleStep();
    const {
        resetLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { resetServiceLocationStep } = useServiceLocationStep();
    const { resetCalendarStep } = useCalendarStep();

    const pathName = history.location.pathname;
    const queryParams = history.location.search;
    const authService = new AuthenticationService();
    const regionEU = new AppConfigurationService().isRegionEU();
    const {
        osbPersonalDetail,
        setOSBPersonalDetail,
        resetPersonalDetailStep,
    } = usePersonalDetailStep();
    const profile = useUserProfile();
    const {
        osbDealerStep,
        setOSBDealerStepPayload,
        resetDealerStep,
    } = useDealerStep();
    const [fireEvents] = useAnalytics();
    const [applicationName, setApplicationName] = useState<string>('');
    const [charNoToStrip, setCharNoToStrip] = useState<string>('');
    // setting this to true after setting deeplink params in both normal and Auth journeys
    const [isJourneyInitialized, setIsJourneyInitialized] = useState<boolean>(
        false
    );
    const stepsListForProgressBar = [
        OsbPathDealerStep(),
        OsbPathServicesStep(),
        OsbPathDeliveryStep(),
        OsbPathServiceLocationStep(),
        OsbPathCalenderStep(),
        OsbPathPersonalDetailsStep(),
    ];
    const profileService = new ProfileService();

    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    // Vehicle data object framing with default vehicle information
    const frameVehicleData = (aemContent?: any) => {
        const vehicleData: VehicleData = {
            mileage: getObjectFromAEMJson(
                OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MILEAGE,
                aemContent
            ),
            modelName:
                getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MODEL,
                    aemContent
                ) || OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MODEL_REACT,
            buildYear: getObjectFromAEMJson(
                OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_BUILD_YEAR,
                aemContent
            ),
        };
        return vehicleData;
    };

    // this method provides access to all dealers active in the market
    const marketDealerInfoService = async () => {
        try {
            const results = await fetchMarketDealerInfo(deepLinkParams);
            if (results) {
                const {
                    filterDeliveryServices,
                    filterDidServices,
                    filterRegularServices,
                } = results;
                if (
                    filterDeliveryServices ||
                    filterDidServices ||
                    filterRegularServices
                ) {
                    setOSBDealerStepPayload({
                        filterDeliveryServices: filterDeliveryServices ?? [],
                        filterDidServices: filterDidServices ?? [],
                        filterRegularServices: filterRegularServices ?? [],
                    });
                }
            }
            dispatch({ type: 'RESPONSE' });
        } catch (error) {
            if (error) {
                dispatch({
                    type: 'ERROR',
                    error: error,
                });
                LogService.log(
                    'DataLayer',
                    `Received error response from OsbDslDealerDetailsService.getDealerDetails service: ${error}`
                );
            }
        }
    };

    // dealer deeplink scenario
    const setDealerDetailsFromMarketDealers = async (dealerId: string) => {
        if (vehicleData) {
            try {
                const results = await fetchMarketDealerInfo(deepLinkParams);
                const dealerProfile = findDealerFromMarketDealers(
                    dealerId,
                    results?.dealers || []
                );
                if (dealerProfile != null) {
                    setOSBDealerStepPayload(
                        {
                            selectedDealerProfile: dealerProfile || {},
                            selectedDealerName: dealerProfile?.dealerName || '',
                            searchDepth:
                                DEALER_STEP_KEYS.SEARCH_DEPTH_FOR_DEALER_DEEPLINK,
                            hasMobileService: hasMobileService(
                                dealerProfile.delivery || []
                            ),
                        },
                        false
                    );
                    manageOsbStepVisibility(
                        STEP_PROGRESS_BAR_KEYS.DEALER,
                        false
                    );
                    setOSBStepPayload({ displayProgressBar: true });
                    updateProgressBarStatus(
                        STEP_PROGRESS_BAR_KEYS.SERVICE,
                        false,
                        true
                    );
                    const navigationUrl = buildNavigationUrl(
                        OsbPathServicesStep(),
                        queryParams
                    );
                    const navigate = () => history.push(navigationUrl);
                    navigate();
                } else {
                    // dealer not present in market dealer
                    setOSBDealerStepPayload({
                        selectedDealerId: '',
                        isDealerSelected: false,
                    });

                    setOSBStepPayload({ isDealerDeepLinked: false });
                    const navigationUrl = buildNavigationUrl(
                        OsbPathDealerStep(),
                        queryParams
                    );
                    history.push(navigationUrl);
                }
            } catch (error) {
                console.error('Error fetching market dealer info:', error);
                // Handle error as needed, possibly by showing an error message to the user
            }
        }
    };

    // fetches recall data for the vin
    //calling this in main-wrapper for vin deeplinked and auth journeys
    const fetchVehicleRecallData = (vin: string) => {
        ServiceHandler.OsbVehicleRecallService.request(
            vin,
            osbStep.osbThreeLetterCountryCode
        )
            .then(results => {
                setOSBVehicleStepPayload({
                    vehicleRecallService: results,
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: 'ERROR',
                    error: error.message,
                });
            });
    };

    const getOsbThreeLetterCountryCode = (
        threeLetterCountryCodeFromAEM: string
    ) => {
        const osbThreeLetterCountryCode: string = threeLetterCountryCodeFromAEM
            ? threeLetterCountryCodeFromAEM.trim().toUpperCase()
            : OsbUtilService.getAppConfiguration().threeLetterCountryCode.toUpperCase();
        if (osbThreeLetterCountryCode) {
            setOSBStepPayload({
                osbThreeLetterCountryCode: osbThreeLetterCountryCode,
            });
        }
    };

    const getOsbTwoLetterCountryCode = (
        twoLetterCountryCodeFromAEM: string
    ) => {
        const osbTwoLetterCountryCode: string = twoLetterCountryCodeFromAEM
            ? twoLetterCountryCodeFromAEM.trim().toUpperCase()
            : '';
        setOSBStepPayload({
            osbTwoLetterCountryCode: osbTwoLetterCountryCode,
        });
    };

    const getOsbImageUploadAEMContent = async () => {
        await callContentService('osb-image-upload').then((result: any) => {
            if (result) {
                setOSBImageServiceStepPayload({
                    osbImageContent: result.elements,
                });
            }
        });
    };
    function buildVehicleLabel(preSelectedVehicle: UserVehicleInfo) {
        return (
            preSelectedVehicle.nickName ||
            preSelectedVehicle.modelName + ' ' + preSelectedVehicle.modelYear
        );
    }

    const fetchConnectedVehicleData = async (
        userVin: string,
        year?: string
    ) => {
        try {
            const vehicleResponse = await getVinVehicleResponse(
                userVin,
                Number(year)
            );

            const authMileage = Number(
                vehicleResponse?.vehiclestatus.odometer.value
            );

            if (authMileage) {
                setOSBStepPayload({
                    isMileageDeepLinked: true,
                });
                setOSBVehicleStepPayload({
                    vinMileage: Math.round(authMileage).toString(),
                    manualMileage: Math.round(authMileage).toString(),
                });
            }
        } catch (error) {
            console.error(error);
        }

        try {
            const vehicleHealthResponse = await getVehicleHealthAlerts(userVin);

            if (vehicleHealthResponse) {
                const { vhaAlertResponseList } = vehicleHealthResponse;
                vhaAlertResponseList.length > 0 &&
                    setOSBVehicleStepPayload({
                        VhaResponse: vhaAlertResponseList[0],
                    });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const prepareAuthenticatedData = async (
        FPVin?: string,
        vehicles?: VehicleDetail[]
    ) => {
        //setting both the flags to true when it is webview
        //isAuthenticatedFlow will be used at common functionality for both auth. and webview journeys
        //isWebViewJourney will be used when we have webview specific functionality or to pass swap token for any service
        setOSBStepPayload({
            isAuthenticatedFlow: true,
            isWebViewJourney: isWebView,
        });

        const vehicleList = vehicles || profile?.vehicles;

        if (profile?.profile) {
            const userProfile = profile.profile;
            const customerDetails = userProfileData(userProfile);
            setOSBPersonalDetail(customerDetails, false);
            if (shouldSkipPersonalDetailsStep(true, customerDetails)) {
                manageOsbStepVisibility(STEP_PROGRESS_BAR_KEYS.CONTACT, false);
            }
        }

        if (vehicleList && vehicleList.length > 0) {
            setOSBServiceStepPayload({
                userVehicles: vehicleList.map(vehicle => {
                    const { modelName, nickName, vin, modelYear } = vehicle;
                    return {
                        modelName,
                        nickName,
                        vin,
                        modelYear,
                    };
                }),
            });

            const lastSelectedVin = profileService.getUserPreferences(
                profile?.profile?.email
            )?.lastSelectedVin;
            const dashboardSelectedVin =
                lastSelectedVin?.trim() ||
                (profile?.vehicles && profile.vehicles.length > 0
                    ? getVehicleDashboardDefaultVin(profile.vehicles)
                    : '');

            const userVin =
                deepLinkParams.get('vin') ||
                deepLinkParams.get('vn') ||
                deepLinkParams.get('reg') ||
                deepLinkParams.get('rn') ||
                osbVehicleStep.vinRegNo ||
                FPVin ||
                dashboardSelectedVin ||
                sessionStorage.getItem('selectedVin') || // this will read value from session Storage as last precedence over other criterias.
                '';
            const preSelectedVehicle = getVehicleFromVin(vehicleList, userVin);

            if (preSelectedVehicle) {
                const userVehicleLabel = buildVehicleLabel(preSelectedVehicle);
                setOSBVehicleStepPayload({
                    vehicleLabel: userVehicleLabel,
                });
                setOSBStepPayload({
                    isVINDeepLinked: true,
                });
                setOSBVehicleStepPayload({
                    vinRegNo: userVin,
                });

                try {
                    const {
                        isConnected,
                    } = await new VehicleTypeService().request(
                        userVin,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    );

                    if (isConnected) {
                        const userVehicleLabel = buildVehicleLabel(
                            preSelectedVehicle
                        );
                        setOSBVehicleStepPayload({
                            vehicleLabel: userVehicleLabel,
                        });
                        fetchConnectedVehicleData(
                            userVin,
                            preSelectedVehicle?.modelYear
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const setFordPassParams = async (
        webviewBundle: any,
        numberOfLeadingCharactersToStrip: number
    ) => {
        const {
            dealerCode = null,
            vin = null,
            preSelectServices = null,
            mileage = null,
            voucherCodes = null,
            groupDealerCode = null,
            skipGeneralAppointmentFlag = null,
            source = null,
        } = webviewBundle;
        if (
            dealerCode &&
            dealerCode.match(REGEX_PATTERNS.ALPHA_NUMERIC_PATTERN)
        ) {
            setOSBDealerStepPayload({
                selectedDealerId: dealerCode.substring(
                    numberOfLeadingCharactersToStrip
                ),
                isDealerSelected: true,
            });
            setOSBStepPayload({ isDealerDeepLinked: true });
        }
        //source value is not provided, it defaults to FORD_PASS,
        //otherwise, it takes the provided source value (FORD_PASS_PRO)
        setOSBStepPayload({
            source: !source ? API_REQUEST_SOURCE_TYPE.FORD_PASS : source,
        });
        if (vin && isValidVinOrReg(vin)) {
            setOSBStepPayload({ isVINDeepLinked: true });
            setOSBVehicleStepPayload({ vinRegNo: vin });
            fetchVehicleRecallData(vin);
        }

        if (
            preSelectServices &&
            preSelectServices.join.match(REGEX_PATTERNS.ALLOW_ANYTHING_PATTERN)
        ) {
            setOSBStepPayload({
                preSelectedServices: preSelectServices?.join() || '',
            });
        }
        if (mileage && mileage.match(REGEX_PATTERNS.NUMERIC_PATTERN)) {
            setOSBStepPayload({ isMileageDeepLinked: true });
            setOSBVehicleStepPayload({
                vinMileage: mileage,
                manualMileage: mileage,
            });
        }

        if (
            voucherCodes &&
            voucherCodes.length > 0 &&
            voucherCodes[0].match(REGEX_PATTERNS.ALLOW_ANYTHING_PATTERN)
        ) {
            setOSBStepPayload({ isVoucherDeepLinked: true });
            setOSBStepPayload({ deepLinkvoucherCode: voucherCodes[0] });
            setOSBServiceStepPayload({ voucherCode: voucherCodes[0] }, false);
        }

        if (
            groupDealerCode &&
            groupDealerCode.match(REGEX_PATTERNS.NUMERIC_PATTERN)
        ) {
            setOSBDealerStepPayload({
                groupDealerCode: groupDealerCode,
                isDealerSelected: true,
            });
        }

        setOSBStepPayload({
            isGASkipped: skipGeneralAppointmentFlag?.toLowerCase() === 'true',
        });

        try {
            // TODO: Do we need to call profile service explicitly even when we call useUserProfile()?
            const { profile, vehicles } = await profileService.request();
            const customerDetails = userProfileData(profile);
            setOSBPersonalDetail(customerDetails, false);
            if (shouldSkipPersonalDetailsStep(true, customerDetails)) {
                manageOsbStepVisibility(STEP_PROGRESS_BAR_KEYS.CONTACT, false);
            }
            setIsJourneyInitialized(true);
            // pass vehicles explicityly in case of webview as useUserProfile hook doesnt work
            // coz theres no Swap token available on authentication service.
            // TODO: please remove vehicles parameter once we have swapToken passed to authentication service.
            prepareAuthenticatedData(webviewBundle.vin, vehicles);
        } catch (error) {
            setIsJourneyInitialized(true);
            console.log('unable to load profile', error);
        }
    };

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, [history.location.pathname]);

    useEffect(() => {
        authService.onIsAuthenticated().then((isAuthenticated: boolean) => {
            setOSBStepPayload({
                source:
                    authService.getwebviewBundle()?.source ||
                    API_REQUEST_SOURCE_TYPE.WEB,
            });
            if (isWebView && isAuthenticated) {
                setFordPassParams(
                    authService.getwebviewBundle(),
                    Number(charNoToStrip)
                );
            } else {
                setIsJourneyInitialized(true);
            }
        });
    }, [charNoToStrip]);

    const getOsbAppAEMContent = async () => {
        await callContentService('osb-app').then((result: any) => {
            if (result) {
                const vehicleData: VehicleData = frameVehicleData(
                    result.elements
                );
                setVehicleData(vehicleData);
                marketDealerInfoService();
                setOSBVehicleStepPayload({
                    defaultModelName:
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MODEL,
                            result.elements
                        ) || OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MODEL_REACT,
                    defaultBuildYear: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_BUILD_YEAR,
                        result.elements
                    ),
                    defaultMileage: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.DEFAULT_VEHICLE_MILEAGE,
                        result.elements
                    ),
                    vinRegDataPattern: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.VIN_REG_DATA_PATTERN,
                        result.elements
                    ),
                    allowSpecialCharacters: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.ALLOW_VINREG_SPECIAL_CHARACTERS,
                        result.elements
                    ),
                });

                setOSBStepPayload({
                    polygon: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.POLYGON,
                        result.elements
                    ),
                    geoCountryCode:
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.GEO_COUNTRY_CODE,
                            result.elements
                        ) || OsbUtilService.getAppConfiguration().countryCode,
                    locationStepLabel: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.LOCATION_STEP_LABEL,
                        result.elements
                    ),
                    deliveryStepLabel: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.DELIVERY_STEP_LABEL,
                        result.elements
                    ),
                    osbEnableImage: Boolean(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.ENABLE_IMAGE_UPLOAD,
                            result.elements
                        )
                    ),
                    localStorageExpiry:
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.LOCAL_STORAGE_EXPIRY,
                            result.elements
                        ) || OSB_CLIENT_STORAGE_KEYS.OSB_CLIENT_STORAGE_EXPIRY,

                    internalErrorMessage: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.INTERNAL_ERROR_MESSAGE,
                        result.elements
                    ),
                });
                setOSBStepPayload({
                    osbShowTooltipAriaLabel: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.OSB_SHOW_TOOLTIP_ARIEA_LABEL,
                        result.elements
                    ),
                    osbDismissTooltipAriaLabel: getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.OSB_DISABLE_TOOLTIP_ARIEA_LABEL,
                        result.elements
                    ),
                    enableNewLandingPage:
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.ENABLE_NEW_LANDING_PAGE,
                            result.elements
                        ) || false,
                });

                if (
                    getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.ENABLE_IMAGE_UPLOAD,
                        result.elements
                    )
                ) {
                    getOsbImageUploadAEMContent();
                }
                const threeLetterCountryCodeFromAEM = getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.OSB_THREE_LETTER_COUNTRY_CODE,
                    result.elements
                );
                getOsbThreeLetterCountryCode(threeLetterCountryCodeFromAEM);
                const twoLetterCountryCodeFromAEM = getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.OSB_TWO_LETTER_COUNTRY_CODE,
                    result.elements
                );
                getOsbTwoLetterCountryCode(twoLetterCountryCodeFromAEM);
            }
        });
    };
    const getOsbAppMoreAEMContent = async () => {
        return await callContentService('osb-app-more-elements').then(
            (result: any) => {
                if (result) {
                    if (
                        deepLinkParams.get('dealerId') &&
                        deepLinkParams
                            .get('dealerId')
                            ?.match(REGEX_PATTERNS.ALPHA_NUMERIC_PATTERN)
                    ) {
                        const dealerId = deepLinkParams.get('dealerId') || '';
                        setOSBDealerStepPayload({
                            selectedDealerId: dealerId.substring(
                                getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.NUMBER_OF_CHARS_TO_STRIP,
                                    result.elements
                                )
                            ),
                            isDealerSelected: true,
                        });
                        setOSBStepPayload({ isDealerDeepLinked: true });
                        setIsJourneyInitialized(true);
                    }
                    // calling this method to retrieve FP data, strip the extra chars from dealercode and set into store
                    setCharNoToStrip(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.NUMBER_OF_CHARS_TO_STRIP,
                            result.elements
                        )
                    );

                    setApplicationName(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.APPLICATION_NAME,
                            result.elements
                        )
                    );
                    setOSBStepPayload({
                        backgroundImagePathDesktop:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.BACKGROUND_IMAGE_PATH_DESKTOP,
                                result.elements
                            ) ||
                            '/content/dam/global-owner/demo/gb/en-gb/images/vehicle-page/osb-desktop.jpg',
                        backgroundImagePathMobile:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.BACKGROUND_IMAGE_PATH_MOBILE,
                                result.elements
                            ) ||
                            '/content/dam/global-owner/demo/gb/en-gb/images/vehicle-page/osb-mobile.jpg',
                        backgroundImagePathLargeDesktop:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.BACKGROUND_IMAGE_PATH_LARGE_DESKTOP,
                                result.elements
                            ) ||
                            '/content/dam/global-owner/demo/gb/en-gb/images/vehicle-page/osb-desktop-large.jpg',
                        backgroundImagePathTablet:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.BACKGROUND_IMAGE_PATH_TABLET,
                                result.elements
                            ) ||
                            '/content/dam/global-owner/demo/gb/en-gb/images/vehicle-page/osb-tablet.jpg',
                        serviceBookingTitle: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.SERVICE_BOOKING_TITLE,
                            result.elements
                        ),
                        pricePrefix: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.PRICE_PREFIX,
                            result.elements
                        ),
                        priceSuffix: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.PRICE_SUFFIX,
                            result.elements
                        ),
                        pricePrecision:
                            parseInt(
                                getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.PRICE_PRECISION,
                                    result.elements
                                )
                            ) || 0,
                        priceSeparator: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.PRICE_SEPARATOR,
                            result.elements
                        ),
                        decimalSeparator: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.DECIMAL_SEPARATOR,
                            result.elements
                        ),
                        porLabel: getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.PRICE_ON_REQUEST,
                            result.elements
                        ),
                        freeLabel: getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.FREE_LABEL,
                            result.elements
                        ),
                        timeSuffixLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.TIME_SUFFIX_LABEL,
                            result.elements
                        ),
                        twelveHrsTimeFormat: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.TWELVE_HRS_TIME_FORMAT,
                            result.elements
                        ),
                        amTimeLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.AM_TIME_LABEL,
                            result.elements
                        ),
                        pmTimeLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.PM_TIME_LABEL,
                            result.elements
                        ),
                        isVideoLinkEnabled:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.IS_VIDEO_LINK_ENABLED,
                                result.elements
                            ) || false,

                        videoLinkLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.VIDEO_LINK_LABEL,
                            result.elements
                        ),
                        videoPlayerBackButtonLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.VIDEO_PLAYER_BACK_BUTTON_LABEL,
                            result.elements
                        ),
                        enablePickupNDeliveryLocationDateAndTime: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.ENABLE_PICKUP_AND_DELIVERY_LOCATION_DATE_AND_TIME,
                            result.elements
                        ),
                        dealerAddressOrder: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.DEALER_ADDRESS_ORDER,
                            result.elements
                        ),
                        enableOsbHomeRetrieveBooking:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.ENABLE_OSB_HOME_RETRIEVE_BOOKING,
                                result.elements
                            ) || false,
                        fordAccountEnable:
                            getObjectFromAEMJson(
                                FORD_ACCOUNT_KEYS.FORD_ENABLE_FLAG,
                                result.elements
                            ) || false,
                        osbHomeRetrieveBookingHeader: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.OSB_HOME_RETRIEVE_BOOKING_HEADER,
                            result.elements
                        ),
                        osbHomeRetrieveBookingCopyText: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.OSB_HOME_RETRIEVE_BOOKING_COPY_TEXT,
                            result.elements
                        ),
                        osbHomeRetrieveBookingButtonLabel: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.OSB_HOME_RETRIEVE_BOOKING_BUTTON,
                            result.elements
                        ),
                        numberOfLeadingCharactersToStrip: getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.NUMBER_OF_CHARS_TO_STRIP,
                            result.elements
                        ),
                        voucherSuccessMessagelabel:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.VOUCHER_SUCCESS_MESSAGE,
                                result.elements
                            ) || '',
                        enableMobileService:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.ENABLE_MOBILE_SERVICE,
                                result.elements
                            ) || false,
                        displayCountryDialCodeComponent:
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.DISPLAY_COUNTRY_DIAL_CODE_COMPONENT,
                                result.elements
                            ) || false,
                    });
                }
                return result;
            }
        );
    };

    const triggerOSBAnalyticsCommonAttributes = () => {
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_START_PAGE,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW
        );
    };

    const clearStoreData = () => {
        resetDealerStep();
        resetServiceStep();
        resetLightDeliveryServiceStep();
        resetServiceLocationStep();
        resetCalendarStep();
        resetPersonalDetailStep();
        resetStepProgressBar();
    };

    const getDealerLocatorDealerId = async (
        dealerId: string,
        dealerIdInSession: string
    ) => {
        await callContentService('osb-app-more-elements').then(
            (result: any) => {
                if (result) {
                    const dealerLocatorDealerId = dealerId.substring(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.NUMBER_OF_CHARS_TO_STRIP,
                            result.elements
                        )
                    );
                    if (
                        dealerLocatorDealerId.toLowerCase() !==
                        dealerIdInSession.toLowerCase()
                    ) {
                        clearStoreData();
                        removeAllClientStorage();
                    } else {
                        manageOsbStepVisibility(
                            STEP_PROGRESS_BAR_KEYS.DEALER,
                            false
                        );
                    }
                }
            }
        );
    };

    useEffect(() => {
        // Logic to reset session storage and global store
        // If a different dealer is deeplinked in same tab during a journey
        // Handles different dealer selected from dealer locator

        const dealerInSessionStorage = getClientStorageValue(
            OSB_CLIENT_STORAGE_KEYS.OSB_DEALER_STEP_KEY
        );
        if (dealerInSessionStorage) {
            // Journey was already started and a dealer was selected
            const dealerIdInSession =
                dealerInSessionStorage.selectedDealerProfile?.dealerCode;
            // PC to OSB flow has started
            if (osbStep.isPcFlow) {
                if (
                    dealerIdInSession.toLowerCase() !==
                    osbDealerStep.selectedDealerId.toLowerCase()
                ) {
                    removeAllClientStorage();
                }
            }
            if (deepLinkParams.get('dealerId')) {
                // From dealer locator
                const dealerId = deepLinkParams.get('dealerId');
                if (dealerId) {
                    getDealerLocatorDealerId(dealerId, dealerIdInSession);
                }
            } else if (deepLinkParams.get('dc')) {
                // Dealer code is deeplinked
                const deeplinkedDealerId = deepLinkParams.get('dc');
                if (
                    deeplinkedDealerId &&
                    deeplinkedDealerId.toLowerCase() !==
                        dealerIdInSession.toLowerCase()
                ) {
                    clearStoreData();
                    removeAllClientStorage();
                } else {
                    manageOsbStepVisibility(
                        STEP_PROGRESS_BAR_KEYS.DEALER,
                        false
                    );
                }
            }
        }
    }, []);

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        clearDigitalData();
        const brandName = OsbUtilService.getAppConfiguration().brand;
        setOSBStepPayload({
            brandName: brandName,
            isMainWrapperInitialized: false,
            UrlQueryParams: queryParams,
        });
        function processDeepLinkParams(numberOfChar: string) {
            if (!osbStep.isRetrieveFlow && !isWebView) {
                const dealerCode =
                    deepLinkParams.get('dc') ||
                    osbDealerStep.selectedDealerId ||
                    '';
                const dealerId = deepLinkParams.get('dealerId') || '';
                const groupDealerCode = deepLinkParams.get('gdc') || '';
                const preSelectedServices =
                    deepLinkParams.get('preselectservices') ||
                    osbStep.preSelectedServices ||
                    '';
                const isGASkipped = deepLinkParams.get('skipga') || '';
                const vin =
                    deepLinkParams.get('vin') ||
                    deepLinkParams.get('vn') ||
                    deepLinkParams.get('reg') ||
                    deepLinkParams.get('rn') ||
                    osbVehicleStep.vinRegNo ||
                    '';
                const mileage =
                    deepLinkParams.get('mileage') ||
                    osbVehicleStep.vinMileage ||
                    osbVehicleStep.manualMileage ||
                    '';
                const voucherCode = deepLinkParams.get('vc') || '';
                if (
                    dealerCode &&
                    dealerCode.match(REGEX_PATTERNS.ALPHA_NUMERIC_PATTERN)
                ) {
                    setOSBDealerStepPayload({
                        selectedDealerId: dealerCode.toUpperCase(),
                        isDealerSelected: true,
                    });
                    setOSBStepPayload({ isDealerDeepLinked: true });
                }
                if (vin && isValidVinOrReg(vin, regionEU)) {
                    setOSBStepPayload({ isVINDeepLinked: true });
                    setOSBVehicleStepPayload({ vinRegNo: vin });
                    fetchVehicleRecallData(vin);
                }
                if (mileage && mileage.match(REGEX_PATTERNS.NUMERIC_PATTERN)) {
                    setOSBStepPayload({ isMileageDeepLinked: true });
                    setOSBVehicleStepPayload({
                        vinMileage: mileage,
                        manualMileage: mileage,
                    });
                }
                if (
                    voucherCode &&
                    voucherCode.match(REGEX_PATTERNS.ALLOW_ANYTHING_PATTERN)
                ) {
                    setOSBStepPayload({ isVoucherDeepLinked: true });
                    setOSBStepPayload({ deepLinkvoucherCode: voucherCode });
                    setOSBServiceStepPayload(
                        { voucherCode: voucherCode },
                        false
                    );
                }
                if (
                    groupDealerCode &&
                    groupDealerCode.match(REGEX_PATTERNS.NUMERIC_PATTERN)
                ) {
                    setOSBDealerStepPayload({
                        groupDealerCode: groupDealerCode,
                        isDealerSelected: true,
                    });
                }
                if (
                    preSelectedServices &&
                    preSelectedServices.match(
                        REGEX_PATTERNS.ALLOW_ANYTHING_PATTERN
                    )
                ) {
                    setOSBStepPayload({
                        preSelectedServices: preSelectedServices,
                    });
                }

                if (isGASkipped) {
                    setOSBStepPayload({
                        isGASkipped: isGASkipped.toLowerCase() === 'true',
                    });
                }

                if (!dealerId) {
                    setIsJourneyInitialized(true);
                }
            } else if (isWebView) {
                const dealerCode =
                    authService.getwebviewBundle()?.dealerCode ||
                    deepLinkParams.get('dealerCode');
                if (
                    dealerCode &&
                    dealerCode.match(REGEX_PATTERNS.ALPHA_NUMERIC_PATTERN)
                ) {
                    setOSBDealerStepPayload({
                        selectedDealerId: dealerCode.substring(
                            Number(numberOfChar)
                        ),
                        isDealerSelected: true,
                    });
                    setOSBStepPayload({ isDealerDeepLinked: true });
                }
            }
        }
        Promise.all([getOsbAppAEMContent(), getOsbAppMoreAEMContent()])
            .then(([, osbAppMoreAEMContent]) => {
                processDeepLinkParams(
                    getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.NUMBER_OF_CHARS_TO_STRIP,
                        osbAppMoreAEMContent.elements
                    )
                );
            })
            .catch(error => {
                dispatch({
                    type: 'ERROR',
                    error: 'Error in fetching AEM content',
                });
                console.log('ERROR-Error in fetching AEM content' + error);
            });
        triggerOSBAnalyticsCommonAttributes();
    }, []);

    useEffect(() => {
        if (!isWebView) {
            // authentication-service will take care of setting fmaAuthenticated value in sessionStorage.
            // refer login () and logout() methods in authentication-service.ts file

            const fmaAuthenticated = getFromSessionStorage('fmaAuthenticated');

            if (fmaAuthenticated && fmaAuthenticated === 'true') {
                // making fmaAuthenticated as a boolean value
                const isGOAuthenticated = JSON.parse(fmaAuthenticated);

                // below check blocks reexecution of the code
                // if user is authenticated and first name and last name are present means
                // profile creation is already done and reexecution is not needed
                if (
                    isGOAuthenticated &&
                    osbPersonalDetail?.firstName === '' &&
                    osbPersonalDetail?.lastName === ''
                ) {
                    prepareAuthenticatedData();
                } else if (osbStep.isAuthenticatedFlow !== isGOAuthenticated) {
                    // user is unauthenticated
                    // login page redirect
                    authService.login();
                }
                // setting isAuthenticatedFlow and isFmaAuthenticated
                // isAuthenticatedFlow - will determin, whether it is an authenticated flow (or) not
                // isFmaAuthenticated - will trigger this useEffect (see useEffect dependency).
                // Its value updating as false, in every step navigation.
                // More details - refer 'invalidateAuthenticate()' method in use-osb-step.ts
                setOSBStepPayload({
                    isAuthenticatedFlow: isGOAuthenticated,
                    isFmaAuthenticated: isGOAuthenticated,
                });
            }
        }
    }, [profile, osbStep.isFmaAuthenticated]);
    const navigateToPersistedRoute = (persistedRoute: any) => {
        /* when user tries to navigate to a persited path that is not editable,
        then navigate the user to the last immediate editale step */
        const persistedStep = osbStepProgressBar.progressBarDetails.find(
            step =>
                step.navigationPath.toLowerCase() ===
                persistedRoute.toLowerCase()
        );
        //if the persisted step is not editable
        if (persistedStep && !persistedStep.isEditable) {
            const editableSteps = osbStepProgressBar.progressBarDetails.filter(
                step => step.isEditable
            );
            if (editableSteps.length > 0) {
                history.push(
                    buildNavigationUrl(
                        editableSteps[editableSteps.length - 1].navigationPath,
                        queryParams
                    )
                );
            } // If the persisted route is editable, then navigate to the persisted path
        } else {
            history.push(buildNavigationUrl(persistedRoute, queryParams));
        }
    };
    useEffect(() => {
        const isInitialLoadComplete =
            !httpState.isLoading &&
            !osbStep.isMainWrapperInitialized &&
            isJourneyInitialized;
        const isDeepLinked =
            osbStep.isDealerDeepLinked ||
            osbStep.isVINDeepLinked ||
            osbStep.isMileageDeepLinked ||
            osbStep.isVoucherDeepLinked;
        const isMainPath = [
            OsbPathStartStep(),
            OsbPathStartStep() + '/',
            OsbPathVehicleStep(),
            OsbPathDealerStep(),
            OsbPathServicesStep(),
            OsbPathDeliveryStep(),
            OsbPathCalenderStep(),
            OsbPathPersonalDetailsStep(),
            OsbPathServiceLocationStep(),
        ].includes(pathName);
        const isProtectedPath = [
            OsbPathBookingConfirmationStep(),
            OsbPathBookingCancelStep(),
        ].includes(pathName);
        if (isInitialLoadComplete) {
            setOSBStepPayload({
                isMainWrapperInitialized: true,
                source: osbStep.source || API_REQUEST_SOURCE_TYPE.WEB,
            });
            if (isDeepLinked || isMainPath) {
                let persistedRoute = getPersistedRoute(
                    OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY
                );

                if (isClientStorageExpired()) {
                    persistedRoute = null;
                    removeClientStorage(
                        OSB_CLIENT_STORAGE_KEYS.OSB_CLIENT_STORAGE_EXPIRY_PERIOD_KEY
                    );
                }
                if (
                    (osbStep.isDealerDeepLinked && !persistedRoute) ||
                    isWebView
                ) {
                    setDealerDetailsFromMarketDealers(
                        osbDealerStep.selectedDealerId
                    );
                } else if (
                    (osbStep.isRetrieveFlow || osbStep.isDealerDeepLinked) &&
                    pathName === OsbPathDealerStep()
                ) {
                    /*Redirects to service step, when the user tries for dealer step path navigation during OSB retrieve flow */
                    history.push(
                        buildNavigationUrl(OsbPathServicesStep(), queryParams)
                    );
                } else if (persistedRoute) {
                    navigateToPersistedRoute(persistedRoute);
                } else {
                    history.push(
                        buildNavigationUrl(OsbPathDealerStep(), queryParams)
                    );
                }
            } else if (!osbStep.isAuthenticatedFlow && isProtectedPath) {
                // redirecting to OSB page if user access booking confirmation or cancel confirmation directly
                history.push(
                    buildNavigationUrl(OsbPathDealerStep(), queryParams)
                );
            }
        }
    }, [
        httpState.isLoading,
        osbStep.isMainWrapperInitialized,
        isJourneyInitialized,
    ]);

    useEffect(() => {
        const addOsbEuHeader = () => {
            const elementHeader = document.getElementById('euheaderId');
            if (elementHeader) {
                elementHeader.classList.add('osbEuHeader');
            }
        };
        const removeOsbEuHeader = () => {
            const osbEuHeader = document.getElementsByClassName('osbEuHeader');
            if (osbEuHeader.length > 0) {
                const elementHeader = document.getElementById('euheaderId');
                if (elementHeader) {
                    elementHeader.classList.remove('osbEuHeader');
                }
            }
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', addOsbEuHeader);
            window.addEventListener('popstate', removeOsbEuHeader);
            return () => {
                const elementHeader = document.getElementById('euheaderId');
                if (elementHeader) {
                    elementHeader.classList.remove('osbEuHeader');
                }
                window.removeEventListener('scroll', addOsbEuHeader);
                window.removeEventListener('popstate', removeOsbEuHeader);
            };
        }
    }, []);

    return (
        <ViewportContextProvider>
            <div className="osb-main-wrapper-view">
                {!isWebView && (
                    <OSBBanner
                        applicationName={applicationName}
                        webview={isWebView}
                    />
                )}
                {osbStep.displayProgressBar &&
                    stepsListForProgressBar.includes(pathName) && (
                        <OSBStepProgressBar />
                    )}

                {props.children}
                <div className="main-wrapper-bottom-space"></div>
            </div>
        </ViewportContextProvider>
    );
}
