import React, { useContext, useEffect, useState } from 'react';
import { AutoComplete, Dropdown, PrimaryButton } from '../../../common';
import './review-service-price.scss';
import ServicePriceInput from '../service-price-input/service-price-input';
import { OSBUseLocation } from '../../owners-osb/common/osb-use-location/osb-use-location';
import FindMyVinPopup from '../find-my-vin-popup/find-my-vin-popup';
import GoogleMapService from '../../../../services/osb-service/google-map-service/google-map-service';
import {
    PRICE_CALCULATOR_CONSTANTS,
    PRICE_CALCULATOR_ANALYTICS,
} from '../price-calculator-constant';
import { buildVehicleLabel } from '../pc-utils';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import ProfileService from '../../../../services/profile-service/profile-service';
import {
    UserVehicleInfo,
    VehicleDetail,
} from '../../../../models/profile-with-vehicles';
import { useServiceStep, useVehicleStep } from '../../../../hooks/owners-osb';
import useUserProfile from '../../../../hooks/use-user-profile';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { ServicePriceCalculatorResult } from '../service-price-calculator-result/service-price-calculator-result';
import errorWarning from '../../../../assets/error-warning.svg';
import {
    triggerGetQuoteAnalytics,
    triggerLocationSignInAnalytics,
    triggerUserInputAnalytics,
} from '../analytics/price-calculator-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';

import {
    getFromSessionStorage,
    getObjectFromAEMJson,
    getVinVehicleResponse,
    isThisVIN,
    isValidVin,
} from '../../owners-osb/osb-utils';
import ServerContext from '../../../../contexts/serverContext';
import { getInitialPrognosticsDetails } from '../../../../hooks/owners-osb/use-vehicle-step';
import ServiceHandler from '../../../../services/service-handler';
import { LogService } from '../../../../services/log-service/log-service';

interface Option {
    label: string;
    value: string;
}
interface Props {
    findMyVinContent?: any;
    priceCalculatorContent: any;
    allOsbDealers: DealerProfileInfo[];
    osbThreeLetterCountryCode: string;
}
const ReviewServicePrice = (props: Props) => {
    const profile = useUserProfile();
    const authService = new AuthenticationService();
    const profileService = new ProfileService();
    const [vinRegInput, setVinRegInput] = useState<string>('');
    const [mileageInput, setMileageInput] = useState<string>('');
    const [locationInput, setLocationInput] = useState<string>('');
    const [updatedVinRegInput, setUpdatedVinRegInput] = useState<string>('');
    const [updatedMileageInput, setUpdatedMileageInput] = useState<string>('');
    const [updatedLocationInput, setUpdatedLocationInput] = useState<string>(
        ''
    );
    const [serviceResultSection, setServiceResultSection] = useState(false);
    const [showFindMyVinPopup, setShowFindMyVinPopup] = useState<boolean>(
        false
    );
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    const onModalClose = () => {
        setShowFindMyVinPopup(false);
    };

    const renderFindMyVinPopup = () => {
        triggerLocationSignInAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.FIND_MY_VIN
        );
        setShowFindMyVinPopup(true);
    };

    const [showVinRegLabel, setShowVinRegLabel] = useState<boolean>(false);
    const vinRegLabelShow = (flag: boolean) => {
        setShowVinRegLabel(flag);
    };

    const [showMileageLabel, setshowMileageLabel] = useState<boolean>(false);
    const mileageLabelShow = (flag: boolean) => {
        setshowMileageLabel(flag);
    };
    const [showLocationLabel, setshowLocationLabel] = useState<boolean>(false);
    const locationLabelShow = (flag: boolean) => {
        setshowLocationLabel(flag);
    };

    const [vinRegErrorMsg, setVinRegErrorMsg] = useState<string>('');
    const [mileageErrorMsg, setMileageErrorMsg] = useState<string>('');
    const [locationErrorMsg, setLocationErrorMsg] = useState<string>('');
    const [serviceErrorMsg, setServiceErrorMsg] = useState<string>('');
    const [isAuthenticatedFlow, setIsAuthenticatedFlow] = useState<boolean>(
        false
    );

    const { setOSBServiceStepPayload } = useServiceStep();
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const [userVehicleList, setUserVehicleList] = useState<VehicleDetail[]>([]);
    const [isManualVinRegEntry, setIsManualVinRegEntry] = useState<boolean>(
        false
    );
    const [fireEvents] = useAnalytics();

    const fetchVehicleErrorMessage = (error: string) => {
        if (error.trim().length > 0) {
            setServiceResultSection(false);
            if (error === 'VIN_NOT_FOUND') {
                setVinRegErrorMsg(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Invalid_Vin_Error_Message,
                        props.priceCalculatorContent
                    )
                );
            } else if (error === 'REG_NOT_FOUND') {
                setVinRegErrorMsg(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Invalid_Reg_Error_Message,
                        props.priceCalculatorContent
                    )
                );
            } else {
                setServiceErrorMsg(error);
            }
        }
    };

    const locationErrorMsgDetails = (error: string) => {
        if (error === 'LOCATION_ERROR') {
            setServiceResultSection(false);
            setLocationErrorMsg(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_CONSTANTS.Location_Unknown_Label,
                    props.priceCalculatorContent
                )
            );
        }
    };

    const errorMsgDealerDetails = (error: string) => {
        if (error.trim().length > 0) {
            setServiceResultSection(false);
            setServiceErrorMsg(error);
        } else {
            setServiceErrorMsg('');
        }
    };

    const errorServicesDetails = (error: string) => {
        if (error.trim().length > 0) {
            setServiceResultSection(false);
            setServiceErrorMsg(error);
        } else {
            setServiceErrorMsg('');
        }
    };

    const checkForConnectedVehicleData = async (
        preSelectedVehicle: VehicleDetail
    ) => {
        try {
            const { vin = '' } = preSelectedVehicle;
            const { isConnected } = await new VehicleTypeService().request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            );

            if (isConnected) {
                const response = await getVinVehicleResponse(vin);
                const { vehiclestatus } = response ?? {};
                if (vehiclestatus && vehiclestatus?.odometer) {
                    const authMileage = Number(vehiclestatus.odometer.value);
                    if (authMileage) {
                        setMileageInput(Math.round(authMileage).toString());
                        setOSBVehicleStepPayload({
                            vinMileage: Math.round(authMileage).toString(),
                            manualMileage: Math.round(authMileage).toString(),
                        });
                    }
                }
                // New prognostic call for PC :
                ServiceHandler.VehiclePrognosticsService.getActivePrognosticsV2(
                    vin
                )
                    .then(result => {
                        if (result && result.prognosticsData) {
                            setOSBVehicleStepPayload({
                                vehiclePrognosticData:
                                    result?.prognosticsData[0],
                            });
                        }
                    })
                    .catch(() => {
                        osbVehicleStep.vehiclePrognosticData = getInitialPrognosticsDetails();
                        LogService.log(
                            'Authentication',
                            'Received error response from prognostics service'
                        );
                    });
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        //For Auth Flow - Get the user vehicle data
        const fmaAuthenticated = getFromSessionStorage('fmaAuthenticated');
        setIsAuthenticatedFlow(fmaAuthenticated === 'true');
        const { vehicles = [] } = profile ?? {};
        if (vehicles.length > 0) {
            const lastSelectedVin = profileService.getUserPreferences(
                profile?.profile?.email
            )?.lastSelectedVin;

            const preSelectedVin = lastSelectedVin || vehicles[0]?.vin; // Select first vehicle by default
            setVinRegInput(preSelectedVin);
            setUserVehicleList(vehicles);
            setOSBServiceStepPayload({
                userVehicles: vehicles.map(vehicle => {
                    const { modelName, nickName, vin, modelYear } = vehicle;
                    return {
                        modelName,
                        nickName,
                        vin,
                        modelYear,
                    };
                }),
            });

            const preSelectedVehicle = vehicles.find(
                vehicle => vehicle.vin === preSelectedVin
            );
            if (preSelectedVehicle) {
                const userVehicleLabel = buildVehicleLabel(preSelectedVehicle);
                setOSBVehicleStepPayload({
                    vinRegNo: preSelectedVin,
                    vehicleLabel: userVehicleLabel,
                });
                checkForConnectedVehicleData(preSelectedVehicle);
            }
        }
    }, [profile]);

    useEffect(() => {
        setOSBVehicleStepPayload({ vinMileage: '' });
    }, []);

    const showCurrentLocation = () => {
        triggerLocationSignInAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.USE_MY_LOCATION_LABEL
        );
        navigator.geolocation.getCurrentPosition(
            position => {
                GoogleMapService.getReverseGeoCoding(
                    position.coords.latitude,
                    position.coords.longitude,
                    function(data: any) {
                        if (data) {
                            setLocationInput(data);
                        }
                    }
                );
            },
            error => {
                if (error.code === 1) {
                    setLocationErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Location_Permission_Denied_Label,
                            props.priceCalculatorContent
                        )
                    );
                } else if (error.code === 2) {
                    setLocationErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Location_Position_Unavailable_Label,
                            props.priceCalculatorContent
                        )
                    );
                } else if (error.code === 3) {
                    setLocationErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Location_Timeout_Label,
                            props.priceCalculatorContent
                        )
                    );
                } else {
                    setLocationErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Location_Unknown_Label,
                            props.priceCalculatorContent
                        )
                    );
                }
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 }
        );
    };

    const getQuote = () => {
        if (validateServicePriceInput()) {
            triggerGetQuoteAnalytics(fireEvents);
            setUpdatedVinRegInput(vinRegInput);
            setUpdatedMileageInput(mileageInput);
            setUpdatedLocationInput(locationInput);
            setServiceResultSection(true);
        }
    };

    const buttonTextLineCount = (buttonText: string) => {
        return buttonText.length <= 15 ? true : false;
    };

    const validateServicePriceInput = () => {
        const isValidateVinRegNo = validateVinRegNo(vinRegInput);
        const isValidMileage = validateMileage(mileageInput);
        const isValidLocation = validateLocation(locationInput);

        if (isValidateVinRegNo && isValidMileage && isValidLocation)
            return true;
        else return false;
    };

    const validateVinRegNo = (vinRegNo: string) => {
        if (vinRegNo === '') {
            setVinRegErrorMsg(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_CONSTANTS.Vin_Reg_Validation_Message,
                    props.priceCalculatorContent
                )
            );
            return false;
        } else {
            const regEx = getObjectFromAEMJson(
                PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_Pattern,
                props.priceCalculatorContent
            );
            const isVinRegValid = isValidVin(vinRegNo, regEx);
            if (!isVinRegValid) {
                if (isThisVIN(vinRegNo)) {
                    //VIN
                    setVinRegErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Invalid_Vin_Error_Message,
                            props.priceCalculatorContent
                        )
                    );
                } else {
                    setVinRegErrorMsg(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Invalid_Reg_Error_Message,
                            props.priceCalculatorContent
                        )
                    );
                }
                return false;
            } else {
                setVinRegErrorMsg('');
                return true;
            }
        }
    };

    const onVinRegChange = (e: any) => {
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.VIN_LABEL
        );
        setVinRegInput(e.target.value);
        validateVinRegNo(e.target.value);
    };

    const validateLocation = (location: string) => {
        if (location.trim().length > 0) {
            return true;
        } else {
            setLocationErrorMsg(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_CONSTANTS.Location_Validation_Message,
                    props.priceCalculatorContent
                )
            );
            return false;
        }
    };
    const validateMileage = (mileage: string) => {
        const correctMileage = mileage.replace(/[^0-9 .,']+$/, '');
        const sanitisedMileage = correctMileage
            ? correctMileage.replace(/[ .,']/g, '')
            : '';
        const finalMileage = sanitisedMileage.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ''
        );
        setMileageInput(finalMileage);
        if (finalMileage === '') {
            const errorMsg = getObjectFromAEMJson(
                PRICE_CALCULATOR_CONSTANTS.Mileage_Validation_Message,
                props.priceCalculatorContent
            );
            setMileageErrorMsg(errorMsg);
            return false;
        } else {
            setMileageErrorMsg('');
            return true;
        }
    };

    const onMileageChange = (e: any) => {
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.MILEAGE_LABEL
        );
        validateMileage(e.target.value);
    };

    const fetchLocationInput = (input: string) => {
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.LOCATION_LABEL
        );
        setLocationInput(input);
        if (input !== '') {
            setLocationErrorMsg('');
        }
    };

    const handleSignInClick = () => {
        triggerLocationSignInAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.LOGIN_CTA_LABEL
        );
        authService.login();
    };

    const handleChooseYourVehicleClick = () => {
        setIsManualVinRegEntry(false);
        setOSBVehicleStepPayload({
            vinMileage: '',
        });
    };

    const onVinDropdownChange = (label: any, index: any, value: any) => {
        if (value === 'vinManualEntry') {
            setIsManualVinRegEntry(true);
            setOSBVehicleStepPayload({ vinRegNo: '', vinMileage: '' });
            setVinRegInput('');
            setOSBVehicleStepPayload({
                vehiclePrognosticData: getInitialPrognosticsDetails(),
            });
        } else {
            setVinRegInput(value);
            setIsManualVinRegEntry(false);
            setOSBVehicleStepPayload({ vinRegNo: value, vehicleLabel: label });
            const selectedVehicle = userVehicleList.find(
                vehicle => vehicle.vin === value
            );
            setOSBVehicleStepPayload({
                vehiclePrognosticData: getInitialPrognosticsDetails(),
            });
            selectedVehicle && checkForConnectedVehicleData(selectedVehicle);
        }
    };

    const getVehicleList = () => {
        const VehicleNameList: Option[] = [];
        if (userVehicleList !== undefined) {
            userVehicleList.forEach((item: UserVehicleInfo) => {
                const VehicleModelAndYear =
                    item.modelName + ' ' + item.modelYear;
                const vehicleName = item.nickName
                    ? item.nickName
                    : VehicleModelAndYear;
                const option: Option = {
                    label: vehicleName,
                    value: item.vin || '',
                };
                VehicleNameList.push(option);
            });
        }
        const manualEntryFieldLabel = getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Enter_NewVin_Label,
            props.priceCalculatorContent
        );

        if (manualEntryFieldLabel !== '' && manualEntryFieldLabel !== null) {
            const defaultOption: Option = {
                label: manualEntryFieldLabel,
                value: 'vinManualEntry',
            };
            VehicleNameList.push(defaultOption);
        }
        return VehicleNameList;
    };

    const authFlowVinRegNoSection = () => {
        return (
            <>
                {isManualVinRegEntry ? (
                    <>
                        <div className="vinreg-auth-wrapper">
                            <ServicePriceInput
                                inputLabel={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_Label,
                                    props.priceCalculatorContent
                                )}
                                inputError={vinRegErrorMsg}
                                showLabel={
                                    showVinRegLabel || vinRegInput.length > 0
                                }
                            >
                                <input
                                    id="vinRegAuthInput"
                                    className={`vinreg-auth-input-text ${
                                        vinRegErrorMsg
                                            ? 'vinreg-input-text-error'
                                            : ''
                                    } ${
                                        vinRegInput.length > 0
                                            ? 'bg_input_color_onchange'
                                            : ''
                                    }`}
                                    data-testid="vinRegAuthInput"
                                    type="text"
                                    aria-label="vinRegAuthInput"
                                    aria-labelledby="vinRegAuthInput"
                                    name="vinRegAuthInput"
                                    placeholder="Reg. no. or VIN"
                                    value={vinRegInput.toUpperCase()}
                                    onChange={e => onVinRegChange(e)}
                                    onBlur={() => vinRegLabelShow(false)}
                                    onFocus={() => vinRegLabelShow(true)}
                                />
                            </ServicePriceInput>
                            <span
                                className="auth-vin-icon"
                                onClick={renderFindMyVinPopup}
                            >
                                &#9432;
                            </span>
                        </div>
                        {userVehicleList.length > 0 && (
                            <div
                                className="choose-vehicle-link"
                                onClick={handleChooseYourVehicleClick}
                            >
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Or_Choose_Your_VehicleLink,
                                    props.priceCalculatorContent
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="pc-dropdown-vin-wrapper">
                        <div className="choose-vehicle-label">
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Choose_Your_Vehicle_Label,
                                props.priceCalculatorContent
                            )}
                        </div>
                        <Dropdown
                            showLabel={false}
                            label={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Please_Select_PlaceHolder,
                                props.priceCalculatorContent
                            )}
                            options={getVehicleList()}
                            value={osbVehicleStep.vehicleLabel}
                            onChange={onVinDropdownChange}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <div
                className="review-service-price-container"
                id="pc-header-section"
            >
                <h1 className="review-header" aria-label="review-header">
                    {getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.PC_Review_Header,
                        props.priceCalculatorContent
                    )}
                </h1>
                {serviceErrorMsg.length > 0 && (
                    <div className="service-error">
                        <span>
                            <img
                                className="osb-error-icon"
                                src={errorWarning}
                                alt="error icon"
                            />{' '}
                            {serviceErrorMsg}
                        </span>
                    </div>
                )}

                <div className="review-text">
                    <span>
                        {
                            getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.PC_SUBHEADER,
                                props.priceCalculatorContent
                            ).split('##')[0]
                        }
                        {!isAuthenticatedFlow && (
                            <>
                                {
                                    getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.PC_SUBHEADER,
                                        props.priceCalculatorContent
                                    ).split('##')[1]
                                }

                                <span
                                    className="sign-in"
                                    onClick={handleSignInClick}
                                >
                                    {
                                        getObjectFromAEMJson(
                                            PRICE_CALCULATOR_CONSTANTS.PC_SUBHEADER,
                                            props.priceCalculatorContent
                                        ).split('##')[2]
                                    }
                                </span>
                                {
                                    getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.PC_SUBHEADER,
                                        props.priceCalculatorContent
                                    ).split('##')[3]
                                }
                            </>
                        )}
                    </span>
                </div>
                <div className="vehicle-input-container">
                    {isAuthenticatedFlow && (
                        <div className="input-regno-vin-authflow">
                            {authFlowVinRegNoSection()}
                        </div>
                    )}
                    {!isAuthenticatedFlow && (
                        <div className="input-regno-vin">
                            <ServicePriceInput
                                inputLabel={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_Label,
                                    props.priceCalculatorContent
                                )}
                                inputError={vinRegErrorMsg}
                                showLabel={
                                    showVinRegLabel || vinRegInput.length > 0
                                }
                            >
                                <input
                                    id="vinRegInput"
                                    className={`vinreg-input-text ${
                                        vinRegErrorMsg
                                            ? 'vinreg-input-text-error'
                                            : ''
                                    } ${
                                        vinRegInput.length > 0
                                            ? 'bg_input_color_onchange'
                                            : ''
                                    } `}
                                    data-testid="input-text"
                                    type="text"
                                    aria-label="vinRegInput"
                                    aria-labelledby="vinRegInput"
                                    name="vinRegInput"
                                    placeholder={getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_PlaceHolder,
                                        props.priceCalculatorContent
                                    )}
                                    value={vinRegInput.toUpperCase()}
                                    onChange={e => onVinRegChange(e)}
                                    onBlur={() => vinRegLabelShow(false)}
                                    onFocus={() => vinRegLabelShow(true)}
                                />
                            </ServicePriceInput>
                            <div
                                className="vin-reg-info-icon"
                                onClick={renderFindMyVinPopup}
                            >
                                <span className="find-vin-text">
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.Where_To_Find_My_Vin,
                                        props.priceCalculatorContent
                                    )}
                                </span>
                                <span className="find-vin-icon">&#9432;</span>
                            </div>
                        </div>
                    )}
                    <div className="input-mileage">
                        <ServicePriceInput
                            inputLabel={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Mileage_Label,
                                props.priceCalculatorContent
                            )}
                            inputError={mileageErrorMsg}
                            showLabel={
                                showMileageLabel || mileageInput.length > 0
                            }
                        >
                            <input
                                id="mileageInput"
                                data-testid="input-text"
                                className={`mileage-input-text ${
                                    mileageErrorMsg
                                        ? 'mileage-input-text-error'
                                        : ''
                                } ${
                                    mileageInput.length > 0 ||
                                    osbVehicleStep.vinMileage.length > 0
                                        ? 'bg_input_color_onchange'
                                        : ''
                                }`}
                                type="text"
                                aria-label="mileageInput"
                                aria-labelledby="mileageInput"
                                name="mileageInput"
                                placeholder={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Mileage_PlaceHolder,
                                    props.priceCalculatorContent
                                )}
                                value={
                                    mileageInput || osbVehicleStep.vinMileage
                                }
                                onChange={e => onMileageChange(e)}
                                onBlur={() => mileageLabelShow(false)}
                                onFocus={() => mileageLabelShow(true)}
                            />
                        </ServicePriceInput>
                    </div>
                    <div
                        className={`input-location ${
                            locationInput.length > 0
                                ? 'input-location_onchange'
                                : ''
                        } `}
                    >
                        <div className="pc-autocomplete">
                            <ServicePriceInput
                                inputLabel={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.My_Location_Label,
                                    props.priceCalculatorContent
                                )}
                                inputError={locationErrorMsg}
                                showLabel={
                                    showLocationLabel ||
                                    locationInput.length > 0
                                }
                            >
                                <AutoComplete
                                    name="myLocation"
                                    id="myLocation"
                                    google={true}
                                    placeHolderText={getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.My_Location_PlaceHolder,
                                        props.priceCalculatorContent
                                    )}
                                    text={locationInput}
                                    message={getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.Search_Hint_Label,
                                        props.priceCalculatorContent
                                    )}
                                    errorClass={`${
                                        locationErrorMsg
                                            ? 'pc-input-text-error'
                                            : ''
                                    } `}
                                    fetchAutoCompleteInput={fetchLocationInput}
                                    searchStringLength={getObjectFromAEMJson(
                                        PRICE_CALCULATOR_CONSTANTS.Autocomplete_Search_String_Length,
                                        props.priceCalculatorContent
                                    )}
                                    onBlur={() => locationLabelShow(false)}
                                    onFocus={() => locationLabelShow(true)}
                                />
                            </ServicePriceInput>
                        </div>
                        <div className="pc-use-location">
                            <OSBUseLocation
                                showCurrentLocation={showCurrentLocation}
                                useLocationLabel={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Use_My_Location_Label,
                                    props.priceCalculatorContent
                                )}
                            />
                        </div>
                    </div>

                    <div
                        className={`btn-get-quote ${
                            buttonTextLineCount(
                                getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Get_A_Quote_Btn_Label,
                                    props.priceCalculatorContent
                                )
                            )
                                ? 'btn-single-text-margin'
                                : 'btn-double-text-margin'
                        }`}
                    >
                        <PrimaryButton
                            role="link"
                            color={'dark'}
                            fill={'fill'}
                            chevron={false}
                            ariaLabel="get-a-quote"
                            onClick={() => getQuote()}
                        >
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Get_A_Quote_Btn_Label,
                                props.priceCalculatorContent
                            )}
                        </PrimaryButton>
                    </div>
                </div>
                <div className="find-my-vin-popup-container">
                    <FindMyVinPopup
                        showFindMyVinPopup={showFindMyVinPopup}
                        onModalClose={onModalClose}
                        aemContent={props.findMyVinContent}
                    />
                </div>
            </div>
            <div>
                {serviceResultSection && (
                    <ServicePriceCalculatorResult
                        allOsbDealers={props.allOsbDealers}
                        isAuthenticatedFlow={isAuthenticatedFlow}
                        vinRegNo={updatedVinRegInput}
                        mileage={updatedMileageInput}
                        location={updatedLocationInput}
                        fetchVehicleErrorMessage={fetchVehicleErrorMessage}
                        locationErrorMsgDetails={locationErrorMsgDetails}
                        errorMsgDealerDetails={errorMsgDealerDetails}
                        errorServicesDetails={errorServicesDetails}
                        osbThreeLetterCountryCode={
                            props.osbThreeLetterCountryCode
                        }
                        priceCalculatorContent={props.priceCalculatorContent}
                    />
                )}
            </div>
        </>
    );
};

export default ReviewServicePrice;
