import React from 'react';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { HelpRouterFragment } from '../../../models/experiencefragments/help-router';

const euHelpRouterPopularLinks = () => {
    const [helpRouterContent] = useExperienceContent<HelpRouterFragment>(
        'common',
        'help-router',
        'helprouter'
    );

    return (
        <div className={'help-router-popular-links'}>
            <p>{helpRouterContent?.mainMenu.sectionTitle}</p>
            <p>
                <a
                    href={
                        helpRouterContent?.mainMenu.recommendedLinks.link1
                            .link1CtaUrlPath
                    }
                    aria-label={
                        helpRouterContent?.mainMenu.recommendedLinks.link1
                            .link1AriaLabel
                    }
                >
                    {
                        helpRouterContent?.mainMenu.recommendedLinks.link1
                            .link1CtaTitle
                    }
                </a>
            </p>
            <p>
                <a
                    href={
                        helpRouterContent?.mainMenu.recommendedLinks.link2
                            .link2CtaUrlPath
                    }
                    aria-label={
                        helpRouterContent?.mainMenu.recommendedLinks.link2
                            .link2AriaLabel
                    }
                >
                    {
                        helpRouterContent?.mainMenu.recommendedLinks.link2
                            .link2CtaTitle
                    }
                </a>
            </p>
            <p>
                <a
                    href={
                        helpRouterContent?.mainMenu.recommendedLinks.link3
                            .link3CtaUrlPath
                    }
                    aria-label={
                        helpRouterContent?.mainMenu.recommendedLinks.link3
                            .link3AriaLabel
                    }
                >
                    {
                        helpRouterContent?.mainMenu.recommendedLinks.link3
                            .link3CtaTitle
                    }
                </a>
            </p>
        </div>
    );
};
export default euHelpRouterPopularLinks;
