import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import './light-datetime-lincoln.scss';
import Calendar from '../calendar';
import { TimeslotPicker } from '../timeslot-picker';
import { PrimaryButton } from '../../../../common';
import {
    AvailableDates,
    DealerCalendarDetails,
    TimeRange,
} from '../../../../../models/osb-model/dealer-calendar-details';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import {
    DATE_TIME_STEP,
    STEP_PROGRESS_BAR_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
    SERVICE_FLOW,
    SERVICE_DELIVERY_TYPE,
} from '../../osb-constant';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import {
    useCalendarStep,
    useHttp,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    usePersonalDetailStep,
    useServiceStep,
    useStepProgressBar,
    useVehicleStep,
    useViewport,
    useDealerStep,
    useServiceLocationStep,
    useRetrieveBookingStep,
    usePageRefresh,
} from '../../../../../hooks/owners-osb';
import { LightBookingSummary } from '../../light-booking-summary/light-booking-summary';
import BookingSummaryAccordion from '../../light-booking-summary/booking-summary-accordion/booking-summary-accordion';
import { OSBSecondaryButton } from '../../common/osb-secondary-button/osb-secondary-button';
import {
    triggerBookServiceGlobalCTAOnClickAnalytics,
    triggerDateTimeLoadAnalytics,
    triggerDateTimeLoadErrorAnalytics,
    triggerRetrieveAmendDateAndTImeLoadAnalytics,
    triggerRetrieveDateTimeAmendErrorAnalytics,
} from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import {
    OsbPathBookingRetrieveSummaryStep,
    OsbPathBookingReviewStep,
    OsbPathCalenderStep,
    OsbPathDeliveryStep,
    OsbPathPersonalDetailsStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
} from '../../osb-controller';
import {
    getObjectFromAEMJson,
    setRouteToPersist,
    buildNavigationUrl,
    getLightVehicleData,
    ErrorResponse,
    isConvenienceOptionTypePresent,
    formatMobileNumber,
    getGoMainHeaderHeight,
} from '../../osb-utils';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import { LightPickupCalendar } from './light-pickup-service-calendar';
import ServiceHandler from '../../../../../services/service-handler';
import { OSBPayload } from '../../../../../services/shortcode-service/shortcode-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { useCampaignStatesStep } from '../../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import useDealerCalendarAvailability from '../../../../../hooks/owners-osb/use-dealer-calendar-availability';
const dateFns = require('date-fns');

const appConfig = OsbUtilService.getAppConfiguration();
const localeAsString = appConfig.languageRegionCode;

interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}
export const LightDateTime = (props: Props) => {
    const [showPickupServiceCalendar, setShowPickupServiceCalendar] = useState(
        false
    );
    const [errorMsgService, setErrorMsgService] = useState('');
    const { osbRetrieveBookingStep } = useRetrieveBookingStep();
    const {
        osbStepProgressBar,
        updateProgressBarStatus,
    } = useStepProgressBar();
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const {
        osbCalendarStep,
        setOSBCalendarStepPayload,
        callContentService,
    } = useCalendarStep();
    const [
        disablePickupCalendarContinueButton,
        setDisablePickupCalendarContinueButton,
    ] = useState(
        osbCalendarStep.selectedPickupDate?.length > 0 &&
            osbCalendarStep.selectedPickupTime?.length > 0
            ? false
            : true
    );
    const { httpState, dispatch } = useHttp();
    const { osbDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbServiceStep } = useServiceStep();
    const { osbPersonalDetail } = usePersonalDetailStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const [fireEvents] = useAnalytics();
    const history = useHistory();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const { isMobileView } = useViewport();
    const { fetchAvailability } = useDealerCalendarAvailability();
    const [
        availableDatesWithTimeslots,
        setAvailableDatesWithTimeslots,
    ] = useState<AvailableDates[]>([]);
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
    const [calendarDateErrorMsg, setCalendarDateErrorMsg] = useState<string>(
        ''
    );
    const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
    const [availableTimeRanges, setAvailableTimeRanges] = useState<string[]>(
        []
    );
    const [calendarStartDate, setCalendarStartDate] = useState<string>('');
    const [calendarEndDate, setCalendarEndDate] = useState<string>('');
    const [dateTimeContent, setDateTimeContent] = useState<
        OsbContentResponse
    >();
    const [callDealerPrefix, setCallDealerPrefix] = useState<string>('');
    const [callDealerPostfix, setCallDealerPostfix] = useState<string>('');
    const [callDealerAnchorText, setCallDealerAnchorText] = useState<string>(
        ''
    );
    const [open, setOpen] = useState(false);
    const [defaultTime, setDefaultTime] = useState<string>(
        osbCalendarStep.selectedTime || ''
    );
    const [defaultTimeRange, setDefaultTimeRange] = useState<string>(
        osbCalendarStep.selectedTimeRange || ''
    );
    const [defaultDate, setDefaultDate] = useState<string>(
        osbCalendarStep.selectedDate || ''
    );
    const [isPickupDeliverySelected] = useState(
        isConvenienceOptionTypePresent(
            SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE,
            osbLightDeliveryServiceStep.selectedServices
        ) || false
    );
    const isRetrieveStep = osbStep.isRetrieveFlow;
    const { osbServiceLocationDetail } = useServiceLocationStep();
    let sameDayPickUp =
        osbCalendarStep.dealerCalendarInfo.availableDates?.filter(
            (entry: any) => entry.date === osbCalendarStep.selectedDate
        )[0]?.sameDayPickUp || '';
    usePageRefresh();

    const closeModal = () => {
        setOpen(false);
    };

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const bookable = useAllOSBState();

    const goToPersonalDetailsStep = async () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.CONTINUE_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        if (
            isPickupDeliverySelected &&
            !showPickupServiceCalendar &&
            osbStep.enablePickupNDeliveryLocationDateAndTime
        ) {
            // pickup and delivery calendar work only in new calendar design
            setShowPickupServiceCalendar(true);
        } else {
            invalidateAuthentication();
            setOSBCalendarStepPayload({
                selectedTime: defaultTime,
                selectedDate: defaultDate,
                selectedTimeRange: defaultTimeRange,
            });
            if (!defaultDate && isMobileView) {
                setCalendarDateErrorMsg(
                    dateTimeContent?.elements
                        .find(e => e.name === DATE_TIME_STEP.PLEASE_SELECT_TEXT)
                        ?.value.toString() ?? ''
                );

                setIsCalendarVisible(false);
                return;
            }

            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DATETIME, true);
            if (isRetrieveStep) {
                await fetchAvailability();
                history.push(
                    buildNavigationUrl(
                        OsbPathBookingRetrieveSummaryStep(),
                        osbStep.UrlQueryParams
                    )
                );
            } else {
                if (
                    osbPersonalDetail.firstName &&
                    osbPersonalDetail.lastName &&
                    osbPersonalDetail.contactNumber &&
                    osbPersonalDetail.email
                ) {
                    setRouteToPersist(
                        OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                        OsbPathBookingReviewStep(),
                        osbStep.localStorageExpiry
                    );

                    history.push(
                        buildNavigationUrl(
                            OsbPathBookingReviewStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                } else {
                    history.push(
                        buildNavigationUrl(
                            OsbPathPersonalDetailsStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                }
            }
        }
    };

    function trimSecondsFromTimeslots(selectedTimeslot: string[]) {
        return selectedTimeslot.map(str => str.slice(0, -3));
    }
    const trimSecondsFromTimesRange = (selectedTimesRange: TimeRange[]) => {
        const timeRanges: string[] = [];
        for (const range of selectedTimesRange) {
            timeRanges.push(
                range.start.slice(0, -3) + ' - ' + range.end.slice(0, -3)
            );
        }
        return timeRanges;
    };
    const trimDefaultTimeRange = (range: any) => {
        if (range?.length > 0) {
            return (
                range[0].start.slice(0, -3) + ' - ' + range[0].end.slice(0, -3)
            );
        }
        return '';
    };
    const goToDeliveryServicesStep = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        invalidateAuthentication();
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DATETIME, false);
        isRetrieveStep
            ? history.push(
                  buildNavigationUrl(
                      OsbPathBookingRetrieveSummaryStep(),
                      osbStep.UrlQueryParams
                  )
              )
            : osbServiceStep.isMobileServiceSelected
            ? history.push(
                  buildNavigationUrl(
                      OsbPathServiceLocationStep(),
                      osbStep.UrlQueryParams
                  )
              )
            : !osbDealerStep.isDealerHasDeliveryServices
            ? history.push(
                  buildNavigationUrl(
                      OsbPathServicesStep(),
                      osbStep.UrlQueryParams
                  )
              )
            : history.push(
                  buildNavigationUrl(
                      OsbPathDeliveryStep(),
                      osbStep.UrlQueryParams
                  )
              );
    };
    const fetchSelectedTime = (selectTime: string) => {
        setDefaultTime(selectTime);
        setOSBCalendarStepPayload({ selectedTime: selectTime });
        setOSBCalendarStepPayload({
            selectedPickupDate: '',
            selectedPickupTime: '',
        });
        setDisablePickupCalendarContinueButton(true);
    };

    const fetchSelectedTimeRange = (selectTimeRange: string) => {
        setDefaultTimeRange(selectTimeRange);
        setOSBCalendarStepPayload({ selectedTimeRange: selectTimeRange });
    };

    const fetchSelectedDate = (selectDate: string) => {
        setDefaultDate(selectDate);
        setOSBCalendarStepPayload({ selectedDate: selectDate });
        setIsCalendarVisible(false);
        setCalendarDateErrorMsg('');
        setOSBCalendarStepPayload({
            selectedPickupDate: '',
            selectedPickupTime: '',
        });
        setDisablePickupCalendarContinueButton(true);
    };
    const setServiceAppointmentScreen = () => {
        setShowPickupServiceCalendar(false);
    };

    const fetchSelectedTimeslots = (selectedTimeslot: string[]) => {
        setDefaultTime(
            selectedTimeslot.length > 0 ? selectedTimeslot[0].slice(0, -3) : ''
        );
        setOSBCalendarStepPayload({
            selectedTime:
                selectedTimeslot.length > 0
                    ? selectedTimeslot[0].slice(0, -3)
                    : '',
        });
        setAvailableTimeSlots(trimSecondsFromTimeslots(selectedTimeslot));
        setOSBCalendarStepPayload({
            selectedPickupDate: '',
            selectedPickupTime: '',
        });
        setDisablePickupCalendarContinueButton(true);
    };
    const fetchSelectedTimeRanges = (selectedTimeRange: TimeRange[]) => {
        setDefaultTimeRange(
            selectedTimeRange.length > 0
                ? selectedTimeRange[0].start.slice(0, -3) +
                      ' - ' +
                      selectedTimeRange[0].end.slice(0, -3)
                : ''
        );
        setOSBCalendarStepPayload({
            selectedTimeRange:
                selectedTimeRange[0].start.slice(0, -3) +
                ' - ' +
                selectedTimeRange[0].end.slice(0, -3),
        });
        setAvailableTimeRanges(trimSecondsFromTimesRange(selectedTimeRange));
    };
    const isSelectedDateAvailable = (
        selectDate: string,
        availableDates: AvailableDates[]
    ) => {
        return (
            availableDates.filter(entry => entry.date === selectDate).length > 0
        );
    };

    const isSelectedTimeAvailable = (
        selectTime: string,
        selectedDate: string,
        selectTimeRange: string,
        availableDates: AvailableDates[]
    ) => {
        const availableDts = availableDates.filter(
            entry => entry.date === selectedDate
        );
        const timeRanges = trimSecondsFromTimesRange(
            availableDts &&
                availableDts[0].availableTimeRanges &&
                availableDts[0].availableTimeRanges[0]
                ? availableDts[0].availableTimeRanges
                : []
        );
        const filterSelectedRange = timeRanges.filter(
            range => range === selectTimeRange
        );

        if (
            (availableDts &&
                availableDts[0].availableTimeSlots &&
                availableDts[0]?.availableTimeSlots?.indexOf(
                    `${selectTime}:00`
                ) > -1) ||
            (filterSelectedRange && filterSelectedRange.length > 0)
        ) {
            return true;
        }
        return false;
    };
    const setDefaultStartAndEndDate = () => {
        const newDate = dateFns.format(new Date(), 'yyyy-MM-dd').toString();
        const newMonth = new Date().getMonth() + 3;
        setCalendarStartDate(newDate);
        setCalendarEndDate(
            new Date().getFullYear() +
                '-' +
                newMonth +
                '-' +
                new Date().getDay()
        );
    };

    const getSameDayPickUp = (selectDate: string, results: any) => {
        sameDayPickUp = results?.filter(
            (entry: any) => entry.date === selectDate
        )[0].sameDayPickUp;
    };

    const initCalendar = (results: DealerCalendarDetails) => {
        setAvailableDatesWithTimeslots(results ? results.availableDates : []);
        if (results.availableDates?.length === 0) {
            setDefaultStartAndEndDate();
        } else {
            setCalendarStartDate(results ? results.calendarStart : '');
            setCalendarEndDate(results ? results.calendarEnd : '');
        }
        let selectDate = '';
        let selectTime = '';
        let selectTimeRange = '';
        if (isMobileView) {
            selectDate =
                osbCalendarStep.selectedDate.length > 0
                    ? osbCalendarStep.selectedDate
                    : '';
        } else {
            if (osbCalendarStep.selectedDate.length > 0) {
                selectDate = osbCalendarStep.selectedDate;
            }
        }
        if (osbCalendarStep.selectedTime.length > 0) {
            selectTime = osbCalendarStep.selectedTime;
        }
        if (osbCalendarStep.selectedTimeRange.length > 0) {
            selectTimeRange = osbCalendarStep.selectedTimeRange;
        }
        if (isSelectedDateAvailable(selectDate, results.availableDates)) {
            setDefaultDate(selectDate);
            if (
                isSelectedTimeAvailable(
                    selectTime,
                    selectDate,
                    selectTimeRange,
                    results.availableDates
                )
            ) {
                setOSBCalendarStepPayload({
                    selectedDate: selectDate,
                    selectedTime: selectTime,
                    selectedTimeRange: selectTimeRange,
                });
                setDefaultTime(selectTime);
                setDefaultTimeRange(selectTimeRange);
            } else {
                const filterTime =
                    results &&
                    results.availableDates &&
                    results.availableDates.filter(
                        entry => entry.date === selectDate
                    )[0];
                const filterTimeRange = trimDefaultTimeRange(
                    results.availableDates[0]?.availableTimeRanges
                );
                setOSBCalendarStepPayload({
                    selectedDate: selectDate,
                    selectedTime:
                        (filterTime &&
                            filterTime.availableTimeSlots &&
                            filterTime.availableTimeSlots[0].slice(0, 5)) ||
                        '',
                    selectedTimeRange: filterTimeRange,
                });
                setDefaultTime(
                    (filterTime &&
                        filterTime.availableTimeSlots &&
                        filterTime.availableTimeSlots[0].slice(0, 5)) ||
                        ''
                );
                setDefaultTimeRange(filterTimeRange);
            }
            getSameDayPickUp(selectDate, results.availableDates);
        } else {
            // global store is taking sometime to reflect, so setting it directly

            osbCalendarStep.selectedDate = results.availableDates[0]
                ? results.availableDates[0]?.date
                : '';
            osbCalendarStep.selectedTime =
                results &&
                results.availableDates &&
                results.availableDates[0].availableTimeSlots &&
                results.availableDates[0].availableTimeSlots[0]
                    ? results.availableDates[0]?.availableTimeSlots[0]?.slice(
                          0,
                          5
                      )
                    : '';
            setOSBCalendarStepPayload({
                selectedDate: results.availableDates[0]?.date,
                selectedTime:
                    results &&
                    results.availableDates[0] &&
                    results.availableDates[0]?.availableTimeSlots
                        ? results.availableDates[0]?.availableTimeSlots[0]?.slice(
                              0,
                              5
                          )
                        : '',
                selectedTimeRange: trimDefaultTimeRange(
                    results.availableDates[0]?.availableTimeRanges
                ),
            });
            setDefaultDate(results.availableDates[0]?.date);
            setDefaultTime(
                results &&
                    results.availableDates[0] &&
                    results.availableDates[0].availableTimeSlots
                    ? results.availableDates[0].availableTimeSlots[0].slice(
                          0,
                          5
                      )
                    : ''
            );
            setDefaultTimeRange(
                trimDefaultTimeRange(
                    results.availableDates[0]?.availableTimeRanges
                )
            );
            sameDayPickUp = results.availableDates[0]?.sameDayPickUp || '';
        }
        if (results.availableDates.length > 0) {
            setAvailableTimeSlots(
                osbCalendarStep.selectedDate.length > 0
                    ? trimSecondsFromTimeslots(
                          results.availableDates.filter(
                              entry =>
                                  entry.date === osbCalendarStep.selectedDate
                          )[0]?.availableTimeSlots || []
                      )
                    : trimSecondsFromTimeslots(
                          results &&
                              results.availableDates[0] &&
                              results.availableDates[0].availableTimeSlots
                              ? results &&
                                    results.availableDates[0] &&
                                    results.availableDates[0].availableTimeSlots
                              : []
                      )
            );
            setAvailableTimeRanges(
                osbCalendarStep.selectedDate.length > 0
                    ? trimSecondsFromTimesRange(
                          results.availableDates.filter(
                              entry =>
                                  entry.date === osbCalendarStep.selectedDate
                          )[0]?.availableTimeRanges || []
                      )
                    : trimSecondsFromTimesRange(
                          results &&
                              results.availableDates[0] &&
                              results.availableDates[0].availableTimeRanges
                              ? results &&
                                    results.availableDates[0] &&
                                    results.availableDates[0]
                                        .availableTimeRanges
                              : []
                      )
            );
        }
        dispatch({ type: 'RESPONSE' });
    };
    const processCallDealerText = (callDealerText: any) => {
        if (callDealerText !== '') {
            let cdtext = callDealerText.replace('<p>', '');
            cdtext = cdtext.replace('</p>', '');
            const callDealerArr = cdtext.split('&lt;CTD&gt;');
            if (typeof callDealerArr[1] !== 'undefined') {
                const callDealerArr1 = callDealerArr[1].split('&lt;/CTD&gt;');
                setCallDealerPrefix(callDealerArr[0]);
                setCallDealerAnchorText(callDealerArr1[0]);
                if (typeof callDealerArr1[1] !== 'undefined') {
                    setCallDealerPostfix(callDealerArr1[1]);
                }
            } else {
                setCallDealerPrefix(callDealerText);
            }
        } else {
            setCallDealerPrefix(callDealerText);
        }
    };
    const getDateTimeContent = async () => {
        await callContentService('calendar-step')
            .then(results => {
                setDateTimeContent(results);
                processCallDealerText(
                    getObjectFromAEMJson('text', results.elements)
                );
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };
    const getFormatedDate = (dateFormatAuthored: string) => {
        return osbCalendarStep.selectedDate
            ? OsbUtilService.constructDisplayDate(
                  osbCalendarStep.selectedDate,
                  dateFormatAuthored
              )
            : '';
    };

    const getCalanderComponent = (): JSX.Element => {
        return isMobileView && !isCalendarVisible ? (
            <></>
        ) : (
            <>
                <Calendar
                    calendarStart={calendarStartDate}
                    calendarEnd={calendarEndDate}
                    setSelectedDate={fetchSelectedDate}
                    setTimeslots={fetchSelectedTimeslots}
                    message={
                        !httpState.isLoading
                            ? dateTimeContent?.elements
                                  .find(
                                      e => e.name === DATE_TIME_STEP.SELECT_DATE
                                  )
                                  ?.value?.toString()
                            : ''
                    }
                    availableDates={availableDatesWithTimeslots}
                    initSelectDate={osbCalendarStep.selectedDate}
                    localeAsString={localeAsString}
                    isMobileView={isMobileView}
                    setTimeRanges={fetchSelectedTimeRanges}
                    isMobileServiceSelected={
                        osbServiceStep.isMobileServiceSelected
                    }
                />
            </>
        );
    };

    const getTimePickerComponent = (): JSX.Element => {
        return (
            <>
                <TimeslotPicker
                    data={availableTimeSlots}
                    setSelectedTime={fetchSelectedTime}
                    setSelectedTimeRange={fetchSelectedTimeRange}
                    selectedDate={defaultDate}
                    message={
                        !httpState.isLoading
                            ? dateTimeContent?.elements
                                  .find(
                                      e =>
                                          e.name ===
                                          DATE_TIME_STEP.AVAILABLE_TIMES
                                  )
                                  ?.value?.toString()
                            : ''
                    }
                    initSelectTime={defaultTime}
                    initSelectTimeRange={defaultTimeRange}
                    localeAsString={localeAsString}
                    isMobileView={isMobileView}
                    selectTimeLabel={
                        !httpState.isLoading
                            ? dateTimeContent?.elements
                                  .find(
                                      e =>
                                          e.name ===
                                          DATE_TIME_STEP.SELECT_TIME_SLOT_DROPDOWN_LABEL
                                  )
                                  ?.value?.toString()
                            : ''
                    }
                    timeRanges={availableTimeRanges}
                    hasMobileService={osbDealerStep.hasMobileService}
                    isMobileServiceSelected={
                        osbServiceStep.isMobileServiceSelected
                    }
                />
            </>
        );
    };

    const showCalendar = () => {
        setIsCalendarVisible(true);
        setCalendarDateErrorMsg('');
    };

    const triggerCallDealerCtaAnalytics = () => {
        const osbCallDealerInfo: OSBPayload = {
            dealerID: osbDealerStep.selectedDealerId,
        };
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CALL_DEALER_CTA,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                osbPayload: osbCallDealerInfo,
            },
            false
        );
    };

    const getSelectedServicePayLoad = () => {
        const serviceStepServiceIds = OsbUtilService.getServiceIDs(
            osbServiceStep.selectedServices
        );

        const deliveryStepServiceIds = OsbUtilService.getServiceIDs(
            osbLightDeliveryServiceStep.selectedServices
        );

        return [...serviceStepServiceIds, ...deliveryStepServiceIds];
    };

    const fetchCalendarResponse = async () => {
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerProfile = osbDealerStep.selectedDealerProfile;
        const bookingDetails = osbRetrieveBookingStep.retrieveBookingDetails;
        let selectedDealerCode = '';
        if (isRetrieveStep && !OsbUtilService.isEmptyObject(bookingDetails)) {
            selectedDealerCode = bookingDetails.bookings[0].dealer.dealerCode;
        }
        if (selectedDealerCode === '') {
            selectedDealerCode =
                selectedDealerProfile.dealerCode ||
                osbDealerStep.selectedDealerId ||
                '';
        }

        await ServiceHandler.OsbDealerCalendarAvailability.getCalendarInfo({
            dealerCode: selectedDealerCode,
            serviceDeliveryType:
                osbDealerStep.hasMobileService &&
                osbServiceStep.isMobileServiceSelected
                    ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                    : '',
            selectedServices: getSelectedServicePayLoad(),
            vehicleData: vehicleData,
            requestSourceType: osbStep.source,
            bookable: bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(results => {
                // resetting selectedDate and selectedTime to blank,
                // to initilize calendar and timeslot component
                setOSBCalendarStepPayload({
                    selectedDate: '',
                    selectedTime: '',
                    selectedTimeRange: '',
                });
                setOSBCalendarStepPayload({
                    dealerCalendarInfo: results.value,
                });
                if (results.value?.availableDates?.length === 0) {
                    setDefaultStartAndEndDate();
                    dispatch({ type: 'RESPONSE' });
                } else {
                    initCalendar(results.value);
                }
                if (Array.isArray(results.value?.campaignStates)) {
                    setOSBCampaignStates({
                        campaignStates: [...results.value.campaignStates],
                    });
                }
                setErrorMsgService('');
            })
            .catch((error: ErrorResponse) => {
                setErrorMsgService(osbStep.internalErrorMessage);
                if (error?.statusCode && error?.statusCode === 400) {
                    setDefaultStartAndEndDate();
                    osbCalendarStep.selectedDate = '';
                    osbCalendarStep.selectedTime = '';
                    setOSBCalendarStepPayload({
                        selectedDate: '',
                        selectedTime: '',
                        selectedTimeRange: '',
                    });
                    dispatch({ type: 'RESPONSE' });
                }

                if (!isRetrieveStep) {
                    triggerDateTimeLoadErrorAnalytics(
                        osbServiceStep.totalPriceForAnalytics || '0.00',
                        [
                            ...osbServiceStep.selectedServices,
                            ...osbLightDeliveryServiceStep.selectedServices,
                        ],
                        osbServiceStep.voucherCode,
                        osbVehicleStep,
                        osbStep,
                        fireEvents,
                        LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
                    );
                } else {
                    triggerRetrieveDateTimeAmendErrorAnalytics(
                        osbVehicleStep,
                        osbStep,
                        fireEvents,
                        LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
                    );
                }
            });
    };
    const pickupDateOrTimeslotChange = () => {
        setDisablePickupCalendarContinueButton(
            osbCalendarStep.selectedPickupDate?.length <= 0 ||
                osbCalendarStep.selectedPickupTime?.length <= 0
        );
    };
    const pickupDateChange = (pickupDate: string) => {
        setOSBCalendarStepPayload({ selectedPickupDate: pickupDate });
        pickupDateOrTimeslotChange();
    };
    const pickupTimeslotChange = (pickupTimeslot: string) => {
        setOSBCalendarStepPayload({ selectedPickupTime: pickupTimeslot });
        pickupDateOrTimeslotChange();
    };
    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        (async () => {
            await getDateTimeContent();
            await fetchCalendarResponse();
            dispatch({ type: 'RESPONSE' });
        })();
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, [isMobileView]);

    useEffect(() => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DATETIME, false, true);
        const stepDetails = osbStepProgressBar.progressBarDetails.find(
            step =>
                step.key === STEP_PROGRESS_BAR_KEYS.DATETIME && step.isEditable
        );

        if (stepDetails || osbStep.isRetrieveFlow) {
            setRouteToPersist(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                OsbPathCalenderStep(),
                osbStep.localStorageExpiry
            );
            if (!isRetrieveStep) {
                triggerDateTimeLoadAnalytics(
                    osbServiceStep.totalPriceForAnalytics || '0.00',
                    [
                        ...osbServiceStep.selectedServices,
                        ...osbLightDeliveryServiceStep.selectedServices,
                        ...osbServiceLocationDetail.selectedOtherOptions,
                    ],
                    osbServiceStep.voucherCode,
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
                );
            }

            if (isRetrieveStep) {
                triggerRetrieveAmendDateAndTImeLoadAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
                );
            }
        }
    }, []);

    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    <div className="osb-light-journey-datetime-page">
                        {!showPickupServiceCalendar ? (
                            <div className="osb-datetime-container">
                                {errorMsgService.length > 0 ? (
                                    <div className="error-message">
                                        {errorMsgService}
                                    </div>
                                ) : (
                                    ''
                                )}
                                {(osbStep.isWebViewJourney || !isMobileView) &&
                                    !isRetrieveStep && (
                                        <OSBSecondaryButton
                                            dataTestId="BackLinkToDeliveryOptionPage"
                                            type="svg"
                                            direction="left"
                                            text={
                                                dateTimeContent?.elements
                                                    .find(
                                                        e =>
                                                            e.name ===
                                                            DATE_TIME_STEP.BACK_ON_CALENDAR
                                                    )
                                                    ?.value?.toString() ?? ''
                                            }
                                            onClickHandler={
                                                goToDeliveryServicesStep
                                            }
                                        />
                                    )}

                                {!httpState.isLoading ? (
                                    <div className="datetime-title">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: isMobileView
                                                    ? dateTimeContent?.elements
                                                          .find(
                                                              e =>
                                                                  e.name ===
                                                                  DATE_TIME_STEP.MOBILE_TITLE
                                                          )
                                                          ?.value?.toString() ??
                                                      ''
                                                    : dateTimeContent?.elements
                                                          .find(
                                                              e =>
                                                                  e.name ===
                                                                  DATE_TIME_STEP.TITLE
                                                          )
                                                          ?.value?.toString() ??
                                                      '',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                <div className="osb-calendar-container-panel">
                                    <div className="calendar-component">
                                        {!isMobileView && (
                                            <>
                                                <div className="cal-main-head">
                                                    {!httpState.isLoading
                                                        ? dateTimeContent?.elements
                                                              .find(
                                                                  e =>
                                                                      e.name ===
                                                                      DATE_TIME_STEP.SELECT_DATE
                                                              )
                                                              ?.value?.toString()
                                                        : ''}
                                                </div>
                                                <div className="selected-date">
                                                    {getObjectFromAEMJson(
                                                        DATE_TIME_STEP.SELECTED_DATE_LABEL,
                                                        dateTimeContent?.elements ||
                                                            []
                                                    )}
                                                    :{' '}
                                                    {getFormatedDate(
                                                        dateTimeContent?.elements
                                                            .find(
                                                                e =>
                                                                    e.name ===
                                                                    DATE_TIME_STEP.DATE_FORMAT_CALENDAR
                                                            )
                                                            ?.value?.toString() ??
                                                            ''
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {isMobileView && (
                                            <>
                                                <label className="input-label">
                                                    {
                                                        <div>
                                                            {dateTimeContent?.elements
                                                                .find(
                                                                    e =>
                                                                        e.name ===
                                                                        DATE_TIME_STEP.PLACEHOLDER_LABEL
                                                                )
                                                                ?.value?.toString() ??
                                                                ''}
                                                        </div>
                                                    }
                                                </label>
                                                <input
                                                    id="calendarDate"
                                                    data-testid="calendarDateTestId"
                                                    type="text"
                                                    className={`input-field${
                                                        calendarDateErrorMsg
                                                            ? '-error'
                                                            : ''
                                                    } `}
                                                    placeholder={
                                                        dateTimeContent?.elements
                                                            .find(
                                                                e =>
                                                                    e.name ===
                                                                    DATE_TIME_STEP.PLACEHOLDER_LABEL
                                                            )
                                                            ?.value?.toString() ??
                                                        ''
                                                    }
                                                    aria-label="calendarDate"
                                                    aria-labelledby="calendarDate"
                                                    name="calendarDate"
                                                    value={getFormatedDate(
                                                        dateTimeContent?.elements
                                                            .find(
                                                                e =>
                                                                    e.name ===
                                                                    DATE_TIME_STEP.DATE_FORMAT_CALENDAR
                                                            )
                                                            ?.value?.toString() ??
                                                            ''
                                                    )}
                                                    onChange={() =>
                                                        showCalendar()
                                                    }
                                                    onFocus={() =>
                                                        showCalendar()
                                                    }
                                                />
                                            </>
                                        )}
                                        {calendarDateErrorMsg && (
                                            <div className="osb-error-message">
                                                {calendarDateErrorMsg}
                                            </div>
                                        )}
                                    </div>
                                    {!isMobileView && (
                                        <div className="timeslot-component">
                                            <div className="main-head">
                                                {!httpState.isLoading
                                                    ? dateTimeContent?.elements
                                                          .find(
                                                              e =>
                                                                  e.name ===
                                                                  DATE_TIME_STEP.AVAILABLE_TIMES
                                                          )
                                                          ?.value?.toString()
                                                    : ''}
                                            </div>
                                            <div className="input-label">
                                                {!httpState.isLoading
                                                    ? dateTimeContent?.elements
                                                          .find(
                                                              e =>
                                                                  e.name ===
                                                                  DATE_TIME_STEP.SELECT_TIME_SLOT_DROPDOWN_LABEL
                                                          )
                                                          ?.value?.toString()
                                                    : ''}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="cal-slot-section">
                                    <div>{getCalanderComponent()}</div>
                                    <div className="timeslot-component">
                                        {getTimePickerComponent()}
                                    </div>
                                </div>

                                <div className="button-container">
                                    {
                                        <div className="continue-button">
                                            <PrimaryButton
                                                dataTestId="DateAndTimePageContinueClick"
                                                role="link"
                                                color={'dark'}
                                                fill={'fill'}
                                                chevron={false}
                                                onClick={
                                                    goToPersonalDetailsStep
                                                }
                                            >
                                                {dateTimeContent?.elements
                                                    .find(
                                                        e =>
                                                            e.name ===
                                                            DATE_TIME_STEP.CONTINUE_ON_CALENDAR
                                                    )
                                                    ?.value?.toString() ?? ''}
                                            </PrimaryButton>
                                        </div>
                                    }
                                </div>
                                {!isMobileView && (
                                    <div
                                        className="footer-text"
                                        data-testid="same-day-pickup-container"
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    dateTimeContent?.elements
                                                        .find(
                                                            e =>
                                                                e.name ===
                                                                DATE_TIME_STEP.DROP_OFF_TITLE
                                                        )
                                                        ?.value?.toString()
                                                        .replace(
                                                            '####',
                                                            sameDayPickUp.slice(
                                                                0,
                                                                -3
                                                            )
                                                        ) ?? '',
                                            }}
                                        />
                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone
                                            ? callDealerPrefix
                                            : ''}
                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone ? (
                                            <Popup
                                                trigger={
                                                    <span className="call-dealer-link">
                                                        {callDealerAnchorText}
                                                    </span>
                                                }
                                                onOpen={
                                                    triggerCallDealerCtaAnalytics
                                                }
                                                open={open}
                                                className="dealer-call-cta-popup"
                                                position="right center"
                                                closeOnDocumentClick={true}
                                                modal={false}
                                                onClose={closeModal}
                                            >
                                                {
                                                    osbDealerStep
                                                        .selectedDealerProfile
                                                        .primaryPhone
                                                }
                                            </Popup>
                                        ) : (
                                            ''
                                        )}

                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone
                                            ? parse(callDealerPostfix)
                                            : ''}
                                    </div>
                                )}
                                {isMobileView && (
                                    <div className="footer-text">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    dateTimeContent?.elements
                                                        .find(
                                                            e =>
                                                                e.name ===
                                                                DATE_TIME_STEP.DROP_OFF_TITLE
                                                        )
                                                        ?.value?.toString()
                                                        .replace(
                                                            '####',
                                                            sameDayPickUp.slice(
                                                                0,
                                                                -3
                                                            )
                                                        ) ?? '',
                                            }}
                                        />
                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone
                                            ? callDealerPrefix
                                            : ''}
                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone ? (
                                            <a
                                                className="call-dealer-link"
                                                href={`tel:+${formatMobileNumber(
                                                    osbDealerStep
                                                        .selectedDealerProfile
                                                        .primaryPhone
                                                )}`}
                                                onClick={
                                                    triggerCallDealerCtaAnalytics
                                                }
                                                title={
                                                    osbDealerStep
                                                        .selectedDealerProfile
                                                        .primaryPhone
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {callDealerAnchorText}
                                            </a>
                                        ) : (
                                            ''
                                        )}
                                        {osbDealerStep.selectedDealerProfile
                                            .primaryPhone
                                            ? parse(callDealerPostfix)
                                            : ''}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="osb-pickup-service-calendar-container">
                                <LightPickupCalendar
                                    dateTimeContent={dateTimeContent?.elements}
                                    calendarStartDate={calendarStartDate}
                                    calendarEndDate={calendarEndDate}
                                    availableDatesWithTimeslots={
                                        availableDatesWithTimeslots
                                    }
                                    editTrigger={setServiceAppointmentScreen}
                                    selectedPickupDateValue={pickupDateChange}
                                    selectedPickupTimeslotValue={
                                        pickupTimeslotChange
                                    }
                                />

                                <div className="pickup-calendar-continue-button">
                                    <PrimaryButton
                                        role="link"
                                        color={'dark'}
                                        fill={'fill'}
                                        chevron={false}
                                        onClick={goToPersonalDetailsStep}
                                        disabled={
                                            disablePickupCalendarContinueButton
                                        }
                                    >
                                        {dateTimeContent?.elements
                                            .find(
                                                e =>
                                                    e.name ===
                                                    DATE_TIME_STEP.CONTINUE_ON_CALENDAR
                                            )
                                            ?.value?.toString() ?? ''}
                                    </PrimaryButton>
                                </div>
                            </div>
                        )}

                        {!isMobileView && (
                            <div className="osb-light-journey-booking-summary">
                                <LightBookingSummary
                                    stepName={STEP_PROGRESS_BAR_KEYS.DATETIME}
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        )}
                    </div>

                    {isMobileView && (
                        <div className="osb-mobile-booking-summary">
                            <div className="view-summary-accordion">
                                <BookingSummaryAccordion
                                    index="0"
                                    className=""
                                    expandMultiplePanels={true}
                                    stepName={STEP_PROGRESS_BAR_KEYS.DATETIME}
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
