/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { VehicleStep } from '../../models/osb-model/osb-vehicle-step';
import {
    StoreState,
    VehicleStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { VehicleRecallInfo } from '../../models/osb-model/vehicle-recall-info';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { ConnectedVehicleResponse } from '../../models/connected-vehicle';
import { OsbVhaAlertResponse } from '../../services/vha-service/vha-service.interface';
import { PrognosticsDataV2 } from '../../models/vehicle-prognostics';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { useOSBStep } from './use-osb-step';
import { VehicleInfo } from '../../models/osb-model/osb-vehicle-lookup';

export const getInitialVehicleDetails = (): VehicleInfo => {
    return {
        bodyStyle: '',
        modelName: '',
        defaultModel: false,
        engine: '',
        engineFuelDescription: '',
        transmission: '',
        fuelType: '',
        interiorEnvironment: '',
        marketCode: '',
        mileageInKm: '',
        mileageInMiles: '',
        modelID: '',
        modelNameWithoutYear: '',
        transmissionType: '',
        vehicleLineCode: '',
        vehicleType: '',
        version: '',
        color: '',
        registrationNumber: '',
        vin: '',
        buildDate: '',
        vehicleImageURL: '',
    };
};
export const getInitialVehicleRecallService = (): VehicleRecallInfo => {
    return {
        applicationName: '',
        country: '',
        fsa: [{ description: '', fsaNumber: '', launchDate: '' }],
        recalls: [{ description: '', fsaNumber: '', launchDate: '' }],
        recallsCount: 0,
        totalFsaCount: 0,
        vehicleDetails: { make: '', model: '', vin: '', year: 1900 },
    };
};
export const getInitialVehicleAttributes = (): VehicleAttributes => {
    return {
        year: 2001,
        model: '',
        make: '',
        nickName: '',
        registrationNo: '',
        vin: '',
        uomSpeed: '',
    };
};

export const getInitialVHAService = (): OsbVhaAlertResponse | undefined => {
    return {
        activeAlerts: [
            {
                body: {
                    whatIsHappening: '',
                    whatShouldIdo: '',
                },
                contentType: '',
                headline: '',
            },
        ],
    };
};

export const getInitialOilLifeDetails = (): PrognosticsDataV2 | undefined => {
    return {
        featureType: '',
        confidenceLevel: 0,
        shouldShow: false,
        urgency: '',
        messageDesc: '',
        eventTime: '',
        featureData: {
            iolm: 0,
            remainingMiles: 0,
            remainingKMs: 0,
            estimatedDate: {
                day: 0,
                month: 0,
                year: 0,
            },
        },
        dtsMessage: {
            para1: '',
            para2: '',
            para3: '',
        },
    };
};
export const getInitialPrognosticsDetails = (): PrognosticsDataV2 => {
    return {
        featureType: '',
        confidenceLevel: 0,
        shouldShow: false,
        urgency: '',
        messageDesc: '',
        eventTime: '',
        featureData: {
            iolm: 0,
            remainingMiles: 0,
            remainingKMs: 0,
            nextIntervalMiles: 0,
            nextIntervalKMs: 0,
            estimatedDate: {
                day: 0,
                month: 0,
                year: 0,
            },
        },
        dtsMessage: {
            para1: '',
            para2: '',
            para3: '',
        },
    };
};
export const getInitialVehicleState = (): VehicleStep => {
    return {
        vehicleDetails: getInitialVehicleDetails(),
        vinRegNo: '',
        vehicleLabel: '',
        vinMileage: '',
        model: '',
        modelYear: '',
        manualMileage: '',
        vehicleStepFlow: '',
        vehicleRecallService: getInitialVehicleRecallService(),
        vehicleAttributes: getInitialVehicleAttributes(),
        connectedVehicleResponse: '',
        VhaResponse: getInitialVHAService(),
        oilLifeData: getInitialOilLifeDetails(),
        vehiclePrognosticData: getInitialPrognosticsDetails(),
        defaultModelName: '',
        defaultBuildYear: '',
        defaultMileage: '',
        vinRegDataPattern: '',
        allowSpecialCharacters: false,
    };
};
//Configure and initialize vehicle step state in global store
const configureVehicleStepStore = () => {
    const actions = {
        //Action to set the vehicle step details to global store
        SET_VEHICLE_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as VehicleStepState).vehicleStep,
                ...(payload as VehicleStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_VEHICLE_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { vehicleStep: newState };
        },

        SET_VEHICLE_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as VehicleStepState).vehicleStep,
                ...payload,
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_VEHICLE_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { vehicleStep: newState };
        },
    };
    initializeStore(actions, {
        vehicleStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_VEHICLE_STEP_KEY
            ) || getInitialVehicleState(),
    });
};

configureVehicleStepStore();

// custom hook for vehicle step to handle the actions and updating payload in global store
export const useVehicleStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalVehicleStep = (
        vehicleStep: VehicleStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_VEHICLE_STEP',
            vehicleStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setGlobalVehicleStepPayload = (
        payload: {
            [key: string]:
                | string
                | VehicleInfo
                | VehicleRecallInfo
                | ConnectedVehicleResponse
                | VehicleAttributes
                | OsbVhaAlertResponse
                | PrognosticsDataV2
                | {}
                | [];
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_VEHICLE_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetVehicleStep = (args?: {
        shouldReload?: boolean;
        clearAll?: boolean;
    }) => {
        const vehicleStepData = getInitialVehicleState();
        if (!(args?.clearAll || false)) {
            const currentVehicleStepData = (globalState as VehicleStepState)
                .vehicleStep;
            vehicleStepData.defaultModelName =
                currentVehicleStepData.defaultModelName;
            vehicleStepData.defaultMileage =
                currentVehicleStepData.defaultMileage;
            vehicleStepData.defaultBuildYear =
                currentVehicleStepData.defaultBuildYear;
            vehicleStepData.vinRegDataPattern =
                currentVehicleStepData.vinRegDataPattern;
            vehicleStepData.allowSpecialCharacters =
                currentVehicleStepData.allowSpecialCharacters;
        }

        dispatch(
            'SET_VEHICLE_STEP',
            vehicleStepData,
            args?.shouldReload || true,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbVehicleStep: (globalState as VehicleStepState).vehicleStep,
        setOSBVehicleStep: setGlobalVehicleStep,
        setOSBVehicleStepPayload: setGlobalVehicleStepPayload,
        resetVehicleStep: resetVehicleStep,
        callContentService: callContentService,
    };
};
