import React, { ReactNode, useContext } from 'react';

import seoService, {
    SeoConfig,
} from '../../../services/seo-service/seo-service';
import { SearchBar, Breadcrumbs, ViewBanner } from '..';
import { PageType } from './page-type';

import './view-template.scss';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { FeatureLinksList } from '../vehicle-portal/components/feature-links-list/feature-links-list';
import ServerContext from '../../../contexts/serverContext';
import { useSearchContent } from '../search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../../../views/page-not-found-view/hooks/use-help-cards-content';
import VehicleNotifications from '../vehicle-notifications/vehicle-notifications';
import BrowseByTopic from '../../../support/components/browse-by-topic/browse-by-topic';
import { ASTUTE } from '../../../support/support-constants';
import { useBbtContent } from '../../../support/components/browse-by-topic/hook/use-bbt-content';
import DisclosuresLinkSyndicationAware from '../disclosures-link/disclosures-link-syndication-aware';
import { PreImagineEscapeHatch } from '../pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../constants';
interface Props {
    page: PageType;
    seoConfig?: SeoConfig;
    children?: ReactNode;
    ctaTopComponent?: ReactNode;
    ctaBottomComponent?: ReactNode;
    backgroundImageDesktop?: string;
    backgroundImageMobile?: string;
    breadcrumb?: string;
    hideSearchBar?: boolean;
    className?: string;
    vehicleAttributes?: VehicleAttributes;
    topMasthead?: ReactNode;
    carousel?: ReactNode;
    displayBbtCategories?: boolean;
    hideLinksList?: boolean;
    showDisclosureContent?: boolean;
}
const PAGE_DASHBOARD = 'DASHBOARD';

export const ViewTemplate = (props: Props) => {
    const serverContext = useContext(ServerContext);
    const searchContent = useSearchContent(props.vehicleAttributes);
    const helpCardsContent = useHelpCardsContent(props.vehicleAttributes);
    const bbtContent = useBbtContent();
    props.seoConfig && seoService.set(props.seoConfig, serverContext);
    const isLanding = props.page === 'Landing';
    const propClass = props.className ? props.className : '';
    const noBreadcrumb = props.breadcrumb ? '' : 'no-breadcrumb';
    const pages: PageType[] = ['VIN', 'YMM', 'DASHBOARD'];
    const { showDisclosureContent = true } = props;
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion =
        appConfig.brand === BRAND.lincoln.LONG_NAME ||
        !pages.includes(props.page);
    return (
        <div
            className={['shared-view', noBreadcrumb, propClass]
                .join(' ')
                .trim()}
            style={currentVersion ? {} : { backgroundColor: 'white' }}
        >
            {props.breadcrumb && <Breadcrumbs currentPage={props.breadcrumb} />}
            {pages.indexOf(props.page) >= 0 && props.topMasthead}
            <div id="Hero">
                {!isLanding && props.page === PAGE_DASHBOARD && (
                    <VehicleNotifications
                        vehicleAttributes={props.vehicleAttributes}
                    />
                )}
                <ViewBanner
                    topMasthead={
                        pages.indexOf(props.page) === -1 && props.topMasthead
                    }
                    topComponent={props.ctaTopComponent}
                    bottomComponent={props.ctaBottomComponent}
                    backgroundImageDesktop={props.backgroundImageDesktop || ''}
                    backgroundImageMobile={props.backgroundImageMobile || ''}
                />
            </div>
            {isLanding ? (
                <>
                    {props.carousel}
                    {props.children}
                </>
            ) : (
                <>
                    {props.children}
                    {props.carousel}
                </>
            )}
            {props.displayBbtCategories &&
                ASTUTE.SUPPORTED_MARKETS.includes(
                    serverContext.currentLanguageRegionCode
                ) && <BrowseByTopic bbtContent={bbtContent} />}
            {!props.hideLinksList && (
                <FeatureLinksList vehicleAttributes={props.vehicleAttributes} />
            )}

            {!props.hideSearchBar &&
                searchContent &&
                helpCardsContent &&
                (currentVersion ? (
                    <SearchBar
                        vehicleAttributes={props.vehicleAttributes}
                        searchContent={searchContent}
                        helpCardsContent={helpCardsContent}
                    />
                ) : (
                    <PreImagineEscapeHatch
                        searchContent={searchContent}
                        helpCardsContent={helpCardsContent}
                    />
                ))}
            {showDisclosureContent && <DisclosuresLinkSyndicationAware />}
        </div>
    );
};
