import React, { ReactNode, useContext, useEffect } from 'react';
import { BetaWrapper } from '../../utils/beta-wrapper/beta-wrapper';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { useAppInformationContent } from './hook/use-breadcrumb-content';
import './breadcrumbs.scss';
import { SYNDICATED_HEADER_COUNTRIES } from '../../../constants';
import ServerContext from '../../../contexts/serverContext';
import { Link } from 'react-router-dom';

interface Props {
    currentPage: string;
    breadcrumbName?: string;
    children?: ReactNode;
    hashId?: string;
    hideFirstBreadcrumb?: boolean;
    type?: string;
    onClick?: (e: React.MouseEvent) => void;
    crumbSeparator?: '/' | '>';
}

export const FirstBreadcrumbText = (props: Props, breadcrumbStart?: string) => {
    if (props.hashId === '#') {
        return 'support';
    } else {
        return props.breadcrumbName ? props.breadcrumbName : breadcrumbStart;
    }
};

export const Breadcrumbs = (props: Props) => {
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const isLandingPage = props.currentPage === 'Landing';
    const { currentRegionCode } = new AppConfigurationService();
    const isSyndicateHeader = SYNDICATED_HEADER_COUNTRIES.includes(
        currentRegionCode
    );
    const breadcrumbStart =
        appInformationContent?.breadcrumbStart ||
        appInformationContent?.applicationName;
    const firstBreadcrumbText = FirstBreadcrumbText(props, breadcrumbStart);
    const HomepageBreadcrumb = () => {
        if (appInformationContent) {
            return <BetaWrapper text={breadcrumbStart || ''} />;
        } else {
            return <></>;
        }
    };
    const DynamicBreadcrumbs = () => {
        const url =
            props.hashId && props.hashId !== 'support'
                ? `/${props.hashId}`
                : '/';
        const crumbSeparator = props.crumbSeparator || '>';
        return appInformationContent && firstBreadcrumbText ? (
            <>
                {!props.hideFirstBreadcrumb && (
                    <>
                        <Link
                            onClick={(e: React.MouseEvent) =>
                                props.onClick && props.onClick(e)
                            }
                            to={url}
                            className="breadcrumb-link"
                        >
                            <BetaWrapper text={firstBreadcrumbText} />
                        </Link>
                        <span className="back-slash">{crumbSeparator}</span>
                    </>
                )}
                {props.children}
                <span className="current-page" aria-current={true} tabIndex={0}>
                    {props.currentPage}
                </span>
            </>
        ) : (
            <></>
        );
    };

    useEffect(() => {
        const resizeHandler = () => {
            const navbarClass =
                process.env.NODE_ENV === 'production'
                    ? 'navbar-static-top'
                    : 'navigation-bar';
            const headerClass =
                process.env.NODE_ENV === 'production'
                    ? 'global-owner-syndicated-lincoln-header'
                    : 'lincoln-na-header';
            let navbar = document.getElementsByClassName(
                navbarClass
            )[0] as HTMLElement;

            const resize = (intervalId?: NodeJS.Timeout) => {
                intervalId && clearInterval(intervalId);
                const enclosingHeader = document.getElementsByClassName(
                    headerClass
                )[0] as HTMLElement;
                enclosingHeader.style.height = `${navbar.offsetHeight}px`;
            };

            if (!navbar) {
                const intervalId = setInterval(() => {
                    navbar = document.getElementsByClassName(
                        navbarClass
                    )[0] as HTMLElement;
                    if (navbar) {
                        resize(intervalId);
                    }
                }, 100);
            } else resize();
        };
        if (serverContext.brand === 'lincoln') {
            window.addEventListener('resize', resizeHandler);
            resizeHandler();
        }
    }, []);

    return (
        <div
            className={`breadcrumbs ${
                isSyndicateHeader ? 'syndicate-header' : ''
            } ${props.type === 'common' ? 'breadcrumbs-common' : ''}`}
        >
            <div className="breadcrumbs-content">
                {isLandingPage ? (
                    <HomepageBreadcrumb />
                ) : (
                    <DynamicBreadcrumbs />
                )}
            </div>
        </div>
    );
};
