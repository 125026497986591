import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../common';
import './schedule-report.scss';
import useUserProfile from '../../../../hooks/use-user-profile';
import { ToggleSwitch } from '../../../common/toggle-switch/toggle-switch';
import Dropdown from '../../../common/dropdown/dropdown';
import successIcon from '../../../../assets/check-mark.svg';
import errorWarningIcon from '../../../../assets/error-warning.svg';
import VHRSheduleService, {
    VHRScheduleResponse,
    VHRUpdateResponse,
} from '../../../../services/vhr-service/vhr-schedule';
import { Modal } from '../../../common/modal/modal';
import { ScheduleReportContentProps } from '../../../../models/service-maintenance-models/vehicle-health-alerts';
import { useContentFragment } from '../../../utils/aem-content-utils/use-content-fragment';
interface Props {
    vinNumber: string;
    scheduleReportLabel: string;
    aem_category?: string;
    aem_name?: string;
}

interface ModalContentProps {
    scheduleReportHeading: string;
    scheduleReportSubheading: string;
    scheduleReportContentOne: string;
    scheduleReportContentTwo: string;
    handleKeyPressEmail: (e: any) => void;
    scheduleReportEmailLabel: string;
    emailAdditionalInfoContent: string;
    emailAdditionalInfoHeader: string;
    handleTooltipKeyPress: (e: any) => void;
    toggleLabel: string;
    toggleOnLabel: string;
    toggleOffLabel: string;
    vhrFrequencyToggle: boolean;
    frequencyToggleChange: (e: any) => void;
    profile: any;
    frequencyOptionsMap: Map<string, string>;
    frequencyLabel: string;
    frequencyDropdownChange: (index: number) => void;
    vhrFrequencyDropdown: string;
    getOptionValue: (frequency: string) => string | undefined;
    saveErrorMessage: string;
    showVHRSaveMessage: boolean;
    saveSuccessfulMessage: string;
    vhrUpdateError: boolean;
}

const ModalContent = (props: ModalContentProps) => {
    return (
        <>
            <div className="modal-heading">{props.scheduleReportHeading}</div>

            <div className="modal-subheading">
                {props.scheduleReportSubheading}
            </div>
            <div className="schedule-report-message">
                <span />
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.scheduleReportContentOne,
                    }}
                />
            </div>
            <div className="schedule-report-offers">
                <span />
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.scheduleReportContentTwo,
                    }}
                />
            </div>
            <hr className="schedule-report-divider" />
            <div className="schedule-report-email-wrapper">
                <div
                    className="schedule-report-email-label"
                    onClick={e => props.handleKeyPressEmail(e)}
                    onKeyUp={e => props.handleKeyPressEmail(e)}
                >
                    <span className={'email-info-txt'}>
                        {props.scheduleReportEmailLabel}
                    </span>
                    {props.emailAdditionalInfoContent && (
                        <img
                            className="email-info-icon"
                            role="button"
                            tabIndex={0}
                            alt={props.scheduleReportEmailLabel + ' tooltip'}
                            aria-label={
                                'OPEN ' +
                                props.scheduleReportEmailLabel +
                                ' TOOLTIP'
                            }
                        />
                    )}
                </div>
                <p className="schedule-report-email">
                    {props.profile?.profile.email}
                </p>
                <div
                    className={'schedule-report-email-label-text'}
                    aria-modal="true"
                    role="dialog"
                >
                    <img
                        className="popover-close"
                        data-testid="popover-close"
                        alt=""
                        aria-label="close tooltip"
                        onClick={e => props.handleTooltipKeyPress(e)}
                        onKeyDown={e => props.handleTooltipKeyPress(e)}
                    />
                    <h2 className={'popover-heading'}>
                        {props.emailAdditionalInfoHeader}
                    </h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.emailAdditionalInfoContent,
                        }}
                    />
                </div>
            </div>
            <hr className="schedule-report-divider" />
            <div className="schedule-report-toggle">
                <div>
                    <label>{props.toggleLabel}</label>
                </div>
                <ToggleSwitch
                    name="vhr-schedule-frequency"
                    className="vhr-schedule-frequency"
                    onLabel={props.toggleOnLabel}
                    offLabel={props.toggleOffLabel}
                    ariaLabel={`${props.toggleLabel} toggle`}
                    checked={props.vhrFrequencyToggle}
                    onChange={props.frequencyToggleChange}
                />
            </div>
            {props.vhrFrequencyToggle && (
                <>
                    <hr className="schedule-report-divider" />
                    <div className="schedule-report-frequency">
                        <div>
                            <Dropdown
                                options={Array.from(
                                    props.frequencyOptionsMap.values()
                                )}
                                label={props.frequencyLabel}
                                showLabel={true}
                                onChange={(label: string, index: number) =>
                                    props.frequencyDropdownChange(index)
                                }
                                value={props.getOptionValue(
                                    props.vhrFrequencyDropdown
                                )}
                                className="select-vehicle-dropdown"
                            />
                        </div>
                    </div>
                </>
            )}
            {props.saveErrorMessage &&
                props.showVHRSaveMessage &&
                props.saveSuccessfulMessage && (
                    <div
                        className={`vhr-save-messages-container ${
                            props.vhrUpdateError ? 'error-hr-line' : ''
                        }`}
                    >
                        <img
                            className="vhr-save-message-icon"
                            src={
                                props.vhrUpdateError
                                    ? errorWarningIcon
                                    : successIcon
                            }
                        />
                        <span
                            className={props.vhrUpdateError ? 'error-text' : ''}
                        >
                            {props.vhrUpdateError
                                ? props.saveErrorMessage
                                : props.saveSuccessfulMessage}
                        </span>
                    </div>
                )}
        </>
    );
};

export const ScheduleReport = (props: Props) => {
    const POPOVER_CLOSE_SELECTOR =
        '.schedule-report-email-label-text .popover-close';
    const MODAL_CLOSE_SELECTOR = '.modal-header .modal-close';
    const EMAIL_TOOLTIP_ICON_SELECTOR =
        '.schedule-report-email-label .email-info-icon';
    const EMAIL_POPOVER = '.schedule-report-email-label-text';
    const MODAL_TOGGLE_SELECTOR = '.vhr-schedule-frequency div.slider';
    const MODAL_DROPDOWN_SELECTOR =
        '.select-vehicle-dropdown button.dropdown-button';
    const MODAL_SAVE_BUTTON_SELECTOR = '.modal-footer .primary-button';
    const scheduleReport: ScheduleReportContentProps = useContentFragment(
        props.aem_category,
        props.aem_name
    );
    const {
        scheduleReportHeading,
        scheduleReportSubheading,
        scheduleReportContentOne,
        scheduleReportContentTwo,
        scheduleReportEmailLabel,
        toggleLabel,
        toggleOnLabel,
        toggleOffLabel,
        frequencySemiAnnually,
        frequencyAnnually,
        frequencyMonthly,
        frequencyQuarterly,
        frequencyLabel,
        emailAdditionalInfoHeader,
        emailAdditionalInfoContent,
        saveButtonLabel,
        saveSuccessfulMessage,
        saveErrorMessage,
    } = scheduleReport;
    const frequencyOptionsMap = new Map([
        ['M', frequencyMonthly],
        ['Q', frequencyQuarterly],
        ['S', frequencySemiAnnually],
        ['Y', frequencyAnnually],
    ]);
    const profile = useUserProfile();
    const vhrScheduleService = new VHRSheduleService();
    const [vhrFrequencyToggle, setVhrFrequencyToggle] = useState<any>(false);
    const [vhrFrequencyDropdown, setVhrFrequencyDropdown] = useState<any>();
    const [showScheduleReportsModal, setShowScheduleReportsModal] = useState<
        boolean
    >(false);

    const [vhrUpdateError, setVhrUpdateError] = useState<boolean>(false);
    const [showVHRSaveMessage, setShowVHRSaveMessage] = useState<boolean>(
        false
    );
    const [disablePrimaryModalButton, setDisabledPrimaryModalButton] = useState<
        boolean
    >(true);
    const [
        vhrScheduleGetResponse,
        setVhrScheduleGetResponse,
    ] = useState<VHRScheduleResponse | null>();

    const isNonEmptyArray = (element: any) =>
        Array.isArray(element) && element.length > 0;

    const getVhrSchedule = () => {
        vhrScheduleService
            .getVhrSchedule(props.vinNumber)
            .then((vhrResponse: VHRScheduleResponse) => {
                if (isNonEmptyArray(vhrResponse?.VinResponseList)) {
                    setVhrScheduleGetResponse(vhrResponse);
                    vhrResponse.VinResponseList.forEach(data => {
                        if (data.Vin === props.vinNumber) {
                            setVhrFrequencyToggle(!!data.Frequency);
                            setVhrFrequencyDropdown(data?.Frequency);
                        }
                    });
                } else {
                    setVhrScheduleGetResponse(null);
                }
            });
    };

    useEffect(() => {
        if (props.vinNumber) {
            getVhrSchedule();
        }
    }, []);

    const setFocusToElement = (selector: string, time: number) => {
        setTimeout(() => {
            const selectedElement = document.querySelector(
                selector
            ) as HTMLElement;
            selectedElement?.focus();
        }, time);
    };

    const handleVhrUpdateError = () => {
        setVhrUpdateError(true);
        setShowVHRSaveMessage(true);
        setDisabledPrimaryModalButton(false);
        setFocusToElement(MODAL_SAVE_BUTTON_SELECTOR, 500);
    };

    const handleVhrUpdateSuccess = (vhrResponse: VHRUpdateResponse) => {
        if (!vhrResponse || vhrResponse?.status !== 200) {
            setVhrUpdateError(true);
        } else {
            setVhrUpdateError(false);
        }
        setShowVHRSaveMessage(true);
        setDisabledPrimaryModalButton(true);
        setFocusToElement(MODAL_CLOSE_SELECTOR, 200);
        getVhrSchedule();
    };

    const updateVhrSchedule = (frequency: string) => {
        vhrScheduleService
            .updateVhrSchedule(frequency, props.vinNumber)
            .then((vhrResponse: VHRUpdateResponse) => {
                handleVhrUpdateSuccess(vhrResponse);
            })
            .catch(() => {
                handleVhrUpdateError();
            });
    };

    const deleteVhrSchedule = () => {
        vhrScheduleService
            .deleteVhrSchedule(props.vinNumber)
            .then((vhrResponse: VHRUpdateResponse) => {
                handleVhrUpdateSuccess(vhrResponse);
            })
            .catch(() => {
                handleVhrUpdateError();
            });
    };
    const setVhrSchedule = () => {
        if (vhrFrequencyToggle) {
            updateVhrSchedule(vhrFrequencyDropdown);
        } else {
            deleteVhrSchedule();
        }
    };

    const getOptionValue = (frequency: string) => {
        if (frequencyOptionsMap.get(frequency))
            return frequencyOptionsMap.get(frequency);
        return Array.from(frequencyOptionsMap.values())[0];
    };

    const getFrequencyFromVhrResponse = () => {
        let frequency = null;
        if (isNonEmptyArray(vhrScheduleGetResponse?.VinResponseList)) {
            vhrScheduleGetResponse?.VinResponseList.map(data => {
                if (data.Vin === props.vinNumber) {
                    frequency = data?.Frequency;
                }
            });
        }
        return frequency;
    };

    const checkSaveButtonState = () => {
        let disabledButton = false;
        const frequency = getFrequencyFromVhrResponse();
        if (vhrFrequencyToggle === !!frequency) {
            if (
                vhrFrequencyToggle === false ||
                vhrFrequencyDropdown === frequency
            ) {
                disabledButton = true;
            }
        }
        setDisabledPrimaryModalButton(disabledButton);
    };
    useEffect(() => {
        checkSaveButtonState();
    }, [vhrFrequencyDropdown, vhrFrequencyToggle]);

    const frequencyDropdownChange = (index: number) => {
        setShowVHRSaveMessage(false);
        setVhrFrequencyDropdown(Array.from(frequencyOptionsMap.keys())[index]);
        setFocusToElement(MODAL_DROPDOWN_SELECTOR, 100);
    };

    const setFocusOnToggle = () => {
        setTimeout(() => {
            const firstElement = document.querySelector(
                MODAL_TOGGLE_SELECTOR
            ) as HTMLElement;
            firstElement.setAttribute('tabIndex', '0');
            firstElement.focus();
        }, 200);
    };

    const frequencyToggleChange = (e: any) => {
        e.stopPropagation();
        setShowVHRSaveMessage(false);
        setVhrFrequencyToggle(!vhrFrequencyToggle);
        setVhrFrequencyDropdown(Array.from(frequencyOptionsMap.keys())[0]);
        setFocusOnToggle();
    };

    const setPopoverTabIndex = (isSet: boolean) => {
        const closeElement = document.querySelector(
            POPOVER_CLOSE_SELECTOR
        ) as HTMLElement;
        closeElement?.setAttribute('tabIndex', isSet ? '0' : '-1');
    };

    const emailShowPopup = (isShow: boolean) => {
        const popover = document.querySelector(EMAIL_POPOVER) as HTMLElement;
        if (popover) {
            if (isShow) {
                setPopoverTabIndex(true);
                popover.style.display = 'block';
            } else {
                setPopoverTabIndex(false);
                popover.style.display = 'none';
            }
        }
    };

    const handleTooltipKeyPress = (e: any) => {
        if (e.keyCode === 13 || (e.keyCode !== 9 && e.keyCode !== 16)) {
            emailShowPopup(false);
            setFocusToElement(EMAIL_TOOLTIP_ICON_SELECTOR, 200);
        }
    };

    const handleKeyPressEmail = (e: any) => {
        if (e.keyCode === 13 || (e.keyCode !== 9 && e.keyCode !== 16)) {
            emailShowPopup(true);
            setFocusToElement(POPOVER_CLOSE_SELECTOR, 100);
        }
    };

    const closeScheduleReportsModal = () => {
        getVhrSchedule();
        setShowScheduleReportsModal(false);
        setShowVHRSaveMessage(false);
    };

    const renderModal = () => {
        return (
            <Modal
                name={'schedule-report-modal'}
                role="dialog"
                aria-label=""
                onClose={closeScheduleReportsModal}
                primaryBtnLabel={saveButtonLabel}
                onPrimaryBtnClick={setVhrSchedule}
                isVisible={showScheduleReportsModal}
                disablePrimaryButton={disablePrimaryModalButton}
                preventClose={false}
            >
                <ModalContent
                    scheduleReportHeading={scheduleReportHeading}
                    scheduleReportSubheading={scheduleReportSubheading}
                    scheduleReportContentOne={scheduleReportContentOne}
                    scheduleReportContentTwo={scheduleReportContentTwo}
                    handleKeyPressEmail={handleKeyPressEmail}
                    scheduleReportEmailLabel={scheduleReportEmailLabel}
                    emailAdditionalInfoContent={emailAdditionalInfoContent}
                    emailAdditionalInfoHeader={emailAdditionalInfoHeader}
                    handleTooltipKeyPress={handleTooltipKeyPress}
                    toggleLabel={toggleLabel}
                    toggleOnLabel={toggleOnLabel}
                    toggleOffLabel={toggleOffLabel}
                    vhrFrequencyToggle={vhrFrequencyToggle}
                    frequencyToggleChange={frequencyToggleChange}
                    profile={profile}
                    frequencyOptionsMap={frequencyOptionsMap}
                    frequencyLabel={frequencyLabel}
                    frequencyDropdownChange={frequencyDropdownChange}
                    vhrFrequencyDropdown={vhrFrequencyDropdown}
                    getOptionValue={getOptionValue}
                    saveErrorMessage={saveErrorMessage}
                    showVHRSaveMessage={showVHRSaveMessage}
                    saveSuccessfulMessage={saveSuccessfulMessage}
                    vhrUpdateError={vhrUpdateError}
                />
            </Modal>
        );
    };
    return (
        <div className="vhr-schedule-report-cta">
            <div className="schedule-report-btn">
                <PrimaryButton
                    color={'light'}
                    fill={'fill'}
                    chevron={false}
                    className={' no-shadow-transparent vhr-schedule-report'}
                    onClick={() => setShowScheduleReportsModal(true)}
                >
                    <div className="vhr-schedule-report-button">
                        <img className="vhr-schedule-report-icon" alt="" />
                        {props.scheduleReportLabel}
                    </div>
                </PrimaryButton>
            </div>
            {showScheduleReportsModal && renderModal()}
        </div>
    );
};
